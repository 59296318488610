import React from 'react';
import {
   Text, View, ScrollView, TouchableOpacity,
  } from 'react-native';
  import Grid from '@material-ui/core/Grid';
  import { Link } from 'react-router-dom';
  import {Helmet} from 'react-helmet';
  import Button from '@material-ui/core/Button';
  import Hidden from '@material-ui/core/Hidden';
  import ModalSelector from './../../../components/ModalSelector/ModalSelector'
  import CryptoJS from 'crypto-js';
  import validate from 'validate.js';
  import md5 from 'md5';
  import moment from 'moment';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { options } from './options';
import { planTypes } from './planTypes';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api26, busiSecKey } from './../../../config/api';
import {
  addCommas, removeCommas, clearEmptyStrings, removeSpaces, msgCodeDetail,
  handleExpiredLogin,
} from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import PromotionCode from './../../../lib/PromotionCode';
import Navbar from './../../../components/Navbar/Navbar';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';

import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import { stateStorage } from './../../../lib/StateStorage';
import TextInput from './../../../components/TextField/TextField';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';

export default class FireInsurance1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state ?
        this.props.location.state.broker : undefined,
      sumInsuredAmount: 5000000,
      buildingAge: 40,
      planType: 'fire',
      messages: {},
      text: {},
    };

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    try{
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        this.state.businessEmailAddress = undefined;
        localStorage.removeItem('businessEmail');

        this.state.promotionCodes = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        this.state.promotionCodes = undefined;
        localStorage.removeItem('promotionCodes');

        this.state.businessEmailAddress = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const referral = searchParams.get('refcode');
      if (referral) {
        this.state.referral = referral;
      }

    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    localStorage.setItem('preventGoBack', 'false');
  }

  componentDidMount() {
    stateStorage.getState('fire1', (state) => {
      if (state && !this.state.promotionCodes && !this.state.businessEmailAddress) {
        if (state.promotionCodes) {
          delete this.state.businessEmailAddress;
        }
        if (state.businessEmailAddress) {
          delete this.state.promotionCodes;
        }
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          submitButtonDisabled: member && !this.state.broker,
          member: member,
          token: token,
        }, () => {
          if (!this.state.broker) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                if (insuranceAdvisors.FIRE) {
                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.FIRE.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'FIRE',
                      (promotionCodes) => {
                        this.setState({
                          submitButtonDisabled: false,
                          businessEmailAddress: undefined,
                          promotionCodes: promotionCodes ?
                            promotionCodes : this.state.promotionCodes,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      sumInsuredAmount: state.sumInsuredAmount,
      buildingAge: state.buildingAge,
      businessEmailAddress: state.businessEmailAddress,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      sumInsuredAmount: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {
          greaterThanOrEqualTo: 1000000,
          notValid: this.state.text.notANumber,
          notGreaterThanOrEqualTo: this.state.text.lessThanMinimum,
        },
      },
      buildingAge: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      businessEmailAddress: {
        format: englishFormat,
      },
    }
    const messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;

      premium(
        {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': 'EAPP',
          'pl': 'FIRE',
          'member.id' : this.state.member ? this.state.member.id : undefined,
          'member.token' : this.state.token ?this.state.token.tokenStr : undefined,
          'insuredHome.buildingAge': this.state.buildingAge == 'above' ?
            51 : parseInt(this.state.buildingAge),
          'insuredHome.sumInsured': this.state.sumInsuredAmount,
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        },
        (msgDesc) => {
          this.setState({submitButtonDisabled: false, alert: undefined}, () => {
            stateStorage.setState('fire1', this.state);
            stateStorage.setState('fire2', undefined);

            this.props.history.push('/fire2',  {
              broker: this.state.broker,
              planType: this.state.planType,
              sumInsuredAmount: this.state.sumInsuredAmount,
              buildingAge: this.state.buildingAge,
              promotionCodes: removeSpaces(this.state.promotionCodes),
              businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
              showBusinessEmailAddress: this.state.showBusinessEmailAddress,
              premium: msgDesc.premium,
              referral: this.state.referral,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) => {
          if (msgCode == 40) {
            if (this.state.broker) {

              this.setState({
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
              alert:
                Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{text: this.state.text.ok,
                    onPress: () => this.setState({alert: null}),
                  }],
                ),
              });
            }
            else {
              this.setState({alert:
                Alert.alert(
                  this.state.text.addInsuranceAdvisor,
                  this.state.text.wouldYouLike
                  + msgDesc.agent.brokerName
                  + this.state.text.toBeYourInsuranceAdvisor,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        if (!this.state.bindingInsuranceAdvisor) {
                          this.setState({bindingInsuranceAdvisor: true}, () => {
                            insuranceAdvisors.setInsuranceAdvisor(
                              {agentCode: msgDesc.agent.agentCode},
                              ['FIRE'],
                              this.state.promotionCodes,
                              this.state.language,
                              () => this.setState({bindingInsuranceAdvisor: false},
                                () => this._nextScreen())
                            );
                          });
                        }
                      }
                    },
                  ],
                  { cancelable: false }
                ),
              })
            }
          }
          else if (msgCode == 56) {

            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert:
              Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                }],
              )
            });
          }
          else {
            handleDefault();
          }
          this.setState({submitButtonDisabled: false});
        },
      )
    }
  }


  render() {
    const provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Fire%20Insurance%20Policy.pdf';

    return (
    <div className='App'>
      <Helmet>
        <title>Fire Insurance | Bravo Generali</title>
      </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
                <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                    <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                        <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.fire}</Text>
                        <p></p>
                       <Text style={[styles.greetingText,{fontSize: 20}]}>{this.state.text.duetofire}</Text>
                    </View>

                </Grid>
            </Hidden>
        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>


        <ScrollView style={styles.form}>
        {this.state.alert}
          <View style={styles.fieldset}>
            <Text style={styles.h2}>{this.state.text.fireInsurance}</Text>
          </View>

          <Text>
          {this.state.text.yourHomeLongContent}

          </Text>
          <ProductEdges
            productEdges={[
              (this.state.text.coverBuildingSORB),
              (this.state.text.ageOfBuiling),
            ]}
          />

          <AttachmentLink
            provision={provision}
            history={this.props.history}
            noPremiumRates
          />

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.sumInsuredAmount}</Text>
            <TextInput
              style={styles.input}
              onChangeText={(text) => this.setState({sumInsuredAmount: removeCommas(text)})}
              value={addCommas(this.state.sumInsuredAmount)}
              keyboardType='numeric'
              placeholder={this.state.text.pleaseInputHere}
            />
            <Text>{this.state.text.itCanBe}</Text>
            <Text style={formStyles.message}>{this.state.messages.sumInsuredAmount}</Text>
            {
              this.state.sumInsuredAmount > 10000000 &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
          </View>

          <View style={styles.fieldset}>
            <Text style={styles.h4}>{this.state.text.ageOfBuilding}</Text>
            <ModalSelector
              data={[
                { key: '40', label: this.state.text.ageOfBuilding40 },
                { key: '45', label: this.state.text.ageOfBuilding45 },
                { key: '50', label: this.state.text.ageOfBuilding50 },
                { key: 'above', label: this.state.text.ageOfBuildingAbove },
              ]}
              initValue={this.state.buildingAge ?
                options.buildingAge[this.state.buildingAge].label[this.state.language]
                : this.state.text.pleaseChoose
              }
              onChange={(option) => this.setState({buildingAge: option.key })}
              cancelText={this.state.text.cancel}
            />
            <Text style={formStyles.message}>{this.state.messages.buildingAge}</Text>
            {
              this.state.buildingAge == 'above' &&
                <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
            }
          </View>

          {
            !this.state.businessEmailAddress &&
              <View style={styles.fieldset}>
                <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
                <TextInput
                  style={styles.input}
                  placeholder={this.state.text.optional}
                  value={this.state.promotionCodes}
                  onChangeText={(text) => this.setState({promotionCodes: text})}
                />
                <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
              </View>
          }

          {
            !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
              <View style={styles.fieldset}>
                <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
                <TextInput
                  style={styles.input}
                  placeholder={this.state.text.optional}
                  keyboardType='email-address'
                  value={this.state.businessEmailAddress}
                  onChangeText={(text) => this.setState({businessEmailAddress: text})}
                />
                <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
              </View>
          }

          {
            this.state.submitButtonDisabled ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.getAQuote}</Text>
              </TouchableOpacity>
          }
        </ScrollView>
        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
       </Grid>

      <Footer/>

  </div>
    );
  }
  }

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  planTypes: {
    marginTop: 10,
  },

};
