export const zhHK = {
  manualUnderwriting: '您的申請需要核保',
  total: '合共',
  total2: '共',
  flatDiscount: '定額折扣',
  finalTotal: '合共',
  pleaseWait: '請稍等',
  levyIsnotincluded: '不包括保險徵費及有關費用',
  roundedToTheNearestPercentage: '*四捨五入至最近百分比',
  less: '減',
  currency:'港元',
};
