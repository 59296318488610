import React from 'react';
import { View } from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import {
  Heading2, NameInput, DocumentInput, DropDownList, DateInput, YesNoQuestion,
} from './../../Form';

export default class DomesticHelperYesNoForm extends React.Component {
  render() {
    const {domesticHelper, onChange, messages} = this.props;

    return (
      <View>

        <YesNoQuestion
          label={Locale.text.thePlaceOfEmploymentIsInHK}
          value={domesticHelper.isUnoccupied}
          onChange={(isUnoccupied) => onChange({isUnoccupied})}
          message={messages && messages.isUnoccupied}
        />

        <YesNoQuestion
          label={Locale.text.theHelperHasEverBeenRufusedForEnrollment}
          value={domesticHelper.isRefused}
          onChange={(isRefused) => onChange({isRefused})}
          manualUnderwriting={domesticHelper.isRefused == 'Y'}
          message={messages && messages.isRefused}
        />

        <YesNoQuestion
          label={Locale.text.theHelperIsReceivingOrContemplatingAnyMdeical}
          value={domesticHelper.isMedAttn}
          onChange={(isMedAttn) => onChange({isMedAttn})}
          manualUnderwriting={domesticHelper.isMedAttn == 'Y'}
          message={messages && messages.isMedAttn}
        />

        <YesNoQuestion
          label={Locale.text.theHelperIsFullTimeOverseasHelper}
          value={domesticHelper.isProposalDeclined}
          onChange={(isProposalDeclined) => onChange({isProposalDeclined})}
          message={messages && messages.isProposalDeclined}
        />

      </View>
    );
  }
}
