import React from 'react';
import { Text, Image, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { languageStorage } from './../../lib/LanguageStorage';
import { Hidden } from '@material-ui/core';
export default class ProductDetailFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planDetailsFolded: false,
      language: 'enUS',
    };
  }

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
      })
    );
  }

  _togglePlanDetails() {
    if (this.props.planDetails) {
      this.setState({planDetailsFolded: !this.state.planDetailsFolded});
    }
  }

  render() {
    let planDetails = [];
    const details = this.props.planDetails;
    for (let detail in details) {
      planDetails.push(
        <View>
          {/* Desktop version */}
          <Hidden xsDown>
            <View key={detail} style={styles.planDetail}>
              <View style={styles.planDetailKey}>
                <Text style={styles.planDetailKeyText}>{details[detail].key}</Text>
              </View>
              <View style={styles.planDetailValue}>
                <Text style={styles.planDetailValueText}>{details[detail].value}</Text>
              </View>
            </View>
          </Hidden>

          {/* Mobile Version */}
          <Hidden smUp>
            <View key={detail} style={styles.planDetailMobile}>
            <View style={styles.planDetailKeyMobile}>
              <Text style={styles.planDetailTextMobile}>{details[detail].key}</Text>
            </View>
            <View style={styles.planDetailValueMobile}>
              <Text style={styles.planDetailTextMobile}>{details[detail].value}</Text>
            </View>
          </View>
          </Hidden>
        </View>
      );
    }

    return (
      <View style={{minHeight: 60, backgroundColor: this.props.backgroundColor}}>
      <TouchableOpacity style={styles.planType} onPress={() => this._togglePlanDetails()}>
        <View style={styles.planTypeText}>
          <Text style={{color: this.props.color}}>{this.props.label[this.state.language]}</Text>
        </View>
        <View style={styles.planTypeArrow}>
          {
            this.state.planDetailsFolded ?
            <Image style={styles.planTypeArrowImage} source={whiteArrowRight} /> :
            <Image style={styles.planTypeArrowImage} source={whiteArrowDown} />
          }
        </View>
      </TouchableOpacity>
      {
        this.state.planDetailsFolded ? null :
        <View>
          { planDetails }
        </View>
      }
      </View>
    );
  }
}

const whiteArrowDown = require('./white_arrow_down.png');
const whiteArrowRight = require('./white_arrow_right.png');

const styles = {
  planType: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 60,
  },
  planTypeText: {
    flex: 9,
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 20,
  },
  planTypeArrow: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  planTypeArrowImage: {
    width: 10,
    height: 10,
  },
  planDetail: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'space-between',
    backgroundColor: '#FAFAFA',
    minHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  planDetailMobile: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 5,
    paddingBottom:5,
    justifyContent: 'space-between',
    backgroundColor: '#FAFAFA',
    minHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  planDetailKey: {
    justifyContent: 'center',
    marginLeft: 20,
  },
  planDetailKeyMobile: {
    justifyContent: 'start',
    marginLeft: 20,
  },
  planDetailValue: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: 20,
    marginLeft: 0,
  },
  planDetailValueMobile: {
    justifyContent: 'start',
    alignItems: 'start',
    marginRight: 0,
    marginLeft: 20,
  },
  planDetailText: {
    justifyContent: 'center',
    textAlign: 'right',
  },
  planDetailTextMobile: {
    justifyContent: 'start',
    textAlign: 'left',
  },
};
