import Request from './Request';

export default class PromoteAPI {
  /* 9.1 檢查優惠碼 */
  static checkPromotionCode(data, onSuccess, onFail) {
    Request.post('/promote/promotionCode/check', data, onSuccess, onFail);
  }

  /* 9.2 產生短網址碼 */
  static createReferral(data, onSuccess, onFail) {
    Request.post('/promotion/referral/create', data, onSuccess, onFail);
  }

  /* 9.3 取得短網址內容 */
  static getReferral(data, onSuccess, onFail) {
    Request.post('/promotion/referral/get', data, onSuccess, onFail);
  }
}
