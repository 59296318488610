import React from 'react';
import { Text, View, ScrollView, TouchableOpacity } from 'react-native';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import md5 from 'md5';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import Alert  from './../../../components/Alert/Alert';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { addCommas, msgCodeDetail } from './../../../lib/utils';
import { languageStorage } from './../../../lib/LanguageStorage';
import { removeSpaces } from './../../../lib/utils';
import { host, api26, busiSecKey } from './../../../config/api';
import { planTypes } from './planTypes';
import { options } from './options';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import { formStyles } from './../../../config/styles';
import ProductDetailFolder from './../../../components/ProductDetailFolder/ProductDetailFolder';
import TotalCostSection from './../../../components/TotalCostSection/TotalCostSection';

export default class IPA2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      broker: this.props.location.state.broker,
      planType: this.props.location.state.planType,
      people: this.props.location.state.people,
      numberOfChildren: this.props.location.state.numberOfChildren,
      numberOfIndividuals: this.props.location.state.numberOfIndividuals,
      occupations: this.props.location.state.occupations,
      insuredOccupation: this.props.location.state.insuredOccupation,
      spouseOccupation: this.props.location.state.spouseOccupation,
      insuredOccupationLabel: this.props.location.state.insuredOccupationLabel,
      spouseOccupationLabel: this.props.location.state.spouseOccupationLabel,
      coverageA1: this.props.location.state.coverageA1,
      coverageA2: this.props.location.state.coverageA2,
      coverageB: this.props.location.state.coverageB,
      coverageC: this.props.location.state.coverageC,
      coverageD: this.props.location.state.coverageD,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
      originalPrice: this.props.location.state.originalPrice,
      salePrice: this.props.location.state.salePrice,
      referral: this.props.location.state.referral,
      premium: this.props.location.state.premium,
      text: {},
    };
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    window.scrollTo(0, 0);

    stateStorage.getState('ipa2', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }));

      memberStorage.getMember((member, token) => this.setState({
        member: member,
        token: token,
      }))
  }

  _isLogin(){

    if (this.state.member) {
      this._nextScreen();
    }else{
      this.setState({
        alert:
          Alert.alert(
            this.state.text.forBetterCustomerExperiencelogin,
            this.state.text.forBetterCustomerExperience,
            [{
              text: this.state.text.forBetterCustomerExperiencelogin,
              onPress: () => this.props.history.push('Login'),
            },{
              text: this.state.text.forBetterCustomerExperiencregister,
              onPress: () => this.props.history.push('Register'),
            },
            {
              text: this.state.text.forBetterCustomerExperienceCon,
              onPress: () => this._nextScreen(),
            }],
            
          )
      });

  }
  }

  _nextScreen() {
 

    delete this.state.alert;
    stateStorage.setState('ipa2', this.state);
    stateStorage.setState('ipa3', undefined);

        this.props.history.push('/ipa3', {
          broker: this.state.broker,
          planType: this.state.planType,
          people: this.state.people,
          numberOfChildren: this.state.numberOfChildren,
          numberOfIndividuals: this.state.numberOfIndividuals,
          promotionCodes: removeSpaces(this.state.promotionCodes),
          businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
          showBusinessEmailAddress: this.state.showBusinessEmailAddress,
          referral: this.state.referral,
          insuredOccupation: this.state.insuredOccupation,
          spouseOccupation: this.state.spouseOccupation,
          insuredOccupationLabel: this.state.insuredOccupationLabel,
          spouseOccupationLabel: this.state.spouseOccupationLabel,
          occupations: this.state.occupations,
          coverageA1: this.state.coverageA1,
          coverageA2: this.state.coverageA2,
          coverageB: this.state.coverageB,
          coverageC: this.state.coverageC,
          coverageD: this.state.coverageD,
        });
  }

  _getTitle(number){
    let ordinalNumber;
    if (number == 1) {
      ordinalNumber = ' 1st ';
    }
    else if (number == 2) {
      ordinalNumber = ' 2nd ';
    }
    else if (number == 3) {
      ordinalNumber = ' 3rd ';
    }
    else {
      ordinalNumber = ' ' + number + 'th ';
    }
    if (this.state.language == 'zhHK') {
      return `第 ${number} 位 ${this.state.occupationOfThe}`
    }
    if (number) {
      return this.state.text.occupationOfThe + ordinalNumber + this.state.text.insured
    }
    return "";
  }

  render() {
    let planDetails = [
      {
        key: this.state.text.coverType,
        value: options.people[this.state.people].label[this.state.language],
      }
    ];

    let occupationArray = this.state.people == "group" ? this.state.insuredOccupation.split(";") : [this.state.insuredOccupation]
    occupationArray.forEach((occupation, index) => {

      planDetails.push(
        {
          key: this.state.people == "group" ? this._getTitle(index + 1) : this.state.text.occupationOfTheInsured,
          value: this.state.occupations.find((obj) => obj.key == occupation).label
        }
      )
    })

    if (this.state.people == 'withSpouse') {
      planDetails.push(
        {
          key: this.state.text.occupationOfTheSpouse,
          value: this.state.spouseOccupationLabel.label,
        }
      );
    }
    if (this.state.planType == 'IPAT') {
      planDetails.push(
        {
          key: this.state.text.coverageA1,
          value: '$' + addCommas(this.state.coverageA1),
        },
        {
          key: this.state.text.coverageA2,
          value: '$' + addCommas(this.state.coverageA2),
        },
        {
          key: this.state.text.coverageB,
          value: '$' + addCommas(this.state.coverageB),
        },
        {
          key: this.state.text.coverageC,
          value: '$' + addCommas(this.state.coverageC),
        },
        {
          key: 'Coverage D',
          value: this.state.coverageD == 'Y' ? this.state.text.yes : this.state.text.no ,
        },
      );
    }

    if (this.state.promotionCodes) {
      planDetails.push(
        {
          key: this.state.text.promotionCodes,
          value: this.state.promotionCodes,
        }
      );
    }
    if (this.state.businessEmailAddress) {
      planDetails.push(
        {
          key: this.state.text.businessEmailAddress,
          value: this.state.businessEmailAddress,
        }
      );
    }

    return (
      <div className='App'>
        <Helmet>
          <title>{this.state.text.generaliPersonalAccidentInsurancePlan}</title>
        </Helmet>

        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />
        {this.state.alert}
        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

              <View style={[styles.greeting,{fontWeight:'bold',}]}>
                <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.generaliPersonalAccidentInsurancePlan}</Text>
                <p></p>
                {
                  this.state.language == 'zhHK' ?
                  <View>
                  <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                  </View>:
                  <View>
                  <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                  </View>

                }

              </View>

          </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <View style={styles.container, styles.fie}>

      <ScrollView style={styles.form}>
        <Text style={styles.h4}>{this.state.text.iWouldLikeToBuy}</Text>

        <ProductDetailFolder
          label={planTypes[this.state.planType].label}
          color={planTypes[this.state.planType].color}
          backgroundColor={planTypes[this.state.planType].backgroundColor}
          planDetails={planDetails}
        />

        <TotalCostSection
           premium={this.state.premium}
        />
      </ScrollView>

      <TouchableOpacity style={styles.submitButton} onPress={() => this._isLogin()}>
        <Text style={styles.submitButtonText}>{this.state.text.buyNow}</Text>
      </TouchableOpacity>

      </View>
      </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
    </Grid>
      <Footer/>
  </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  h4: {
    marginTop: 20,
    marginBottom: 20,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },

fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
  },
};
