import { Platform } from 'react-native';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { platform } from './../config/platform';
import { host, api16, api22, api61, busiSecKey } from './../config/api';
import { memberStorage } from './../lib/MemberStorage';
import Alert from './../components/Alert/Alert';
import Member from './../Member';

export const checkVersion = (language) => {
  fetch(host + api61, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
  .then((response) => response.json())
  .then((responseJson) => {
    let minVersion, currentVersion, store;
    if (Platform.OS == 'android') {
      minVersion = parseFloat(responseJson.android.minVersion);
      currentVersion = parseFloat(responseJson.android.currentVersion);
      store = 'Google Play Store';
    }
    if (Platform.OS == 'ios') {
      minVersion = parseFloat(responseJson.ios.minVersion);
      currentVersion = parseFloat(responseJson.ios.currentVersion);
      store = 'App Store';
    }
    if (platform.version < minVersion) {
      Alert.alert(
        language == 'enUS' ? 'Version Check' : '版本檢查',
        language == 'enUS' ?
        ('You are running an old version of this app! ' +
          'Please head to ' + store + ' to get the latest version.') :
        ('您正在使用過時的版本！' +
          '請前往 ' + store + ' 安裝本應用程式最新的版本。'),
        [
          {
            text: language == 'enUS' ? 'Retry' : '重新檢查',
            onPress: () => checkVersion(language),
          },
        ],
        { cancelable: false }
      );
    }
  })
  .catch((error) => {
    // alert('Facing error when getting the current version!');
  });
};

export const getEcash = (language, navigation, callback, handleAlert) => {
  memberStorage.getMember((member, token) => {
    if (!member) {
      return;
    }
    const data = JSON.stringify({
      'member.id': member.id,
      'member.token': token.tokenStr,
      'timeStamp': Date.now(),
    });
    const hmac = hmacSha256(data);
    fetch(host + api16, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.msgCode == 0) {
        callback && callback(responseJson.msgDesc.ecash);
      }
      else if (responseJson.msgCode == 12) {
        handleAlert && handleAlert(handleExpiredLogin(language, navigation));
      }
      else {
        alert('Failed on getting your voucher!'
          + ' Message Code: ' + responseJson.msgCode);
      }
    })
    .catch((error) => {
      // alert('Facing error when getting your voucher!');
    });
  });
}

export const decline = (message, language, history, onReinput) => {
  return Alert.alert(
    language == 'zhHK' ? '申請已撤銷' : 'Application declined',
    message,
    [
      {text: language == 'enUS' ? 'Re-input' : '重新輸入', onPress: () => onReinput()},
    ],
    { cancelable: false },
  );
};

export const formatName = (title, firstName, lastName) => {
  title = title && title != 'Miss' ? title + '.' : title;
  title = title ? title + ' ' : '';
  lastName = lastName ? lastName.toUpperCase() : '';
  const comma = lastName && firstName ?', ' : '';
  firstName = firstName ? firstName : '';

  return title + lastName + comma + firstName;
};

export const clearEmptyStrings = (states) => {
  let state;
  for (state in states) {
    if (states[state] === '') {
      delete states[state];
    }
  }
  return states;
}

export const addCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',');
  }
}

export const removeCommas = (number) => {
  if (number) {
    return number.toString().replace(/,/g, '');
  }
}

export const msgCodeDetail = (msgCode) => {
  switch (msgCode) {
    case 0:
      return {
        'msgCode': 0,
        'desc_en': 'Operation Success',
        'desc_zh': '操作成功',
        'remark_en': '',
        'remark_zh': ''
      };
    case 1:
      return {
        'msgCode': 1,
        'desc_en': 'Invalid Submission Signature',
        'desc_zh': '簽名無效',
        'remark_en': '',
        'remark_zh': ''
      };
    case 10:
      return {
        'msgCode': 10,
        'desc_en': 'Wrong Data format',
        'desc_zh': '數據錯誤',
        'remark_en': '',
        'remark_zh': ''
      };
    case 12:
      return {
        'msgCode': 12,
        'desc_en': 'Login Expired',
        'desc_zh': '登入已過期',
        'remark_en': '',
        'remark_zh': ''
      };
    case 20:
      return {
        'msgCode': 20,
        'desc_en': 'Invalid login and password',
        'desc_zh': '帳號或密碼錯誤',
        'remark_en': '',
        'remark_zh': ''
      };
    case 21:
      return {
        'msgCode': 21,
        'desc_en': 'Invalid data submission',
        'desc_zh': '資料提交失敗',
        'remark_en': '',
        'remark_zh': ''
      };
    case 22:
      return {
        'msgCode': 22,
        'desc_en': 'Operation Failure',
        'desc_zh': '操作失敗',
        'remark_en': '',
        'remark_zh': ''
      };
    case 23:
      return {
        'msgCode': 23,
        'desc_en': 'Upload failure',
        'desc_zh': '上載檔案失敗',
        'remark_en': '',
        'remark_zh': ''
      };
    case 30:
      return {
        'msgCode': 30,
        'desc_en': 'Validation error',
        'desc_zh': '驗證失敗',
        'remark_en': '',
        'remark_zh': ''
      };
    case 31:
      return {
        'msgCode': 31,
        'desc_en': 'Account exists already',
        'desc_zh': '帳號已存在',
        'remark_en': '',
        'remark_zh': ''
      };
    case 52:
      return {
        'msgCode': 52,
        'desc_en': 'Failed in searching products',
        'desc_zh': '產品不存在',
        'remark_en': '',
        'remark_zh': ''
      };
    case 53:
      return {
        'msgCode': 53,
        'desc_en': 'Invalid agent information',
        'desc_zh': '代理人不正確',
        'remark_en': '',
        'remark_zh': ''
      };
    case 54:
      return {
        'msgCode': 54,
        'desc_en': 'PolicyHolder is under aged',
        'desc_zh': '投保人年齡未達最低要求',
        'remark_en': '',
        'remark_zh': ''
      };
    case 60:
      return {
        'msgCode': 60,
        'desc_en': 'Missing payment info',
        'desc_zh': '欠缺付款資料',
        'remark_en': '',
        'remark_zh': ''
      };
    case 61:
      return {
        'msgCode': 61,
        'desc_en': 'Payment Failure',
        'desc_zh': '付款失敗',
        'remark_en': '',
        'remark_zh': ''
      };
    case 62:
      return {
        'msgCode': 62,
        'desc_en': 'Exceed maximum insured value',
        'desc_zh': '超過最高投保額',
        'remark_en': 'For IPA Tailor-made plans',
        'remark_zh': 'IPA Tailor-made Plan 適用'
      };
    case 63:
      return {
        'msgCode': 63,
        'desc_en': 'Insured value hasn’t reach the mininum value',
        'desc_zh': '保費未達最低要求',
        'remark_en': 'For IPA Tailor-made plans',
        'remark_zh': 'IPA Tailor-made Plan 適用'
      };
    case 64:
      return {
        'msgCode': 64,
        'desc_en': 'Insufficient ecash',
        'desc_zh': 'eCash 額不足',
        'remark_en': '',
        'remark_zh': ''
      }
    default:
      return {
        'msgCode': 0,
        'desc_en': 'Unknown Error',
        'desc_zh': '不知名錯誤',
        'remark_en': '',
        'remark_zh': ''
      }
  }
};

export const handleExpiredLogin = (language, history) => {
  let alert;

  Member.logout();

  alert = Alert.alert(
    language == 'zhHK' ? '登入已過期' : 'Login Expired',
    language == 'zhHK' ?
      '您此次登入的時效已過，請重新登入。' :
      'Your login has expired. Please login again.',
    [
      {
        text: language == 'zhHK' ? '登入' : 'Login',
        onPress: () => history.push('Login'),
      },
    ],
    { cancelable: false },
  );

  return alert;
};

export const removeSpaces = (string) => {
  if (string) {
    string = string.replace(/\s/g,'');
  }
  return string;
};

export const checkTyphoonMessage =
  (pn, pl, language, history, callback, onAccept) => {
  const data = JSON.stringify({
    pn: pn,
    pl: pl,
    timeStamp: Date.now(),
  });
  const hmac = hmacSha256(data);
  fetch(host + api22, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
  })
  .then((response) => response.json())
  .then((responseJson) => {
    if (responseJson.msgCode == 0 && responseJson.msgDesc.plan.notices) {
      const notice = responseJson.msgDesc.plan.notices[0];
      if (notice) {
        callback(
          Alert.alert(
            undefined,
            language == 'enUS' ? notice.contentEnUs : notice.contentZhHk,
            [
              {
                text: language == 'enUS' ? 'Accept' : '接受',
                onPress: () =>
                  onAccept && onAccept([{noticeId: notice.id, agree: 'Y'}])
              },
            ]
          )
        );
      }
      else {
        onAccept();
      }
    }
  })
  .catch((error) => {
    alert('Facing error when getting the typhoon message!');
  });
};

export const hmacSha256 = (data, mainKey) => {
  const key = mainKey ? mainKey : CryptoJS.AES.decrypt(
    busiSecKey, 'ACTj4a4589rxPEIW2PoE').toString(CryptoJS.enc.Utf8);
  const hmac = CryptoJS.HmacSHA256(data, key);
	const hmacHex = CryptoJS.enc.Hex.stringify(hmac);
  return hmacHex;
}
