import React from 'react';
import {View} from 'react-native';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Button} from './../../Form';
import HomeScreen from './HomeScreen';
import GrossArea from './GrossArea';
import IntroParagraph from './../components/IntroParagraph';
import ProductEdges from './../components/ProductEdges';
import LeafletAndProvision from './../components/LeafletAndProvision';
import PlanType from './../components/PlanType';
import SizeOfTheProperty from './SizeOfTheProperty';
import DiscountInput from './../components/DiscountInput';

export default class HomeScreen1 extends HomeScreen {
  componentDidMount() {
    this.initialize();

    GrossArea.initialize().then(() => {
      this.updateInsuredHome({grossArea: GrossArea.grossAreas[0].id});
    });
  }

  render() {
    const {policy, insuredHome, payment} = this.state.insuranceProduct;

    return(
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.homePackageInsurance}
        {Locale.text.homePackageInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.homePackageInsurance}
            </Heading1>

            <IntroParagraph>
              {Locale.text.oncePurchaseLongConent}
            </IntroParagraph>

            <ProductEdges>
              {Locale.text.comprehensiveCoverage}
              {Locale.text.legalLiability}
              {Locale.text.homePackagePersonalAccident}
              {Locale.text.optionalBuildingInsurance}
              {Locale.text.CompetitiveAnnualPremiumWithLowExcess}
            </ProductEdges>

            <LeafletAndProvision
              screen={this}
              leaflet={leaflet}
              provision={provision}
            />

            <PlanType
              options={this.includeBuildingOptions}
              value={insuredHome.includeBuilding}
              onChange={(includeBuilding) =>
                this.updateInsuredHome({includeBuilding})}
            />

            <SizeOfTheProperty
              cozy
              label={Locale.text.homePackageSizeOfTheProperty}
              value={insuredHome.grossArea}
              onChange={(grossArea) => this.updateInsuredHome({grossArea})}
            />

            <DiscountInput
              cozy
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={this.state.initialized &&
                !this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('Home2')}
            >
              {Locale.text.getAQuote}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}

const leaflet = 'Product_leaflet_Home.pdf';
const provision = 'Product%20Provision_Home%20Package%20Insurance%20Policy.pdf';
