export const enUS = {
  getAQuote: 'Get a Quote',
  executivePersonalAccidentProtector: 'Executive Personal Accident Protector',
  sometimesEvenMinorAccidentsLongContent: 'Our "EPA" offers you and your family a unique concierge service provide 24 hours private service delegated hotline allows you to enjoy life.',
  productEdges: 'Product Edges',
  highBenefitLimits: 'High Benefit Limits (Personal Accident - HK$10,000,000)',
  allInOnePackageWithPersonalAccident: 'All in one package with Personal Accident, Accident Medical Expenses, and Hospital Cash Benefits',
  competitiveFamilyPlan: 'Competitive Family Plan',
  oneStopGenerali: 'One-stop Generali Concierge Hotline',
  forDetailsOfTheProduct:'  details of the product, exclusions and premium rates, please refer to Product Leaflets and Policy Provisions.',

  planType: 'Plan Type',
  individual: 'Individual',
  family: 'Family',
  individualPlan: 'Individual Plan',
  familyPlan: 'Family Plan',
  promotionCodes: 'Promotion Codes',
  optional: 'Optional',
  businessEmailAddress: 'Business Email / Membership',
  businessEmailMembership: 'Business Email\n/ Membership',

  iWouldLikeToBuy: 'I would like to buy',
  total: 'Total$4800',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  getInsured: 'Get Insured',
  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',
  insuranceAdvisorMobile:'Insurance Advisor Mobile Number',
  productType:'Product Type',
  pleaseInputInEnglish: '* Please input in English *',
  effectiveDate: 'Effective Date',
  periodOfInsurance: 'Period of Insurance',
  bothDatesInclusive: '(both dates inclusive)',
  policyholder: 'Policyholder',
  name: 'Name',
  title:'Title',
  firstName:'First Name',
  lastName: 'Last Name',
  hkid:'HKID Number',
  pleaseEnterAllHKID:'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  dateOfBirth:'Date of Birth',
  spouse: 'Spouse',
  first: '1st ',
  child: 'Child',
  addAChild: 'Add a Child',
  ndChild:'2nd Child',
  questions: 'Questions',
  pleasePickADate:'Please pick a date',
  mobileNumber:'Mobile Number',
  correspondenceAddress:'Correspondence Address',
  emailAddress: 'Email Address',
  policyholderIs:'Policyholder is',
  theInsured:'the insured',
  oneOfTheInsureds: 'one of the Insureds',
  insured:'Insured',
  occupationClericalAndNonManualWork:'Occupation: Clerical and Non-manual work',
  fullTimeStudentUnmarriedAndUnemployed: 'Full time student, unmarried and unemployed',
  yes:'Yes',
  no:'No',
  manualUnderwritingsIsRequired:'* Manual underwriting is required *',
  question:'Question',
  haveYourOrOtherCoveredMembersAPPOflife:'Have your or other covered members’ applications of life, accident or medical insurance ever been declined or postponed, or your insurance ever been modified, rated up, cancelled or refused invitation for renewal?',
  insurer:'Insurer',
  benefit:'Benefit',
  sumInsured:'Sum Insured',
  reason:'Reason',
  condition:'Condition',
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'Do you or other covered members have any physical or mental impairment or condition?',
  sufferedArea:'Suffered Area',
  diagnosis:'Diagnosis',
  thisFieldCannotBeBlank: 'This field cannot be blank',
  specialRequests:'Special Requests',
  referral: 'Referral Code',

  importantNote:'Important Notes:',
  onlyApplicalbeToExecutivesAndClericalWork:'Only applicable to Executives and Clerical Work.',
  withValidHKID:'With valid HKID card and resident in Hong Kong',
  beneficiaryWillBeTheLE:'Beneficiary will be the Legal Estate of the insured according to the Hong Kong law.',
  assicurazioniGeneraliSpAReserves:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application',
  iWECerrifyNotesOfIPAPI:'I/We certify that I/we have read and agreed to the Important Notes of Executive Personal Accident Protector Insurance.',
  iWeCertifyDisclaimerAndPICS:'I/We certify that I/we have read and agreed to the Disclaimer and Personal Information Collection Statement.',
  iWeHerebyAuthorizedAnyLicensed:'I/We hereby authorized any licensed physician, hospital, clinic or other medical or medically related facility, insurance company, institution or persons that has any records or knowledge of myself/ ourselves, to give to the Company any such information for the underwriting and claims assessment process. I/We also authorize all said sources to give such records or knowledge to any agency employed by the Company to collect and transmit such information.',
  iWeDoNotConsentToTheProvision: 'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentToTheUse: 'I/We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',

  confirm:'Confirm',
  payment: 'Payment',
  youHave: 'You have ',
  eCashNow: 'Generali voucher now. Would you want to use it?',
  executivePersonalAccidentProtector: 'Executive Personal Accident Protector',
  submit:'Submit',
  pleaseWait:'Please Wait',
  sexsex: 'Sex',

  brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay ',
  brokerDeclarationII:' commission and provide my/our personal data to the said broker/agent for arranging and managing the said policy.',
  invalidPromotionCode: 'Invalid promotion code',
  addInsuranceAdvisor: 'Add Insurance Advisor',
  wouldYouLike: 'Would you like ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  needsToBeInEnglish: 'Needs to be in English',
  thankYou: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  ccccc: '.',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  check: 'Please check your inputs again.',
  cancel: 'cancel',
  theInsuranceIsIssuedByContent: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  alert: 'Alert',
  ok: 'OK',
  submissionFailed: 'Submission Failed',
  invalidMobileNumber: 'Invalid insurance advisor mobile number.',
  tryAgain: 'Our system is temporarily not available, please try again later.',

  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  epa: 'Executive Personal Accident Insurance',
  enjoy: '24-hour dedicated concierge services so you can sit back and enjoy life.',
  forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
forBetterCustomerExperiencelogin:'Log in',
forBetterCustomerExperiencregister:'Register',
forBetterCustomerExperienceCon:'Continue without log in',
}
