import React from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import { DateTimePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

export default class G_DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      text: {},
      judge: this.props.judge ? true : false,
    };
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        {
          this.props.fullDate ?
        <DatePicker
        formatDate={(date) => moment(date).format('MMMM D, YYYY')}
          format={this.props.date, 'MMMM dd, yyyy'}
          openTo="year"
          views={["year", "month", "day"]}
          format='MMMM dd, yyyy'
          minDate={this.state.judge ? this.props.date : this.props.minDate}
          maxDate={this.props.maxDate}
          value={this.props.date ? this.props.date : null}
          emptyLabel=''
          onChange={(date) => this.props.onDateChange(moment(date).format('MMMM D, YYYY'))}
          disabled={this.props.disabled}
        />
        :
        <DatePicker
        formatDate={(date) => moment(date).format('MMMM D, YYYY')}
          format={this.props.date, 'MMMM dd, yyyy'}
          format='MMMM dd, yyyy'
          minDate={this.state.judge ? this.props.date : this.props.minDate}
          maxDate={this.props.maxDate}
          value={this.props.date ? this.props.date : null}
          emptyLabel=''
          onChange={(date) => this.props.onDateChange(moment(date).format('MMMM D, YYYY'))}
          disabled={this.props.disabled}
        />
  }
      </MuiPickersUtilsProvider>
    );
  }
}
