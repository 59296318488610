import React from 'react';
import moment from 'moment';

import config from './../../config';
import Locale from './../../Locale';
import Member from './../../Member';
import Validation from './../../Validation';
import ApplyAPI from './../../api/ApplyAPI';
import AgentAPI from './../../api/AgentAPI';
import InsuranceProductScreen from './../InsuranceProductScreen';

export default class AHIFScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, 'AHIF');

    const searchParams = new URLSearchParams(this.props.location.search);

      const key = searchParams.get('key');
      console.log('key'+key)

    this.premiumPlanOptions = [
      {value: 'I', label: Locale.text.individualPlan},
    ];

    const {insuranceProduct} = this.state;

    if (!insuranceProduct.pl) {
      insuranceProduct.pl = 'AHI0';
      insuranceProduct.ahif = {premiumPlan: 'I'};
      insuranceProduct.members = [{relationship: 'insured'}];
    }
  }

  updateAHIF(data) {
    this.updateInsuranceProduct('ahif', data);
  }

  updateMembers(index, data) {
    const {members} = this.state.insuranceProduct;

    if (members[index]) {
      Object.assign(members[index], data);
    }
    else {
      members[index] = data;
    }

    this.setState({insuranceProduct: this.state.insuranceProduct});
  }

  getPremiumPlanLabel() {
    const {ahif} = this.state.insuranceProduct;

    switch (ahif.premiumPlan) {
      case 'I': return Locale.text.individualPlan;
    }
  }

  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  
  componentDidMount() {
   // window.addEventListener('beforeunload', this.handleLeavePage);

  }
  componentWillUnmount() {
    //window.removeEventListener("beforeunload", this.handleLeavePage);
 }

  componentDidUpdate() {
    const {ahif, members} = this.state.insuranceProduct;

    if (ahif.premiumPlan == 'I' && members.length > 1) {
      while (members.length > 1) {
        members.pop();
      }

      this.refresh();
    }
  }

  buyAgain() {
    if (super.buyAgain()) {
      const {insuranceProduct} = this.state;
      const {dataFormat} = config.date;

      insuranceProduct.ahif = this.getParam('ahif');
      insuranceProduct.members = this.getParam('members');

      insuranceProduct.members.forEach((member) => {
        member.dateOfBirth = moment(member.dateOfBirth).format(dataFormat);
        member.relationship = member.relationship.relationshipKey;
      });
    }
  }

  getQuote(nextScreen) {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        ahif, payment, members} = this.state.insuranceProduct;

      const {trimAndRemoveEmptyStrings, getValidator} = Validation;

      [agent, policy, policyHolder, ahif, payment].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator('text'),
          promotionCode: getValidator('text'),
        }
      );

      messages.discount = discountValidation.validate();

      if (nextScreen == 'ahif4') {
        const agentValidation = new Validation(
          agent,
          {mobile: getValidator('number')}
        );

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(
          policy,
          {
            effectiveDateStart: getValidator('text', true),
            specialRequest: getValidator('text'),
          }
        );

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(
          policyHolder,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            mobile: getValidator('number', true),
            email: getValidator('email', true),
            address1: getValidator('text'),
            documentType: getValidator('text', true),
            documentNo: getValidator('HKID', true),
            dateOfBirth: getValidator('text', true),
          }
        );

        messages.policyHolder = policyHolderValidation.validate();

        const membersMessages = [];

        members.forEach((member, index) => {
          const memberValidation = new Validation(
            member,
            {
              firstName: getValidator('text', true),
              lastName: getValidator('text', true),
              documentType: getValidator('text', true),
              documentNo: getValidator('HKID', true),
              dateOfBirth: getValidator('text', true),
              relationship: getValidator('text', true),
            }
          );

          const memberMessages = memberValidation.validate();

          if (memberMessages) {
            membersMessages[index] = memberMessages;
          }
        });

        if (JSON.stringify(membersMessages) != '[]') {
          messages.members = membersMessages;
        }
      }

      const invalid = JSON.stringify(messages) != '{}';

      if (invalid) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else if (
        nextScreen == 'ahif4' && ahif.premiumPlan == 'F' && members.length == 1
      ) {
        this.setState({waiting: false}, () => {
          this.alert(
            Locale.text.check,
            Locale.text.youHaveOneInsuredOnly,
            [
              {
                text: Locale.text.ok,
                onPress: () => {this.updateAHIF({premiumPlan: 'I'}); this.dismissAlert()},
              },
              {
                text: Locale.text.cntinueInputting,
                onPress: () => {this.setState({waiting: false}); this.dismissAlert()},
              },
            ],
            {cancelable: false},
          );
        });
      }
      else {
        this.readPremium({
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'policy.effectiveDateStart': policy.effectiveDateStart,
          'policy.businessEmail': policy.businessEmail,
          'ahi.premiumPlan': ahif.premiumPlan,
          'payment.promotionCode': payment.promotionCode,
        })
        .then(({premium, levy}) => {
          this.push(nextScreen, {premium, levy});
        });
      }
    });
  }

  confirm() {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        ahif, members, payment} = this.state.insuranceProduct;

      const {getValidator, getProductConfirmationValidator} = Validation;

      const confirmationValidation = new Validation(
        policy,
        {
          disclaimerAndPIC: getProductConfirmationValidator(),
          declaration: getProductConfirmationValidator(),
          allowPrivacyPromote: getValidator('text', true),
          commissionsDisclosures:
            (this.state.agent || this.state.broker || payment.promotionCode) &&
              getProductConfirmationValidator(),
        }
      );

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.apply(
          Locale.text.ahiInsurance,
          this.state.broker ? AgentAPI.ahi : ApplyAPI.ahi,
          {
            'pn': pn,
            'pl': pl,
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'agent.mobile': agent.mobile,
            'policy.effectiveDateStart': policy.effectiveDateStart,
            'policy.effectiveDateEnd': policy.effectiveDateEnd,
            'policy.allowPrivacyPromote3P': policy.allowPrivacyPromote3P,
            'policy.allowPrivacyPromote': policy.allowPrivacyPromote,
            'policy.businessEmail': policy.businessEmail,
            'policy.specialRequest': policy.specialRequest,
            'policyHolder.salutation': policyHolder.salutation,
            'policyHolder.firstName': policyHolder.firstName,
            'policyHolder.lastName': policyHolder.lastName,
            'policyHolder.mobile': policyHolder.mobile,
            'policyHolder.email': policyHolder.email,
            'policyHolder.address1': policyHolder.address1,
            'policyHolder.documentType': policyHolder.documentType,
            'policyHolder.documentNo': policyHolder.documentNo,
            'policyHolder.dateOfBirth': policyHolder.dateOfBirth,
            'ahi.premiumPlan': ahif.premiumPlan,
            'members': members,
            'payment.ecash': payment.ecash,
            'payment.promotionCode': payment.promotionCode,
            'rawRequest': JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }
}
