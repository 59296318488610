export const host = 'https://eapp.generali.com.hk/bravoapi20';
export const cms = 'https://eapp.generali.com.hk';

export const api11 = '/member/verify/send';
export const api12 = '/member/register';
export const api13 = '/member/login';
export const api14 = '/member/advisor/check';
export const api15 = '/member/advisor/refer';
export const api16 = '/member/ecash/check';
export const api17 = '/member/deviceToken/save';
export const api18 = '/member/advisor/disconnect';
export const api21 = '/product/list';
export const api22 = '/product/details';
export const api23 = '/product/home/getGrossAreas';
export const api24 = '/product/landlord/getNetAreas';
export const api25 = '/product/asap/getCountries';
export const api26 = '/product/premium';
export const api27 = '/product/travel/getTraverllerRelationships';
export const api210 = '/product/getOccupations';
export const api211 = '/product/ussp/getStates';
export const api212 = '/product/ussp/getUniversities';
export const api213 = '/product/ussp/getVisaStatuses';
export const api31 = '/apply/home';
export const api32 = '/apply/dh';
export const api33 = '/apply/asap';
export const api34 = '/apply/fire';
export const api35 = '/apply/landlord';
export const api36 = '/apply/travel/single';
export const api37 = '/apply/travel/annual';
export const api38 = '/apply/pa/epa';
export const api39 = '/apply/pa/ipa';
export const api310 = '/pay';
export const api311 = '/pay/paid';
export const api331 = '/apply/ussp';
export const api41a = '/schedule/request';
export const api41b = '/schedule/download';
export const api42 = '/claim/submit';
export const api43 = '/provision/download';
export const api44 = '/ecCard/download';
export const api51 = '/message/send';
export const api52 = '/message/list';
export const api53 = '/message/tokenList';
export const api54 = '/message/cmsToken/set';
export const api55 = '/message/cmsToken/get';
export const api61 = '/system/appVersion';
export const api62 = '/system/captcha/verify';
export const api71 = '/history/policies';
export const api72 = '/history/policy';
export const api73 = '/history/claims';
export const api81 = '/agent/history/policies';

export const busiSecKey = 'U2FsdGVkX1/IHngPu4J9xPEv920LrMSwIMS/TJu3DS+CkP6JmxNGvuY7OuA5t3IXH4BNTibTu4s32doJ45C6fQ==';
