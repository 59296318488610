export const zhHK = {
  instantClaim: '即時索償',
  travelInsurance: '旅遊保險計劃',
  insuredDetails: '受保人詳情',
  policyNumber: '保單編號',
  policyholderName: '保單持有人姓名',
  insuredName: '受保人姓名',
  hkidNumberPassNumber:'香港身份證號碼 / 護照號碼',
  hkid: '香港身份證',
  passport: '護照',
  cancel: '取消',
  pleaseEnter: '請輸入您的香港身份證的所有數字。',
  forExample: '例: 您的香港身份證是A123456（7），請輸入A1234567。',
  mobileNumber: '手提電話號碼',
  emailAddress: '電郵',

  typeOfClaim:'索償類別',

  medicalExpense: '醫療費用',
  medicalExpenseReClaimDetails: '醫療費用索賠詳情',
  injuryOrSickness: '受傷 / 疾病',
  pleaseChoose: '請選擇',
  injury: '受傷',
  sickness: '疾病',
  dateOfInjuryOrSickness: '受傷 / 生病日期',
  pleasePickADate: '請選擇日期',
  ifInjury: '如果受傷，事故是怎麼發生的?',
  ifSickness: '如果病了，疾病什麼時候開始出現?',
  natureOfInjury: '受傷性質 / 病因',
  claimAmount: '索償金額',
  nameAndAddressOfDoctorOrHospital: '診治醫生/ 醫院名稱及地址',
  name: '姓名',
  address: '地址',
  ifItWasAHospitalPeriod: '如醫院﹐留院時間為',
  pleasePickADateOfHospital: '請選擇留院時段',
  documentsAttached: '附加文件',
  travelItinerary: '行程表 / 機票存根 / 登機證',
  openPhoto: '開啟照片',
  hospitalMedicalBillsReceiptReport: '住院醫療 / 醫藥費收據',
  pleaseBeReminded: '有關住院索償，如果索償金額高於港幣5,000及其等值，請寄回相關醫療賬單正本到本公司。我們會先處理你提交之電子索償申請，在收到醫療賬單的正本後，我們方可發放索償金額。如你成功提交索賠申請後，將收到索賠參考編號。請將醫療賬單的正本寄回香港皇后大道東8號忠意保險大廈5樓，並在信封上註明索賠參考編號以作跟進。',

  loss: '行李、旅遊文件、個人財物損失',
  lossClaimDetail: '行李、旅遊文件、個人財物損失索賠詳情',
  dateOfLossDamage: '遺失/ 損壞日期',
  timeOfLossDamage: '遺失/ 損壞時間',
  placeOfLossDamage: '遺失/ 損壞地點',
  reportedPoliceStation: '報案的警署/公共交通機構/酒店等的名稱及聯絡資料',
  firstReportedPlace: '第一事發地點',
  reportedPlace: '事發地點',
  contactInformation: '聯絡資訊',
  addAReportedPlace: '添加事發的地點',
  howTheLossDamageOccurredDiscovered: '損失/損壞物品之事發經過 (例如物品擺放位置及發現意外的地點，時間和經過)',
  lostDamagedItems: '損失/損壞物品',
  firstLostDamagedItem: '第一個遺失/損壞的物品',
  dateWhenLostDamagedItems: '損失/損壞日期',
  OriginalPurchaseValue: '原購買價錢',
  pleaseFillInTheAmount: '請填入金額',
  replacementRepairCost: '更換/ 維修成本',
  addALostDamagedItem: '添加遺失/損壞的物品',
  lossDamageReportIssuedByTheRelevantAuthorities: '客運 / 航空公司 / 酒店之事件証明 / 警方報案記錄',
  photosShowingTheExtent: '能顯示損毀程度的彩色照片，如適用',
  purchaseReceiptRepairQuotation: '有關的損失物件的購買收據、維修報價單 / 發票、重新購置收據，如適用',
  compensationBreakdownFromOtherInsurers: '列出由其他保險公司 / 第三者（如客運 / 航空公司）獲得的補償細項，如適用',

  journeyCancellation: '行程取消 / 縮短 / 更改',
  journeyCancellationClaimDetails: '行程取消 / 縮短 / 更改索賠詳情',
  dateOfOriginalSchedule: '原定行程日期',
  locationOfOriginalSchedule: '原定行程地點',
  dateOfCCRJourney: '行程取消 / 縮短 / 更改的日期',
  locationOfCCRJourney: '行程取消 / 縮短 / 更改的地點',
  reasonForJourneyCCR: '行程取消 / 縮短 / 更改的原因',
  ifTheJourneyCurtailmentIsDueTo: '如行程取消或提早結束旅程原因是因為受保人本人或受保人的直系親屬或親密的生意伙伴死亡、嚴重受傷或患病，請提供以下資料:',
  fullNameOfTheSickPerson: '死亡、受傷或患者姓名',
  relationshipToTheInsuredPerson: '與受保人關係',
  diagnosis: '診斷',
  receiptShowingAnyPrepaidCosts: '旅費按金 或 額外交通 及/或 住宿費用的收據',
  documentationIssuedByTravelAgent: '由旅遊代理 / 客運 / 航空公司 發出的旅程取消証明文件並列出 “不獲退款 / 已退還金額”項目',

  travelBaggageDelay: '旅程延誤 / 行李延誤',
  travelBaggageDelayDetails: '旅程延誤 / 行李延誤索賠詳情',
  reasonForDelay: '延誤原因',
  flightDelayed: '航班延誤',
  missedConnection: '錯過轉乘班機',
  baggageDelayed: '行李延誤',
  smallOthers: '其他',
  whichAirport: '機場名稱',
  delayedFlightNo: '延誤航班編號',
  dateNTime: '日期及時間',
  pleasePickADateNTime: '請選擇日期與時間',
  emergencyEssentialItemsPurchased: '購買之緊急必需品',
  firstItem: '第一项索償物件',
  addAPurchasedItem: '添加索償物件',
  datePurchased: '購買日期',
  pricePaid: '購買金額',
  documentationIndicatingTheReasonForDelay: '由旅遊代理 / 客運 / 航空公司 發出的証明信件並列明延誤原因及延誤時間',

  personalAccident: '人身意外保障 (死亡 / 永久傷殘 / 燒傷)',
  personalAccidentClaimDetails: '人身意外保障索賠詳情',
  dateOfAccident: '意外發生的日期',
  timeOfAccident: '意外發生的時間',
  placeOfAccident: '意外地點',
  describeHowTheAccidentOccurred: '詳述意外發生的經過及所遭受的損傷',
  nameAndAddressOfTheAttendingDoctor: '診治醫生名稱及地址',
  permanentDisabilityDegreeNExtent: '永久傷殘的程度(如適用)',
  causeOfDeath: '死亡原因(如適用)',
  nameAndContactNumberOfWitness: '證人名稱及聯繫電話(如適用)',
  contactNumber: '聯繫電話',
  accidentReportIssuedByRelevantAuthorities: '客運 / 航空公司 / 酒店之意外報告 / 警方報案記錄及調查報告，如適用',
  medicalReportStatingTheDateOfAttendance: '由具有醫學資格，獲認可及合法授權從事疾病或意外受傷診療的人士發出的醫療報告並列明受傷程度及到診日期，如適用',
  copyOfDeathCertificate: '死亡證 / 驗屍、屍體解剖、事後的調查分析報告副本，如適用',

  careVisit: '親屬探望',
  careVisitClaimDetails: '親屬探望索賠詳情',
  nameOfVisitingFamilyMember: '探望之親屬名稱',
  durationOfVisit: '探望時間',
  proofOfRelationship: '與受保人之關係證明，如適用',
  receiptsOfTravelingAccommodationExpensesIncurred: '旅費、交通 及/或 住宿費用的收據',

  personalLiability:'個人責任',
  personalLiabilityClaimDetails: '個人責任索賠詳情',
  dateOfTheIncident: '事發日期',
  timeOfTheIncident: '事發時間',
  pleasePickTime: '請選擇時間',
  placeOfTheIncident: '事發地點',
  fullNameOfTheThirdPartyClaimant: '第三者索償人姓名',
  contactOfTheThirdPartyClaimant: '第三者索償人手提電話號碼',
  fullNameOfWitness: '證人姓名(如適用)',
  contactOfWitness: '證人手提電話號碼(如適用)',
  copyOfIncidentReportFromRelevantParties: '由有關機構 (如：當地警方及法定機構、酒店、體育中心等) 發出的之事件報告副本',
  claimLetterAndInvoice: '對方的申索信件及發票',
  otherDocuments: '其他相關文件 – 請註明',
  importantNotes: '重要聲明:',
  anyLawsuit: '如收到任何第三者對有關事件的索償要求、法庭傳票、通告及書面命令，或涉及任何法律訴訟，切勿自行處理，應立即通知及提交本公司處理',
  noLiabilityShould: '未得到本公司事先同意前，不要向第三者承認任何責任或達成和解或付款承諾',

  others: '其他',
  othersClaimDetails: '其他索賠詳情',
  descriptionOfTheAccident: '事件詳細經過',

  otherInsurance:'其他保險公司',
  otherInsuranceCoverage: '請問除本公司外，有否投保於其他保險公司？',
  yes:'有',
  nameOfInsurer: '保險公司名稱',
  typeOfCoverage: '投保種類',
  policyEffectiveDate: '保單生效日期',
  no:'沒有',
  submit: '提交',
  pleaseWait: '請稍候',

  isNotAValidHKID: '無效香港身分證',
  CityUniversityOfHongKong: '香港城市大學',
  uowCollegeHongKong: '伍倫貢大學',
  needsToBeInEnglish: '需要使用英文填寫',
  thisFieldCannotBeBlank: '必須填寫空格',
  applicationJson: 'application/json',
  congratulations: '恭喜',
  youHaveSuccessfully: '您已成功提交您的索償！',
  yourClaimApplication: '您的索償申請已收到。',
  failedOnSubmitting: '提交索償失敗！',
  messageCode: '訊息碼：',
  facingErrorWhenSubmitting: '提交索償遇到錯誤！',
  errorMessage: '錯誤訊息：',
  insuranceAdvisorMobile: '保險顧問電話號碼',
  policyholderThe: 'Policyholder / The',
  policy: 'Policy ',
  number: 'Number',
  certificate: '/ Certificate ',
  employerName: '/ 僱員姓名',
  staffStudentNumber: '員工 / 學生編號',
  occupationPosition: '職業 / 職位',
  stationedCityCountry: '派駐城市 / 國家',
  homeResidingCountry: '定居國家',
  dateOfSecondment: '派駐至該城市 / 國家的日期',
  purposeOfTrip: '外遊目的',
  business: '商務',
  accidentHappen:'請詳述意外發生經過？',
  symptomsFirstAppear: '首次出現病徵的時間？',
  pleasePickTime: '請選擇時間',
  pleaseFillInDetails: '請填寫細節',
  relationshipToInsuredPerson: '與受保人關係',
  documentShowingTheStudentIdentity: '海外留學生證明文件，如學生證及/或入學信',
  medicalCertificateIndicatingDiagnosis: '醫療證明文件清楚指出受保人 / 其直系親屬 / 旅遊夥伴 或 緊密商業夥伴所患之病症及不適合外遊之理由，如適用',
  deathCertificate: '死亡證，如適用',
  proofOfRelationship: '與受保人之關係證明，如適用',
  item:'物品',
  lostDamagedItem: '遺失/損壞的物品',

  notValidEmail:'這不是正確的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是正確的香港身份證號碼',

  fieldsMissing: '仍有空白欄位',
  someBlankFields: '目前仍有些欄位未填寫。您還要送出索償嗎？',
  resumeFilling: '返回填寫',

  thankYou: '謝謝您',
  submitted: '您的索償已成功送出。',
  claimReferenceNumber: '索償索引編號：',
  cancel: '取消',

  specialRequests: '其他 / 特別要求',

  declaration: '聲明',
  declaration1: '本人/吾等作出聲明，就本人/吾等所知及所信，上述資料均屬真確及並無遺漏。',
  declaration2: '本人/吾等授權任何醫院、醫生、診所、其他人士、有關官方或機構，向忠意保險有限公司(香港分行)（「忠意」）或其受權之代表提供所有有關本人之損失、受傷、症病、病歷、醫療診斷及藥方、警方報告、口供、所有醫院或醫療報告之副本。',
  declaration3: '本人同意所有文件及收據予忠意將不獲退還。',
  declaration5: '我/我們不同意提供個人資料被第三方作為行銷用途使用',
  declaration6: '我/我們不同意提供個人資料給貴公司作為行銷用途',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成申請',
  reservesTheRight: '忠意保險有限公司 (香港分行) 保留權利向索賠人要求提交索償證明文件的正本。請提交索償後，保留正本至少3個月。',

  ok: '確認',
}
