import React from 'react';
import {Text, View} from 'react-native';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Button} from './../../Form';
import HomeScreen from './HomeScreen';
import GrossArea from './GrossArea';
import InformationForQuotation from './../components/InformationForQuotation';
import Total from './../components/Total';
import Alert from "./../../components/Alert/Alert";
export default class HomeScreen2 extends HomeScreen {
  buyNow() {
    if (this.state.broker) {
      this.askCustomerToLogin().then(() => this.push("Home3"));
    } else {
      if (Locale.getPaymentStatus()) {
        this.askCustomerToLogin().then(() => this.push("Home3"));
      } else {
        this.setState({
          alert: Alert.alert(
            Locale.text.paymentStopService1,
            Locale.text.paymentStopService2,
            [
              {
                text: Locale.text.yes,
                onPress: () => this.setState({ alert: null }),
              },
            ]
          ),
        });
      }
    }
    //this.askCustomerToLogin().then(() => this.push('Home3'));
  }

  render() {
    const {policy, insuredHome, payment} = this.state.insuranceProduct;

    const data = [{
      label: Locale.text.homePackageSizeOfTheProperty,
      value: GrossArea.getDesc(insuredHome.grossArea),
    }];

    if (payment.promotionCode) {
      data.push({
        label: Locale.text.promotionCodes,
        value: payment.promotionCode,
      });
    }

    if (policy.businessEmail) {
      data.push({
        label: Locale.text.businessEmailMembership,
        value: policy.businessEmail,
      });
    }

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.homePackageInsurance}
        {Locale.text.homePackageInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <InformationForQuotation
              planTypeOptions={this.includeBuildingOptions}
              planType={insuredHome.includeBuilding}
              data={data}
            />

            <Total
              premium={this.getParam('premium')}
            />
          </Form>

          <Button withMargin onPress={() => this.buyNow()}>
            {Locale.text.buyNow}
          </Button>
        </View>
      </Layout>
    );
  }
}
