import React from 'react';

import Locale from './../../Locale';
import Member from './../../Member';
import Validation from './../../Validation';
import InsuranceProductScreen from './../InsuranceProductScreen';
import GrossArea from './GrossArea';
import ApplyAPI from './../../api/ApplyAPI';
import AgentAPI from './../../api/AgentAPI';

export default class HomeScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, 'HOME');

    this.includeBuildingOptions = [
      {value: 'N', label: Locale.text.homeContentsOnly},
      {value: 'Y', label: Locale.text.homeContentsOnlyNBuilding},
    ];

    if (!this.state.insuranceProduct.pl) {
      this.state.insuranceProduct.pl = 'HOME';
      this.state.insuranceProduct.insuredHome = {
        includeBuilding: 'N',
      };
    }
  }

  handleLeavePage(e) {
    const confirmationMessage = 'Some message';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  
  componentDidMount() {
   // window.addEventListener('beforeunload', this.handleLeavePage);

  }
  componentWillUnmount() {
   // window.removeEventListener("beforeunload", this.handleLeavePage);
 }

  componentDidUpdate() {
    const {insuredHome} = this.state.insuranceProduct;

    if (insuredHome.includeBuilding != 'Y' && insuredHome.isPostPolicy) {
      this.updateInsuredHome({isPostPolicy: undefined});
    }

    if (insuredHome.isPostPolicy != 'Y' && insuredHome.mortgageeName) {
      this.updateInsuredHome({mortgageeName: undefined});
    }

    if (insuredHome.isPostPolicy != 'Y' && insuredHome.mortgageeAddress) {
      this.updateInsuredHome({mortgageeAddress: undefined});
    }
  }

  updateInsuredHome(data) {
    this.updateInsuranceProduct('insuredHome', data);
  }

  getPlanCodeLabel() {
    const {pl} = this.state.insuranceProduct;

    switch (pl) {
      case 'HOME': return Locale.text.homePackageInsurance;
    }
  }

  getIncludeBuildingLabel() {
    const {insuredHome} = this.state.insuranceProduct;

    switch (insuredHome.includeBuilding) {
      case 'Y': return Locale.text.homeContentsOnlyNBuilding;
      case 'N': return Locale.text.homeContentsOnly;
    }
  }

  getHomeTypeLabel() {
    const {insuredHome} = this.state.insuranceProduct;

    switch (insuredHome.homeType) {
      case 'A': return Locale.text.apartment;
      case 'H': return Locale.text.house;
    }
  }

  getQuote(nextScreen) {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        insuredHome, payment} = this.state.insuranceProduct;

      const {trimAndRemoveEmptyStrings, getValidator} = Validation;

      [agent, policy, policyHolder, insuredHome, payment].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator('text'),
          promotionCode: getValidator('text'),
        }
      );

      messages.discount = discountValidation.validate();

      if (nextScreen == 'Home4') {
        const agentValidation = new Validation(
          agent,
          {mobile: getValidator('number')}
        );

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(
          policy,
          {
            effectiveDateStart: getValidator('text', true),
            specialRequest: getValidator('text'),
          }
        );

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(
          policyHolder,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            mobile: getValidator('number', true),
            email: getValidator('email', true),
            address1: getValidator('text'),
          }
        );

        messages.policyHolder = policyHolderValidation.validate();

        const insuredHomeValidation = new Validation(
          insuredHome,
          {
            fullAddress: getValidator('text', true),
            homeType: getValidator('text', true),
            grossArea: getValidator('number', true),
            isUnoccupied: getValidator('text', true),
            includeBuilding: getValidator('text', true),
            isOldBuilding: getValidator('text', true),
            isAnyLoss: getValidator('text', true),
            isProposalDeclined: getValidator('text', true),
            isPostPolicy: getValidator(
              'text', insuredHome.includeBuilding == 'Y'),
            mortgageeName: getValidator(
              'text', insuredHome.isPostPolicy == 'Y'),
            mortgageeAddress: getValidator(
              'text', insuredHome.isPostPolicy == 'Y'),
          }
        );

        messages.insuredHome = insuredHomeValidation.validate();
      }

      const invalid = JSON.stringify(messages) != '{}';

      if (invalid) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.readPremium({
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'payment.promotionCode': payment.promotionCode,
          'policy.businessEmail': policy.businessEmail,
          'policy.effectiveDateStart':policy.effectiveDateStart,
          'insuredHome.grossArea': insuredHome.grossArea,
          'insuredHome.includeBuilding': insuredHome.includeBuilding,
        })
        .then(({premium, levy}) => {
          this.push(nextScreen, {premium, levy});
        });
      }
    });
  }

  confirm() {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        insuredHome, payment} = this.state.insuranceProduct;

      const {getValidator, getProductConfirmationValidator} = Validation;

      const confirmationValidation = new Validation(
        policy,
        {
          disclaimerAndPIC: getProductConfirmationValidator(),
          allowPrivacyPromote: getValidator('text', true),
          commissionsDisclosures:
            (this.state.agent || this.state.broker || payment.promotionCode) &&
              getProductConfirmationValidator(),
        }
      );

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.apply(
          this.getPlanCodeLabel(),
          this.state.broker ? AgentAPI.home : ApplyAPI.home,
          {
            'pn': pn,
            'pl': pl,
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'agent.mobile': agent.mobile,
            'policy.effectiveDateStart': policy.effectiveDateStart,
            'policy.effectiveDateEnd': policy.effectiveDateEnd,
            'policy.allowPrivacyPromote3P': policy.allowPrivacyPromote3P,
            'policy.allowPrivacyPromote': policy.allowPrivacyPromote,
            'policy.businessEmail': policy.businessEmail,
            'policy.specialRequest': policy.specialRequest,
            'policyHolder.salutation': policyHolder.salutation,
            'policyHolder.firstName': policyHolder.firstName,
            'policyHolder.lastName': policyHolder.lastName,
            'policyHolder.mobile': policyHolder.mobile,
            'policyHolder.email': policyHolder.email,
            'policyHolder.address1':policyHolder.address1,
            'policyHolder.documentType': policyHolder.documentType,
            'policyHolder.documentNo': policyHolder.documentNo,
            'policyHolder.dateOfBirth': policyHolder.dateOfBirth,
            'insuredHome.fullAddress': insuredHome.fullAddress,
            'insuredHome.area': 'HK',
            'insuredHome.homeType': insuredHome.homeType,
            'insuredHome.grossArea': insuredHome.grossArea,
            'insuredHome.isUnoccupied': insuredHome.isUnoccupied,
            'insuredHome.includeBuilding': insuredHome.includeBuilding,
            'insuredHome.isOldBuilding': insuredHome.isOldBuilding,
            'insuredHome.isAnyLoss': insuredHome.isAnyLoss,
            'insuredHome.isProposalDeclined': insuredHome.isProposalDeclined,
            'insuredHome.isPostPolicy': insuredHome.isPostPolicy,
            'insuredHome.mortgageeName': insuredHome.mortgageeName,
            'insuredHome.mortgageeAddress': insuredHome.mortgageeAddress,
            'payment.ecash': payment.ecash,
            'payment.promotionCode': payment.promotionCode,
            'rawRequest': JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }
}
