import React from 'react';
import {View, Text} from 'react-native';

import Locale from './../../Locale';
import {TextInput, EmailInput} from './../../Form';

export default class DiscountInput extends React.Component {

  render() {
    const {cozy, promotionCode, businessEmail,
      directCustomer, onChange, messages} = this.props;

    return (
      <View>
        {
          !businessEmail &&
            <TextInput
              cozy={cozy}
              label={Locale.text.promotionCodes}
              placeholder={Locale.text.optional}
              value={promotionCode}
              onChange={(promotionCode) => onChange({promotionCode})}
              message={messages && messages.promotionCode}
            />
        }

        {
          !promotionCode && directCustomer &&
            <EmailInput
              cozy={cozy}
              label={Locale.text.businessEmailAddress}
              placeholder={Locale.text.optional}
              value={businessEmail}
              onChange={(businessEmail) => onChange({businessEmail})}
              message={messages && messages.businessEmail}
            />
        }
      </View>
    );
  }
}
