export const enUS = {
  instantClaim: 'Instant Claim',
  homeInsurance: 'Home Insurance',
  insuredDetails: 'Insured Details',
  policyNumber: 'Policy Number',
  policyholderName: 'Policyholder Name',
  insuredHomeAddress: 'Insured Home Address',
  hkidNumberPassNumber:'HKID card Number / Passport Number',
  hkid: 'HKID',
  passport: 'Passport',
  cancel: 'cancel',
  mobileNumber: 'Mobile Number',
  emailAddress: 'E-mail Address',
  typeOfClaim:'Type of Claim',
  propertyDamageOrLoss: 'Property Damage / Loss of Property / Building',
  publicLiability: 'Public Liability',
  claimDetails: 'Claim Details',
  dateOfIncident: 'Date of Incident',
  pleasePickADate: 'Please pick a date',
  timeOfIncident: 'Time of Incident',
  pleasePickTime: 'Please pick time',
  placeOfIncident: 'Place of Incident',
  detailIncident: 'Description of Incident',
  nameOfWitness: 'Name of Witness (if any)',
  contactOfWitness: 'Contact of Witness (if any)',
  wasTheIncidentReported: 'Was the incident reported to Police',
  nameOfThePoliceStation: 'Name of the Police Station',
  policeReportNumber: 'Police Report Number',
  hasAnyPrecautionaryMeasures: 'Has any precautionary measures been taken at the time of incident?',
  pleaseAdviseInDetails: 'Please Advise in Details',
  followingTheIncident: 'Following the incident, has any remedy work been taken to minimize the loss?',
  haveYouEverExperienced: 'Have you ever experienced similar nature of incident?',
  datesOfIncidents: 'Date(s) of Incident(s)',
  nameOfThirdParty: 'Name of Third Party',
  contactOfThirdParty: 'Contact of Third Party',
  addressOfThirdParty: 'Address of Third Party',
  natureAndExtentOfDamageOrInjuries: 'Nature and Extent of Damage or Injuries',
  hasClaimBeenMade: 'Has claim been made?',
  claimAmount: 'Claim Amount',
  currency: 'Currency',
  pleaseFillInTheAmount: 'Please fill in the amount',
  anyStepsBeenTaken: 'Any steps been taken to compromise or settle the matter in any way?',
  particularsOfMainContractor: 'Particulars of Main Contractor or Contractor, if any',
  isThereAnyWorkByContract: 'Is there any work by contract undertaken at the time of incident?',
  name: 'Name',
  trade: 'Trade',
  contactNoOrEmail: 'Contact no. / Email',
  address: 'Address',
  theirRespectiveThirdParty: 'Their Respective Third Party Liability Insurance Policy Details',
  nameOfInsuranceCompany: 'Name of insurance company',
  documentsAttached: 'Documents Attached',
  detailedBreakdown: 'Detailed brakdown of the claimed items together with relevant purchase receipt(s) and / or repair quotation(s), invoice(s) and receipt(s)',
  openPhoto: 'Open Photo',
  colouredPhotograph: 'Coloured photograph of the vicinity showing the extent of damage to the affected item(s)',
  incidentReportMadeByTheBuilding: 'Incident report made by the Building Management Office with their confirmation on the exact cause of damage and the remedial actions they had taken in order to minimize the damage',
  theCorrespondence: 'The corespondence(s) among the Policyholder / Insured, tenant, the building management and the parties concerned (e.g. the owner of the affected unit(s), the FEHD / BD Joint Office etc.) in connection with the matter, if any',
  otherRelevantInformation: 'Other relevant information / documents',
  others: 'Others',
  dateOfAccident: 'Date of Accident',
  timeOfAccident: 'Time of Accident',
  placeOfAccident: 'Place of Accident',
  descriptionOfTheAccident: 'Description of the Accident',
  otherInsurance: 'Other Insurance',
  doYouHaveOtherInsurance: 'Do you have other insurance coverage?',
  yes: 'Yes',
  nameOfInsurer: 'Name of Insurer',
  typeOfCoverage: 'Type of Coverage',
  policyEffectiveDate: 'Policy Effective Date',
  no: 'No.',
  submit: 'Submit',
  pleaseWait: 'Please Wait',
  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',
  inCaseOfBurglary: 'In case of burglary, state mode of entry to and exit from the premises by the culprit',
  haveYouReportedTheIncident: 'Have you reported the incident to police?',
  areYouTheSoleOwner: 'Are you the sole owner of the damaged/lost Property(ies)?',
  otherOwners: 'Other Owner(s)',
  ofTheOtherOwners: ' of the Other Owner(s)',
  includingTheHirePurchase: 'Including the hire-purchase owner',
  addAnotherOwner: 'Add Another Owner',
  areYouEntitled: 'Are you entitled to claim under any other insurance policies in respect of this incident?',
  nameOfInsuranceCompanyies: 'Name of Insurance Company(ies)',
  respectivePolicyNumber: 'Respective Policy Number',
  detailsOfCoverage: 'Details of Coverage',
  haveYouEverSustainedLosses: 'Have you ever sustained losses of similar nature?',
  detailsOfIncidents: 'Details of Incident(s)',
  haveYouEverMadeClaimUnder: 'Have you ever made claim under any other insurance policy(ies)?',
  details: 'Details',
  remarks: 'Remarks',
  totalClaimAmount: 'Total Claim Amount',
  detailedBreakdownOfTheClaimedItems: 'Detailed breakdown of the claimed items together with relevant purchase receipt(s) and / or repair quotation(s), invoice(s) and receipt(s)',
  colouredPhotographsOfTheVicinity: 'Coloured photographs of the vicinity showing the extent of damage to the affected item(s)',
  detailDescriptionOf: 'Detail Description of the Incident',
  fullDescriptionOf: 'Full Description of Items Damaged or Lost',
  addAnItem: 'Add an Item',
  memoReportsAndOrStatements: 'Police memo / report(s) and/or statement(s) taken by the Insured / Family Member(s) / Parties under concerns (for Loss of Property / Attempted Theft / Theft claims only)',
  aCopyOfDuly: 'A copy of duly stamped Rental Agreement',
  theCorrespondencesAmong: 'The correspondence(s) among the Policyholder / Insured, tenant, the building management and the parties concerned (e.g. the owner of the affected unit(s), the FEHD / BD Joint Office etc.) in connection with the matter, if any',
  howWasItHappened: 'How was it happened?',
  pleaseDescribeThe: 'Please describe the injuries sustained, indicating the part of body injured and the type of injury, e.g. fracture, cut, bruise and etc.',
  wasTheAccidentReported: 'Was the accident reported to the Police?',
  caseReferenceNumber: 'Case Reference Number',
  nameOfDoctorHospital: 'Name of Doctor/ Hospital',
  dateOfFirstConsultation: 'Date of First Consultation',
  proofOfRelationship: 'Proof of relationship',
  isNotAValidHKID: 'is not a valid HKID.',
  needsToBeInEnglish: 'needs to be in English',
  thisFieldCannotBeBlank: 'This field cannot be blank',
  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  accidentalDeath: 'Accidental Death',
  rentalIncomeLoss: ' Rental Income Loss /',
  deathCertificate: 'Death certificate',
  includingBrandName:'Including brand name, model and serial no.',
  dateOfPurchase: 'Date of Purchase',
  purchasePrice: 'Purchase Price',
  fieldsMissing: 'Fields Missing',
  someBlankFields: 'There are some blank fields. Do you still want to submit your claim?',
  resumeFilling: 'Resume Filling',
  thankYou: 'Thank You',
  submitted: 'Your claim has been submitted.',
  claimReferenceNumber: 'Claim reference number: ',
  item: 'Item',

  specialRequests: 'Others / Special Request',

  declaration: 'Declaration',
  declaration1: 'I/We declare that the above information is in all respect true and complete to the best of my/our knowledge and belief.',
  declaration2: 'I/We, the claimant, hereby authorize any hospital, physician, clinic, or other person/authority/organization, to furnish to Assicurazioni Generali S.p.A. - Hong Kong Branch (“the Company”) or its authorized representative, any and all information with respect to my loss, injury or illness, medical history, consultation, prescriptions or treatment and copies of police reports, incident reports, statement and all hospital or medical records.',
  declaration3: 'I hereby agree that all documents and receipts submitted to the Company will not be returned.',
  declaration5: 'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  declaration6: 'I/We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the application',
  reservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to request the claimants to submit the original copies of the supporting documents. Please keep the originals for at least 3 months after submitting the claim.',

  ok: 'OK',
}
