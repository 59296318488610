export const zhHK = {
  settings: '設定',
  register: '登記',
  or:'或者',
  createAccount: '建立帳戶',
  name: '姓名',
  mobileNumber: '手機號碼',
  emailAddress: '電郵地址',
  getConfirmationCode: '獲取確認碼',
  confirmationCodeSent: '確認碼已發送',
  confirmationCodeSentTo: '確認碼已發送到：',
  enterCodeBelow: '請在下方輸入六位數字確認碼',
  pleaseWait: '請稍等',
  login: '登入',
  loginWithMobilePhone: '用我的手機登入',
  welcome: '歡迎',
  loggedIn: '您已成功登入！\n為更好保障您的個人資料，我們建議您每次使用後請登出。',
  ok: '確認',
  accountCreated: '您已成功登記！',
  youHaveRegistered: '您已完成登記',
  alreadyRegistered: '這個帳號先前已完成登記。您可以使用這組電話號碼登入。',
  logout: '登出',
  languages: '語言',
  preferredLanguage: '語言偏好',
  alert: '請注意',
  pleaseRegisterFirst: '請先登記。',
  referenceCode: '參考碼',
  codeBySMS: '以SMS再次發送六位數字確認碼',
  codeByEmail: '以電郵再次發送六位數字確認碼',
  // Insurance Advisor Screen
  insuranceAdvisor: '保險顧問',
  insuranceAdvisorIs: '保險顧問：',
  notSpecifiedYet: '目前尚未指定',
  change: '更換',
  changeInsuranceAdvisor: '要更換保險顧問嗎？',
  wouldYouLike: '你希望 ',
  comma: ' （來自 ',
  toBeYourInsuranceAdvisor: ' ）成為你的保險顧問嗎？',
  yes: '是',
  no: '否',
  requiredToLogin: '需要登入',
  pleaseLogin: '在管理你的保險顧問前，請先登入！',
  login: '登入',
  register: '登記',
  product: '產品：',
  pleaseInputTheMobileNumber: '請輸入保險顧問的手機號碼',
  confirm: '確認',
  removeInsuranceAdvisor: '移除保險顧問',
  doYouWantToRemove: '你想要移除',
  questionMark: '的保險顧問嗎？',
  thanksAndWeWillInform: '謝謝你，我們會通知',
  accordingly: '。',
  pleaseCheckTheProductList: '請檢查保險顧問列表，並按確認。',

  thisFieldCannotBeBlank: '必須填寫空格',
  thisfieldsetldCannotBeBlank: '必須填寫空格',
  invalidMobileNumber: '這不是有效的手機號碼',
  notValidEmail: '這不是有效的電郵地址',
  needsToBeInEnglish: '需以英文填寫',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成登記',

  // Developer Screen
  developerChecker: '開發人員檢查工具',
  host: '主機',
  token: '令牌',
  version: '版本',

  // product names
  travelInsurnace: '旅遊保險',
  gereraliTravelPackageInsurance: '忠意旅遊保',
  annualStudyAbroadProtector: '全年海外留學保障',
  homeInsurnace: '家居保險',
  homePackageInsurance: '家居保險',
  fireInsurance: '火險',
  domesticHelperInsurance: '家庭僱傭保險',
  accidentInsurance: '人身意外險',
  generaliPersonalAccidentInsurancePlan: '人身意外保險計劃',
  executivePersonalAccidentProtector: '人身意外險',
  affinityProduct: '特約保險',
  cityU: '旅遊計劃 - 香港城市大學',
  uow: '旅遊計劃 - 香港澳大利亞伍倫貢書院',
  centalineLandlordProtector: '中原收租保',
  centalineHomeProtector: '中原家居保',
  centalineTravelProtector: '中原旅遊保',
  centalineDomesticHelperProtector: '中原家傭保',

  iCerifyThatIHaveReadAndAgreedDisclaimerAndPersonal:'我 / 我們證明我/我們已閱讀並同意免責聲明和個人信息收集聲明。',
  iWeDoNotConsentPDto3PartyMarketing:'我/我們不同意提供個人資料被第三方作為行銷用途使用',
  iWeDoNotConsentPDToCompanyMarketing:'我/我們不同意提供個人資料給貴公司作為行銷用途',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  history: '記錄',
  instantEnrollment: '報價',
  roarUs: '聯絡我們',
  privilegedClub: '專享禮遇',
  instantClaims: '索償',
  settings: '設定',
  insurance: '保險顧問',
  advisors: '在這裡管理您的保險顧問。',
  languageSettings: '語言設定',
  choose: '選擇您偏好的語言設定。',
  registration: '登記',
  bravo: '歡迎來到Bravo Generali！',
  back: '歡迎回來！',
  seting: '設定',
  setingme: '在此管理您的偏好設定。',
  referenceCode: '參考碼',
  optional: '非必填',
}
