export const enUS = {
  quickLinks: 'Quick Links',
  buyAgain: 'Buy Again',
  loading: 'Loading',
  travel: 'Travel',
  helper: 'Helper',
  promotion: 'Promotion',
  homeScreen: 'Home',
  test: 'testing',

  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  history: 'History',
  home: 'Home',
  instantEnrollment: 'Insurance Product',
  roarUs: 'Roar Us',
  privilegedClub: 'Privilege Club',
  instantClaims: 'Claims',
  settings: 'Settings',
  cancel: 'cancel',
  alert: 'Alert',
  pleaseAgree: 'Please agree The legal information & Privacy statement.',
  ok: 'OK',
  toEnjoyTheBenefits: 'To enjoy the benefits of policy or claims history checking and one-click purchase/claim, you can now register with us.',
  register: 'Register',
  forThoseAlreadyRegistered: 'Log-in (for those already registered)',
  continueWithoutRegistration: 'Continue without registration',
  youUndertake: 'You undertake and confirm that:',
  youHavePersonallyRead: 'You have personally read, understood, and accepted',
  theLegalInformation: 'The legal information',
  privacyStatement: 'Privacy statement',
  youCanAlsoChoose: 'You can also choose your preferred language',
  confirm: 'Confirm',
  hellohello: 'Hello, ',
  hisofpu:'History of purchase / claims',
  purchase:'You can get a quotation and confirm your purchase in just a few clicks',
  company:'Contact us',
  information:'Latest news, vouchers and other information',
  claimpolicy:'You can submit a claim in just a few clicks',
  personalInformation:'Language and insurance advisor',
  borker: 'Borker',

  personCollection: 'Personal Information Collection Statement',
  legalInformation: 'Legal Information',
  Privacy: 'Privacy, Security & Cookies',
  email: 'mailto:bravo@generali.com.hk',
  phone: '+852 6682 1112',
  copy: 'Copyright © 2019 GENERALI LIFE (HONG KONG) LIMITED & ASSICURAZIONI GENERALI S.p.A. – HONG KONG BRANCH',
  reserved: 'All rights reserved',
  group: 'PART OF GENERALI GROUP',
  welcome: 'Welcome to Bravo Generali!',
 iemes: 'Sorry that we do not support IE. For the best browsing experience, please use:',
 ieborker: 'Get a quote and Buy for Your Clients',
 submission: 'Easy digital claims submission at your fingertips.',
 tuned: 'Offers are coming soon, stay tuned!',
 help: 'We are here to help.',
 setingme: 'Manage your preferences here.',
 policiemessages: 'View details of your clients’ policies.',

 apply:"New application for overseas study abroad, $100 coffee vouchers.",
 voucher:"Voucher: 'From now until August 31, each successfully issued 「Overseas Study Abroad Insurance」 insurance will receive $100 Starbucks Gifts. '",
 item:'Promotional Terms and Conditions:',
 itemfirst:'1. Promotional offers will be available from now until 11:59 pm on August 31, 2019 (Hong Kong time).',
 itemsecond:'2. The promotion offer is only applicable to the new application for “Overseas Study Abroad Guarantee”.',
 itemthird:'3. All dates and times related to the promotion offer are subject to the server of the loyalty insurance.',
 itemfourth:'4. This promotion offer is only available to residents of Hong Kong Identity Card aged 18 or above.',
 itemfifth:'5. All gifts issued by this promotional offer cannot be exchanged or exchanged for cash under any circumstances. The number of gifts is limited while stocks last.',
 itemsixth:'6. The gift card will be sent within one month of the policy issue date.',
 itemseventh:'7. Loyalty Insurance is not responsible for any failure to receive the award notice and ritual if the information provided/filled by the customer is false, incorrect or incorrect.',
 itemeighth:'8. The Company has the absolute right to interpret these Terms and Conditions and to retain all changes or to cancel this activity and its related arrangements. In case of any dispute, the company reserves the right of final decision without further notice.',
 time:'July 10, 2019',

 NeedToMakeClaim:'Need to make a claim?',
 followingLink:'To submit a claim, please click the following link.',
 followingLinkText:'https://www.generali.com.hk/EN_US/claims_and_support/individual',
 claimContactText:'Claim contact email: ',
 claimContactEmail:'genclaims_info@generali.com.hk',
}
