import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import moment from 'moment';

import config from './../../config';
import Locale from './../../Locale';
import { DateInput, Message } from './../../Form';

export default class EffectiveDate extends React.Component {
  getEffectiveDateEnd(effectiveDateStart, format, plan) {
    let effectiveDateEnd = moment(effectiveDateStart)
      .add(1, "year")
      .subtract(1, "day");
    if (plan == "DH2") {
      effectiveDateEnd = moment(effectiveDateStart)
        .add(2, "year")
        .subtract(1, "day");
    }

    if (format) {
      return effectiveDateEnd.format(format);
    } else {
      return effectiveDateEnd;
    }
  }

  render() {
    const { dataFormat, displayFormat } = config.date;
    const { value, plan, onChange, messages } = this.props;

    return (
      <View>
        <DateInput
          label={Locale.text.effectiveDate}
          minDate={moment().add(0, "day")}
          maxDate={moment().add(1, "day").add(6, "months")}
          value={value}
          onChange={(effectiveDateStart) =>
            onChange({
              effectiveDateStart,
              effectiveDateEnd: this.getEffectiveDateEnd(
                effectiveDateStart,
                dataFormat,
                plan
              ),
            })
          }
        />

        {messages && <Message>{messages.effectiveDateStart}</Message>}

        {value && (
          <View style={styles.periodOfInsurance}>
            <Text style={styles.periodOfInsuranceLabel}>
              {Locale.text.periodOfInsurance}
            </Text>

            <View style={styles.periodOfInsuranceValue}>
              <Text>
                {moment(value).format(displayFormat)}
                {" - "}
                {this.getEffectiveDateEnd(value, displayFormat, plan)}
              </Text>

              <Text>{Locale.text.bothDatesInclusive}</Text>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  periodOfInsurance: {
    marginTop: 5,
    marginBottom: 5,
  },
  periodOfInsuranceLabel: {
    marginTop: 10,
    marginBottom: 10,
  },
  periodOfInsuranceValue: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
