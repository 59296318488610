import Request from './Request';

export default class AgentAPI {
  /* 2.1 檢視產品列表 */
  static list(data, onSuccess, onFail) {
    Request.post('/agent/product/list', data, onSuccess, onFail);
  }

  /* 2.2 檢視單一產品 */
  static details(data, onSuccess, onFail) {
    Request.post('/agent/product/details', data, onSuccess, onFail);
  }

  /* 2.6 取得產品保費 */
  static premium(data, onSuccess, onFail) {
    Request.post('/agent/product/premium', data, onSuccess, onFail);
  }

  /* 3.1 投保家居保險 */
  static home(data, onSuccess, onFail) {
    Request.post('/agent/apply/home', data, onSuccess, onFail);
  }

  /* 3.2 投保外籍傭工保險 */
  static dh(data, onSuccess, onFail) {
    Request.post('/agent/apply/dh', data, onSuccess, onFail);
  }

  /* 3.3 投保全年海外留學保障 */
  static asap(data, onSuccess, onFail) {
    Request.post('/agent/apply/asap', data, onSuccess, onFail);
  }

  /* 3.4 投保火險 */
  static fire(data, onSuccess, onFail) {
    Request.post('/agent/apply/fire', data, onSuccess, onFail);
  }

  /* 3.5 投保收租保 */
  static landlord(data, onSuccess, onFail) {
    Request.post('/agent/apply/landlord', data, onSuccess, onFail);
  }

  /* 3.6 投保單次旅遊保險 */
  static singleTravel(data, onSuccess, onFail) {
    Request.post('/agent/apply/travel/single', data, onSuccess, onFail);
  }

  /* 3.7 投保全年旅遊保險 */
  static annualTravel(data, onSuccess, onFail) {
    Request.post('/agent/apply/travel/annual', data, onSuccess, onFail);
  }

  /* 3.8 投保行政人員意外保險 */
  static epaPa(data, onSuccess, onFail) {
    Request.post('/agent/apply/pa/epa', data, onSuccess, onFail);
  }

  /* 3.9 投保個人意外保險 */
  static ipaPa(data, onSuccess, onFail) {
    Request.post('/agent/apply/pa/ipa', data, onSuccess, onFail);
  }

  /* 3.16 投保乘客意外保險 */
  static rpaPa(data, onSuccess, onFail) {
    Request.post('/agent/apply/pa/rpa', data, onSuccess, onFail);
  }

  /* 3.19 投保意外住院入息保險 */
  static ahi(data, onSuccess, onFail) {
    Request.post('/agent/apply/pa/ahi', data, onSuccess, onFail);
  }

  /* 4.2 索償 */
  static submit(data, onSuccess, onFail) {
    Request.post('/agent/claim/submit', data, onSuccess, onFail);
  }

  /* 7.1 取得投保記錄列表 */
  static policies(data, onSuccess, onFail) {
    Request.post('/agent/history/policies', data, onSuccess, onFail);
  }

  /* 7.2 取得投保記錄詳細 */
  static policy(data, onSuccess, onFail) {
    Request.post('/agent/history/policy', data, onSuccess, onFail);
  }

  /* 7.3 取得索償記錄列表 */
  static claims(data, onSuccess, onFail) {
    Request.post('/agent/history/claims', data, onSuccess, onFail);
  }

  /* 8.1 啟用財務顧問帳號 */
  static activate(data, onSuccess, onFail) {
    Request.post('/agent/activate', data, onSuccess, onFail);
  }

  /* 8.2 財務顧問登入 */
  static login(data, onSuccess, onFail) {
    Request.post('/agent/login', data, onSuccess, onFail);
  }

  /* 8.3 延長授權有效期 */
  static extendToken(data, onSuccess, onFail) {
    Request.post('/agent/token/extend', data, onSuccess, onFail);
  }

  /* 8.4 檢查優惠碼 */
  static checkPromotionCode(data, onSuccess, onFail) {
    Request.post('/agent/promotionCode/check', data, onSuccess, onFail);
  }
}
