import React from "react";
import {
  Text,
  Switch,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CryptoJS from "crypto-js";
import moment from "moment";
import md5 from "md5";

import validate from "validate.js";
import validid from "validid";
import { enUS } from "./en-US.js";
import { zhHK } from "./zh-HK.js";
import { planTypes } from "./planTypes";
import { options } from "./options";
import { removeSpaces } from "./../../../lib/utils";
import { platform } from "./../../../config/platform";
import { formStyles } from "./../../../config/styles";
import { memberStorage } from "./../../../lib/MemberStorage";
import { stateStorage } from "./../../../lib/StateStorage";
import { languageStorage } from "./../../../lib/LanguageStorage";
import { msgCodeDetail } from "./../../../lib/utils";
import { insuranceAdvisors } from "./../../../lib/InsuranceAdvisors";
import {
  host,
  api26,
  api36,
  api37,
  api310,
  busiSecKey,
} from "./../../../config/api";
import {
  formatName,
  clearEmptyStrings,
  addCommas,
  removeCommas,
} from "./../../../lib/utils";
import DatePicker from "./../../../components/DatePicker/DatePicker";
import ModalSelector from "./../../../components/ModalSelector/ModalSelector";
import NameInput from "./../../../components/NameInput/NameInput";
import HKID from "./../../../lib/HKID";
import Alert from "./../../../components/Alert/Alert";
import TextInput from "./../../../components/TextField/TextField";
import PromoteAPI from "./../../../api/PromoteAPI";
import Navbar from "./../../../components/Navbar/Navbar";
import Footer from "./../../../components/Footer/Footer";
import YesNoQuestion from "./../../../components/YesNoQuestion/YesNoQuestion";
import Member from "./../../../Member";
import ProductAPI from "./../../../api/ProductAPI";
import AgentAPI from "./../../../api/AgentAPI";
import Insured from "../../../insurance-product/ipa/Insured";

export default class IPA3 extends React.Component {
  constructor(props) {
    super(props);
    const { policy, ipa, members, numberOfChildren, numberOfIndividuals } =
      this.props.location.state;

    if (policy) {
      alert(JSON.stringify(members));
      this.state = {
        planType: members[0].PAMemberIPA.planCover,
        people: members.length == 1 ? "individual" : "family",
        effectiveDateStart: moment(
          policy.effectiveDateStart,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        effectiveDateEnd: moment(
          policy.effectiveDateEnd,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        businessEmailAddress: policy.businessEmail,
        policyholderTitle: policy.policyHolder.salutation
          ? policy.policyHolder.salutation.descEnUs.replace(/\./g, "")
          : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderHKID: policy.policyHolder.documentNo,
        policyholderDOB: moment(
          policy.policyHolder.dateOfBirth,
          "YYYY-MM-DD hh:mm:ss"
        ).format("MMMM D, YYYY"),
        policyholderPhone: policy.policyHolder.mobile,
        policyholderAddress: policy.policyHolder.address1,
        policyholderEmail: policy.policyHolder.email,
        insuredIndividuals: [],
        insuredChildren: [],
        // policyholderDOB: moment(data['policyHolder.dateOfBirth'], 'YYYY-MM-DD').format('MMMM D, YYYY'),
        // insuredOccupation: data['ipa.occupationClass'],
        // insuredRightHanded: data['ipa.rightHanded'],
        occupations: this.props.location.state.occupations,
        insuredOccupation: this.props.location.state.insuredOccupation,
        spouseOccupation: this.props.location.state.spouseOccupation,
        question1: ipa.insuranceDeclined ? "Y" : "N",
        // question1Reason: data['ipa.declineRemarks'],
        question2: ipa.hasImpairment ? "Y" : "N",
        // question2Diagnosis: data['ipa.diagnosis'],
        coverageA1: this.props.location.state.coverageA1,
        coverageA2: this.props.location.state.coverageA2,
        coverageB: this.props.location.statecoverageB,
        coverageC: this.props.location.state.coverageC,
        coverageD: this.props.location.state.coverageD,
        isInsured: policy.policyHolder.documentNo == members[0].documentNo,
        specialRequests: policy.specialRequest,
        referral: policy.referral,
        messages: {},
        text: {},
      };
      members.forEach((member) => {
        if (member.relationship.relationshipKey == "insured") {
          this.state.insuredTitle = member.salutation
            ? member.salutation.descEnUs.replace(/\./g, "")
            : undefined;
          this.state.insuredFirstName = member.firstName;
          this.state.insuredLastName = member.lastName;
          this.state.insuredDOB = moment(
            member.dateOfBirth,
            "YYYY-MM-DD hh:mm:ss"
          ).format("MMMM D, YYYY");
          this.state.insuredHKID = member.documentNo;
          this.state.insuredRightHanded = member.PAMemberIPA.rightHanded
            ? "Y"
            : "N";
          this.state.insuredOccupation = member.PAMemberIPA.occupationClass;
        }
        if (member.relationship.relationshipKey == "spouse") {
          this.state.spouseTitle = member.salutation
            ? member.salutation.descEnUs.replace(/\./g, "")
            : undefined;
          this.state.spouseFirstName = member.firstName;
          this.state.spouseLastName = member.lastName;
          this.state.spouseDOB = moment(
            member.dateOfBirth,
            "YYYY-MM-DD hh:mm:ss"
          ).format("MMMM D, YYYY");
          this.state.spouseHKID = member.documentNo;
          this.state.spouseRightHanded = member.PAMemberIPA.rightHanded
            ? "Y"
            : "N";
          this.state.spouseOccupation = member.PAMemberIPA.occupationClass;
        }
        if (member.relationship.relationshipKey == "child") {
          if (!this.state.showFirstChild) {
            this.state.child1Title = member.salutation
              ? member.salutation.descEnUs.replace(/\./g, "")
              : undefined;
            this.state.child1FirstName = member.firstName;
            this.state.child1LastName = member.lastName;
            this.state.child1DOB = moment(
              member.dateOfBirth,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMMM D, YYYY");
            this.state.child1HKID = member.documentNo;
            this.state.showFirstChild = true;
          }
          if (!this.state.showSecondChild) {
            this.state.child2Title = member.salutation
              ? member.salutation.descEnUs.replace(/\./g, "")
              : undefined;
            this.state.child2FirstName = member.firstName;
            this.state.child2LastName = member.lastName;
            this.state.child2DOB = moment(
              member.dateOfBirth,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMMM D, YYYY");
            this.state.child2HKID = member.documentNo;
            this.state.showSecondChild = true;
          }
        }
      });
    } else {
      this.state = {
        broker: this.props.location.state.broker,
        planType: this.props.location.state.planType,
        people: this.props.location.state.people,
        numberOfChildren: parseInt(numberOfChildren),
        numberOfIndividuals: parseInt(numberOfIndividuals),
        insuredIndividuals: [],
        insuredChildren: [],
        occupations: this.props.location.state.occupations,
        insuredOccupation: this.props.location.state.insuredOccupation,
        spouseOccupation: this.props.location.state.spouseOccupation,
        insuredOccupationLabel: this.props.location.state.insuredOccupationLabel,
        spouseOccupationLabel: this.props.location.state.spouseOccupationLabel,
        coverageA1: this.props.location.state.coverageA1,
        coverageA2: this.props.location.state.coverageA2,
        coverageB: this.props.location.state.coverageB,
        coverageC: this.props.location.state.coverageC,
        coverageD: this.props.location.state.coverageD,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress:
          this.props.location.state.showBusinessEmailAddress,
        isInsured: true,
        messages: {},
        text: {},
      };

    }
  }

  componentDidMount() {

    if (localStorage.getItem("preventGoBack") == "true") {
      window.location = "/";
      return;
    }
    stateStorage.getState("ipa3", (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState(
        {
          language: language,
          text: getText(language),
        },
        () => {
          // init HKID validation
          const text = this.state.text;
          validate.validators.hkid = function (
            value,
            options,
            key,
            attributes
          ) {
            if (!value || validid.hkid(value)) {
              return null;
            } else {
              return text.notValidHKID;
            }
          };

          // init member and token
          memberStorage.getMember((member, token) =>
            this.setState(
              {
                member: member,
                token: token,
              },
              () => {
                if (this.state.broker) {
                  // init broker-specific input field
                  this.setState({
                    insuranceAdvisorMobile:
                      this.state.member && this.state.member.agent.isAdmin
                        ? undefined
                        : this.state.broker.mobile,
                  });
                } else {
                  // init bound insurance advisor
                  insuranceAdvisors.getInsuranceAdvisors(
                    this.state.language,
                    (insuranceAdvisors) => {
                      const insuranceAdvisor = insuranceAdvisors.IPA;
                      if (insuranceAdvisor) {
                        this.setState({ insuranceAdvisor: insuranceAdvisor });
                      } else {
                        this.setState({ showBusinessEmailAddress: true });
                      }
                    }
                  );
                }
              }
            )
          );
        }
      )
    );
  }

  _apiDateFormat(date) {
    return moment(date, "MMMM D, YYYY").format("YYYY-MM-DD");
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState("ipa3", rawRequest);
    stateStorage.setState("ipa4", undefined);
  }

  _nextScreen() {
    this.setState({ submitButtonDisabled: true });

    const state = clearEmptyStrings(this.state);
    let attributes = {
      effectiveDate: state.effectiveDateStart,
      policyholderAddress: state.policyholderAddress,
      policyholderTitle: state.policyholderTitle,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderHKID: state.policyholderHKID,
      policyholderDOB: state.policyholderDOB,
      policyholderPhone: state.policyholderPhone,
      policyholderEmail: state.policyholderEmail,
      question1: state.question1,
      question2: state.question2,
      specialRequests: state.specialRequests,
      referral: state.referral,
      businessEmailAddress: state.businessEmailAddress,

    };
    if (!this.state.isInsured) {
      attributes.insuredFirstName = state.insuredFirstName;
      attributes.insuredLastName = state.insuredLastName;
      attributes.insuredDOB = state.insuredDOB;
      attributes.insuredHKID = state.insuredHKID;
    }
    if (state.people == "withSpouse" || state.people == "withChildren") {
      attributes.numberOfChildren = state.numberOfChildren;
    }
    if (state.people == "withSpouse") {
      attributes.spouseFirstName = state.spouseFirstName;
      attributes.spouseLastName = state.spouseLastName;
      attributes.spouseDOB = state.spouseDOB;
      attributes.spouseHKID = state.spouseHKID;
      attributes.spouseTitle = state.spouseTitle
    }
    // if(this.state.people == 'withSpouse' || this.state.people == 'withChildren'){
    //   attributes.insuredChildren = []
    //   state.insuredChildren.forEach((children, index) => {
    //     attributes.insuredChildren.push({children})
    //   })
    // }
    if (state.question1 == "Y") {
      attributes.question1Insurer = state.question1Insurer;
      attributes.question1Benefit = state.question1Benefit;
      attributes.question1Reason = state.question1Reason;
    }
    if (state.question1 == "Y") {
      attributes.question2Diagnosis = state.question2Diagnosis;
    }


    const englishFormat = {
      pattern: "[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+",
      message: this.state.text.needsToBeInEnglish,
    };

    let constraints = {
      effectiveDate: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderAddress: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderTitle: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderFirstName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderLastName: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderHKID: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      },
      policyholderDOB: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderPhone: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        numericality: { message: this.state.text.notANumber },
      },
      policyholderEmail: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        email: { message: this.state.text.notValidEmail },
        format: englishFormat,
      },
      question1: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      question2: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
      businessEmailAddress: {
        format: englishFormat,
      },
    };

    if(this.state.people == 'withSpouse' || this.state.people == 'withChildren' ) {
      constraints.numberOfChildren = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      };
    }

    if (this.state.people == "withSpouse") {
      constraints.spouseFirstName = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
      constraints.spouseLastName = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
      constraints.spouseDOB = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      };
      constraints.spouseTitle = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      };
      constraints.spouseHKID = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      };


    }
    if (this.state.question1 == "Y") {
      constraints.question1Insurer = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
      constraints.question1Benefit = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
      constraints.question1Reason = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
    }
    if (this.state.question2 == "Y") {
      constraints.question2Diagnosis = {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
    }
    let messages = validate(attributes, constraints, { fullMessages: false });

    let individualConstraints = {
      "firstName": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      "lastName": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      "dateOfBirth": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      "documentNo": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      },
      "occupation": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      "salutation": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      "relationship": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      }
    }

    //Individual Constraints
    let individualMessages = []
    this.state.insuredIndividuals.forEach((individual) => {
      let individualMessage = validate(clearEmptyStrings(individual), individualConstraints, {fullMessages: false})
      if(individualMessage != undefined){
        individualMessages.push(individualMessage)
      }
    })
    
    if(individualMessages.length != 0){
      if(!messages){
        messages = {}
      }
      messages.insuredIndividuals = individualMessages.length != 0 ? individualMessages : undefined
    }

    //Children Constraints
    let childConstraints = {
      "firstName": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      "lastName": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      "dateOfBirth": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      },
      "documentNo": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        hkid: { message: this.state.text.notValidHKID },
        format: englishFormat,
      },
      "salutation": {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
      }
    }

    if(this.state.people == 'withSpouse' || this.state.people == 'withChildren' ) {
      let childrenMessages = []
      this.state.insuredChildren.forEach((children) => {
        let childMessage = validate(clearEmptyStrings(children), childConstraints, {fullMessages: false})
        if(childMessage != undefined){
          childrenMessages.push(childMessage)
        }
      })
      
      if(childrenMessages.length != 0){
        if(!messages){
          messages = {}
        }
        messages.insuredChildren = childrenMessages.length != 0 ? childrenMessages : undefined
      }
    }
    console.log("state:", this.state)
    if (messages) {

      this.setState({
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    } else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;
      
      let people = 'I';
      let noOfChildren = '0';
      if (this.state.people == 'withSpouse') {
        people = 'F';
        noOfChildren = this.state.numberOfChildren
      }
      if (this.state.people == 'withChildren') {
        people = 'IC';
        noOfChildren = this.state.numberOfChildren
      }

      //Merge occupation
      let mergedOccupations = ""
      if(this.state.people == 'group'){
        for(let [i, individual] of this.state.insuredIndividuals.entries()){
          if(i == 0){
            mergedOccupations += individual.occupation
            continue;
          }
          mergedOccupations += (";" + individual.occupation)
        }    
      } else {
        mergedOccupations = this.state.insuredIndividuals[0].occupation
      }

      premium(
      {
        dt: moment().format("YYYYMMDDhhmmss"),
        pn: "EAPP",
        pl: this.state.planType,
        "payment.promotionCode": this.state.promotionCodes,
        "policy.effectiveDateStart": this._apiDateFormat(this.state.effectiveDateStart),
        "policy.businessEmail": this.state.businessEmailAddress,
        insuredOccupation: mergedOccupations,
        spouseOccupation: this.state.people == "withSpouse" ? this.state.spouseOccupation : undefined, 
        noOfChildren: noOfChildren ? noOfChildren.toString() : undefined, // Number of Children (Only provide when choosing "Indivdual and Children")
        premiumPlan: people,
        "ipa.sumInsuredA1": null,
        "ipa.sumInsuredA2": null,
        "ipa.sumInsuredB": null,
        "ipa.sumInsuredC": null,
        "ipa.includeD": null,
        "member.id": this.state.member ? this.state.member.id : undefined,
        "member.token": this.state.token ? this.state.token.tokenStr : undefined,
      },
      (msgDesc) => {
          let members = [];
          // if (this.state.isInsured) {
          //   members.push({
          //     salutation: this.state.policyholderTitle,
          //     firstName: this.state.policyholderFirstName,
          //     lastName: this.state.policyholderLastName,
          //     documentType: "HKID",
          //     documentNo: this.state.policyholderHKID,
          //     dateOfBirth: this._apiDateFormat(this.state.policyholderDOB),
          //     relationship: "insured",
          //     occupation: this.state.insuredOccupation,
          //   });
          // } else {
          //   members.push({
          //     salutation: this.state.insuredTitle,
          //     firstName: this.state.insuredFirstName,
          //     lastName: this.state.insuredLastName,
          //     documentType: "HKID",
          //     documentNo: this.state.insuredHKID,
          //     dateOfBirth: this._apiDateFormat(this.state.insuredDOB),
          //     relationship: "insured",
          //     occupation: this.state.insuredOccupation,
          //   });
          // }
          let individuals = this.state.insuredIndividuals
          individuals.forEach((individual) => {
            individual.dateOfBirth = this._apiDateFormat(individual.dateOfBirth)
            members.push(individual)
          })

          if (this.state.people == "withSpouse") {
              members.push({
                salutation: this.state.spouseTitle,
                firstName: this.state.spouseFirstName,
                lastName: this.state.spouseLastName,
                documentType: "HKID",
                documentNo: this.state.spouseHKID,
                dateOfBirth: this._apiDateFormat(this.state.spouseDOB),
                relationship: "spouse",
                occupation: this.state.spouseOccupation,
              });
          }
            
          //Append Children to Member 
          let hasChildren = this.state.people == "withSpouse" || this.state.people == "withChildren"
          let children = hasChildren ? this.state.insuredChildren : []
          children.forEach((child) => { 
            child.dateOfBirth = this._apiDateFormat(child.dateOfBirth)
            child.occupation = undefined;
            members.push(child)
          })

          let rawRequest = Object.assign({}, state);
          delete rawRequest.messages;
          delete rawRequest.text;

          let premium = msgDesc.premium;
          let levy = msgDesc.levy;
          let total = premium.discount + levy;

          stateStorage.setState("ipa3", rawRequest);
          stateStorage.setState("ipa4", undefined);

          this.props.history.push("/ipa4", {
            broker: this.state.broker,
            insuranceAdvisor: state.insuranceAdvisor,
            insuranceAdvisorMobile: state.insuranceAdvisorMobile,
            product: state.product,
            planType: state.planType,
            effectiveDateStart: state.effectiveDateStart,
            effectiveDateEnd: state.effectiveDateEnd,
            promotionCodes: removeSpaces(state.promotionCodes),
            businessEmailAddress: removeSpaces(state.businessEmailAddress),
            specialRequests: state.specialRequests,
            referral: state.referral,
            occupations: this.state.occupations,
            policyholderTitle: state.policyholderTitle,
            policyholderFirstName: state.policyholderFirstName,
            policyholderLastName: state.policyholderLastName,
            policyholderDOB: state.policyholderDOB,
            policyholderPhone: state.policyholderPhone,
            policyholderEmail: state.policyholderEmail,
            policyholderHKID: state.policyholderHKID,
            policyholderAddress: state.policyholderAddress,
            insuredOccupation: state.insuredOccupation,
            spouseOccupation: state.spouseOccupation,
            insuredOccupationLabel: state.insuredOccupationLabel,
            spouseOccupationLabel: state.spouseOccupationLabel,
            question1: state.question1,
            question1Insurer: state.question1Insurer,
            question1Benefit: state.question1Benefit,
            question1SumInsured: state.question1SumInsured,
            question1Reason: state.question1Reason,
            question1Condition: state.question1Condition,
            question2: state.question2,
            question2SufferedArea: state.question2SufferedArea,
            question2Diagnosis: state.question2Diagnosis,
            coverageA1: this.state.coverageA1,
            coverageA2: this.state.coverageA2,
            coverageB: this.state.coverageB,
            coverageC: this.state.coverageC,
            coverageD: this.state.coverageD,
            members: members,
            rawRequest: rawRequest,
            premium: premium,
            levy: levy,
            total: total,
          });

        this.setState({ submitButtonDisabled: false });
      },
      (msgCode, msgDesc, handleDefault) => {
        if (msgCode == 40) {
          if (this.state.broker) {
            this.setState({
              messages: {
                promotionCodes: this.state.text["invalidPromotionCode"],
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () => this.setState({ alert: null }),
                    style: "cancel",
                  },
                ]
              ),
            });
          } else {
            Alert.alert(
              this.state.text.addInsuranceAdvisor,
              this.state.text.wouldYouLike +
              msgDesc.agent.brokerName +
              this.state.text.toBeYourInsuranceAdvisor,
              [
                {
                  text: this.state.text.yes,
                  onPress: () =>
                    insuranceAdvisors.setInsuranceAdvisor(
                      { agentCode: msgDesc.agent.agentCode },
                      ["IPA"],
                      this.state.language,
                      () =>
                        this.setState({ nextScreen: true }, () =>
                          this._nextScreen()
                        )
                    ),
                },
                {
                  text: this.state.text.no,
                  onPress: () =>
                    this.setState({
                      messages: {
                        promotionCodes:
                          this.state.text["invalidPromotionCode"],
                      },
                      alert: Alert.alert(
                        this.state.text.alert,
                        this.state.text.check,
                        [
                          {
                            text: this.state.text.ok,
                            onPress: () => this.setState({ alert: null }),
                            style: "cancel",
                          },
                        ]
                      ),
                    }),
                },
              ],
              { cancelable: false }
            );
          }
        } else if (msgCode == 56) {
        this.setState({
          messages: {
            promotionCodes: this.state.text["invalidPromotionCode"],
          },
          alert: Alert.alert(this.state.text.alert, this.state.text.check, [
            {
              text: this.state.text.ok,
              onPress: () => this.setState({ alert: null }),
              style: "cancel",
            },
          ]),
        });
        } else {
          handleDefault();
        }
        this.setState({ submitButtonDisabled: false });
      }
      )
    }
  }

  _onInsuredIndividualChange(newInsured, index, type) {
      let newInsuredIndividual = type == 'children' ? this.state.insuredChildren : this.state.insuredIndividuals
      newInsuredIndividual[index] = newInsured
      if(type == "children"){
        this.setState({
          insuredChildren: [...newInsuredIndividual]
        });
      } else {
        this.setState({
          insuredIndividuals: [...newInsuredIndividual]
        });
      }

  }

  render() {
    const color = Member.getThemeColor();
    let occupationArray = this.state.insuredOccupation.toString().includes(";") ? this.state.insuredOccupation.split(";") : [this.state.insuredOccupation] 

    //Individuals(adult) data
    for(let individualIndex = 0; individualIndex < this.state.numberOfIndividuals; individualIndex++) {
      let isSameToPolicyHolder = individualIndex == 0 && this.state.isInsured
      let previousDataExists = this.state.insuredIndividuals[individualIndex] != undefined ? true : false
      let insured = this.state.insuredIndividuals[individualIndex]

      if(isSameToPolicyHolder){
        this.state.insuredIndividuals[individualIndex] =  {
          firstName: this.state.policyholderFirstName,
          lastName: this.state.policyholderLastName,
          documentType: "HKID",
          documentNo: this.state.policyholderHKID,
          dateOfBirth: this.state.policyholderDOB,
          salutation: this.state.policyholderTitle,
          relationship: "insured",
          occupation: previousDataExists ? insured.occupation : occupationArray[individualIndex],
        }
      } else {
        this.state.insuredIndividuals[individualIndex] =  {
          firstName: previousDataExists ? insured.firstName : undefined,
          lastName: previousDataExists ? insured.lastName : undefined,
          documentType: "HKID",
          documentNo: previousDataExists ? insured.documentNo : undefined,
          dateOfBirth: previousDataExists ? insured.dateOfBirth : undefined,
          salutation: previousDataExists ? insured.salutation : undefined,
          relationship: previousDataExists ? insured.relationship : "",
          occupation: previousDataExists ? insured.occupation : occupationArray[individualIndex],
        }
      }
      if(this.state.people != "group"){
        break;
      }
      
    }

    //Children data
    for(let childrenIndex = 0; childrenIndex < parseInt(this.state.numberOfChildren); childrenIndex++){
      let previousDataExists = this.state.insuredChildren[childrenIndex] != undefined ? true : false
      let insured = this.state.insuredChildren[childrenIndex]
      this.state.insuredChildren[childrenIndex] = {
        firstName: previousDataExists ? insured.firstName : undefined,
        lastName: previousDataExists ? insured.lastName : undefined,
        documentType: "HKID",
        documentNo: previousDataExists ? insured.documentNo : undefined,
        dateOfBirth: previousDataExists ? insured.dateOfBirth : undefined,
        salutation: previousDataExists ? insured.salutation : undefined,
        relationship: "child",
        occupation: "N/A",
      }
    }
    

    //Handle component rendering for individuals and children
    let insuredIndividuals = [];
    let insuredChildren = [];
    for (let [index, insured] of Object.values(this.state.insuredIndividuals).entries()) {
      const isEditable = this.state.isInsured && index == 0 ? false : true;
      insuredIndividuals.push(
        <Insured
          index={index}
          type='individual'
          insured={insured}
          number={index}
          isEditable={isEditable}
          formatName={formatName}
          messages={this.state.messages.insuredIndividuals ? this.state.messages.insuredIndividuals[index] : undefined}
          people={this.state.people}
          occupationsList={this.state.occupations}
          onInsuredChange={(newInsured) => {this._onInsuredIndividualChange(newInsured, index, "individual")}}
          options={options}
          effectiveDateStart={this.state.effectiveDateStart}
        />
      );
    }

    for (let [index, insured] of this.state.insuredChildren.entries()) {
      insuredChildren.push(
        <Insured
          index={index}
          type='children'
          insured={insured}
          number={index}
          isEditable={true}
          formatName={formatName}
          messages={this.state.messages.insuredChildren ? this.state.messages.insuredChildren[index] : undefined}
          people={this.state.people}
          occupationsList={this.state.occupations}
          onInsuredChange={(newInsured) => {this._onInsuredIndividualChange(newInsured, index, "children")}}
          options={options}
          effectiveDateStart={this.state.effectiveDateStart}
        />
      );
    }

    return (
      <div className="App">
        <Helmet>
          <title>{this.state.text.generaliPersonalAccidentInsurancePlan}</title>
        </Helmet>

        <Navbar
          title={this.state.text.getInsured}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
          <Hidden xsDown>
            <Grid
              item
              xs
              style={{ marginBottom: 13, marginLeft: "5%", marginRight: "5%" }}
            >
              <View style={[styles.greeting, { fontWeight: "bold" }]}>
                <Text
                  style={[
                    styles.greetingText,
                    { marginTop: "10%", fontSize: 35 },
                  ]}
                >
                  {this.state.text.epa}
                </Text>
                <p></p>
                {this.state.language == "zhHK" ? (
                  <View>
                    <Text style={{ fontSize: 20, textAlign: "center" }}>
                      {this.state.text.enjoy}
                    </Text>
                  </View>
                ) : (
                  <View>
                    <Text style={{ fontSize: 20, textAlign: "center" }}>
                      {this.state.text.enjoy}
                    </Text>
                  </View>
                )}
              </View>
            </Grid>
          </Hidden>

          <Grid
            item
            xs
            style={{
              marginTop: 30,
              marginBottom: 50,
              backgroundColor: "white",
            }}
          >
            <ScrollView style={(formStyles.form, styles.fie)}>
              {this.state.alert}
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h2}>
                  {this.state.text.generaliPersonalAccidentInsurancePlan}
                </Text>
              </View>

              {this.state.insuranceAdvisor || this.state.broker ? (
                <View style={formStyles.fieldset}>
                  <Text style={{ textAlign: "left" }}>
                    {this.state.text.theInsuranceIsIssued}
                    {this.state.broker
                      ? this.state.broker.name
                      : this.state.insuranceAdvisor.agent.broker.nameEnUs}
                    {this.state.text.professionalInsurance}
                  </Text>
                </View>
              ) : (
                <View style={formStyles.fieldset}>
                  <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
                </View>
              )}

              <Text>{this.state.text.pleaseInputInEnglish}</Text>

              {this.state.broker && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.insuranceAdvisorMobile}
                  </Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) =>
                      this.setState({ insuranceAdvisorMobile: text })
                    }
                    value={this.state.insuranceAdvisorMobile}
                    editable={
                      this.state.member && this.state.member.agent.isAdmin
                    }
                    keyboardType="phone-pad"
                    maxLength={8}
                  />
                </View>
              )}

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.planType}</Text>
                <ModalSelector
                  data={[
                    {
                      key: "IPA1",
                      label: planTypes.IPA1.label[this.state.language],
                    },
                    {
                      key: "IPA2",
                      label: planTypes.IPA2.label[this.state.language],
                    },
                    {
                      key: "IPA3",
                      label: planTypes.IPA3.label[this.state.language],
                    },
                  ]}
                  initValue={
                    planTypes[this.state.planType].label[this.state.language]
                  }
                  onChange={(option) => this.setState({ planType: option.key })}
                  cancelText={this.state.text.cancel}
                />
              </View>
              <View style={formStyles.fieldset}>
                <Text style={styles.h4}>{this.state.text.coverType}</Text>
                <ModalSelector
                  data={[
                    {
                      key: "individual",
                      label:
                        options.people.individual.label[this.state.language],
                    },
                    {
                      key: "withSpouse",
                      label:
                        options.people.withSpouse.label[this.state.language],
                    },
                    {
                      key: "group",
                      label: options.people.group.label[this.state.language],
                    },
                    {
                      key: "withChildren",
                      label:
                        options.people.withChildren.label[this.state.language],
                    },

                  ]}
                  initValue={
                    options.people[this.state.people].label[this.state.language]
                  }
                  onChange={(option) => {
                    this.setState({ people: option.key, insuredIndividuals: []})}
                  }
                  cancelText={this.state.text.cancel}
                />
              </View>
              {(this.state.people == "withSpouse" ||
                this.state.people == "withChildren") && (
                  <View>
                    <View style={formStyles.hr} />
                    <View style={formStyles.fieldset}>
                      <Text style={[formStyles.h3, { color }]}>
                        {
                          options.people[this.state.people].label[
                          this.state.language
                          ]
                        }
                      </Text>
                    </View>
                    <View style={styles.fieldset}>
                    <Text style={styles.h4}>{this.state.text.numberOfChildren}</Text>
                      <TextInput
                        style={styles.input}
                        onChangeText={(text) => {
                          if(parseInt(text) > 10 || parseInt(text) < 0){
                            this.setState({alert: Alert.alert(
                              this.state.text.alert,
                              this.state.text.limitedTo10,
                              [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
                            )})
                            return;                          
                          }
                          let lessChild = text < this.state.numberOfChildren
                          this.setState({ 
                            numberOfChildren: text,
                            insuredChildren: lessChild ? this.state.insuredChildren.slice(0, parseInt(text)) : this.state.insuredChildren
                          })

                        }
                        }
                        value={this.state.numberOfChildren}
                        keyboardType="numeric"
                      />
                      <Text style={formStyles.message}>
                        {this.state.messages.numberOfChildren}
                      </Text>
                    </View>
                  </View>
                )}
              {this.state.people == "group" && (
                <View>
                  <View style={formStyles.hr} />
                  <View style={formStyles.fieldset}>
                    <Text style={[formStyles.h3, { color }]}>
                      {this.state.text.groupOfIndividuals}
                    </Text>
                  </View>
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.numberOfIndividuals}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) => {
                        if(parseInt(text) > 10 || parseInt(text) < 1){
                          this.setState({alert: Alert.alert(
                            this.state.text.alert,
                            this.state.text.limitedTo10,
                            [{text: this.state.text.ok, onPress: () => this.setState({alert: null})}],
                          )})
                          return;                          
                        }
                        let lessIndividuals = text < this.state.numberOfIndividuals
                        this.setState({ 
                          numberOfIndividuals: text,
                          insuredIndividuals: lessIndividuals ? this.state.insuredIndividuals.slice(0, parseInt(text)) : this.state.insuredIndividuals
                        })

                      }
                      }
                      value={this.state.numberOfIndividuals}
                      keyboardType="numeric"
                    />
                    {parseInt(this.state.numberOfIndividuals) > 10 && (
                      <Text>
                        {this.state.text.manualUnderwritingIsRequired}
                      </Text>
                    )}
                  </View>
                </View>
              )}

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.effectiveDate}
                </Text>
                <DatePicker
                  style={formStyles.datepicker}
                  date={this.state.effectiveDateStart}
                  mode="date"
                  placeholder={this.state.text.pleasePickADate}
                  format="MMMM D, YYYY"
                  minDate={moment().add(1, "days").format("MMMM D, YYYY")}
                  maxDate={moment()
                    .add(1, "days")
                    .add(6, "months")
                    .format("MMMM D, YYYY")}
                  onDateChange={(date) => {
                    this.setState({
                      effectiveDateStart: date,
                      effectiveDateEnd: moment(date, "MMMM D, YYYY")
                        .add(1, "years")
                        .subtract(1, "days")
                        .format("MMMM D, YYYY"),
                    });
                  }}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.effectiveDate}
                </Text>
              </View>
              {this.state.effectiveDateStart && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.periodOfInsurance}
                  </Text>
                  <View style={styles.periodOfInsurance}>
                    <Text>
                      {this.state.effectiveDateStart} -{" "}
                      {this.state.effectiveDateEnd}
                    </Text>
                    <Text>{this.state.text.bothDatesInclusive}</Text>
                  </View>
                </View>
              )}
              {!this.state.businessEmailAddress && (
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>
                    {this.state.text.promotionCodes}
                  </Text>
                  <TextInput
                    style={formStyles.input}
                    placeholder={this.state.text.optional}
                    onChangeText={(text) =>
                      this.setState({ promotionCodes: text })
                    }
                    value={this.state.promotionCodes}
                  />
                  <Text style={formStyles.message}>
                    {this.state.messages.promotionCodes}
                  </Text>
                </View>
              )}
              {!this.state.promotionCodes &&
                this.state.showBusinessEmailAddress && (
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>
                      {this.state.text.businessEmailAddress}
                    </Text>
                    <TextInput
                      style={formStyles.input}
                      placeholder={this.state.text.optional}
                      keyboardType="email-address"
                      onChangeText={(text) =>
                        this.setState({ businessEmailAddress: text })
                      }
                      value={this.state.businessEmailAddress}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.businessEmailAddress}
                    </Text>
                  </View>
                )}
              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>
                  {this.state.text.policyholder}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.name}</Text>
                <NameInput
                  title={this.state.policyholderTitle}
                  firstName={this.state.policyholderFirstName}
                  lastName={this.state.policyholderLastName}
                  onChange={(title, firstName, lastName) =>
                    this.setState({
                      policyholderTitle: title,
                      policyholderFirstName: firstName,
                      policyholderLastName: lastName,
                    })
                  }
                />
                <Text style={formStyles.message}>
                  { this.state.messages.policyholderFirstName || this.state.messages.policyholderLastName || this.state.messages.policyholderTitle }
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ policyholderHKID: text ? validid.utils.normalize(text) : text })
                  }
                  value={this.state.policyholderHKID}
                  placeholder={this.state.text.a1234567}
                />
                <Text>{this.state.text.pleaseEnterAllHKID}</Text>
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderHKID}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                <DatePicker
                  style={formStyles.datepicker}
                  date={this.state.policyholderDOB}
                  mode="date"
                  placeholder={this.state.text.pleasePickADate}
                  format="MMMM D, YYYY"
                  minDate={moment(this.state.effectiveDateStart)
                    .subtract(66, "years")
                    .add(1, 'days')
                    .format("MMMM D, YYYY")}
                  maxDate={moment(this.state.effectiveDateStart)
                    .subtract(18, "years")
                    .format("MMMM D, YYYY")}
                  onDateChange={(date) =>
                    this.setState({ policyholderDOB: date })
                  }
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderDOB}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.mobileNumber}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ policyholderPhone: text })
                  }
                  value={this.state.policyholderPhone}
                  keyboardType="phone-pad"
                  maxLength={8}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderPhone}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.correspondenceAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ policyholderAddress: text })
                  }
                  value={this.state.policyholderAddress}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderAddress}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>
                  {this.state.text.emailAddress}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ policyholderEmail: text })
                  }
                  value={this.state.policyholderEmail}
                  keyboardType="email-address"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.policyholderEmail}
                </Text>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.isInsured}
                      onValueChange={(value) => {
                        let newInsuredIndividual = this.state.insuredIndividuals[0]
                        newInsuredIndividual.relationship = value ? "parInLaw" : undefined
                        this.setState({ 
                          isInsured: value,
                        }, this._onInsuredIndividualChange(newInsuredIndividual, 0, "individual"))
                      }}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>
                      {this.state.text.policyholderIs}{" "}
                      {this.state.people == "individual"
                        ? this.state.text.theInsured
                        : this.state.text.oneOfTheInsureds}
                    </Text>
                  </View>
                </View>
              </View>

              {insuredIndividuals}

              {this.state.people == "withSpouse" && (
                <View>
                  <View style={formStyles.hr} />
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h3}>{this.state.text.spouse}</Text>
                  </View>

                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.name}</Text>
                    <NameInput
                      title={this.state.spouseTitle}
                      firstName={this.state.spouseFirstName}
                      lastName={this.state.spouseLastName}
                      onChange={(title, firstName, lastName) =>{
                        this.setState({
                          spouseTitle: title,
                          spouseFirstName: firstName,
                          spouseLastName: lastName,
                        })
                      }
                      }
                    />
                    <Text style={formStyles.message}>
                      { this.state.messages.spouseFirstName || this.state.messages.spouseLastName || this.state.messages.spouseTitle }
                    </Text>
                  </View>

                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>
                      {this.state.text.dateOfBirth}
                    </Text>
                    <DatePicker
                      style={formStyles.datepicker}
                      date={this.state.spouseDOB}
                      mode="date"
                      placeholder={this.state.text.pleasePickADate}
                      format="MMMM D, YYYY"
                      minDate={moment(this.state.effectiveDateStart)
                        .subtract(66, "years")
                        .add(1, 'days')
                        .format("MMMM D, YYYY")}
                      maxDate={moment(this.state.effectiveDateStart)
                        .subtract(18, "years")
                        .format("MMMM D, YYYY")}
                      onDateChange={(date) => {
                        this.setState({ spouseDOB: date });
                      }}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.spouseDOB}
                    </Text>
                  </View>
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) =>
                        this.setState({ spouseHKID: text })
                      }
                      value={this.state.spouseHKID}
                      placeholder={this.state.text.a1234567}
                    />
                    <Text>{this.state.text.pleaseEnterAllHKID}</Text>
                    <Text style={formStyles.message}>
                      {this.state.messages.spouseHKID}
                    </Text>
                  </View>
                  <View style={formStyles.fieldset}>
                    <Text style={styles.h4}>{this.state.text.occupation}</Text>
                    <ModalSelector
                      data={this.state.occupations}
                      initValue={
                        this.state.spouseOccupationLabel
                          ? this.state.spouseOccupationLabel.label
                          : ""
                      }
                      onChange={(option) =>
                        this.setState({
                          spouseOccupation: option.key,
                          spouseOccupationLabel: this.state.occupations.find(
                            (obj) => obj.key === option.key
                          ),
                        })
                      }
                      cancelText={this.state.text.cancel}
                    />
                    {(this.state.spouseOccupation == "OC3" ||
                      this.state.spouseOccupation == "OC4") && (
                        <Text>
                          {this.state.text.manualUnderwritingsIsRequired}
                        </Text>
                      )}
                  </View>
                  <View style={formStyles.hr} />
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.relationship}</Text>
                    <ModalSelector
                      data={[
                        {
                          key: "insured",
                          label: options.relationship.insured[this.state.language]
                        },
                        {
                          key: "spouse",
                          label: options.relationship.spouse[this.state.language]
                        },
                        {
                          key: "child",
                          label: options.relationship.child[this.state.language]
                        },
                        {
                          key: "parents",
                          label: options.relationship.parents[this.state.language]
                        },
                        {
                          key: "parInLaw",
                          label: options.relationship.parInLaw[this.state.language]
                        },            
                        {
                          key: "employee",
                          label: options.relationship.employee[this.state.language]
                        },
                      ]}
                      initValue={options.relationship.spouse[this.state.language]}
                      disabled={true}
                    />
                  </View>
                </View>)}

              { 
                (this.state.people == 'withChildren' || this.state.people == 'withSpouse') && 
                <View>
                  {insuredChildren}

                  {this.state.numberOfChildren != 10 &&
                    <TouchableOpacity style={formStyles.addButton}
                    onPress={() => {

                      this.setState({numberOfChildren: parseInt(this.state.numberOfChildren) + 1})
                      }}>
                      <Text style={formStyles.addButtonText}>{this.state.text.addAChild}</Text>
                    </TouchableOpacity>
                  }
                </View>
              }



              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.questions}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={
                    this.state.text.haveYourOrOtherCoveredMembersAPPOflife
                  }
                  choice={this.state.question1}
                  onChange={(choice) => this.setState({ question1: choice })}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.question1}
                </Text>
              </View>
              {this.state.question1 == "Y" && (
                <View>
                  <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.insurer}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) =>
                        this.setState({ question1Insurer: text })
                      }
                      value={this.state.question1Insurer}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.question1Insurer}
                    </Text>
                  </View>
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.benefit}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) =>
                        this.setState({ question1Benefit: text })
                      }
                      value={this.state.question1Benefit}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.question1Benefit}
                    </Text>
                  </View>

                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.reason}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) =>
                        this.setState({ question1Reason: text })
                      }
                      value={this.state.question1Reason}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.question1Reason}
                    </Text>
                  </View>
                </View>
              )}
              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={
                    this.state.text
                      .doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment
                  }
                  choice={this.state.question2}
                  onChange={(choice) => this.setState({ question2: choice })}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.question2}
                </Text>
              </View>
              {this.state.question2 == "Y" && (
                <View>
                  <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>

                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>
                      {this.state.text.diagnosis}
                    </Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) =>
                        this.setState({ question2Diagnosis: text })
                      }
                      value={this.state.question2Diagnosis}
                    />
                    <Text style={formStyles.message}>
                      {this.state.messages.question2Diagnosis}
                    </Text>
                  </View>
                </View>
              )}

              <View style={formStyles.hr} />
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>
                  {this.state.text.specialRequests}
                </Text>
              </View>
              <View style={formStyles.fieldset}>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) =>
                    this.setState({ specialRequests: text })
                  }
                  value={this.state.specialRequests}
                  placeholder={this.state.text.optional}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.specialRequests}
                </Text>
                {!!this.state.specialRequests && (
                  <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                )}
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.referral}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ referral: text })}
                  value={this.state.referral}
                  placeholder={this.state.text.optional}
                />
                <Text style={formStyles.message}>
                  {this.state.messages.referral}
                </Text>
              </View>
              {this.state.submitButtonDisabled ? (
                <View style={formStyles.submitButtonDisabled}>
                  <Text style={formStyles.submitButtonText}>
                    {this.state.text.pleaseWait}
                  </Text>
                </View>
              ) : (
                <TouchableOpacity
                  style={formStyles.submitButton}
                  onPress={() => this._nextScreen()}
                >
                  <Text style={formStyles.submitButtonText}>
                    {this.state.text.buyNow}
                  </Text>
                </TouchableOpacity>
              )}
            </ScrollView>
          </Grid>
          <Hidden xsDown>
            <div style={{ marginRight: "5%" }} />
          </Hidden>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fie: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
