import React from 'react';
import { Text, View, ScrollView, TouchableOpacity } from 'react-native';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../../lib/LanguageStorage';
import { host, api26, busiSecKey } from './../../../config/api';
import { msgCodeDetail } from './../../../lib/utils';
import { planTypes } from './planTypes';
import { removeSpaces } from './../../../lib/utils';
import { stateStorage } from './../../../lib/StateStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import Alert  from './../../../components/Alert/Alert';
import { instituteLocations } from './instituteLocations';
import { formStyles } from './../../../config/styles';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import ProductDetailFolder from './../../../components/ProductDetailFolder/ProductDetailFolder';
import TotalCostSection from './../../../components/TotalCostSection/TotalCostSection';

import Member from './../../../Member';

export default class ASAP2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      broker: this.props.location.state.broker,
      planType: this.props.location.state.planType,
      instituteLocation: this.props.location.state.instituteLocation,
      promotionCodes: this.props.location.state.promotionCodes,
      businessEmailAddress: this.props.location.state.businessEmailAddress,
      showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
      premium: this.props.location.state.premium,
      referral: this.props.location.state.referral,
      text: {},
    };
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('asap2', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0)
    const getText = (language) => {
     let text = enUS;
     if (language == 'zhHK') {
       text = zhHK;
     }
     return text;
   };
   languageStorage.getLanguage(
     (language) => this.setState({
       language: language,
       text: getText(language),
     }));

     memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }))
  }

  _isLogin(){

    if (this.state.member) {
      this._nextScreen();
    }else{
      this._nextScreen();
      // this.setState({
      //   alert:
      //     Alert.alert(
      //       this.state.text.forBetterCustomerExperiencelogin,
      //       this.state.text.forBetterCustomerExperience,
      //       [{
      //         text: this.state.text.forBetterCustomerExperiencelogin,
      //         onPress: () => this.props.history.push('Login'),
      //       },{
      //         text: this.state.text.forBetterCustomerExperiencregister,
      //         onPress: () => this.props.history.push('Register'),
      //       },
      //       {
      //         text: this.state.text.forBetterCustomerExperienceCon,
      //         onPress: () => this._nextScreen(),
      //       }],

      //     )
      // });

  }
  }

  _nextScreen() {
    delete this.state.alert;
    if (this.state.broker) {
    stateStorage.setState('asap2', this.state);
    stateStorage.setState('asap3', undefined);

    this.props.history.push('/asap3', {
      broker: this.state.broker,
      planType: this.state.planType,
      instituteLocation: this.state.instituteLocation,
      promotionCodes: removeSpaces(this.state.promotionCodes),
      businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
      showBusinessEmailAddress: this.state.showBusinessEmailAddress,
      referral: this.state.referral,
    });
  }else{
      var getToday = new Date();
      var startTime = moment(getToday, "DD-MM-YYYY hh:mm:ss");
      //var startTime = moment("11-03-2022 23:31:00", "DD-MM-YYYY hh:mm:ss"); //start 1380m
      var endTime = moment("09-07-2022 23:00:00", "DD-MM-YYYY hh:mm:ss");
      var minutesDiff = endTime.diff(startTime, "minutes");
      console.log("Minutes:" + minutesDiff);
      if (minutesDiff > 1440 || minutesDiff < 1) {
        stateStorage.setState('asap2', this.state);
        stateStorage.setState('asap3', undefined);
        this.props.history.push('/asap3', {
          broker: this.state.broker,
          planType: this.state.planType,
          instituteLocation: this.state.instituteLocation,
          promotionCodes: removeSpaces(this.state.promotionCodes),
          businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
          showBusinessEmailAddress: this.state.showBusinessEmailAddress,
          referral: this.state.referral,
        });
      } else {
        this.setState({
          alert: Alert.alert(
            this.state.text.paymentStopService1,
            this.state.text.paymentStopService2,
            [
              {
                text: this.state.text.ok,
                onPress: () => {
                  this.setState({ alert: null });
                },
              },
            ]
          ),
        });
      }
  }
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    let planDetails = [
      {
        key: this.state.text.location,
        value: instituteLocations[this.state.instituteLocation].label[this.state.language],
      },
    ];
    if (this.state.promotionCodes) {
      planDetails.push(
        {
          key: this.state.text.promotionCodes,
          value: this.state.promotionCodes,
        }
      );
    }
    if (this.state.businessEmailAddress) {
      planDetails.push(
        {
          key: this.state.text.businessEmailMembership,
          value: this.state.businessEmailAddress,
        }
      );
    }

    return (
      <div className='App'>
      <Navbar
        title={this.state.text.getAQuote}
        backButton
        history={this.props.history}
      />

      <Helmet>
        <title>Annual Study Abroad Protector | Bravo Generali</title>
      </Helmet>

      {this.state.alert}

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.asap}</Text>
              <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.ensure}</Text>
            </View>

        </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <View style={styles.container, styles.fie}>

            <ScrollView style={styles.form}>
            <Text style={styles.h4}>{this.state.text.iWouldLikeToBuy}</Text>

            <ProductDetailFolder
              label={planTypes[this.state.planType].label}
              color={planTypes[this.state.planType].color}
              backgroundColor={planTypes[this.state.planType].backgroundColor}
              planDetails={planDetails}
            />

            <TotalCostSection premium={this.state.premium} />
          </ScrollView>

          <TouchableOpacity style={[styles.submitButton, {backgroundColor}]} onPress={() => this._isLogin()}>
            <Text style={styles.submitButtonText}>{this.state.text.buyNow}</Text>
          </TouchableOpacity>
         </View>
        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  h4: {
    marginTop: 20,
    marginBottom: 20,
  },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
};
