import React from 'react';
import {View, Text} from 'react-native';

import Locale from './../../Locale';
import {Heading2, TextInput,
  DropDownList, YesNoQuestion, Divider} from './../../Form';
import HengFaChuenChecking from './../HengFaChuenChecking';

export default class InsuredHomeForm extends React.Component {
  checkHengFaChuen() {
    const {fullAddress} = this.props.insuredHome;

    if (fullAddress && fullAddress.length) {
      return HengFaChuenChecking.check(fullAddress);
    }
  }

  render() {
    const {insuredHome, onChange, messages} = this.props;

    return (
      <View>
        <YesNoQuestion
          label={Locale.text.beingTheOwnerOftheInsuredPremises}
          value={insuredHome.isOwnedByHolder}
          onChange={(isOwnedByHolder) => onChange({isOwnedByHolder})}
          message={messages && messages.isOwnedByHolder}
        />

        <Divider />

        <Heading2>{Locale.text.insuredPremises}</Heading2>

        <TextInput
          label={Locale.text.address}
          value={insuredHome.fullAddress}
          onChange={(fullAddress) => onChange({fullAddress})}
          message={messages && messages.fullAddress}
        />

        {
          this.checkHengFaChuen() &&
            <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        <DropDownList
          label={Locale.text.residentialOrCommercial}
          options={[
            {value: 'residential', label: Locale.text.residential},
            {value: 'commercial', label: Locale.text.commercial},
          ]}
          value={insuredHome.residentialOrCommercial}
          onChange={(residentialOrCommercial) =>
            onChange({residentialOrCommercial})}
          message={messages && messages.residentialOrCommercial}
        />

        <DropDownList
          label={Locale.text.houseOrApartment}
          options={[
            {value: 'H', label: Locale.text.house},
            {value: 'A', label: Locale.text.apartment},
          ]}
          value={insuredHome.homeType}
          onChange={(homeType) => onChange({homeType})}
          message={messages && messages.homeType}
        />

        {
          insuredHome.homeType == 'H' &&
          <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        <YesNoQuestion
          label={Locale.text.postCopyToMortgagee}
          value={insuredHome.isPostPolicy}
          onChange={(isPostPolicy) => onChange({isPostPolicy})}
          message={messages && messages.isPostPolicy}
        />

        {
          insuredHome.isPostPolicy == 'Y' &&
            <TextInput
              label={Locale.text.nameOfMortgagee}
              value={insuredHome.mortgageeName}
              onChange={(mortgageeName) => onChange({mortgageeName})}
              message={messages && messages.mortgageeName}
            />
        }

        {
          insuredHome.isPostPolicy == 'Y' &&
            <TextInput
              label={Locale.text.addressOfMortgagee}
              value={insuredHome.mortgageeAddress}
              onChange={(mortgageeAddress) => onChange({mortgageeAddress})}
              message={messages && messages.mortgageeAddress}
            />
        }

        <YesNoQuestion
          label={Locale.text.fireIsUnoccupiedForLonger30Days}
          value={insuredHome.isUnoccupied}
          onChange={(isUnoccupied) => onChange({isUnoccupied})}
          manualUnderwriting={insuredHome.isUnoccupied == 'Y'}
          message={messages && messages.isUnoccupied}
        />

        <YesNoQuestion
          label={Locale.text.anyLossDamageLiabilityOrAccident}
          value={insuredHome.isAnyLoss}
          onChange={(isAnyLoss) => onChange({isAnyLoss})}
          manualUnderwriting={insuredHome.isAnyLoss == 'Y'}
          message={messages && messages.isAnyLoss}
        />

        <YesNoQuestion
          label={Locale.text.anyInsuranceCompnayDeclined}
          value={insuredHome.isProposalDeclined}
          onChange={(isProposalDeclined) => onChange({isProposalDeclined})}
          manualUnderwriting={insuredHome.isProposalDeclined == 'Y'}
          message={messages && messages.isProposalDeclined}
        />
      </View>
    );
  }
}
