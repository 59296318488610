export const enUS = {
  instantClaims: 'InstantClaimsScreens',
  submitAClaim: 'You can submit a claim in just a few clicks',
  travelInsurance: 'Travel Insurance',
  helperNPersonal: 'Helper & Personal Accident Insurance',
  homeInsurance: 'Home Insurance',
  useTheMobileApp: 'Please Use the Mobile App',
  toSubmitAClaim: 'For claim submissions, please download our “BRAVO GENERALI” app.',
  ok: 'OK',
  claimAlertText1: 'Insurance Products (excluding USA Plan):',
  claimAlertText2: 'For claim submissions, please download our “BRAVO GENERALI” app.',
  claimAlertText3: 'USA Plan:',
  claimAlertText4: 'Medical related benefits: Please submit your claim via the below link:-',
  claimAlertText5: 'Other Products:',
  claimAlertText6: 'For claim submissions, please download our “BRAVO GENERALI” app.',
  NeedToMakeClaim:'Need to make a claim?',
  followingLink:'To submit a claim, please click the following link.',
  followingLinkText:'https://www.generali.com.hk/EN_US/claims_and_support/individual',
  claimContactText:'Claim contact email: ',
  claimContactEmail:'genclaims_info@generali.com.hk',
  // Payment Screen
  validation: 'Validation',
  submit: 'Submit',
  validationFailed: 'Validation Failed',
  recaptchaValidationFailed: 'Failed on validating the ReCAPTCHA!',
  retry: 'Retry',
  thankYou: 'Thank You',
  submitted: 'Your claim has been submitted.',
  claimReferenceNumber: 'Claim reference number: ',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  history: 'History',
  instantEnrollment: 'Quote',
  roarUs: 'Roar Us',
  privilegedClub: 'Privilege Club',
  instantClaims: 'Claims',
  settings: 'Settings',
  claimsSubmission: 'Claims Submission',
  submission: 'Easy digital claims submission at your fingertips.',
}
