import React from 'react';
import {Text, View, FlatList} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import Format from './../../Format';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, Heading2, YesNoQuestion, Button} from './../../Form';
import FireScreen from './FireScreen';
import HengFaChuenChecking from './../HengFaChuenChecking';
import Confirmation from './../components/Confirmation';
import PolicyHolderConfirmation from './../components/PolicyHolderConfirmation';
import SpecialRequestConfirmation
  from './../components/SpecialRequestConfirmation';
import ImportantNotes from './../components/ImportantNotes';
import DisclaimerAndPIC from './../components/DisclaimerAndPIC';
import DirectMarketing from './../components/DirectMarketing';
import CommissionsDisclosures from './../components/CommissionsDisclosures';
import Payment from './../components/Payment';

export default class FireScreen4 extends FireScreen {
  constructor(props) {
    super(props);

    this.readPlanBroker().then((planBroker) => this.setState({planBroker}));
  }

  render() {
    const {
      insuranceProduct: {agent, policy, policyHolder, insuredHome, payment},
      messages,
    } = this.state;
    const {formatName, formatNumber, formatDate, getYesNoLabel} = Format;
    const manualUnderwriting =
      parseInt(insuredHome.sumInsured) > 10000000 ||
      insuredHome.buildingAge > 50 ||
      insuredHome.isUnoccupied == 'Y' ||
      insuredHome.isAnyLoss == 'Y' ||
      insuredHome.isProposalDeclined == 'Y' ||
      insuredHome.homeType == 'H' ||
      HengFaChuenChecking.check(insuredHome.fullAddress) ||
      !!policy.specialRequest;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getInsured}
        />

        {Locale.text.fireInsurance}
        {Locale.text.fireInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.fireInsurance}
            </Heading1>

            <Confirmation
              data={[
                {
                  label: Locale.text.insuranceAdvisorMobile,
                  value: agent.mobile,
                },
                {
                  label: Locale.text.effectiveDate,
                  value: formatDate(policy.effectiveDateStart) +
                    ' - ' + formatDate(policy.effectiveDateEnd),
                },
                {
                  label: Locale.text.fireSumInsuredAmount,
                  value: formatNumber(insuredHome.sumInsured),
                },
                {
                  label: Locale.text.ageOfBuilding,
                  value: this.getBuildingAgeLabel(),
                },
                {
                  label: Locale.text.promotionCodes,
                  value: payment.promotionCode,
                },
                {
                  label: Locale.text.businessEmailMembership,
                  value: policy.businessEmail,
                },
              ]}
            />

            <PolicyHolderConfirmation policyHolder={policyHolder} />

            <Confirmation
              data={[
                {
                  label: Locale.text.beingTheOwnerOftheInsuredPremises,
                  value: getYesNoLabel(insuredHome.isOwnedByHolder),
                },
              ]}
            />

            <Confirmation
              heading={Locale.text.insuredPremises}
              data={[
                {
                  label: Locale.text.address,
                  value: insuredHome.fullAddress,
                },
                {
                  label: Locale.text.residentialOrCommercial,
                  value: this.getResidentialLabel(),
                },
                {
                  label: Locale.text.houseOrApartment,
                  value: this.getBungalowLabel(),
                },
                {
                  label: Locale.text.postCopyToMortgagee,
                  value: getYesNoLabel(insuredHome.isPostPolicy),
                },
                {
                  label: Locale.text.nameOfMortgagee,
                  value: insuredHome.mortgageeName,
                },
                {
                  label: Locale.text.addressOfMortgagee,
                  value: insuredHome.mortgageeAddress,
                },
                {
                  label: Locale.text.theHomeIsUnoccupiedForLonger30Days,
                  value: getYesNoLabel(insuredHome.isUnoccupied),
                },
                {
                  label: Locale.text.anyLossDamageLiabilityOrAccident,
                  value: getYesNoLabel(insuredHome.isAnyLoss),
                },
                {
                  label: Locale.text.anyInsuranceCompnayDeclined,
                  value: getYesNoLabel(insuredHome.isProposalDeclined),
                },
              ]}
            />

            <SpecialRequestConfirmation policy={policy} />

            <ImportantNotes>
              {Locale.text.assicurazioniGeneraliSpAReservesTheRightTo}
              {Locale.text.policyholderMustBeHongKongResident}
            </ImportantNotes>

            <DisclaimerAndPIC
              screen={this}
              product={Locale.text.fireInsurance}
              planBroker={this.state.planBroker}
              value={policy.disclaimerAndPIC}
              onChange={(disclaimerAndPIC) =>
                this.updatePolicy({disclaimerAndPIC})}
              message={messages && messages.disclaimerAndPIC}
            />

            <DirectMarketing
              screen={this}
              value={policy.allowPrivacyPromote}
              onChange={(allowPrivacyPromote) =>
                this.updatePolicy({
                  allowPrivacyPromote,
                  allowPrivacyPromote3P: allowPrivacyPromote,
                })}
              message={messages && messages.allowPrivacyPromote}
            />

            <CommissionsDisclosures
              agent={this.state.agent}
              broker={this.state.broker}
              promotionCode={payment.promotionCode}
              value={policy.commissionsDisclosures}
              onChange={(commissionsDisclosures) =>
                this.updatePolicy({commissionsDisclosures})}
              message={messages.commissionsDisclosures}
            />

            <Payment
              manualUnderwriting={manualUnderwriting}
              broker={this.state.broker}
              payment={payment}
              onChange={(ecash) => this.updatePayment({ecash})}
              premium={this.getParam('premium')}
              levy={this.getParam('levy')}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.confirm()}
            >
              {Locale.text.submit}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
