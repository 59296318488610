import React from 'react';
import {
  AsyncStorage, Dimensions, Text, Image,
  View, ScrollView, TouchableOpacity, Switch, Linking,
} from 'react-native';
import { Redirect } from 'react-router-dom';
import BrowserDetection from 'react-browser-detection';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { checkVersion } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';

export default class Iemessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };

    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        checkVersion(this.state.language);
      })
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <BrowserDetection>
          {{ ie: () => null, default: () => <Redirect to='/' /> }}
        </BrowserDetection>
        <View style={styles.back}>
        <View style={{justifyContent: 'center',alignItems: 'center'}}>
        <img
          style={{maxHeight: 400}}
          src={require('./LED image -Welcome.png')}
        >
        </img>
        </View>
         <Text style={styles.text}>Sorry that we do not support IE. For the best browsing experience, please use:</Text>
         <Text style={styles.text}>抱歉我們不支援IE。為獲得最佳瀏覽體驗，請用以下瀏覽器</Text>
        </View>
        <Grid container spacing={20}>
           <Grid item xs={5} style={{ textAlign: 'right',}}>
              <Text style={styles.textsmail}>Google Chrome :</Text>
           </Grid>
           <Grid item xs={1} style={{ textAlign: 'center',}}>
             <a href='https://www.google.com/chrome/' target="_blank">
               <img
                 style={{width: 40, height: 40,}}
                 src={'./assets/images/download.png'}
               >
               </img>
             </a>
           </Grid>
           <Hidden xsDown>
           <Grid item xs={6} style={{ textAlign: 'left',marginLeft: -15}}>
              <Text style={styles.textsmail}>https://www.google.com/chrome/</Text>
           </Grid>
           </Hidden>
           </Grid>
           <Grid container spacing={20}>
           <Grid item xs={5} style={{ textAlign: 'right',}}>
              <Text style={styles.textsmail}>Firefox :</Text>
           </Grid>
           <Grid item xs={1} style={{ textAlign: 'center',}}>
             <a href='https://www.mozilla.org/' target="_blank">
               <img
                 style={{width: 40, height: 40,}}
                 src={'./assets/images/download.png'}
               >
               </img>
             </a>
           </Grid>
           <Hidden xsDown>
           <Grid item xs={6} style={{ textAlign: 'left',marginLeft: -15}}>
              <Text style={styles.textsmail}>https://www.mozilla.org/</Text>
           </Grid>
           </Hidden>
         </Grid>
      </div>
    );
  }
}

const styles = {
  container: {
    minHeight: window.innerHeight,
    paddingBottom: '2em',
  },
  text: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 30,
  },
  textsmail: {
    textAlign: 'center',
    fontSize: 18,
  },
};
