import React from 'react';
import {View} from 'react-native';

import Locale from './../../Locale';
import {TextInput} from './../../Form';

export default class MobileNumberOfTheInsuranceAdvisor extends React.Component {
  constructor(props) {
    super(props);

    if (props.broker && props.broker.access == 'sales') {
      props.onChange(props.broker.mobile);
    }
  }

  render() {
    const {broker, value, onChange, messages} = this.props;

    if (broker) {
      return (
        <TextInput
          label={Locale.text.insuranceAdvisorMobile}
          disabled={broker.access == 'sales'}
          value={value}
          onChange={(agent) => onChange(agent)}
          message={messages && messages.mobile}
        />
      );
    }

    return <View />;
  }
}
