import React from 'react';
import {StyleSheet, Text, Image, View, ScrollView,
} from 'react-native';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import AgentAPI from './../../api/AgentAPI';
import HistoryAPI from './../../api/HistoryAPI';
import { formStyles } from './../../config/styles';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { handleExpiredLogin, msgCodeDetail, formatName } from './../../lib/utils';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

export default class BuyAgain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      returnpage:false
    }
  }

  componentDidMount() {

    // this.viewDidAppear = this.props.history.addListener(
    //   'didFocus',
    //   (obj)=>{
    //       if(this.state.returnpage)
    //          this.props.history.goBack();
    //   }
    // )

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }));

    memberStorage.getMember((member, token) => {
      this.setState({
        member: member,
        token: token,
      }, () => {
        if (this.state.member) {
          this.setState({
            loading: true,
          }, () => {
            this._buyAgain(() => {this.setState({loading: false});
            });
          });
        }
      });
    });
  }

  // componentWillUnmount() {
  //   // 移除监听
  //   this.viewDidAppear.remove();
  // }

  _buyAgain() {
    if (this.state.member) {
      HistoryAPI.policies(
        {
          'member.id': this.state.member.id,
          'member.token': this.state.token.tokenStr,
        },
        (msgDesc) => {
          msgDesc.policies.sort((a, b) =>
            moment(b.issueDate).diff(moment(a.issueDate)));

          let mostRecent;
          msgDesc.policies.forEach((policy) => {
            if (
              !mostRecent && (
                policy.policyNo.startsWith('TJ') ||
                policy.policyNo.startsWith('TA')
              )
            ) {
              mostRecent = policy;
            }
          });

          if (mostRecent) {
            HistoryAPI.policy(
              {
                'policyHolder.mobile': this.state.member.mobile,
                'policy.policyNo': mostRecent.policyNo,
              },
              (msgDesc) => {
                this.setState({returnpage:true});
                localStorage.setItem('preventGoBack', 'false');
                this.props.history.replace('/gtravel3', {
                  policy: msgDesc.policy,
                  travel: msgDesc.travel,
                  travellers: msgDesc.travellers,
                  isBuyagain: true,
                });
              }
            );
          }
          else {
            this.props.history.goBack();
          }
        },
        (msgCode, msgDesc, handleDefault) => {
          if (msgCode == 12) {
            handleExpiredLogin(this.state.language, this.props.history);
          }
          else{
            this.props.history.goBack();
          }
        },
      );
    }
  }

  render() {
    return (
      <div className='App'>
        <Navbar
        title={this.state.text.historyList}
          backButton
          history={this.props.history}
          goBack='/'
        />
        <ScrollView>
          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                  <View style={[styles.greeting,{fontWeight:'bold',}]}>
                    <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.buyAgain}</Text>
                    <p></p>
                    <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.adventure}</Text>
                  </View>

              </Grid>
              </Hidden>

            <Grid item xs style={{marginTop:30,marginBottom:50}}>
              <View style={formStyles.form,styles.fie}>
              {this.state.alert}
              <View style={styles.container}>


                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  <View style={styles.loading}>
                    <Text style={{fontSize: 20, textAlign: 'center',}}>{this.state.text.loading}</Text>
                    <Image style={{width: 500, height: 500,}} source={require('./loading.gif')} />
                  </View>
                </View>
              </View>
           </View>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
      </ScrollView>

      <Footer/>
    </div>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  loading:{
    width: '100%',
    height: '100%',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth:'100%',
    maxHeight:'50%',
    paddingLeft:'10%',
    paddingRight:'10%'
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: 'white',
    },
});
