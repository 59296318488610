import React from 'react';

import Locale from './../../Locale';
import Member from './../../Member';
import Validation from './../../Validation';
import InsuranceProductScreen from './../InsuranceProductScreen';
import ApplyAPI from './../../api/ApplyAPI';
import AgentAPI from './../../api/AgentAPI';

export default class DHScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, 'DH');

    this.includeOutpatientOptions = [
      {value: 'Y', label: Locale.text.withOutpatientBenefit},
      {value: 'N', label: Locale.text.withoutOutpatientBenefit},
    ];

    this.DHPlanType = [
      {value: 'DH', label: Locale.text.oneYear},
      {value: 'DH2', label: Locale.text.twoYears},
    ];


    if (!this.state.insuranceProduct.pl) {
      this.state.insuranceProduct.pl = 'DH';
      this.state.insuranceProduct.domesticHelper = {
        includeOutpatient: 'Y',
      };
    }
  }

  componentDidUpdate() {
    const {domesticHelper} = this.state.insuranceProduct;

    if (domesticHelper.isUnoccupied == 'N') {
      this.updateDomesticHelper({isUnoccupied: null});
      this.decline(Locale.text.
        sorryThatYourApplicationIsDeclinedAsOnlyHKPlace);
      return;
    }

    if (domesticHelper.isProposalDeclined == 'N') {
      this.updateDomesticHelper({isProposalDeclined: null});
      this.decline(Locale.text.
        sorryThatYourApplicationIsDeclinedAsOnlyFullTimeOverseas);
      return;
    }
  }

 
  updateDomesticHelper(data) {
    this.updateInsuranceProduct('domesticHelper', data);
  }

  getIncludeGuardianLabel() {
    const {domesticHelper} = this.state.insuranceProduct;

    switch (domesticHelper.includeOutpatient) {
      case 'Y': return Locale.text.withOutpatientBenefit;
      case 'N': return Locale.text.withoutOutpatientBenefit;
    }
  }

  getInsuredCountryLabel() {
    const {domesticHelper} = this.state.insuranceProduct;

    switch (domesticHelper.insuredCountry) {
      case 'sriLanka': return Locale.text.sriLanka;
      case 'indonesia': return Locale.text.indonesia;
      case 'philippines': return Locale.text.philippines;
      case 'singapore': return Locale.text.singapore;
      case 'malaysia': return Locale.text.malaysia;
      case 'thailand': return Locale.text.thailand;
      case 'nepal': return Locale.text.nepal;
      case 'bangladesh': return Locale.text.bangladesh;
      case 'others': return Locale.text.others;
    }
  }

  getQuote(nextScreen) {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        domesticHelper, payment} = this.state.insuranceProduct;

      const {trimAndRemoveEmptyStrings, getValidator} = Validation;

      [agent, policy, policyHolder, domesticHelper, payment].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator('text'),
          promotionCode: getValidator('text'),
        }
      );

      messages.discount = discountValidation.validate();

      if (nextScreen == 'domestic4') {
        const agentValidation = new Validation(
          agent,
          {mobile: getValidator('number')}
        );

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(
          policy,
          {
            effectiveDateStart: getValidator('text', true),
            specialRequest: getValidator('text'),
          }
        );

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(
          policyHolder,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            mobile: getValidator('number', true),
            email: getValidator('email', true),
            address1: getValidator('text', true),
          }
        );

        messages.policyHolder = policyHolderValidation.validate();

        const domesticHelperValidation = new Validation(
          domesticHelper,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            documentType: getValidator('text', true),
            documentNo: domesticHelper.documentType == 'HKID' ?
            getValidator('text', true) : getValidator('text', true),
            dateOfBirth: getValidator('text', true),
            insuredSex: getValidator('text'),
            insuredCountry: getValidator('text', true),
            isUnoccupied: getValidator('text', true),
            isRefused: getValidator('text', true),
            isMedAttn: getValidator('text', true),
            isProposalDeclined: getValidator('text', true),
          }
        );

        messages.domesticHelper = domesticHelperValidation.validate();
      }

      const invalid = JSON.stringify(messages) != '{}';

      if (invalid) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.readPremium({
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'payment.promotionCode': payment.promotionCode,
          'policy.effectiveDateStart':policy.effectiveDateStart,
          'policy.businessEmail': policy.businessEmail,
          'domesticHelper.includeOutpatient': domesticHelper.includeOutpatient,
        })
        .then(({premium, ecLevy, terrorCharge, levy, total}) => {
          this.push(nextScreen, {premium, ecLevy, terrorCharge, levy, total});
        });
      }
    });
  }

  confirm() {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        payment, domesticHelper} = this.state.insuranceProduct;

      const {getValidator, getProductConfirmationValidator} = Validation;

      const confirmationValidation = new Validation(
        policy,
        {
          declaration: getProductConfirmationValidator(),
          disclaimerAndPIC: getProductConfirmationValidator(),
          allowPrivacyPromote: getValidator('text', true),
          commissionsDisclosures:
            (this.state.agent || this.state.broker || payment.promotionCode) &&
              getProductConfirmationValidator(),
        }
      );

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.apply(
          Locale.text.domesticHelperInsurance,
          this.state.broker ? AgentAPI.dh : ApplyAPI.dh,
          {
            'pn': pn,
            'pl': pl,
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'agent.mobile': agent.mobile,
            'policy.effectiveDateStart': policy.effectiveDateStart,
            'policy.effectiveDateEnd': policy.effectiveDateEnd,
            'policy.allowPrivacyPromote3P': policy.allowPrivacyPromote3P,
            'policy.allowPrivacyPromote': policy.allowPrivacyPromote,
            'policy.businessEmail' : policy.businessEmail,
            'policy.specialRequest': policy.specialRequest,
            'policyHolder.salutation': policyHolder.salutation,
            'policyHolder.firstName': policyHolder.firstName,
            'policyHolder.lastName': policyHolder.lastName,
            'policyHolder.mobile': policyHolder.mobile,
            'policyHolder.email': policyHolder.email,
            'policyHolder.address1': policyHolder.address1,
            'policyHolder.preferredContact' : 'em',
            'domesticHelper.address1': policyHolder.address1,
            'domesticHelper.addressArea': 'HK',
            'domesticHelper.salutation': domesticHelper.salutation,
            'domesticHelper.firstName': domesticHelper.firstName,
            'domesticHelper.lastName': domesticHelper.lastName,
            'domesticHelper.gender': domesticHelper.gender,
            'domesticHelper.dateOfBirth': domesticHelper.dateOfBirth,
            'domesticHelper.documentType': domesticHelper.documentType,
            'domesticHelper.documentNo': domesticHelper.documentNo,
            'domesticHelper.originCountry': domesticHelper.insuredCountry,
            'domesticHelper.includeOutpatient': domesticHelper.includeOutpatient,
            'domesticHelper.isRefused': domesticHelper.isRefused,
            'domesticHelper.isMedAttn': domesticHelper.isMedAttn,
            'domesticHelper.isEligible': 'Y',
            'payment.ecash': payment.ecash,
            'payment.promotionCode': payment.promotionCode,
            'rawRequest': JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }
}
