export const zhHK = {
  roarUs: '聯絡我們',
  chat: '與客戶服務員對話',
  phone: '電話聯絡',
  please:'請撥打電話聯絡 +852 6682 1112',
  email: '電郵',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  history: '記錄',
 instantEnrollment: '報價',
  roarUs: '聯絡我們',
  privilegedClub: '專享禮遇',
  instantClaims: '索償',
  settings: '設定',
  ok: '確定',
  contact: '聯絡我們',
  help: '我們在此細心為您服務。',
  customerServiceSupport:'客戶服務支援',
  serviceTime:'週一至週五上午 9:00 至下午 5:30（公眾假期除外)',
  hotline:'熱線電話:',
  hotlineNum:'852 6682 1112',
  actualHotlineNum:'85266821112',
  wts:'Whatsapp:',
  wtsNum:'852 6682 1112',
  actualWtsNum:'66821112',
  email:'電郵:',
  companyEmail:'bravo@generali.com.hk',
  NeedToMakeClaim:'需要提出索償嗎？',
  followingLink:'提交索償，請點擊以下連結。',
  followingLinkText:'https://www.generali.com.hk/ZH_HK/claims_and_support/individual',
  claimContactText:'理賠部門聯絡電郵:',
  claimContactEmail:'genclaims_info@generali.com.hk',
}
