import React from 'react';
import {
  AppState, Platform,
  Image, Text, Switch,
  View, ScrollView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { msgCodeDetail } from './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../lib/InsuranceAdvisors';
import { formStyles } from './../../config/styles';
import { host, api14, api15, busiSecKey } from './../../config/api';
import { promotionCodesStorage } from './../../lib/PromotionCodesStorage';
import TextInput from './../../components/TextField/TextField';
import ListMenu from './../../components/ListMenu/ListMenu';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import Alert  from './../../components/Alert/Alert';

import Member from './../../Member';

export default class InsuranceAdvisorScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceAdvisors: {},
      selectedProducts: {},
      text: {},
    };
    ['ASAP', 'DH', 'PA', 'FIRE', 'HOME', 'TRAVEL'].forEach((product) => {
      this.state.selectedProducts[product] = true;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          // init insurance advisors
          this._getInsuranceAdvisors(() => this._onDeepLink());
        }));
      })
    );

    // init deep linking
    // AppState.addEventListener('change', this._handleAppStateChange.bind(this));
  }

  // componentWillUnmount() {
  //   AppState.removeEventListener('change', this._handleAppStateChange.bind(this));
  // }

  _handleAppStateChange(nextAppState) {
    if (nextAppState == 'active') {
      this.componentDidMount();
    }
  }

  _checkMember() {
    if (!this.state.member) {
      this.setState({alert:
      Alert.alert(
        this.state.text.requiredToLogin,
        this.state.text.pleaseLogin,
        [
          {
            text: this.state.text.login,
            onPress: () => this.props.history.push('/login')
          },
          {
            text: this.state.text.register,
            onPress: () => this.props.history.push('/register')
          },
        ],
        { cancelable: false }
      )
    });
      return false;
    }
    return true;
  }

  _onDeepLink() {
    if (!this._checkMember()) {
      return;
    }
    let reachingInsuranceAdvisor;
    const code = this.props.location.code;
    if (code) {
      reachingInsuranceAdvisor = JSON.parse(CryptoJS.AES.decrypt(
        code, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
    }
    if (reachingInsuranceAdvisor) {
      if (this.state.currentReachingInsuranceAdvisor &&
        reachingInsuranceAdvisor.agentCode ==
        this.state.currentReachingInsuranceAdvisor.agentCode) {
        return;
      }
      this.setState({
        currentReachingInsuranceAdvisor: reachingInsuranceAdvisor,
        promotionCodes: reachingInsuranceAdvisor.promotionCodes,
        alert:
        Alert.alert(
          this.state.text.changeInsuranceAdvisor,
          this.state.text.wouldYouLike +
          reachingInsuranceAdvisor.financialAdvisor +
          this.state.text.comma +
          reachingInsuranceAdvisor.broker +
          this.state.text.toBeYourInsuranceAdvisor,
          [
            {
              text: this.state.text.yes,
              onPress: () =>
              this.setState({alert:
                Alert.alert(
                  this.state.text.changeInsuranceAdvisor,
                  this.state.text.thanksAndWeWillInform +
                  reachingInsuranceAdvisor.financialAdvisor +
                  this.state.text.accordingly +
                  (Object.keys(this.state.insuranceAdvisors).length > 0 ?
                  this.state.text.pleaseCheckTheProductList : ''),
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        this._setInsuranceAdvisor(
                          { agentCode: reachingInsuranceAdvisor.agentCode },
                          ['ASAP', 'DH', 'PA', 'FIRE', 'HOME', 'TRAVEL']
                        );
                        this.setState({alert: null});
                      }
                    }
                  ],
                  { cancelable: false }
                )
              })
            },
            {
              text: this.state.text.no,
              style: 'cancel',
              onPress: () => this.setState({alert: null}),
            },
          ],
          { cancelable: false }
        )
      });
    }
  }

  _getInsuranceAdvisors(callback) {
    this._checkMember();
    insuranceAdvisors.getInsuranceAdvisors(
      this.state.language,
      this.props.history,
      (insuranceAdvisors) => {
        this.setState({insuranceAdvisors: insuranceAdvisors}, () => {
          callback && callback();
        });
      },
      (alert) => {
        this.setState({alert: alert});
      }
    );
  }

  _removeInsuranceAdvisor(selectedProduct) {
    this.setState({alert:
    Alert.alert(
      this.state.text.removeInsuranceAdvisor,
      this.state.text.doYouWantToRemove
      + (selectedProduct ? selectedProduct.displayName : '')
      + this.state.text.questionMark,
      [
        {
          text: this.state.text.yes,
          onPress: () => {
            insuranceAdvisors.removeInsuranceAdvisor(
              selectedProduct.product, this.state.language, (successful) => {
                if (successful) {
                  this.setState({selectedProduct: null});
                  this._getInsuranceAdvisors();
                }
              }
            );
            this.setState({alert: null});
          },
        },
        {
          text: this.state.text.no,
          style: 'cancel',
          onPress: () => this.setState({alert: null}),
        }
      ]
    )
  });
  }

  _setInsuranceAdvisor(insuranceAdvisor, products) {
    insuranceAdvisors.getInsuranceAdvisors(
      this.state.language,
      this.props.history,
      (currentInsuranceAdvisors) => {
        let directSubmission = false;
        // if there is no bound insurance advisor in all products
        if (Object.keys(currentInsuranceAdvisors).length == 0) {
          directSubmission = true;
        }
        // if this method is triggered by change button
        if (products.length == 1) {
          directSubmission = true;
        }
        if (directSubmission || this.state.onPressConfirmButton) {
          if (this.state.onPressConfirmButton) {
            this.setState({onPressConfirmButton: false});
            if (products.length < 1) {
              return;
            }
          }
          insuranceAdvisors.setInsuranceAdvisor(
            insuranceAdvisor,
            products,
            this.state.language,
            undefined,
            (alert) => {
              if (alert) {
                this.setState({alert: alert});
                return;
              }
              else {
                this._getInsuranceAdvisors(() => {
                  if (this.state.promotionCodes) {
                    promotionCodesStorage.setPromotionCodes(
                      this.state.promotionCodes, this.state.member.id);
                  }
                });
              }
            },
            () => {
              this.setState({alert: undefined});
            }
          );
        }
        else {
          this.setState({onPressConfirmButton: true});
        }
      },
      (alert) => {
        this.setState({alert: alert});
      }
    );
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    const allProducts = {
      TRAVEL: 'gereraliTravelPackageInsurance',
      ASAP: 'annualStudyAbroadProtector',
      HOME: 'homePackageInsurance',
      FIRE: 'fireInsurance',
      DH: 'domesticHelperInsurance',
      PA: 'executivePersonalAccidentProtector',
    };
    let products = [];
    let product;
    for (product in allProducts) {
      let selectedProduct = {
        product: product,
        displayName: this.state.text[allProducts[product]],
      };
      products.push(
        <View key={product}>
          <View style={styles.product}>
            {
              this.state.onPressConfirmButton &&
                <View style={styles.left}>
                  <Switch
                    value={this.state.selectedProducts[selectedProduct.product]}
                    onValueChange={(value) => {
                      this.state
                        .selectedProducts[selectedProduct.product] = value;
                      this.setState({
                        selectedProducts: this.state.selectedProducts,
                      });
                    }}
                  />
                </View>
            }
            <View style={styles.center}>
              <Text style={styles.productName}>
                {this.state.text[allProducts[product]]}
              </Text>
              <Text>
                {this.state.text.insuranceAdvisorIs}
                {
                  this.state.insuranceAdvisors[product] &&
                  this.state.insuranceAdvisors[product].isReferred ?
                  this.state.insuranceAdvisors[product].agent.isAdmin ?
                  this.state.insuranceAdvisors[product].agent.broker.nameEnUs :
                  this.state.insuranceAdvisors[product].agent.nameEnUs + ', ' +
                  this.state.insuranceAdvisors[product].agent.broker.nameEnUs :
                  this.state.text.notSpecifiedYet
                }
              </Text>
            </View>
            {
              !this.state.onPressConfirmButton &&
                <View style={styles.right}>
                  <TouchableOpacity
                    style={[styles.changeButton, {borderColor: color}]}
                    onPress={() => this.setState({
                      selectedProduct: selectedProduct,
                    })}
                  >
                    <Text style={[styles.changeButtonText, {color}]}>
                      {this.state.text.change}
                    </Text>
                  </TouchableOpacity>
                </View>
            }
          </View>
        </View>
      );
    }

    return (
      <div className='App'>
        <Helmet>
          <title>Insurance Advisors | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.insuranceAdvisor}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
              {
                this.state.language == 'zhHK' ?
                <View style={[styles.greeting,{fontWeight:'bold',textAlign: 'center'}]}>
                  <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.insurance}</Text>
                  <p></p>
                  <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.advisors}</Text>

                </View>:
                <View style={[styles.greeting,{fontWeight:'bold',textAlign: 'center'}]}>
                  <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.insurance}</Text>
                  <p></p>
                  <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.advisors}</Text>

                </View>
              }

          </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <View style={styles.container, styles.fie}>

              <ScrollView style={formStyles.form}>
              {this.state.alert}
              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h2, {color}]}>{this.state.text.insuranceAdvisor}</Text>
              </View>
              <View style={{textAlign: 'left'}}>
              <View style={styles.products}>
                { products }
              </View>

              {
                this.state.selectedProduct &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={[formStyles.h3, {color}]}>{this.state.text.changeInsuranceAdvisor}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={styles.h4}>
                        {this.state.text.product}{this.state.selectedProduct.displayName}
                      </Text>
                      <Text style={formStyles.h4}>{this.state.text.pleaseInputTheMobileNumber}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({agentMobile: text})}
                        value={this.state.agentMobile}
                        keyboardType='numeric'
                        maxLength={8}
                      />
                    </View>
                    {
                      this.state.insuranceAdvisors[this.state.selectedProduct.product] &&
                        <TouchableOpacity onPress={() =>
                          this._removeInsuranceAdvisor(this.state.selectedProduct)}
                        >
                          <Text style={styles.removeLink}>
                            {this.state.text.removeInsuranceAdvisor}
                          </Text>
                        </TouchableOpacity>
                    }
                    <TouchableOpacity
                      style={[formStyles.submitButton, {backgroundColor}]}
                      onPress={
                        () => {
                          this._setInsuranceAdvisor(
                            {agentMobile: this.state.agentMobile},
                            [this.state.selectedProduct.product]
                          );
                          this.setState({selectedProduct: null});
                        }
                      }
                    >
                      <Text style={formStyles.submitButtonText}>
                        {this.state.text.confirm}
                      </Text>
                    </TouchableOpacity>
                  </View>
              }

              {
                this.state.onPressConfirmButton &&
                  <TouchableOpacity
                    style={[formStyles.submitButton, {backgroundColor}]}
                    onPress={() => {
                      let selectedProducts = [];
                      let selectedProduct;
                      for (selectedProduct in this.state.selectedProducts) {
                        if (this.state.selectedProducts[selectedProduct]) {
                          selectedProducts.push(selectedProduct);
                        }
                      }
                      this._setInsuranceAdvisor(
                        {agentCode:
                          this.state.currentReachingInsuranceAdvisor.agentCode},
                        selectedProducts
                      );
                    }}
                  >
                    <Text style={formStyles.submitButtonText}>
                      {this.state.text.confirm}
                    </Text>
                  </TouchableOpacity>
              }
              </View>
            </ScrollView>
            </View>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
      <Footer/>
   </div>
    )
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  products: {
    marginTop: 10,
  },
  product: {
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 20,
  },
  left: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeButtonImage: {
    width: 20,
    height: 20,
  },
  center: {
    flex: 7,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  productName: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  right: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
  changeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderColor: '#C50000',
    borderWidth: 1,
  },
  changeButtonText: {
    color: '#C50000',
  },
  removeLink: {
    color: '#C50000',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },

};
