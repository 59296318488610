import React from 'react';
import { Text, View } from 'react-native';


import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class AttachmentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      })
    );
  }

  _getAttachmentType(url) {
    if (url.startsWith(leafletsURL)) {
        return leaflets;
    }
    if (url.startsWith(provisionURL)) {
      return provision;
    }
  }

  render() {
    return (
      <View style={styles.attachmentLink}>

      {
        this.state.language == 'enUS' ?
        <View>
        {
          this.props.leaflet && this.props.provision ?
          <Text>
          {
            this.props.noPremiumRates ?
            'For details of the product and exclusions,' :
            'For details of the product, exclusions and premium rates,'
          }
          please refer to {<a href={this.props.leaflet} target="_blank">{this.state.text.productLeaflet}</a>} and {<a href={this.props.provision} target="_blank">{this.state.text.policyProvision}</a>}.
          </Text> :
          <Text>
          For details of the product, exclusions and premium rates,
          please refer to {<a href={this.props.provision} target="_blank">{this.state.text.policyProvision}</a>}.
          </Text>
        }
        </View> :
        <View>
        {
          this.props.leaflet && this.props.provision ?
          <Text>
          {
            this.props.noPremiumRates ?
            '有關產品細節及不保事項，' :
            '有關產品細節，不保事項及保費率，'
          }
          請參閱 {<a href={this.props.leaflet} target="_blank">{this.state.text.productLeaflet}</a>} 及 {<a href={this.props.provision} target="_blank">{this.state.text.policyProvision}</a>} 。
          </Text> :
          <Text>
          {
            this.props.noPremiumRates ?
            '有關產品細節及不保事項，' :
            '有關產品細節，不保事項及保費率，'
          }
          請參閱 {<a href={this.props.provision} target="_blank">{this.state.text.policyProvision}</a>}。
          </Text>
        }
        </View>
      }

      </View>
    );
  }
}

const leaflets = 'Leaflet';
const leafletsURL = 'https://www.app-generali.com/share_files/Product_leaflet/';
const provision = 'Provision';
const provisionURL = 'https://www.app-generali.com/share_files/Product_provision/';

const styles = {
  attachmentLink: {
    marginTop: 10,
    textAlign: 'left',
  },
  linkStyle: {
    color: '#C50000',
  },
};
