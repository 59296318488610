export const zhHK = {
  currency: '貨幣',
  pleaseFill: '請填入金額',
  others: '其他',
  aud: '澳元',
  eur: '歐元',
  gbp: '英鎊',
  hkd: '港幣',
  jpy: '日元',
  rmb: '人民幣',
  usd: '美元',
  cancel: '取消',
}
