import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const instituteLocations = {
  US_CA: {
    label: {
      enUS: enUS.usaCanada,
      zhHK: zhHK.usaCanada,
    },
  },
  OTHERS: {
    label: {
      enUS: enUS.otherCountry,
      zhHK: zhHK.otherCountry,
    },
  },
};
