import React from 'react';
import {
  StyleSheet, Text, Image, View, ScrollView, TouchableOpacity,
} from 'react-native';

import Member from './../Member';
import {Form, Divider} from './../Form';

export default class Inbox extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <Form>
          <View style={styles.inbox}>
            {this.props.children}
          </View>
        </Form>
      </View>
    );
  }
}

export class InboxItem extends React.Component {
  render() {
    const {label, onPress} = this.props;

    return (
      <View>
        <TouchableOpacity style={styles.inboxItem} onPress={onPress}>
          <View style={styles.dot}>
            <Image
              style={styles.dotImage}
              source={require('./images/unread.png')}
            />
          </View>

          <View style={styles.message}>
            <Text>{label}</Text>
          </View>
        </TouchableOpacity>

        <Divider />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
  },
  inbox: {
    marginTop: 10,
  },
  inboxItem: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  dot: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotImage: {
    width: 10,
    height: 10,
  },
  message: {
    flex: 4,
  },
});
