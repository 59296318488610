import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Locale from './../../Locale';
import Member from './../../Member';

export default class LeafletAndProvision extends React.Component {
  viewLeaflet() {
    this.props.screen.push('PDF', {
      title: Locale.text.productLeaflets,
      uri: leafletBaseUrl + this.props.leaflet,
    });
  }

  viewProvision() {
    this.props.screen.push('PDF', {
      title: Locale.text.policyProvisions,
      uri: provisionBaseUrl + this.props.provision,
    });
  }

  render() {
    const color = Member.getThemeColor();

    return (
      <View style={styles.productDetails}>
        <Text>
          {
            this.props.noPremiumRates ?
              <Text>
                {Locale.text.forDetailsOfTheProductAndExclusions}
              </Text> :
              <Text>
                {Locale.text.forDetailsOfTheProductExclusionsAndPremiumRates}
              </Text>
          }

          <Text>
            {Locale.text.pleaseReferTo}
          </Text>

          {
            this.props.leaflet &&
              <a
                style={{color}}
                href={leafletBaseUrl + this.props.leaflet}
                target='_blank'
              >
                {Locale.text.productLeaflet}
              </a>
          }

          {
            this.props.leaflet && this.props.provision &&
              <Text>{Locale.text.productDetailsAnd}</Text>
          }

          {
            this.props.provision &&
              <a
                style={{color}}
                href={provisionBaseUrl + this.props.provision}
                target='_blank'
              >
                {Locale.text.policyProvision}
              </a>
          }

          <Text>
            {Locale.text.productDetailsPeriod}
          </Text>
        </Text>
      </View>
    );
  }
}

const baseUrl = 'https://www.app-generali.com/share_files/';
const leafletBaseUrl = baseUrl + 'Product_leaflet/';
const provisionBaseUrl = baseUrl + 'Product_provision/';

const styles = StyleSheet.create({
  productDetails: {
    marginTop: 5,
    marginBottom: 5,
  },
});
