import React from 'react';
import { Text, Switch, View } from 'react-native';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class YesNoQuestion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  _onChange(choice) {
    this.props.onChange(choice);
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }


  render() {
    return (
      <div>
       <View style={{textAlign: 'Center'}}>
        <Text>{this.props.question}</Text>
       </View>
        <FormControl style={styles.formControl}>
              {
                this.props.reverse ?
                  <Button
                   variant="contained"
                    style={{marginLeft:20}}
                    color={this.props.choice == 'N' ? 'secondary' : null}
                    onClick={(value) => this._onChange(value ? 'N' : null)}
                  >{this.state.text.yes}</Button> :
                  <Button
                    variant="contained"
                    style={{marginRight:20}}
                    color={this.props.choice == 'Y' ? 'secondary' : null}
                    onClick={(value) => this._onChange(value ? 'Y' : null)}
                  >{this.state.text.yes}</Button>
              }

              {
                this.props.reverse ?
                  <Button
                   variant="contained"
                    color={this.props.choice == 'Y' ? 'secondary' : null}
                    onClick={(value) => this._onChange(value ? 'Y' : null)}
                  >{this.state.text.no}</Button> :
                  <Button
                   variant="contained"
                    color={this.props.choice == 'N' ? 'secondary' : null}
                    onClick={(value) => this._onChange(value ? 'N' : null)}
                  >{this.state.text.no}</Button>
              }
            </FormControl>


      </div>
    );
  }
}

const styles = {
  yesNoQuestion: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },

  formControl: {
    display: 'block',
    marginTop: 12,
    marginBottom: 12,
  },
};
