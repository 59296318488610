import React from 'react';
import {Text} from 'react-native';

import Locale from './../Locale';
import {Form} from './../Form';

export default class NoticeTab extends React.Component {
  render() {
    return (
      <Form>
        <Text>
          {Locale.text.noNoticeHistoryYet}
        </Text>
      </Form>
    );
  }
}
