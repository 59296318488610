import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { options } from './options';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api26, busiSecKey } from './../../../config/api';
import { languageStorage } from './../../../lib/LanguageStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { stateStorage } from './../../../lib/StateStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import {
  decline, msgCodeDetail, clearEmptyStrings, removeSpaces, removeCommas,
  addCommas,
} from './../../../lib/utils';
import HKID from './../../../lib/HKID';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import PromoteAPI from './../../../api/PromoteAPI';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert from './../../../components/Alert/Alert';
import DatePicker from './../../../components/DatePicker/DatePicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import NameInput from './../../../components/NameInput/NameInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import validid from 'validid';

export default class FireInsurance3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    if (policy) {
      this.state = {
        planType: 'fire',
        effectiveDateStart: moment(policy.effectiveDateStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        effectiveDateEnd: moment(policy.effectiveDateEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        specialRequests: policy.specialRequests,
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        // propertyAddress: data['insuredHome.fullAddress'],
        // propertyUsage: 'residential',
        // propertyType: data['insuredHome.homeType'],
        // propertyUnoccupied: data['insuredHome.isUnoccupied'],
        // propertyAged: data['insuredHome.isOldBuilding'],
        // propertyLoss: data['insuredHome.isAnyLoss'],
        // policyholderOwner: data['insuredHome.isOwnedByHolder'],
        messages: {},
        text: {},
      };
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        planType: 'fire',
        buildingAge: this.props.location.state.buildingAge,
        sumInsuredAmount: this.props.location.state.sumInsuredAmount,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress:this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('fire3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.navigation,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.FIRE;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.FIRE.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'FIRE',
                      (promotionCodes) => {
                        this.setState({
                          showBusinessEmailAddress: false,
                          businessEmailAddress: undefined,
                          submitButtonDisabled: false,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              }
            );

            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: 'FIRE',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }

            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('fire3', rawRequest);
    stateStorage.setState('fire4', undefined);
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      policyholderDOB: state.policyholderDOB,
      sumInsuredAmount: state.sumInsuredAmount,
      buildingAge: state.buildingAge,
      effectiveDate: state.effectiveDateStart,
      planType: state.planType,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderHKID: state.policyholderHKID,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      policyholderOwner: state.policyholderOwner,
      propertyAddress: state.propertyAddress,
      propertyUsage: state.propertyUsage,
      propertyType: state.propertyType,
      isPostPolicy: state.isPostPolicy,
      propertyUnoccupied: state.propertyUnoccupied,
      propertyLoss: state.propertyLoss,
      propertyDeclined: state.propertyDeclined,
      specialRequests: state.specialRequests,
      referral: state.referral,
      businessEmailAddress: state.businessEmailAddress,
    };
    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      policyholderDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      sumInsuredAmount: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {
          greaterThanOrEqualTo: 1000000,
          notValid: this.state.text.notANumber,
          notGreaterThanOrEqualTo: this.state.text.lessThanMinimum,
        },
      },
      buildingAge: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      effectiveDate: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
      planType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      policyholderHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: {message: this.state.text.notValidHKID},
        format: englishFormat,
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        numericality: {message: this.state.text.notANumber},
      },
      policyholderAddress: {
        format: englishFormat,
      },
      policyholderEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      policyholderOwner: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      propertyAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      },
      propertyUsage: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      propertyType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      isPostPolicy: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      propertyUnoccupied: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      propertyLoss: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      propertyDeclined: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      specialRequests: {
        format: englishFormat,
      },
      referral: {
        format: englishFormat,
      },
      businessEmailAddress: {
        format: englishFormat,
      },
    }

    if (this.state.isPostPolicy == 'Y') {
      attributes.mortgageeName = state.mortgageeName;
      attributes.mortgageeAddress = state.mortgageeAddress;
      constraints.mortgageeName = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
      constraints.mortgageeAddress = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: englishFormat,
      };
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {

      this.setState({
        messages: messages,
        submitButtonDisabled: false,
          alert:
        Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok,
            onPress: () => this.setState({alert: null}),
          }],
        ),
      });
    }
    else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;

      premium(
        {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': 'EAPP',
          'pl': 'FIRE',
          'member.id' : this.state.member ? this.state.member.id : undefined,
          'member.token' : this.state.token ? this.state.token.tokenStr : undefined,
          'insuredHome.buildingAge': this.state.buildingAge == 'above' ?
            51 : parseInt(this.state.buildingAge),
          'insuredHome.sumInsured': this.state.sumInsuredAmount,
          'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        },
        (msgDesc) => {
          this.setState({submitButtonDisabled: false}, () => {
            let rawRequest = Object.assign({}, state);
            delete rawRequest.messages;
            delete rawRequest.text;
            delete rawRequest.alert;

            const premium = msgDesc.premium;
            const levy = msgDesc.levy;
            const total = premium.discount + levy;

            stateStorage.setState('fire3', rawRequest);
            stateStorage.setState('fire4', undefined);

            this.props.history.push('/fire4', {
              broker: state.broker,
              insuranceAdvisor: state.insuranceAdvisor,
              insuranceAdvisorMobile: state.insuranceAdvisorMobile,
              product: state.product,
              sumInsuredAmount: state.sumInsuredAmount,
              buildingAge: state.buildingAge,
              effectiveDateStart: state.effectiveDateStart,
              effectiveDateEnd: state.effectiveDateEnd,
              policyholderTitle: state.policyholderTitle,
              policyholderFirstName: state.policyholderFirstName,
              policyholderLastName: state.policyholderLastName,
              policyholderPhone: state.policyholderPhone,
              policyholderEmail: state.policyholderEmail,
              policyholderDOB: state.policyholderDOB,
              policyholderHKID: state.policyholderHKID,
              propertyAddress: state.propertyAddress,
              propertyType: state.propertyType,
              propertyUnoccupied: state.propertyUnoccupied,
              propertyAged: this.state.buildingAge == 'above' ? 'Y' : 'N',
              propertyLoss: state.propertyLoss,
              propertyDeclined: state.propertyDeclined,
              policyholderOwner: state.policyholderOwner,
              policyholderAddress: state.policyholderAddress,
              isPostPolicy: state.isPostPolicy,
              mortgageeName:
                state.isPostPolicy == 'Y' ? state.mortgageeName : undefined,
              mortgageeAddress:
                state.isPostPolicy == 'Y' ? state.mortgageeAddress : undefined,
              promotionCodes: removeSpaces(state.promotionCodes),
              businessEmailAddress: removeSpaces(state.businessEmailAddress),
              specialRequests: state.specialRequests,
              referral: state.referral,
              rawRequest: rawRequest,
              premium: premium,
              levy: levy,
              total: total,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) => {
          if (msgCode == 40) {
            if (this.state.broker) {

              this.setState({
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
                alert:
                Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{text: this.state.text.ok,
                    onPress: () => this.setState({alert: null}),
                  }],
                ),
              });
            }
            else {
              this.setState({alert:
                Alert.alert(
                  this.state.text.addInsuranceAdvisor,
                  this.state.text.wouldYouLike
                  + msgDesc.agent.brokerName
                  + this.state.text.toBeYourInsuranceAdvisor,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        if (!this.state.bindingInsuranceAdvisor) {
                          this.setState({bindingInsuranceAdvisor: true}, () => {
                            insuranceAdvisors.setInsuranceAdvisor(
                              {agentCode: msgDesc.agent.agentCode},
                              ['FIRE'],
                              this.state.promotionCodes,
                              this.state.language,
                              () => this.setState({bindingInsuranceAdvisor: false},
                                () => this._nextScreen())
                            );
                          });
                        }
                      }
                    },
                  ],
                  { cancelable: false }
                ),
              })
            }
          }
          else if (msgCode == 56) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert:
              Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                }],
              ),
            });
          }
          else {
            alert(msgCodeDetail(msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
          }
          this.setState({submitButtonDisabled: false});
        },
      )
    }
  }



  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Fire Insurance | Bravo Generali</title>
        </Helmet>

        <Navbar
            title={this.state.text.getInsured}
            backButton
            history={this.props.history}
          />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
            <Hidden xsDown>
              <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
                    <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                      <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.fire}</Text>
                      <p></p>
                     <Text style={[styles.greetingText,{fontSize: 20}]}>{this.state.text.duetofire}</Text>
                    </View>

              </Grid>
          </Hidden>
        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>


      <ScrollView style={formStyles.form,styles.fie}>
      {this.state.alert}
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h2}>{this.state.text.fireInsurance}</Text>
      </View>

      {
        this.state.insuranceAdvisor || this.state.broker ?
          <View style={formStyles.fieldset}>
            <Text>
              {this.state.text.theInsuranceIsIssued}
              {
                this.state.broker ?
                this.state.broker.name :
                this.state.insuranceAdvisor.agent.broker.nameEnUs
              }
              {this.state.text.professionalInsurance}
            </Text>
          </View> :
          <View style={formStyles.fieldset}>
            <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
          </View>
      }

      <Text>{this.state.text.pleaseInputInEnglish}</Text>

      {
        this.state.broker &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
              value={this.state.insuranceAdvisorMobile}
              editable={this.state.member && this.state.member.agent.isAdmin}
              keyboardType='phone-pad'
              maxLength={8}
            />
          </View>
      }

      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.productType}</Text>
        <ModalSelector
          data={[
            { key: 'fire', label: planTypes.fire.label[this.state.language] },
          ]}
          initValue={planTypes[this.state.planType].label[this.state.language]}
          onChange={(option) => this.setState({planType: option.key })}
          cancelText={this.state.text.cancel}
        />
        <Text style={formStyles.message}>{this.state.messages.planType}</Text>
      </View>

      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.sumInsuredAmount}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({sumInsuredAmount: removeCommas(text)})}
          value={addCommas(this.state.sumInsuredAmount)}
          keyboardType='numeric'
        />
        <Text>{this.state.text.itCanBe}</Text>
        <Text style={formStyles.message}>{this.state.messages.sumInsuredAmount}</Text>
        {
          this.state.sumInsuredAmount > 10000000 &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>

      <View style={styles.fieldset}>
        <Text style={styles.h4}>{this.state.text.ageOfBuilding}</Text>
        <ModalSelector
          data={[
            { key: '40', label: this.state.text.ageOfBuilding40 },
            { key: '45', label: this.state.text.ageOfBuilding45 },
            { key: '50', label: this.state.text.ageOfBuilding50 },
            { key: 'above', label: this.state.text.ageOfBuildingAbove },
          ]}
          initValue={this.state.buildingAge ?
            options.buildingAge[this.state.buildingAge].label[this.state.language]
            : this.state.text.pleaseChoose
          }
          onChange={(option) => this.setState({buildingAge: option.key })}
          cancelText={this.state.text.cancel}
        />
        <Text style={formStyles.message}>{this.state.messages.buildingAge}</Text>
        {
          this.state.buildingAge == 'above' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>

      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.effectiveDate}</Text>
        <DatePicker
          style={formStyles.datepicker}
          date={this.state.effectiveDateStart}
          mode='date'
          placeholder={this.state.text.pleasePickADate}
          format='MMMM D, YYYY'
          minDate={moment().add(1, 'days').format('MMMM D, YYYY')}
          maxDate={moment().add(1, 'days').add(6, 'months').format('MMMM D, YYYY')}
          onDateChange={(date) => {this.setState({
            effectiveDateStart: date,
            effectiveDateEnd: moment(date, 'MMMM D, YYYY')
              .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
          })}}
          confirmBtnText={this.state.text.ok}
          cancelBtnText={this.state.text.cancel}
        />
        <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
      </View>
      {
        this.state.effectiveDateStart &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.periodOfInsurance}</Text>
            <View style={styles.periodOfInsurance}>
              <Text>
                {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
              </Text>
              <Text>{this.state.text.bothDatesInclusive}</Text>
            </View>
          </View>
      }
      {
        !this.state.businessEmailAddress &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.promotionCodes}</Text>
            <TextInput
              style={formStyles.input}
              placeholder={this.state.text.optional}
              onChangeText={(text) => this.setState({promotionCodes: text})}
              value={this.state.promotionCodes}
            />
            <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
          </View>
      }
      {
        !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.businessEmailAddress}</Text>
            <TextInput
              style={formStyles.input}
              placeholder={this.state.text.optional}
              keyboardType='email-address'
              onChangeText={(text) => this.setState({businessEmailAddress: text})}
              value={this.state.businessEmailAddress}
            />
            <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
          </View>
      }
      <View style={formStyles.hr} />
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h3}>{this.state.text.policyholder}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.name}</Text>
        <NameInput
          title={this.state.policyholderTitle}
          firstName={this.state.policyholderFirstName}
          lastName={this.state.policyholderLastName}
          onChange={(title, firstName, lastName) => this.setState({
            policyholderTitle: title,
            policyholderFirstName: firstName,
            policyholderLastName: lastName,
          })}
          placeholder={this.state.text.nnnnn}
        />
        <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
        <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.hkidNumber}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({policyholderHKID: text})}
          value={this.state.policyholderHKID}
          placeholder='A1234567'
        />
        <Text>{this.state.text.pleaseEnterAllHKID}</Text>
        <Text style={formStyles.message}>{this.state.messages.policyholderHKID}</Text>
      </View>

      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
        <DatePicker
          style={formStyles.datepicker}
          date={this.state.policyholderDOB}
          mode='date'
          placeholder={this.state.text.pleasePickADate}
          format='MMMM D, YYYY'
          minDate={'January 2, 1900'}
          maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
          onDateChange={(date) => {this.setState({policyholderDOB: date})}}
          confirmBtnText={this.state.text.ok}
          cancelBtnText={this.state.text.cancel}
        />
          <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
      </View>

      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({policyholderPhone: text})}
          value={this.state.policyholderPhone}
          keyboardType='phone-pad'
          maxLength={8}
        />
        <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({policyholderAddress: text})}
          value={this.state.policyholderAddress}
          placeholder={this.state.text.optional}
        />
        <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({policyholderEmail: text})}
          value={this.state.policyholderEmail}
          keyboardType='email-address'
        />
        <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.beingTheOwnerOftheInsuredPremises}
          choice={this.state.policyholderOwner}
          onChange={(choice) => this.setState({policyholderOwner: choice})}
        />
        <Text style={formStyles.message}>{this.state.messages.policyholderOwner}</Text>
        {
          this.state.policyholderOwner == 'N' &&
          decline(
            this.state.text.sorryThatYourApplication,//提示文字
            this.state.language,
            this.props.navigation, //不動
            () => this.setState({policyholderOwner: null})  //更改參數
           )
         }
       </View>
      <View style={formStyles.hr} />
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h3}>{this.state.text.insuredPremises}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.address}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({propertyAddress: text})}
          value={this.state.propertyAddress}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyAddress}</Text>
        {
          this.state.propertyAddress && this.state.propertyAddress.length > 0 && (
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('HENGFACHUEN') ||
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('HANGFACHUEN') ||
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('HENGFACHUAN') ||
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('HANGFACHUAN') ||
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('100SHINGTAIROAD') ||
            this.state.propertyAddress.toUpperCase().replace(/ /g, '').includes('SHINGTAIROAD')
          ) ?
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text> : <View />
        }
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.residentialOrCommercial}</Text>
        <ModalSelector
          data={[
            { key: 'residential', label: this.state.text.residential },
            { key: 'commercial', label: this.state.text.commercial },
          ]}
          initValue={this.state.propertyUsage ?
            this.state.propertyUsage == 'residential' ?
            this.state.text.residential : this.state.text.commercial
            : this.state.text.pleaseChoose
          }
          onChange={(option) => this.setState({propertyUsage: option.key })}
          cancelText={this.state.text.cancel}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyUsage}</Text>
        {
          this.state.propertyUsage == 'commercial' &&
          decline(
            this.state.text.sorryThatYour,//提示文字
            this.state.language,
            this.props.navigation, //不動
            () => this.setState({propertyUsage: null})  //更改參數
           )
         }
         </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{this.state.text.houseOrApartment}</Text>
        <ModalSelector
          data={[
            { key: 'H', label: options.H.label[this.state.language] },
            { key: 'A', label: options.A.label[this.state.language] },
          ]}
          initValue={
            this.state.propertyType ?
              options[this.state.propertyType].label[this.state.language] :
              this.state.text.pleaseChoose
          }
          onChange={(option) => this.setState({propertyType: option.key})}
          cancelText={this.state.text.cancel}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyType}</Text>
        {
          this.state.propertyType =='H' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.postCopyToMortgagee}
          choice={this.state.isPostPolicy}
          onChange={(choice) => this.setState({isPostPolicy: choice})}
        />
        <Text style={formStyles.message}>{this.state.messages.isPostPolicy}</Text>
        {
          this.state.isPostPolicy =='Y' &&
            <View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.nameOfMortgagee}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({mortgageeName: text})}
                  value={this.state.mortgageeName}
                />
                <Text style={formStyles.message}>{this.state.messages.mortgageeName}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.addressOfMortgagee}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({mortgageeAddress: text})}
                  value={this.state.mortgageeAddress}
                />
                <Text style={formStyles.message}>{this.state.messages.mortgageeAddress}</Text>
              </View>
            </View>
        }
      </View>
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.theHomeIsUnoccupiedForLonger30Days}
          choice={this.state.propertyUnoccupied}
          onChange={(choice) => this.setState({propertyUnoccupied: choice})}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyUnoccupied}</Text>
        {
          this.state.propertyUnoccupied == 'Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.anyLossDamageLiabilityOrAccident}
          choice={this.state.propertyLoss}
          onChange={(choice) => this.setState({propertyLoss: choice})}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyLoss}</Text>
        {
          this.state.propertyLoss == 'Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      {/*
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.theHomeBuildingIsMoreThan40years}
          choice={this.state.buildingAge == 'above' ? 'Y' : 'N'}
          onChange={(choice) => null}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyAged}</Text>
        {
          this.state.propertyAged == 'Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      */}
      <View style={formStyles.fieldset}>
        <YesNoQuestion
          question={this.state.text.anyInsuranceCompnayDeclined}
          choice={this.state.propertyDeclined}
          onChange={(choice) => this.setState({propertyDeclined: choice})}
        />
        <Text style={formStyles.message}>{this.state.messages.propertyDeclined}</Text>
        {
          this.state.propertyDeclined =='Y' &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      <View style={formStyles.hr} />
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({specialRequests: text})}
          value={this.state.specialRequests}
          placeholder={this.state.text.optional}
        />
        <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
        {
          !!this.state.specialRequests &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h3}>{this.state.text.referral}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this.setState({referral: text})}
          value={this.state.referral}
          placeholder={this.state.text.optional}
        />
        <Text style={formStyles.message}>{this.state.messages.referral}</Text>
      </View>
      {
        this.state.submitButtonDisabled ?
          <View style={formStyles.submitButtonDisabled}>
            <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
          </View> :
          <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
            <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
          </TouchableOpacity>
      }
      </ScrollView>

      </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
     </Grid>

      <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 580,
      paddingLeft: 20,
      paddingRight: 20,
    },
};
