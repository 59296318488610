import React from 'react';
import { Text, TextInput, Switch, View, ScrollView } from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Locale from './../../Locale';
import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { formStyles } from './../../config/styles';
import { languageStorage } from './../../lib/LanguageStorage';
import ListMenu from './../../components/ListMenu/ListMenu';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class Languages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }));
  }

  _setLanguage(language) {
    Locale.setLocale(language);

    languageStorage.setLanguage(language, () => {
      this.props.history.push('/');
    });
  }


  render() {
    const color = Member.getThemeColor();

    return (
      <div className='App'>
        <Helmet>
          <title>Language Settings | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.languages}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.languageSettings}</Text>
                <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.choose}</Text>

            </View>

          </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <ScrollView style={formStyles.form, styles.fieldset}>

              <View style={formStyles.fieldset}>
                <Text style={[formStyles.h3, {color}]}>{this.state.text.preferredLanguage}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.language == 'zhHK'}
                      onValueChange={
                        (value) => value && this._setLanguage('zhHK')
                      }
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text style={formStyles.h4}>繁體中文</Text>
                  </View>
                </View>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.language == 'enUS'}
                      onValueChange={
                        (value) => value && this._setLanguage('enUS')
                      }
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text style={formStyles.h4}>English</Text>
                  </View>
                </View>
              </View>

            </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>

      <Footer/>
    </div>
    )
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
    paddingLeft: 20,
   paddingRight: 20,
  },
};
