import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';

import Locale from './../../Locale';

export default class ImportantNotes extends React.Component {
  render() {
    return (
      <View style={styles.importantNotes}>
        <View style={styles.importantNotesLabel}>
          <Text>
            {Locale.text.importantNotes}
          </Text>
        </View>

        <FlatList
          data={Array.isArray(this.props.children) ?
            this.props.children : [this.props.children]}
          renderItem={({item, index}) =>
            <View style={styles.importantNote}>
              <View style={styles.importantNoteNumber}>
                <Text>{index + 1}.</Text>
              </View>

              <View style={styles.importantNoteText}>
                <Text>{item}</Text>
              </View>
            </View>
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  importantNotes: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#F5F5F5',
  },
  importantNotesLabel: {
    marginBottom: 10,
  },
  importantNote: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
  },
  importantNoteNumber: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  importantNoteText: {
    flex: 9,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
});
