import React from 'react';
import { Text, View } from 'react-native';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from './../../components/TextField/TextField';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';
import { formStyles } from './../../config/styles';

export default class IdentityInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
    this.props.onChange(
      this.props.type ? this.props.type : 'HKID', this.props.number);
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  render() {
    return (
      <View>
        <View style={styles.identityInput}>
          <View style={styles.identityType}>
            <Select
              style={styles.field}
              value={
                this.props.type
              }
              onChange={(event) => this.props.onChange(event.target.value, this.props.number)}
              cancelText={this.state.text.cancel}
            >
            <MenuItem value='HKID'>{this.state.text.hkid}</MenuItem>
            {
              this.props.pp &&
                <MenuItem value='PP'>{this.state.text.passport}</MenuItem>
            }
            </Select>
          </View>
          <View style={styles.identityNumber}>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.props.onChange(this.props.type, text)}
              value={this.props.number}
              placeholder={this.props.type == 'HKID' ? 'A1234567' : null}
              placeholderTextColor='lightgrey'
            />
          </View>
        </View>
        {
          this.props.type == 'HKID' &&
          <View style={{textAlign: 'left'}}>
            <Text>{this.state.text.pleaseEnterAllChars}</Text>
          </View>
        }
      </View>
    );
  }
}

const styles = {
  identityInput: {
    flex: 1,
    flexDirection: 'row',
  },
  identityType: {
    flex: 2,
  },
  identityNumber: {
    flex: 3,
    paddingLeft: 10,
  },
};
