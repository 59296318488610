import React from 'react';
import { Text, View, ScrollView } from 'react-native';

import { formStyles } from './../../config/styles.js';
import { languageStorage } from './../../lib/LanguageStorage';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class Document29EScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => {
        this.setState({
          language: language,
        })
        language==='enUS'? window.location.href = "https://www.app-generali.com/share_files/Product_29doc/29E_en.pdf":window.location.href = "https://www.app-generali.com/share_files/Product_29doc/29E_zh.pdf"
    }
    );
  }

  render() {
    const color = Member.getThemeColor();

    return (
      <div></div>
    //   <div className='App'>
    //     <Navbar
    //       logo backButton
    //       history={this.props.history}
    //     />
    //   <View style={styles.container, styles.fieldset}>
    //     {
    //       this.state.language == 'enUS' &&
    //         <View>
    //           <ScrollView style={formStyles.form}>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h2, {color}]}>
    //               Personal Information Collection Statement
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>a) From time to time, it is necessary for you to supply Generali Life (Hong Kong) Limited / Assicurazioni Generali S.p.A. Hong Kong Branch (where applicable) (the “Company”) with data about yourself(ves), policyholder(s), life insured(s), beneficiary(ies), claimant(s), and/ or other relevant individuals (the “Personal Data”) in connection with the provision of insurance and/ or related products and services to you, the processing of claims under insurance policies issued and/ or arranged by the Company, and/ or the processing of any or all other requests, enquiries and complaints from you.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               b) Provision of the Personal Data to the Company by you is voluntary.  However, failure to supply the Personal Data may result in the Company being unable to provide insurance and/ or related products and services to you, process claims under insurance policies issued and/ or arranged by the Company, and/ or process any or all other requests, enquiries, or complaints from you.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               c) The purposes for which the Personal Data may be used are as follows:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) processing your insurance application, arranging and executing insurance contract, and managing your account with the Company
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) customer services and other related activities;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iii) conducting data matching procedures;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iv) designing insurance and/ or related products and services for customers’ use;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               v) marketing insurance and/ or other related products and services of the Company and/ or its parent company and group companies (hereinafter referred to as the “Group Entities”);
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vi) direct marketing of insurance and/ or other related products and services subject to your prior prescribed consent (if any), and you can exercise the right of opt-out by notifying the Company at any time;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vii) statistical or actuarial research of the Company, its Group Entities, insurance industry associations or federations,  governments and/ or regulatory entities;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               viii) complying with the requirements under any laws, rules, regulations, codes, guidelines, court orders, compliance policies and procedures, and any other relevant requirements which the Company and/ or its Group Entities are expected to comply with, including, without limitation, performing due diligence on customers and making disclosures of the relevant information; and
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ix) fulfilling any other  purposes directly relating to (i) to (viii) above.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               d) The Personal Data held by the Company shall be kept confidential, but the Company may provide the Personal Data to the following parties (whether within or outside the Hong Kong Special Administrative Region) for the purposes set out in paragraph (c) above, without prior notification to you and/ or any other relevant individuals to whom the Personal Data is related:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) intermediaries, claims service providers, coinsurers, reinsurers, banks and credit-card companies, health and medical organizations, business partners, and/ or any other relevant parties, as appropriate, who provide administrative, telecommunication, computer, payment, marketing, investigation, advisory and/ or other services to the Company in connection with the operation of its business;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) relevant insurance industry associations or federations, and/ or members of such industry associations or federations;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iii) overseas locations, as appropriate, of the Company and/ or its Group Entities;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iv) persons to whom the Company and/ or its Group Entities are under an obligation to make disclosure under the requirements as mentioned in (c) (viii);
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               v) any court, government or regulatory entity (including, without limitation, tax authority, insurance authority, etc.) under any laws binding on the Company and/ or its Group Entities;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vi) lawful successors or assigns of the Company; and
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vii) persons who owe a duty of confidentiality to the Company and/ or its Group Entities.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               e) The Company may verify any or all of the Personal Data by using information collected and released or transferred by relevant insurance industry associations or federations, and/ or members of such industry associations or federations.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               f) In accordance with the Personal Data (Privacy) Ordinance:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) any individual has the right to:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               A) check whether the Company holds data about him/ her and, if so, obtain a copy of such data;
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               B) require the Company to correct any data relating to him/ her that is inaccurate; and
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               c) ascertain the Company’s policies and practices in relation to data and to be informed of the kind of data held by the Company; and
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) the Company has the right to charge a reasonable fee for the processing of any data access request.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               g) The person to whom requests for access to data and/ or correction of data and/ or for information regarding policies and practices and kinds of data held are to be addressed as follows:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               Personal Data Protection Officer,
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               Generali Life (Hong Kong) Limited / Assicurazioni Generali S.p.A. Hong Kong Branch (where applicable)
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               21/F Citiplaza One, 1111 King's Road, Taikoo Shing, Hong Kong
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h3, {color}]}>
    //               Use and Provision of Personal Data in Direct Marketing
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               (This section forms part of the Personal Information Collection Statement.)
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               Provision of consent in this Section by you is voluntary and it will not affect your application.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               1) The Personal Data, including but not limited to, name, contact details, other products and services portfolio information, transaction pattern and behavior, financial background and demographic information, etc. may be used for the purpose of direct marketing insurance and/ or other related products and services of the Company and its parent company and group companies (hereinafter referred to as the “Group Entities”) and/ or third parties selected by the Company.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               2) The Personal Data may also be provided to the Group Entities and third party service providers selected by the Company for the purpose set out in paragraph (1) above, including, without limitation, call centres.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               3) The Company requires your consent (which includes an indication of no objection) to the use of Personal Data for the purpose set out in this section.  If you do not wish the Company to use or provide to other parties the Personal Data for the purpose of direct marketing, you may exercise the opt-out right below or by notifying the Company at any time thereafter.
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h3, {color}]}>
    //               Please tick (“✓”) the boxes below if you do not agree with the following use(s) of the Personal Data in direct marketing.
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ☐ I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ☐ I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               (If you do not tick the boxes but confirm the Form/ document, you will be regarded as having indicated you have no objection (i.e. you consent) to the use or transfer to third parties of the Personal Data for the purpose of direct marketing by the Company.)
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               Note: In case of discrepancies between the English and Chinese versions of this Personal Information Collection Statement, the English version shall prevail.
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h2, {color}]}>
    //               Declaration
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               I/ We confirm that I/ we have read and understood the Personal Information Collection Statement (“Statement”).  I/ We agree that Generali may collect, use, transfer and otherwise process my/ our personal data in accordance with the terms of the Statement.  I/ We further confirm that I/ we have obtained the express consent of the life insureds and any other relevant individuals (where applicable) for providing their personal data to Generali for the purposes stated in the Statement and for allowing Generali to collect, use, transfer and otherwise process such personal data in accordance with the terms of the Statement.
    //               </Text>
    //             </View>
    //             </View>
    //           </ScrollView>
    //         </View>
    //     }
    //     {
    //       this.state.language == 'zhHK' &&
    //         <View>
    //           <ScrollView style={formStyles.form, styles.fieldset}>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h2, {color}]}>
    //               收集個人資料聲明
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>a) 閣下須要不時向忠意人壽（香港）有限公司／忠意保險有限公司香港分行﹝如適用﹞（「本公司」）提供關於閣下自己、保單持有人、受保人、受益人、索償人及／或其他有關人士的資料（「個人資料」），以讓本公司為閣下提供保險及／或相關產品與服務，處理經由本公司發出及／或安排的保單之下的索償事宜，及／或處理閣下提出的任何或所有其他要求、查詢和投訴。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               b) 閣下是自願向本公司提供個人資料的。然而，若閣下未能提供個人資料，可能導致本公司不能夠為閣下提供保險及／或相關產品與服務，處理經由本公司發出及／或安排的保單之下的索償事宜，及／或處理閣下提出的任何或所有其他要求、查詢和投訴。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               c) 個人資料可被用於以下用途：
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) 處理閣下的保險申請，安排並執行保險合約，並管理閣下在本公司的賬戶；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) 客戶服務及其他相關活動；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iii) 進行資料核對程序；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iv) 設計保險及／或相關產品與服務供客戶使用；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               v) 推銷本公司及／或本母公司及本集團的公司（下文合稱為「集團實體」）的保險及／或相關產品與服務；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vi) 就閣下事前訂明的允許（如有）約束之下，直接促銷保險及／或其他相關產品與服務，而閣下可在任何時間知會本公司以行使撤回允許的權利；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vii) 本公司、本集團實體、保險業協會或聯會、政府部門及／或監管機構的統計或精算研究；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               viii) 遵從任何法律、規則、規例、守則、指引、法院命令、合規政策和程序的規定，以及本公司及／或本集團實體應要遵守的任何其他有關規定，包括但不限於對客戶進行盡職審查及披露有關資料；及
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ix) 實現與上述（i）至（viii）直接有關的任何其他用途
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               d) 由本公司持有的個人資料將受到保密，但本公司可依據以上（c）段所列的用途向以下各方（不論在香港特別行政區境內還是境外）提供個人資料，事前無須知會閣下及／或該等個人資料所涉及的任何其他有關人士：
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) 與本公司的業務營運相關的中介人、索償服務提供商、共同保險公司、再保險公司、銀行及信用卡公司、健康及醫療機構、業務夥伴及／或任何其他有關各方，以適用者為準，向本公司提供行政、電訊、電腦、付款、推銷、調查、諮詢及／或其他服務；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) 相關的保險業協會或聯會，及／或該等協會或聯會的成員；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iii) 本公司及／或本集團實體的海外辦事處，以適用者為準；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               iv) 根據上述(c) (viii)的規定，本公司及／或本集團實體負有義務須向其作出披露的人士；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               v) 根據任何法律約束之下，本公司及／或本集團實體須向其提供資料的任何法院、政府部門或監管機構（包括但不限於稅務局、保險業監管機構等）；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vi) 本公司的合法繼承人或受讓人；及
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               vii) 對本公司及／或本集團實體負有保密責任的人士。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               e) 本公司可使用由相關的保險業協會或聯會及／或該等協會或聯會的成員所收集及發放或轉移的資料，來核實任何或所有個人資料。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               f) 根據《個人資料（私隱）條例》：
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               i) 任何人士均有權:
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               A) 查詢本公司有沒有持有其資料，如有的話，可取得一份該等資料；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               B) 要求本公司改正其任何不正確的個人資料；及
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               c) 查明關於本公司的個人資料政策和處事常規，並可獲通知有關本公司所持個人資料的種類；及
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ii) 本公司有權就處理任何查閱個人資料的要求之下收取合理的費用。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               g) 如欲查閱及／或改正個人資料及／或查詢關於本公司的政策和處事常規及所持個人資料的種類，請向以下人員提出要求：
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               個人資料保護主任
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               忠意人壽（香港）有限公司 或 忠意保險有限公司香港分行﹝如適用﹞
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               香港英皇道1111號
    //               </Text>
    //               <Text>太古城中心一期21樓
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //             <Text style={[formStyles.h3, {color}]}>
    //               使用及提供個人資料作直接促銷
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               （本節條文是組成「收集個人資料聲明」的一部分。）
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //             <Text>
    //               閣下在本節中提供的允許是自願的，並不會影響閣下的申請。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               1) 個人資料，包括但不限於，姓名、聯絡的詳細資料、其他產品及服務組合資料、交易模式及行為，財務背景及人口統計資料等可被用作於直接促銷本公司及本母公司及本集團的公司（下文合稱為「集團實體」）及／或由本公司所選定的第三方的保險及／或其他相關產品與服務；
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               2) 就以上（1）段所述的用途，個人資料亦可被提供予本集團實體及本公司所選定的第三方服務提供商，包括但不限於，電話服務中心。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               3) 本公司須獲閣下允許（包括表示不反對）本公司可按照本節條文所述的用途使用個人資料。若閣下不希望本公司使用或向第三方提供個人資料作直接促銷用途，閣下可於下方行使退出權利或於日後任何時間知會本公司。
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h3, {color}]}>
    //               如閣下不同意個人資料用作下列直接促銷用途，請在以下方格內加上剔號 (“”):
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ☐ 本人不允許貴公司向本文所述的第三方提供個人資料作直接促銷用途。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               ☐ 本人不允許貴公司使用個人資料作直接促銷用途。
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               （若閣下沒有在方格內加上剔號但確認本表格／文件，閣下會被視之為不反對（即閣下允許）本公司使用或向第三方提供個人資料作直接促銷用途。）
    //               </Text>
    //             </View>

    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               附註：本收集個人資料聲明的英文及中文版本之間如有任何歧義，概以英文版本為準。
    //               </Text>
    //             </View>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h2, {color}]}>
    //               聲明
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //               本人確認已經閱讀並且明白收集個人資料聲明（「該聲明」)。本人同意忠意保險可依照該聲明的條款收集、使用、轉移及以其他方式處理本人的個人資料。本人進一步確認，本人已獲得受保人和任何其他有關人士（如適用）的明示同意，可以按照該聲明所述的用途將他們的個人資料提供給忠意保險，並允許忠意保險可依照該聲明的條款收集、使用、轉移及以其他方式處理該等個人資料。
    //               </Text>
    //             </View>
    //             </View>
    //           </ScrollView>
    //         </View>
    //     }
    //   </View>
    //   <Footer/>
    // </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
};
