import React from 'react';
import {
  Text, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { planTypes } from './planTypes';
import { instituteLocations } from './instituteLocations';
import { platform } from './../../../config/platform';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { formStyles } from './../../../config/styles';
import { msgCodeDetail, clearEmptyStrings, removeSpaces, handleExpiredLogin, checkTyphoonMessage, hmacSha256, } from './../../../lib/utils';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api26, busiSecKey } from './../../../config/api';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';

import Member from './../../../Member';

export default class ASAP1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state ?
        this.props.location.state.broker : undefined,
      planType: 'without',
      instituteLocation: 'US_CA',
      messages: {},
      text: {},
    };

    Member.onReady(() => this.setState(this.state));

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    try {
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        this.state.businessEmailAddress = undefined;
        localStorage.removeItem('businessEmail');

        this.state.promotionCodes = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        this.state.promotionCodes = undefined;
        localStorage.removeItem('promotionCodes');

        this.state.businessEmailAddress = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const referral = searchParams.get('refcode');
      if (referral) {
        this.state.referral = referral;
      }

    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    localStorage.setItem('preventGoBack', 'false');
  }

  componentDidMount() {
    stateStorage.getState('asap1', (state) => {
      if (state && !this.state.promotionCodes && !this.state.businessEmailAddress) {
        if (state.promotionCodes) {
          delete this.state.businessEmailAddress;
        }
        if (state.businessEmailAddress) {
          delete this.state.promotionCodes;
        }
        this.setState(state);
      }
    });

    window.scrollTo(0, 0)
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          submitButtonDisabled: member && !this.state.broker,
          member: member,
          token: token,
        }, () => {
          if (!this.state.broker) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                checkTyphoonMessage(
                  'EAPP',
                  'ASAP',
                  this.state.language,
                  this.props.history,
                  (alert) => this.setState({alert: alert}),
                  (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
                );

                if (insuranceAdvisors.ASAP) {
                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.ASAP.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'ASAP',
                      (promotionCodes) => {
                        this.setState({
                          submitButtonDisabled: false,
                          businessEmailAddress: undefined,
                          promotionCodes: promotionCodes ?
                            promotionCodes : this.state.promotionCodes,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
            if (!this.state.member) {
              checkTyphoonMessage(
                'EAPP',
                'ASAP',
                this.state.language,
                this.props.history,
                (alert) => this.setState({alert: alert}),
                (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
              );

              this.setState({showBusinessEmailAddress: true});
            }
          }
          else {
            checkTyphoonMessage(
              'EAPP',
              'ASAP',
              this.state.language,
              this.props.history,
              (alert) => this.setState({alert: alert}),
              (noticeAgree) => this.setState({noticeAgree: noticeAgree, alert: undefined})
            );
          }
        }));
      })
    );
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      businessEmailAddress: {
        format: englishFormat,
      },
    }
    const messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let fields = {
        'dt': moment().format('YYYYMMDDhhmmss'),
        'pn': 'EAPP',
        'pl': 'ASAP',
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.businessEmail': removeSpaces(this.state.businessEmailAddress),
        'asap.includeGuardian': this.state.planType == 'with' ? 'Y' : 'N',
        'institution.country': this.state.instituteLocation,
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
        const hmac = hmacSha256(data);
        fetch(host + (this.state.broker ? '/agent' : '') + api26, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
        })
        .then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.msgCode == 0 || this.state.nextScreen) {
            this.setState({submitButtonDisabled: false, alert: undefined}, () => {
              stateStorage.setState('asap1', this.state);
              stateStorage.setState('asap2', undefined);

              this.props.history.push('/asap2', {
                broker: this.state.broker,
                planType: this.state.planType,
                instituteLocation: this.state.instituteLocation,
                promotionCodes: removeSpaces(this.state.promotionCodes),
                businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
                showBusinessEmailAddress: this.state.showBusinessEmailAddress,
                premium: responseJson.msgDesc.premium,
                referral: this.state.referral,
              });
            });
          }
          else if (responseJson.msgCode == 20) {
            this.setState({
              alert:
                handleExpiredLogin(this.state.language, this.props.history),
            });
          }
          else if (responseJson.msgCode == 40) {
            if (this.state.broker) {
              this.setState({
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
                alert: Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{
                    text: this.state.text.ok,
                    onPress: () => this.setState({alert: null}),
                    style: 'cancel',
                  }],
                ),
              });
            }
            else {
              this.setState({alert:
              Alert.alert(
                this.state.text.addInsuranceAdvisor,
                this.state.text.wouldYouLike
                + responseJson.msgDesc.agent.brokerName
                + this.state.text.toBeYourInsuranceAdvisor,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () => {
                      if (!this.state.bindingInsuranceAdvisor) {
                        this.setState({bindingInsuranceAdvisor: true}, () => {
                          insuranceAdvisors.setInsuranceAdvisor(
                            {agentCode: responseJson.msgDesc.agent.agentCode},
                            ['ASAP'],
                            this.state.promotionCodes,
                            this.state.language,
                            () => this.setState({bindingInsuranceAdvisor: false},
                              () => this._nextScreen())
                          );
                        });
                      }
                    }
                  },
                ],
                { cancelable: false }
              )
              });
            }
          }
          else if (responseJson.msgCode == 56) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
          }
          this.setState({submitButtonDisabled: false});
        })
        .catch((error) => {
          alert('Facing error when processing the quotation!');
          this.setState({submitButtonDisabled: false});
        });
      }
    }



  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    const leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_ASAP.pdf';
    const provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Annual%20Study%20Abroad%20Protector%20Policy.pdf';

    return (
      <div className='App'>

      <Helmet>
        <title>Annual Study Abroad Protector | Bravo Generali</title>
      </Helmet>

      <Navbar
        title={this.state.text.getAQuote}
        backButton
        history={this.props.history}
      />
      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.asap}</Text>
              <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.ensure}</Text>

            </View>

        </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <ScrollView style={styles.form, styles.fie}>
            {this.state.alert}
            <View style={styles.fieldset}>
              <Text style={[styles.h2, {color}]}>{this.state.text.annualStudyAbroadProtector}</Text>
            </View>

            <View style={{textAlign: 'left'}}>
              <Text>{this.state.text.secondarySchoolNUniversityIsATime}</Text>
            </View>
            <ProductEdges
              productEdges={[
                this.state.text.highMedicalExpenses,
                this.state.text.hospitaliztionBenefitsInHK,
                this.state.text.twelveMonthsConsecutiveCover,
                this.state.text.optionalCoverForAccompanied,
                this.state.text.zeroExcess,
              ]}/>

            <AttachmentLink
              leaflet={leaflet}
              provision={provision}
              history={this.props.history}
            />

            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.planType}</Text>
              <View style={styles.planTypes}>
                <PlanTypePicker
                  selectedPlanType={this.state.planType}
                  onSelect={
                    (selectedPlanType) =>
                      this.setState({planType: selectedPlanType})
                  }
                  planTypes={planTypes}
                />
              </View>
            </View>

            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.locationOfTheOverseasStudyingInstitution}</Text>
              <ModalSelector
                data={[
                  { key: 'US_CA', label: this.state.text.usaCanada },
                  { key: 'OTHERS', label: this.state.text.otherCountry },
                ]}
                initValue={instituteLocations[this.state.instituteLocation].label[this.state.language]}
                onChange={(option) => this.setState({instituteLocation: option.key })}
                cancelText={this.state.text.cancel}
              />
            </View>

            {
              !this.state.businessEmailAddress &&
                <View style={styles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
                  <TextInput
                    style={styles.input}
                    placeholder={this.state.text.optional}
                    placeholderTextColor='lightgrey'
                    value={this.state.promotionCodes}
                    onChangeText={(text) => this.setState({promotionCodes: text})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
                </View>
            }

            {
              !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
                <View style={styles.fieldset}>
                  <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
                  <TextInput
                    style={styles.input}
                    placeholder={this.state.text.optional}
                    placeholderTextColor='lightgrey'
                    keyboardType='email-address'
                    value={this.state.businessEmailAddress}
                    onChangeText={(text) => this.setState({businessEmailAddress: text})}
                  />
                  <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
                </View>
            }

            {
              this.state.submitButtonDisabled ?
                <View style={formStyles.submitButtonDisabled}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                </View> :
                <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._nextScreen()}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.getAQuote}</Text>
                </TouchableOpacity>
            }
          </ScrollView>
       </Grid>
         <Hidden xsDown>
          <div style={{marginRight: '5%'}} />
         </Hidden>
      </Grid>
        <Footer/>
    </div>
    );
  }
}

const styles = {
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //   width: '60%',
  // },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
};
