import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';

export const planTypes = {
  individual: {
    label: {
      enUS: enUS.individualPlan,
      zhHK: zhHK.individualPlan,
    },
    color: 'white',
    backgroundColor: '#8E1230',
  },
  family: {
    label: {
      enUS: enUS.familyPlan,
      zhHK: zhHK.familyPlan,
    },
    color: 'white',
    backgroundColor: '#E9573D',
  },
};
