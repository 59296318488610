import React from 'react';
import { View } from 'react-native';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';
import { host, api311, api62, busiSecKey } from './../../config/api';
import { msgCodeDetail } from './../../lib/utils';
import Alert from './../../components/Alert/Alert';
import Navbar from './../../components/Navbar/Navbar';

const patchPostMessageFunction = () => {
  const originalPostMessage = window.postMessage;

  const patchedPostMessage = (message, targetOrigin, transfer) => {
    originalPostMessage(message, targetOrigin, transfer);
  };

  patchedPostMessage.toString = () =>
    String(Object.hasOwnProperty).replace('hasOwnProperty', 'postMessage');

  window.postMessage = patchedPostMessage;
};

const patchPostMessageJsCode = `(${String(patchPostMessageFunction)})();`;

export default class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptcha: this.props.location.state.recaptcha,
      text: {},
    };

    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
    }
  }

  


  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }));
  }




  render() {
    return (
      <View style={styles.container}>
        <Navbar
          title={this.state.text.payment}
          backButton
          history={this.props.history}
        />

        { this.state.alert }

        {
          this.state.recaptcha ?
            <div style={{width: '100%', minHeight: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ReCAPTCHA
                sitekey='6Lf7_HYUAAAAAGAlKNXmqW44LtmYH8QSzDn1rcFT'
                onChange={(value) => value && this.setState({recaptcha: false})}
              />
            </div>:
            <div>
              { window.location.assign(this.props.location.state.uri) }
            </div>
        }
      </View>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
};
