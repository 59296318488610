export const zhHK = {
  title: '稱謂',
  firstName: '名',
  lastName: '姓',
  mr: '先生',
  mrs: '太太',
  miss: '小姐',
  ms: '女士',
  cancel: '取消',
}
