export const enUS = {
  getAQuote: 'Get a Quote',
  ussstudyplan: 'US Study Plan',
  secondarySchoolNUniversityIsATime: 'The United States has always been the favourite choice of every student and parent to study abroad. In view of this, Generali has sincerely designed a new and comprehensive medical insurance for your children. This plan meets the medical insurance requirements of American colleges and offers more affordable premium options. Your children will be able to study with more peace of mind and focus.',

  productEdges:'Product Edges',
  highMedicalExpenses: 'High Medical Expenses up to HK$1,500,000 (including the illness of COVID-19)',
  hospitaliztionBenefitsInHK: 'Hospitalization Benefits in Hong Kong (for newly diagnosed illness or injury) ',
  twelveMonthsConsecutiveCover: '12 months consecutive cover',
  optionalCoverForAccompanied: 'Optional Cover for Accompanied Parents',
  zeroExcess: 'Zero excess',
 
  point1:"United States' Largest Healthcare Network",
  point2:"No billing required for discharge (except for co-payment)",
  point3:"Medical Coverage - Unlimited Sum Insured Amount (designated plan) / Comprehensive Coverage",
  point4:"Emergency medical assistance",
  point5:"24 hours hotline centre in United States",
  point6:"Terrorism cover",
  point7:"Covers all trips arranged by school (including oversea trips)",
  point8:"12 consecutive months cover without trip duration restriction ",
  point9:"Premium saving",
  point10:"If the application for an insurance waiver to a US university fails, premium will be refunded in full.", 


  forDetailsOfTheProduct:'For details of the product, exclusions and premium rates, please refer to Product Leaflets and Policy Provisions ',

  planType: 'Plan Type',
  withAccompanying: 'Basic',
  withoutAccompanying: 'Plus',
  pleaseInputInEnglish: '* Please input in English *',

  locationOfTheOverseasStudyingInstitution:'Location of The Overseas Studying Institution',
  usaCanada: 'USA / Canada',

  promotionCodes: 'Promotion Codes',
  optional: 'Optional',
  businessEmailAddress: 'Business Email / Membership',
  businessEmailMembership: 'Business Email\n/ Membership',
  getAQuote: 'Get a Quote',
  studentage:'Age',


  iWouldLikeToBuy: 'I would like to buy',
  total: 'Total',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  productType: 'Product Type',
  effectiveDate: 'Effective Date',
  pleasePickADate: 'Please pick a date',

  policyHolder: 'Policyholder',
  thePolicyHolderMustBeThe: 'Policyholder must be the insured student.',
  name: 'Name',
  title: 'Title',
  mr: 'Mr.',
  ms: 'Ms.',
  mrs: 'Mrs.',
  miss: 'Miss',
  firstName: 'First name',
  lastName: 'Last name',

  hkid: 'HKID',
  pleaseEnterAllCharacters: 'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  dateOfBirth: 'Date of Birth',
  mobileNumber: 'Mobile Number',
  correspondenceAddress: 'Correspondence Address',
  emailAddress: 'Email Address',

  insuredStudent: 'Insured Student',
  overseasStudyingInstitution: 'Emergency contact',
  emergencyPhone:'Emergency Phone',
  emergencyLastName:'Emergency Last Name',
  emergencyFirstName:'Emergency First Name',
  location: 'Location',
  address: 'Address',
  specialRequests: 'Special Requests',
  referral: 'Referral Code',
  thisFieldCannotBeBlank: 'This field cannot be blank',

  importantNotes: 'Important Notes:',
  thePolicyHolderShallBe: 'The policyholder shall be the mother, father or legal guardian of the insured student.',
  thisPolicyExcludes: 'This Policy excludes to cover the Insured Person performing job duties of Hazardous Occupation (Please refer definition in our policy jacket).',
  theInsuredShallBe: 'The insured(s) shall be in good health and agree that any pre-existing conditions will not be covered under this insurance. Furthermore, obtaining medical treatment is not a purpose of this trip. All coverages are subject to the terms and conditions of the Policy.',
  assicurazioniGeneraliReservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  iWeCertifyGeneraliUssp: 'I/We certify that I/We have read and agreed to the Important Notes of GENERALI ASAP Insurance.',
  iWeCertifyDisclaimerNPersonalInformation: 'I/We certify that I/We have read and agreed to the Disclaimer and Personal Information Collection Statement.',
  iWeUnderstand: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay the authorized insurance broker commission and provide my/our personal data to the said broker during the continuance of the policy for arranging and managing the said policy.',
  levy: 'Levy',
  confirm: 'Confirm',
  payment: 'Payment',
  cardNumber: 'Card Number',
  expi: 'EXPI',
  mm: 'MM.',
  submit: 'Submit',

  youHave: 'You have ',
  eCashNow: 'Generali voucher now. Would you want to use it?',

  iWeDoNotConsentPDto:'I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay ',
  brokerDeclarationII:' commission and provide my/our personal data to the said broker/agent for arranging and managing the said policy.',

  deductible:'Annual Deductible',
  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  periodOfInsurance: 'Period of Insurance',
  bothDatesInclusive: '(both dates inclusive)',
  optionalBenefit: 'Optional Benefit',
  thereIsAnOptional: 'There is an optional benefit for accompanied father, mother or legal guardian.',
  personToBeInsured: 'Person to be Insured',
  sameAsThePolicyholder: 'Same as the policyholder',
  otherPerson: 'Other person',
  pleaseChoose: 'Please choose',
  relationshipWithThePolicyholder: 'Relationship with the Policyholder',
  other: 'Other',
  spouse: 'Spouse',
  child: 'Child',
  guardian:'Guardian',
  children: 'Children',
  parents: 'Parents',
  relatives: 'Relatives',
  friends: 'Friends',
  others:'Others',
  otherCountry: 'Other country',
  insuredName: 'Insured Name',
  insuredHKID: 'Insured HKID',
  insuredPhone: 'Insured Phone',
  insuredAddress: 'Insured Address',
  insuredDateOfBirth: 'Insured Date of Birth',
  citizenship:'Citizenship',
  pleaseWait: 'Please Wait',
  manualUnderwritingIsRequired:'* Manual underwriting is required *',
  getInsured: 'Get Insured',
  invalidPromotionCode: 'Invalid promotion code',
  addInsuranceAdvisor: 'Add Insurance Advisor',
  wouldYouLike: 'Would you like ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  needsToBeInEnglish: 'Needs to be in English',
  thankYou: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  ccccc: '.',
  stateCode:'States',
  universityCode:'University Name',
  hasMedicalCare:'Have you ever received follow-up or medical care from a healthcare professional',
  takeMedications:'Have you ever been advised by your doctor to take regular prescription medication for more than one month',
  isPregnant:'Pregnant',
  visaStatus:'Insured Student Visa Status',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  instituteName: 'Name',
  check: 'Please check your inputs again.',
  cancel: 'cancel',
  theInsuranceIsIssuedByContent:'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',

  yes:'Yes',
  no:'No',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  alert: 'Alert',
  ok: 'OK',
  submissionFailed: 'Submission Failed',
  invalidMobileNumber: 'Invalid insurance advisor mobile number.',
  tryAgain: 'Our system is temporarily not available, please try again later.',

  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  ussp: 'US Study Plan',
  ensure: 'Ensure your child is safe as they embark on a new chapter of life.',
   forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
  forBetterCustomerExperiencelogin:'Log in',
  forBetterCustomerExperiencregister:'Register',
  forBetterCustomerExperienceCon:'Continue without log in',
  healthdeclarationTitle:'Medical History',
  healthdeclarationquestions1:'In the last 5 years, have you ever had or been advised to have any regular or ongoing (such as monthly, every 2 months, half yearly, annually) follow up consultations or medical care with a psychiatrist for any disease or medical condition?',
  healthdeclarationquestions2:'In the last 5 years, have you been advised by your doctor to take any medications (such as to be taken daily / once per week / as needed as directed by doctor) for a continuous period of more than 1 month?',
  healthdeclarationquestions3:'Are you currently pregnant?',
  healthdeclarationquestions4:'l am eligible for one of the following: \na)Undergraduate student — registered for and attending classes on a full-time basis; or \nb)Graduate student; or \nc)Scholar or researcher who is invited by an educational organization',

  declarationTitle:'Declaration',

  //special case
  paymentStopService1:'Dear Valued Clients,',
  paymentStopService2:'Please be advised that due to service maintenance, purchase attempt via BRAVO during 8 July 23:00 to 9 July 23:00 will be temporarily suspended. We apologize for any inconvenience this may have caused you.',

}
