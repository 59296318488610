import React from 'react';
import {
  Text, Switch, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { planTypes } from './planTypes';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { languageStorage } from './../../../lib/LanguageStorage';
import { memberStorage } from './../../../lib/MemberStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import { hmacSha256, removeSpaces } from './../../../lib/utils';
import { formatName, clearEmptyStrings, msgCodeDetail } from './../../../lib/utils';
import PromotionCode from './../../../lib/PromotionCode';
import { host, api26, busiSecKey } from './../../../config/api';
import { stateStorage } from './../../../lib/StateStorage';
import HKID from './../../../lib/HKID';
import TextInput from './../../../components/TextField/TextField';
import PromoteAPI from './../../../api/PromoteAPI';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import DatePicker from './../../../components/DatePicker/DatePicker';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import NameInput from './../../../components/NameInput/NameInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import validid from 'validid';

import Member from './../../../Member';

export default class EPA3 extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    const epa = this.props.location.state.epa;
    const members = this.props.location.state.members;
    if (policy) {
      this.state = {
        planType: epa.premiumPlan == 'I' ? 'individual' : 'family',
        effectiveDateStart: moment(policy.effectiveDateStart, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        effectiveDateEnd: moment(policy.effectiveDateEnd, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY'),
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        policyholderPhone: policy.policyHolder.mobile,
        policyholderEmail: policy.policyHolder.email,
        policyholderAddress: policy.policyHolder.address1,
        policyholderHKID: policy.policyHolder.documentNo,
        // policyholderDOB: moment(data['policyHolder.dateOfBirth'], 'YYYY-MM-DD').format('MMMM D, YYYY'),
        // insuredOccupation: data['policyHolder.occupation'],
        question1: epa.insuranceDeclined ? 'Y' : 'N',
        // question1condition: data['epa.declineRemarks'],
        question2: epa.hasImpairment ? 'Y' : 'N',
        // question2Diagnosis: data['epa.diagnosis'],
        isInsured: policy.policyHolder.documentNo == members[0].documentNo,
        specialRequests: policy.specialRequest,
        messages: {},
        text: {},
      };
      members.forEach((member) => {
        if (member.relationship.relationshipKey == 'insured') {
          this.state.insuredTitle = member.salutation ? member.salutation.descEnUs.replace(/\./g, '') : undefined;
          this.state.insuredFirstName = member.firstName;
          this.state.insuredLastName = member.lastName;
          this.state.insuredDOB = moment(member.dateOfBirth, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY');
          this.state.insuredHKID = member.documentNo;
        }
        if (member.relationship.relationshipKey == 'spouse') {
          this.state.spouseTitle = member.salutation ? member.salutation.descEnUs.replace(/\./g, '') : undefined;
          this.state.spouseFirstName = member.firstName;
          this.state.spouseLastName = member.lastName;
          this.state.spouseDOB = moment(member.dateOfBirth, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY');
          this.state.spouseHKID = member.documentNo;
        }
        if (member.relationship.relationshipKey == 'child') {
          if (!this.state.showFirstChild) {
            this.state.child1Title = member.salutation ? member.salutation.descEnUs.replace(/\./g, '') : undefined;
            this.state.child1FirstName = member.firstName;
            this.state.child1LastName = member.lastName;
            this.state.child1DOB = moment(member.dateOfBirth, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY');
            this.state.child1HKID = member.documentNo;
            this.state.showFirstChild = true;
          }
          if (!this.state.showSecondChild) {
            this.state.child2Title = member.salutation ? member.salutation.descEnUs.replace(/\./g, '') : undefined;
            this.state.child2FirstName = member.firstName;
            this.state.child2LastName = member.lastName;
            this.state.child2DOB = moment(member.dateOfBirth, 'YYYY-MM-DD hh:mm:ss').format('MMMM D, YYYY');
            this.state.child2HKID = member.documentNo;
            this.state.showSecondChild = true;
          }
        }
      });
    }
    else {
      this.state = {
        broker: this.props.location.state.broker,
        planType: this.props.location.state.planType,
        promotionCodes: this.props.location.state.promotionCodes,
        businessEmailAddress: this.props.location.state.businessEmailAddress,
        showBusinessEmailAddress: this.props.location.state.showBusinessEmailAddress,
        referral: this.props.location.state.referral,
        isInsured: true,
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {
    if (localStorage.getItem('preventGoBack') == 'true') {
      window.location = '/';
      return;
    }

    stateStorage.getState('epa3', (state) => {
      if (state) {
        this.setState(state);
      }
    });

    window.scrollTo(0, 0)
    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        // init HKID validation
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value.toUpperCase())) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };

        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          member: member,
          token: token,
        }, () => {
          if (this.state.broker) {
            // init broker-specific input field
            this.setState({
              insuranceAdvisorMobile:
                this.state.member && this.state.member.agent.isAdmin ?
                  undefined : this.state.broker.mobile
            });
          }
          else {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.navigation,
              (insuranceAdvisors) => {
                const insuranceAdvisor = insuranceAdvisors.PA;
                if (insuranceAdvisor) {
                  this.setState({insuranceAdvisor: insuranceAdvisor});

                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.PA.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'EPA',
                      (promotionCodes) => {
                        this.setState({
                          showBusinessEmailAddress: false,
                          businessEmailAddress: undefined,
                          submitButtonDisabled: false,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              }
            );
            if (!this.state.member && this.state.promotionCodes) {
              PromoteAPI.checkPromotionCode(
                {
                  pn: 'EAPP',
                  pl: 'EPA',
                  promotionCode: this.state.promotionCodes,
                },
                (msgDesc) => {
                  this.setState({insuranceAdvisor: {agent: msgDesc}});
                }
              );
            }

            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  componentWillUnmount() {
    let rawRequest = Object.assign({}, this.state);
    delete rawRequest.messages;
    delete rawRequest.text;
    delete rawRequest.alert;
    delete rawRequest.submitButtonDisabled;

    stateStorage.setState('epa3', rawRequest);
    stateStorage.setState('epa4', undefined);
  }

  _apiDateFormat(date) {
    return moment(date, 'MMMM D, YYYY').format('YYYY-MM-DD');
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      planType: state.planType,
      effectiveDate: state.effectiveDateStart,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      policyholderDOB: state.policyholderDOB,
      policyholderHKID: state.policyholderHKID,
      policyholderPhone: state.policyholderPhone,
      policyholderAddress: state.policyholderAddress,
      policyholderEmail: state.policyholderEmail,
      insuredDOB: state.insuredDOB,
      insuredOccupation: state.insuredOccupation,
      question1: state.question1,
      question2: state.question2,
      specialRequests: state.specialRequests,
      referral: state.referral,
      businessEmailAddress: state.businessEmailAddress,
    };
    if (state.isInsured) {
      attributes.insuredOccupation= state.insuredOccupation;
    }
    else {
      attributes.insuredFirstName = state.insuredFirstName;
      attributes.insuredLastName = state.insuredLastName;
      attributes.insuredDOB= state.insuredDOB;
      attributes.insuredHKID= state.insuredHKID;
      attributes.insuredOccupation= state.insuredOccupation;
    }
    if (state.planType == 'family') {
      attributes.spouseFirstName = state.spouseFirstName;
      attributes.spouseLastName = state.spouseLastName;
      attributes.spouseDOB = state.spouseDOB;
      attributes.spouseHKID = state.spouseHKID;
      attributes.spouseOccupation = state.spouseOccupation;
      if (state.showFirstChild) {
        attributes.child1FirstName = state.child1FirstName;
        attributes.child1LastName = state.child1LastName;
        attributes.child1DOB = state.child1DOB;
        attributes.child1HKID = state.child1HKID;
      };
      if (state.showSecondChild) {
        attributes.child2FirstName = state.child2FirstName;
        attributes.child2LastName = state.child2LastName;
        attributes.child2DOB = state.child2DOB;
        attributes.child2HKID = state.child2HKID;
      };
    };
    if (state.question1 == 'Y') {
      attributes.question1Insurer = state.question1Insurer;
      attributes.question1Benefit = state.question1Benefit;
      attributes.question1SumInsured = state.question1SumInsured;
      attributes.question1Reason = state.question1Reason;
      attributes.question1Condition = state.question1Condition;
    };
    if (state.question2 == 'Y') {
      attributes.question2SufferedArea = state.question2SufferedArea;
      attributes.question2Diagnosis = state.question2Diagnosis;
    };

    const format = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }

    let constraints = {
      planType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      effectiveDate: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderDOB: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      policyholderHKID: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: {message: this.state.text.notValidHKID},
        format: format,
      },
      policyholderPhone: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        numericality: {message: this.state.text.notANumber},
      },
      policyholderAddress: {
        format: format,
      },
      policyholderEmail: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        email: {message: this.state.text.notValidEmail},
        format: format,
      },
      question1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      question2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      specialRequests: {
        format: format,
      },
      referral: {
        format: format,
      },
      businessEmailAddress: {
        format: format,
      },
    }
    if (this.state.isInsured) {
      constraints.insuredOccupation = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
    }
    else{
      constraints.insuredFirstName= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.insuredLastName= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.insuredDOB= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
      constraints.insuredHKID= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: {message: this.state.text.notValidHKID},
        format: format,
      };
      constraints.insuredOccupation= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
    }
    if (this.state.planType == 'family') {
      constraints.spouseFirstName = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.spouseLastName = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.spouseDOB= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
      constraints.spouseHKID= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: {message: this.state.text.notValidHKID},
        format: format,
      };
      constraints.spouseOccupation= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
      if (this.state.showFirstChild) {
        constraints.child1FirstName= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          format: format,
        };
        constraints.child1LastName= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          format: format,
        };
        constraints.child1DOB= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
        };
        constraints.child1HKID= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          hkid: {message: this.state.text.notValidHKID},
          format: format,
        };
      };
      if (this.state.showSecondChild) {
        constraints.child2FirstName= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          format: format,
        };
        constraints.child2LastName= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          format: format,
        };
        constraints.child2DOB= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
        };
        constraints.child2HKID= {
          presence: {message: this.state.text.thisFieldCannotBeBlank },
          hkid: {message: this.state.text.notValidHKID},
          format: format,
        };
      };
    };
    if (this.state.question1 == 'Y') {
      constraints.question1Insurer= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.question1Benefit= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.question1SumInsured= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.question1Reason= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.question1Condition= {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
    };

    if (this.state.question2 == 'Y') {
      constraints.question2SufferedArea = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
      constraints.question2Diagnosis = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      };
    };

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {

      this.setState({alert:
        Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{text: this.state.text.ok, onPress: () => this.setState({alert: null}),style: 'cancel'}],
        ),
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      let fields = {
        'dt': moment().format('YYYYMMDDhhmmss'),
        'pn': 'EAPP',
        'pl': 'EPA',
        'payment.promotionCode': removeSpaces(this.state.promotionCodes),
        'policy.effectiveDateStart': this._apiDateFormat(this.state.effectiveDateStart),
        'policy.businessEmail': removeSpaces(this.state.businessEmailAddress),
        'epa.premiumPlan': this.state.planType == 'individual' ? 'I' : 'F',
      };
      if (this.state.member) {
        fields['member.id'] = this.state.member.id;
      }
      if (this.state.token) {
        fields['member.token'] = this.state.token.tokenStr;
      }
      const data = JSON.stringify({...fields, 'timeStamp': Date.now(),});
      const hmac = hmacSha256(data);
      fetch(host + (this.state.broker ? '/agent' : '') + api26, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0 || this.state.nextScreen) {
          let members = [];
          if (this.state.isInsured) {
            members.push({
              'salutation': this.state.policyholderTitle,
              'firstName': this.state.policyholderFirstName,
              'lastName': this.state.policyholderLastName,
              'documentType': 'HKID',
              'documentNo': this.state.policyholderHKID,
              'dateOfBirth': this._apiDateFormat(this.state.policyholderDOB),
              'relationship': 'insured',
              'occupation': this.state.insuredOccupation == 'Y' ? 'CLERICAL' : 'NONCLERICAL',
            });
          }
          else {
            members.push({
              'salutation': this.state.insuredTitle,
              'firstName': this.state.insuredFirstName,
              'lastName': this.state.insuredLastName,
              'documentType': 'HKID',
              'documentNo': this.state.insuredHKID,
              'dateOfBirth': this._apiDateFormat(this.state.insuredDOB),
              'relationship': 'insured',
              'occupation': this.state.insuredOccupation == 'Y' ? 'CLERICAL' : 'NONCLERICAL',
            });
          }
          if (this.state.planType == 'family') {
            if (this.state.spouseHKID) {
              members.push({
                'salutation': this.state.spouseTitle,
                'firstName': this.state.spouseFirstName,
                'lastName': this.state.spouseLastName,
                'documentType': 'HKID',
                'documentNo': this.state.spouseHKID,
                'dateOfBirth': this._apiDateFormat(this.state.spouseDOB),
                'relationship': 'spouse',
                'occupation': this.state.spouseOccupation == 'Y' ? 'CLERICAL' : 'NONCLERICAL',
              });
            }
            if (this.state.child1HKID) {
              members.push({
                'salutation': this.state.child1Title,
                'firstName': this.state.child1FirstName,
                'lastName': this.state.child1LastName,
                'documentType': 'HKID',
                'documentNo': this.state.child1HKID,
                'dateOfBirth': this._apiDateFormat(this.state.child1DOB),
                'relationship': 'child',
                'occupation': 'STUDENT',
                'fulltimeStudent': this.state.child1FulltimeStudent,
              });
            }
            if (this.state.child2HKID) {
              members.push({
                'salutation': this.state.child2Title,
                'firstName': this.state.child2FirstName,
                'lastName': this.state.child2LastName,
                'documentType': 'HKID',
                'documentNo': this.state.child2HKID,
                'dateOfBirth': this._apiDateFormat(this.state.child2DOB),
                'relationship': 'child',
                'occupation': 'STUDENT',
                'fulltimeStudent': this.state.child2FulltimeStudent,
              });
            }
          }

          let rawRequest = Object.assign({}, state);
          delete rawRequest.messages;
          delete rawRequest.text;
          delete rawRequest.alert;

          const premium = responseJson.msgDesc.premium;
          const levy = responseJson.msgDesc.levy;
          const total = premium.discount + levy;

          stateStorage.setState('epa3', rawRequest);
          stateStorage.setState('epa4', undefined);

      this.props.history.push('/epa4', {
        broker: this.state.broker,
        insuranceAdvisor: state.insuranceAdvisor,
        insuranceAdvisorMobile: state.insuranceAdvisorMobile,
        product: state.product,
        planType: state.planType,
        effectiveDateStart: state.effectiveDateStart,
        effectiveDateEnd: state.effectiveDateEnd,
        promotionCodes: removeSpaces(state.promotionCodes),
        businessEmailAddress: removeSpaces(state.businessEmailAddress),
        specialRequests: state.specialRequests,
        referral: state.referral,
        policyholderTitle: state.policyholderTitle,
        policyholderFirstName: state.policyholderFirstName,
        policyholderLastName: state.policyholderLastName,
        policyholderDOB: state.policyholderDOB,
        policyholderPhone: state.policyholderPhone,
        policyholderEmail: state.policyholderEmail,
        policyholderHKID: state.policyholderHKID,
        policyholderAddress: state.policyholderAddress,
        insuredOccupation: state.insuredOccupation,
        spouseOccupation: state.spouseOccupation,
        question1: state.question1,
        question1Insurer: state.question1Insurer,
        question1Benefit: state.question1Benefit,
        question1SumInsured: state.question1SumInsured,
        question1Reason: state.question1Reason,
        question1Condition: state.question1Condition,
        question2: state.question2,
        question2SufferedArea: state.question2SufferedArea,
        question2Diagnosis: state.question2Diagnosis,
        members: members,
        rawRequest: rawRequest,
        premium: premium,
        levy: levy,
        total: total,
      });
    }
    else if (responseJson.msgCode == 40) {
      if (this.state.broker) {
        this.setState({
          messages: {
            promotionCodes: this.state.text['invalidPromotionCode']
          },
          alert: Alert.alert(
            this.state.text.alert,
            this.state.text.check,
            [{
              text: this.state.text.ok,
              onPress: () => this.setState({alert: null}),
              style: 'cancel',
            }],
          ),
        });
      }
      else {
        this.setState({alert:
          Alert.alert(
            this.state.text.addInsuranceAdvisor,
            this.state.text.wouldYouLike
            + responseJson.msgDesc.agent.brokerName
            + this.state.text.toBeYourInsuranceAdvisor,
            [
              {
                text: this.state.text.ok,
                onPress: () => {
                  if (!this.state.bindingInsuranceAdvisor) {
                    this.setState({bindingInsuranceAdvisor: true}, () => {
                      insuranceAdvisors.setInsuranceAdvisor(
                        {agentCode: responseJson.msgDesc.agent.agentCode},
                        ['EPA'],
                        this.state.promotionCodes,
                        this.state.language,
                        () => this.setState({bindingInsuranceAdvisor: false},
                          () => this._nextScreen())
                      );
                    });
                  }
                }
              },
            ],
            { cancelable: false }
          )
        });
      }
    }
    else if (responseJson.msgCode == 56) {
      this.setState({
        messages: {
          promotionCodes: this.state.text['invalidPromotionCode']
        },
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.check,
          [{
            text: this.state.text.ok,
            onPress: () => this.setState({alert: null}),
            style: 'cancel',
          }],
        ),
      });
    }
    else {
      alert(msgCodeDetail(responseJson.msgCode)
        [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
    }
    this.setState({submitButtonDisabled: false});
  })
  .catch((error) => {
    alert('Facing error when processing the quotation!');
    this.setState({submitButtonDisabled: false});
  });
}
}

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <div className='App'>
        <Helmet>
          <title>Executive Personal Accident Protector | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.getInsured}
          backButton
          history={this.props.history}
        />

          <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

                <View style={[styles.greeting,{fontWeight:'bold',}]}>
                  <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.epa}</Text>
                  <p></p>
                  {
                    this.state.language == 'zhHK' ?
                    <View>
                    <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                    </View>:
                    <View>
                    <Text style={{fontSize: 20,textAlign: 'center'}}>{this.state.text.enjoy}</Text>
                    </View>

                  }

                </View>

            </Grid>
            </Hidden>

            <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
             <ScrollView style={formStyles.form, styles.fie}>
             {this.state.alert}
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h2, {color}]}>{this.state.text.executivePersonalAccidentProtector}</Text>
             </View>

             {
               this.state.insuranceAdvisor || this.state.broker ?
                 <View style={formStyles.fieldset}>
                   <Text style={{textAlign: 'left'}}>
                     {this.state.text.theInsuranceIsIssued}
                     {
                       this.state.broker ?
                       this.state.broker.name :
                       this.state.insuranceAdvisor.agent.broker.nameEnUs
                     }
                     {this.state.text.professionalInsurance}
                   </Text>
                 </View> :
                 <View style={{textAlign: 'left'}}>
                   <Text>{this.state.text.theInsuranceIsIssuedByContent}</Text>
                 </View>
             }
             <View style={formStyles.fieldset}>
              <Text>{this.state.text.pleaseInputInEnglish}</Text>
            </View>

             {
               this.state.broker &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                   <TextInput
                     style={formStyles.input}
                     onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                     value={this.state.insuranceAdvisorMobile}
                     editable={this.state.member && this.state.member.agent.isAdmin}
                     keyboardType='phone-pad'
                     maxLength={8}
                   />
                 </View>
             }

             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.productType}</Text>
               <ModalSelector
                 data={[
                   { key: 'individual', label: planTypes.individual.label[this.state.language] },
                   { key: 'family', label: planTypes.family.label[this.state.language] },
                 ]}
                 initValue={planTypes[this.state.planType].label[this.state.language]}
                 onChange={(option) => this.setState({planType: option.key })}
                 cancelText={this.state.text.cancel}
               />
               <Text style={formStyles.message}>{this.state.messages.planType}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.effectiveDate}</Text>
               <DatePicker
                 style={formStyles.datepicker}
                 date={this.state.effectiveDateStart}
                 mode='date'
                 placeholder={this.state.text.pleasePickADate}
                 format='MMMM D, YYYY'
                 minDate={moment().add(1, 'days').format('MMMM D, YYYY')}
                 maxDate={moment().add(1, 'days').add(6, 'months').format('MMMM D, YYYY')}
                 onDateChange={(date) => {this.setState({
                   effectiveDateStart: date,
                   effectiveDateEnd: moment(date, 'MMMM D, YYYY')
                     .add(1, 'years').subtract(1, 'days').format('MMMM D, YYYY'),
                 })}}
                 confirmBtnText={this.state.text.ok}
                 cancelBtnText={this.state.text.cancel}
               />
               <Text style={formStyles.message}>{this.state.messages.effectiveDate}</Text>
             </View>
             {
               this.state.effectiveDateStart &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.h4}>{this.state.text.periodOfInsurance}</Text>
                   <View style={styles.periodOfInsurance}>
                     <Text>
                       {this.state.effectiveDateStart} - {this.state.effectiveDateEnd}
                     </Text>
                     <Text>{this.state.text.bothDatesInclusive}</Text>
                   </View>
                 </View>
             }
             {
               !this.state.businessEmailAddress &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.h4}>{this.state.text.promotionCodes}</Text>
                   <TextInput
                     style={formStyles.input}
                     placeholder={this.state.text.optional}
                     placeholderTextColor='lightgrey'
                     onChangeText={(text) => this.setState({promotionCodes: text})}
                     value={this.state.promotionCodes}
                   />
                 <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
                 </View>
             }
             {
               !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
                 <View style={formStyles.fieldset}>
                   <Text style={formStyles.h4}>{this.state.text.businessEmailAddress}</Text>
                   <TextInput
                     style={formStyles.input}
                     placeholder={this.state.text.optional}
                     placeholderTextColor='lightgrey'
                     keyboardType='email-address'
                     onChangeText={(text) => this.setState({businessEmailAddress: text})}
                     value={this.state.businessEmailAddress}
                   />
                 <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
                 </View>
             }
             <View style={formStyles.hr} />
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h3, {color}]}>{this.state.text.policyholder}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.name}</Text>
               <NameInput
                 title={this.state.policyholderTitle}
                 firstName={this.state.policyholderFirstName}
                 lastName={this.state.policyholderLastName}
                 onChange={(title, firstName, lastName) => this.setState({
                   policyholderTitle: title,
                   policyholderFirstName: firstName,
                   policyholderLastName: lastName,
                 })}
               />
               <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
               <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
             </View>

             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
               <DatePicker
                 style={formStyles.datepicker}
                 date={this.state.policyholderDOB}
                 mode='date'
                 fullDate={true}
                 placeholder={this.state.text.pleasePickADate}
                 format='MMMM D, YYYY'
                 minDate='January 2, 1900'
                 maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                 onDateChange={(date) => this.setState({policyholderDOB: date})}
                 confirmBtnText={this.state.text.ok}
                 cancelBtnText={this.state.text.cancel}
               />
               <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
             </View>

             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({policyholderHKID: text})}
                 value={this.state.policyholderHKID}
                 placeholder='A1234567'
                 placeholderTextColor='lightgrey'
               />
               <View style={{textAlign: 'left'}}>
               <Text>{this.state.text.pleaseEnterAllHKID}</Text>
               </View>
               <Text style={formStyles.message}>{this.state.messages.policyholderHKID}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({policyholderPhone: text})}
                 value={this.state.policyholderPhone}
                 keyboardType='phone-pad'
                 maxLength={8}
               />
               <Text style={formStyles.message}>{this.state.messages.policyholderPhone}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.correspondenceAddress}</Text>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({policyholderAddress: text})}
                 value={this.state.policyholderAddress}
                 placeholder={this.state.text.optional}
                 placeholderTextColor='lightgrey'
               />
               <Text style={formStyles.message}>{this.state.messages.policyholderAddress}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({policyholderEmail: text})}
                 value={this.state.policyholderEmail}
                 keyboardType='email-address'
               />
               <Text style={formStyles.message}>{this.state.messages.policyholderEmail}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <View style={formStyles.yesNoQuestion}>
                 <View style={formStyles.yesNoQuestionSwitch}>
                   <Switch
                     value={this.state.isInsured}
                     onValueChange={(value) => this.setState({isInsured: value})}
                   />
                 </View>
                 <View style={formStyles.yesNoQuestionText}>
                   <Text>
                     {this.state.text.policyholderIs} {
                       this.state.planType == this.state.text.individual ?
                         this.state.text.theInsured : this.state.text.oneOfTheInsureds
                     }
                   </Text>
                 </View>
               </View>
             </View>
             <View style={formStyles.hr} />
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h3, {color}]}>{this.state.text.insured}</Text>
             </View>
             {
               this.state.isInsured ?
                 <View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.name}</Text>
                     <TextInput
                       style={formStyles.input}
                       value={formatName(
                         this.state.policyholderTitle,
                         this.state.policyholderFirstName,
                         this.state.policyholderLastName
                       )}
                       editable={false}
                     />
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                     <DatePicker
                       style={formStyles.datepicker}
                       date={this.state.policyholderDOB}
                       mode='date'
                       fullDate={true}
                       placeholder={this.state.text.pleasePickADate}
                       format='MMMM D, YYYY'
                       disabled={true}
                       confirmBtnText={this.state.text.ok}
                       cancelBtnText={this.state.text.cancel}
                     />
                     <Text style={formStyles.message}>{this.state.messages.policyholderDOB}</Text>
                   </View>
                   {
                     moment(this.state.policyholderDOB, 'MMMM D, YYYY').add(65, 'years').diff(moment()) < 0 &&
                       <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                   }
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                     <TextInput
                       style={formStyles.input}
                       value={this.state.policyholderHKID}
                       editable={false}
                       placeholder='A1234567'
                       placeholderTextColor='lightgrey'
                     />
                     <View style={{textAlign: 'left'}}>
                     <Text>{this.state.text.pleaseEnterAllHKID}</Text>
                     </View>
                   </View>
                   <View style={formStyles.fieldset}>
                     <YesNoQuestion
                       question={this.state.text.occupationClericalAndNonManualWork}
                       choice={this.state.insuredOccupation}
                       onChange={(choice) => this.setState({insuredOccupation: choice})}
                         />
                     <Text style={formStyles.message}>{this.state.messages.insuredOccupation}</Text>
                     {
                       this.state.insuredOccupation == 'N' &&
                         <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                     }
                     </View>
                 </View> :
                 <View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.name}</Text>
                     <NameInput
                       title={this.state.insuredTitle}
                       firstName={this.state.insuredFirstName}
                       lastName={this.state.insuredLastName}
                       onChange={(title, firstName, lastName) => this.setState({
                         insuredTitle: title,
                         insuredFirstName: firstName,
                         insuredLastName: lastName,
                       })}
                     />
                     <Text style={formStyles.message}>{this.state.messages.insuredFirstName}</Text>
                     <Text style={formStyles.message}>{this.state.messages.insuredLastName}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                     <DatePicker
                       style={formStyles.datepicker}
                       date={this.state.insuredDOB}
                       mode='date'
                       fullDate={true}
                       placeholder={this.state.text.pleasePickADate}
                       format='MMMM D, YYYY'
                       minDate={moment().subtract(66, 'years').add(1, 'days').format('MMMM D, YYYY')}
                       maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                       onDateChange={(date) => {this.setState({insuredDOB: date})}}
                       confirmBtnText={this.state.text.ok}
                       cancelBtnText={this.state.text.cancel}
                     />
                     <Text style={formStyles.message}>{this.state.messages.insuredDOB}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({insuredHKID: text})}
                       value={this.state.insuredHKID}
                       placeholder='A1234567'
                       placeholderTextColor='lightgrey'
                     />
                     <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllHKID}</Text>
                     <Text style={formStyles.message}>{this.state.messages.insuredHKID}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <YesNoQuestion
                       question={this.state.text.occupationClericalAndNonManualWork}
                       choice={this.state.insuredOccupation}
                       onChange={(choice) => this.setState({insuredOccupation: choice})}
                     />
                       <Text style={formStyles.message}>{this.state.messages.insuredOccupation}</Text>
                     {
                       this.state.insuredOccupation == 'N' &&
                         <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                     }
                   </View>
                 </View>
             }
             {
               this.state.planType == 'family' &&
                 <View>
                   <View style={formStyles.hr} />
                   <View style={formStyles.fieldset}>
                     <Text style={[formStyles.h3, {color}]}>{this.state.text.spouse}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.name}</Text>
                     <NameInput
                       title={this.state.spouseTitle}
                       firstName={this.state.spouseFirstName}
                       lastName={this.state.spouseLastName}
                       onChange={(title, firstName, lastName) => this.setState({
                         spouseTitle: title,
                         spouseFirstName: firstName,
                         spouseLastName: lastName,
                       })}
                     />
                     <Text style={formStyles.message}>{this.state.messages.spouseFirstName}</Text>
                     <Text style={formStyles.message}>{this.state.messages.spouseLastName}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                     <DatePicker
                       style={formStyles.datepicker}
                       date={this.state.spouseDOB}
                       mode='date'
                       fullDate={true}
                       placeholder={this.state.text.pleasePickADate}
                       format='MMMM D, YYYY'
                       minDate={moment().subtract(66, 'years').add(1, 'days').format('MMMM D, YYYY')}
                       maxDate={moment().subtract(18, 'years').format('MMMM D, YYYY')}
                       onDateChange={(date) => {this.setState({spouseDOB: date})}}
                       confirmBtnText={this.state.text.ok}
                       cancelBtnText={this.state.text.cancel}
                     />
                     <Text style={formStyles.message}>{this.state.messages.spouseDOB}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({spouseHKID: text})}
                       value={this.state.spouseHKID}
                       placeholder='A1234567'
                       placeholderTextColor='lightgrey'
                     />
                     <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllHKID}</Text>
                     <Text style={formStyles.message}>{this.state.messages.spouseHKID}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <YesNoQuestion
                       question={this.state.text.occupationClericalAndNonManualWork}
                       choice={this.state.spouseOccupation}
                       onChange={(choice) => this.setState({spouseOccupation: choice})}
                     />
                     <Text style={formStyles.message}>{this.state.messages.spouseOccupation}</Text>
                     {
                       this.state.spouseOccupation == 'N' &&
                         <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                     }
                   </View>
                   <View style={formStyles.hr} />

                   {
                     this.state.showFirstChild ?
                   <View>
                   <View style={formStyles.fieldset}>
                     <Text style={[formStyles.h3, {color}]}>{this.state.showSecondChild && this.state.text.first}{this.state.text.child}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.name}</Text>
                     <NameInput
                       title={this.state.child1Title}
                       firstName={this.state.child1FirstName}
                       lastName={this.state.child1LastName}
                       onChange={(title, firstName, lastName) => this.setState({
                         child1Title: title,
                         child1FirstName: firstName,
                         child1LastName: lastName,
                       })}
                     />
                     <Text style={formStyles.message}>{this.state.messages.child1FirstName}</Text>
                     <Text style={formStyles.message}>{this.state.messages.child1LastName}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                     <DatePicker
                       style={formStyles.datepicker}
                       date={this.state.child1DOB}
                       mode='date'
                       fullDate={true}
                       placeholder={this.state.text.pleasePickADate}
                       format='MMMM D, YYYY'
                       minDate={moment().subtract(26, 'years').add(1, 'days').format('MMMM D, YYYY')}
                       maxDate={moment().subtract(1, 'years').format('MMMM D, YYYY')}
                       onDateChange={(date) => {this.setState({child1DOB: date})}}
                       confirmBtnText={this.state.text.ok}
                       cancelBtnText={this.state.text.cancel}
                     />
                     <Text style={formStyles.message}>{this.state.messages.child1DOB}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({child1HKID: text})}
                       value={this.state.child1HKID}
                       placeholder='A1234567'
                       placeholderTextColor='lightgrey'
                     />
                     <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllHKID}</Text>
                     <Text style={formStyles.message}>{this.state.messages.child1HKID}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <YesNoQuestion
                       question={this.state.text.fullTimeStudentUnmarriedAndUnemployed}
                       choice={this.state.child1FulltimeStudent}
                       onChange={(choice) => this.setState({child1FulltimeStudent: choice})}
                     />
                     {
                       this.state.child1FulltimeStudent == 'N' &&
                         <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                     }
                   </View>
                   {
                     !this.state.showSecondChild &&
                       <TouchableOpacity style={formStyles.addButton} onPress={() => this.setState({showSecondChild: true})}>
                         <Text style={formStyles.addButtonText}>{this.state.text.addAChild}</Text>
                       </TouchableOpacity>
                   }
                 </View> :
                 <TouchableOpacity style={formStyles.addButton} onPress={() => this.setState({showFirstChild: true})}>
                   <Text style={formStyles.addButtonText}>{this.state.text.addAChild}</Text>
                 </TouchableOpacity>
               }
                   {
                     this.state.showSecondChild &&
                       <View>
                         <View style={formStyles.hr} />
                         <View style={formStyles.fieldset}>
                           <Text style={[formStyles.h3, {color}]}>{this.state.text.ndChild}</Text>
                         </View>
                         <View style={formStyles.fieldset}>
                           <Text style={formStyles.h4}>{this.state.text.name}</Text>
                           <NameInput
                             title={this.state.child2Title}
                             firstName={this.state.child2FirstName}
                             lastName={this.state.child2LastName}
                             onChange={(title, firstName, lastName) => this.setState({
                               child2Title: title,
                               child2FirstName: firstName,
                               child2LastName: lastName,
                             })}
                           />
                           <Text style={formStyles.message}>{this.state.messages.child2FirstName}</Text>
                           <Text style={formStyles.message}>{this.state.messages.child2LastName}</Text>
                         </View>
                         <View style={formStyles.fieldset}>
                           <Text style={formStyles.h4}>{this.state.text.dateOfBirth}</Text>
                           <DatePicker
                             style={formStyles.datepicker}
                             date={this.state.child2DOB}
                             mode='date'
                             fullDate={true}
                             placeholder={this.state.text.pleasePickADate}
                             format='MMMM D, YYYY'
                             minDate={moment().subtract(25, 'years').add(1, 'days').format('MMMM D, YYYY')}
                             maxDate={moment().subtract(1, 'years').format('MMMM D, YYYY')}
                             onDateChange={(date) => {this.setState({child2DOB: date})}}
                             confirmBtnText={this.state.text.ok}
                             cancelBtnText={this.state.text.cancel}
                           />
                           <Text style={formStyles.message}>{this.state.messages.child2DOB}</Text>
                         </View>
                         <View style={formStyles.fieldset}>
                           <Text style={formStyles.h4}>{this.state.text.hkid}</Text>
                           <TextInput
                             style={formStyles.input}
                             onChangeText={(text) => this.setState({child2HKID: text})}
                             value={this.state.child2HKID}
                             placeholder='A1234567'
                             placeholderTextColor='lightgrey'
                           />
                           <Text style={{textAlign: 'left'}}>{this.state.text.pleaseEnterAllHKID}</Text>
                           <Text style={formStyles.message}>{this.state.messages.child2HKID}</Text>
                         </View>
                         <View style={formStyles.fieldset}>
                           <YesNoQuestion
                             question={this.state.text.fullTimeStudentUnmarriedAndUnemployed}
                             choice={this.state.child2FulltimeStudent}
                             onChange={(choice) => this.setState({child2FulltimeStudent: choice})}
                           />
                           {
                             this.state.child2FulltimeStudent == 'N' &&
                               <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                           }
                         </View>
                       </View>
                   }
                 </View>
             }
             <View style={formStyles.hr} />
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h3, {color}]}>{this.state.text.questions}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <YesNoQuestion
                 question={this.state.text.haveYourOrOtherCoveredMembersAPPOflife}
                 choice={this.state.question1}
                 onChange={(choice) => this.setState({question1: choice})}
               />
               <Text style={formStyles.message}>{this.state.messages.question1}</Text>
             </View>
             {
               this.state.question1 == 'Y' &&
                 <View>
                   <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.insurer}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question1Insurer: text})}
                       value={this.state.question1Insurer}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question1Insurer}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.benefit}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question1Benefit: text})}
                       value={this.state.question1Benefit}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question1Benefit}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.sumInsured}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question1SumInsured: text})}
                       value={this.state.question1SumInsured}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question1SumInsured}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.reason}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question1Reason: text})}
                       value={this.state.question1Reason}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question1Reason}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.condition}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question1Condition: text})}
                       value={this.state.question1Condition}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question1Condition}</Text>
                   </View>
                 </View>
             }
             <View style={formStyles.fieldset}>
               <YesNoQuestion
                 question={this.state.text.doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment}
                 choice={this.state.question2}
                 onChange={(choice) => this.setState({question2: choice})}
               />
               <Text style={formStyles.message}>{this.state.messages.question2}</Text>
             </View>
             {
               this.state.question2 == 'Y' &&
                 <View>
                   <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.sufferedArea}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question2SufferedArea: text})}
                       value={this.state.question2SufferedArea}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question2SufferedArea}</Text>
                   </View>
                   <View style={formStyles.fieldset}>
                     <Text style={formStyles.h4}>{this.state.text.diagnosis}</Text>
                     <TextInput
                       style={formStyles.input}
                       onChangeText={(text) => this.setState({question2Diagnosis: text})}
                       value={this.state.question2Diagnosis}
                     />
                     <Text style={formStyles.message}>{this.state.messages.question2Diagnosis}</Text>
                   </View>
                 </View>
             }
             <View style={formStyles.hr} />
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h3, {color}]}>{this.state.text.specialRequests}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({specialRequests: text})}
                 value={this.state.specialRequests}
                 placeholder={this.state.text.optional}
                 placeholderTextColor='lightgrey'
               />
               <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
               {
                 !!this.state.specialRequests &&
                   <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
               }
             </View>
             <View style={formStyles.fieldset}>
               <Text style={[formStyles.h3, {color}]}>{this.state.text.referral}</Text>
             </View>
             <View style={formStyles.fieldset}>
               <TextInput
                 style={formStyles.input}
                 onChangeText={(text) => this.setState({referral: text})}
                 value={this.state.referral}
                 placeholder={this.state.text.optional}
                 placeholderTextColor='lightgrey'
               />
               <Text style={formStyles.message}>{this.state.messages.referral}</Text>
              </View>
             {
               this.state.submitButtonDisabled ?
                 <View style={formStyles.submitButtonDisabled}>
                   <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                 </View> :
                 <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._nextScreen()}>
                   <Text style={formStyles.submitButtonText}>{this.state.text.buyNow}</Text>
                 </TouchableOpacity>
             }
           </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
        <Footer/>
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  periodOfInsurance: {
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
