import React from 'react';
import { Text, View } from 'react-native';

import Locale from './../../Locale';
import Format from './../../Format';
import { Layout, Navbar } from './../../Screen';
import { Form, Button } from './../../Form';
import FireScreen from './FireScreen';
import InformationForQuotation from './../components/InformationForQuotation';
import Total from './../components/Total';
import Alert from "./../../components/Alert/Alert";
export default class FireScreen2 extends FireScreen {
  buyNow() {
    if (this.state.broker) {
      this.askCustomerToLogin().then(() => this.push("Fire3"));
    } else {
      if (Locale.getPaymentStatus()) {
        this.askCustomerToLogin().then(() => this.push("Fire3"));
      } else {
        this.setState({
          alert: Alert.alert(
            Locale.text.paymentStopService1,
            Locale.text.paymentStopService2,
            [
              {
                text: Locale.text.yes,
                onPress: () => this.setState({ alert: null }),
              },
            ]
          ),
        });
      }
    }
   // this.askCustomerToLogin().then(() => this.push('Fire3'));
  }

  render() {
    const {policy, insuredHome, payment} = this.state.insuranceProduct;

    const data = [
      {
        label: Locale.text.fireSumInsuredAmount,
        value: 'HK$' + Format.formatNumber(insuredHome.sumInsured),
      },
      {
        label: Locale.text.ageOfBuilding,
        value: this.getBuildingAgeLabel(),
      },
    ];

    if (payment.promotionCode) {
      data.push({
        label: Locale.text.promotionCodes,
        value: payment.promotionCode,
      });
    }

    if (policy.businessEmail) {
      data.push({
        label: Locale.text.businessEmailMembership,
        value: policy.businessEmail,
      });
    }

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getAQuote}
        />

        {Locale.text.fireInsurance}
        {Locale.text.fireInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <InformationForQuotation
              planTypeOptions={this.fireOption}
              planType={'FIRE'}
              data={data}
            />

            <Total
              premium={this.getParam('premium')}
            />
          </Form>

          <Button withMargin onPress={() => this.buyNow()}>
            {Locale.text.buyNow}
          </Button>
        </View>
      </Layout>
    );
  }
}
