import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';

import { Heading2, FormElement } from './../../Form';

export default class Confirmation extends React.Component {
  render() {
    const {heading, data} = this.props;

    return (
      <View>
        {heading && <Heading2>{heading}</Heading2>}

        <FlatList
          data={data}
          renderItem={({item}) =>
            item.value &&
              <FormElement>
                <Text style={styles.label}>{item.label}</Text>
                <Text>{item.value}</Text>
              </FormElement>
          }
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  label: {
    fontWeight: 'bold',
  },
});
