export const zhHK = {
  instantClaim: '即時索償',
  homeInsurance: '家居保險',
  insuredDetails: '受保人詳情',
  policyNumber: '保單編號',
  policyholderName: '受保人姓名',
  insuredHomeAddress: '受保地址',
  hkidNumberPassNumber:'香港身份證號碼 / 護照號碼',
  hkid: '香港身份證',
  passport: '護照',
  cancel: '取消',
  mobileNumber: '手提電話號碼',
  emailAddress: '電郵',
  typeOfClaim:'索償類別',
  propertyDamageOrLoss: '財產損失 / 損毀 / 樓宇保險',
  publicLiability: '公眾責任',
  claimDetails: '索賠詳情',
  dateOfIncident: '事發日期',
  pleasePickADate: '請選擇日期',
  timeOfIncident: '事發時間',
  pleasePickTime: '請選擇時間',
  placeOfIncident: '事發地點',
  detailIncident: '意外描述',
  nameOfWitness: '目擊者姓名 ( 如有)',
  contactOfWitness: '目擊者聯絡資料 ( 如有)',
  wasTheIncidentReported: '可有向警方報案？',
  nameOfThePoliceStation: '報案警署',
  policeReportNumber: '報案編號',
  hasAnyPrecautionaryMeasures: '在事故發生時，是否已作出任何安全措施? ',
  pleaseAdviseInDetails: '請提供詳情',
  followingTheIncident: '在事故發生後，是否已作出任何應變措施以減低損失?',
  haveYouEverExperienced: '以往是否遇過類似性質的事故?',
  datesOfIncidents: '何時發生',
  nameOfThirdParty: '第三者姓名',
  contactOfThirdParty: '第三者聯絡資料',
  addressOfThirdParty: '第三者地址',
  natureAndExtentOfDamageOrInjuries: '受損或受傷之性質及程度',
  hasClaimBeenMade: '是否被要求賠償？',
  claimAmount: '索償金額',
  currency: '貨幣',
  pleaseFillInTheAmount: '請填入金額',
  anyStepsBeenTaken: '受保人可有就事件作出任何妥協或賠償承諾',
  particularsOfMainContractor: '總承辦商或承辦商資料，如有',
  isThereAnyWorkByContract: '在事故發生時，是否有以合約形式的工作在進行中?',
  name: '姓名',
  trade: '行業',
  contactNoOrEmail: '電話 / 電郵',
  address: '地址',
  theirRespectiveThirdParty: '其第三者保險之保險公司資料',
  nameOfInsuranceCompany: '保險公司名稱',
  documentsAttached: '附加文件',
  detailedBreakdown: '申索項目詳情，包括購買收據 及/或 更換維修報價單、發票及收據',
  openPhoto: '開啟照片',
  colouredPhotograph: '能顯示涉及地方 / 財物的損毀程度的彩色照片',
  incidentReportMadeByTheBuilding: '由物業管理處發出的事故報告書，內含事故發生之詳情、原因及其減輕損毀程度之措施',
  theCorrespondence: '有關的往來文件及信函，包括受保人、受保租客、管理處、相關人仕及人等 (如屋宇署 / 食物環境衞生署聯合辦事處等) ，如適用',
  otherRelevantInformation: '其他相關資料 / 文件',
  others: '其他',
  dateOfAccident: '意外日期',
  timeOfAccident: '意外時間',
  placeOfAccident: '意外地點',
  descriptionOfTheAccident: '事件詳細經過',
  otherInsurance: '其他保險公司',
  doYouHaveOtherInsurance: '請問除本公司外，有否投保於其他保險公司？',
  yes: '有',
  nameOfInsurer: '保險公司名稱',
  typeOfCoverage: '投保種類',
  policyEffectiveDate: '保單生效日期',
  no: '沒有',
  submit: '提交',
  pleaseWait: '請稍候',
  notValidEmail:'這不是正確的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是正確的香港身份證號碼',
  inCaseOfBurglary: '如屬盜竊，說明竊匪如何進出單位。',
  haveYouReportedTheIncident: '您是否已向警方報案？',
  areYouTheSoleOwner: '您是否損毀/ 損失財物的唯一物主？',
  otherOwners: '其他物主',
  ofTheOtherOwners: '其他物主',
  includingTheHirePurchase: '包含借款人',
  addAnotherOwner: '新增其他物主',
  areYouEntitled: '您是否有權就是次意外向其他保險公司索償？',
  nameOfInsuranceCompanyies: '列明保險公司的名稱',
  respectivePolicyNumber: '保單編號',
  detailsOfCoverage: '保障項目',
  haveYouEverSustainedLosses: '您以往是否曾蒙受類似性質的損失？',
  detailsOfIncidents: '事件細節',
  haveYouEverMadeClaimUnder: '您以往是否曾就其他保險單索償？',
  details: '列明詳情',
  remarks: '備註',
  totalClaimAmount: '總索償金額',
  detailedBreakdownOfTheClaimedItems: '索償項目詳情細項，包括購買收據 及/或 更換維修報價單、發票及收據',
  colouredPhotographsOfTheVicinity: '能顯示涉及地方 / 財物的損毀程度的彩色照片',
  detailDescriptionOf: '意外描述',
  fullDescriptionOf: '損失財物的詳情資料',
  addAnItem: '新增項目',
  memoReportsAndOrStatements: '警方備忘 / 報告 及/或 由受保人 / 其家屬 / 相關人仕提供之供詞 (只適用於申索與財物遺失 / 企圖爆竊 /爆竊)',
  aCopyOfDuly: '已釐印之租務合約副本',
  theCorrespondencesAmong: '有關的往來文件及信函，包括受保人、受保租客、管理處、相關人仕及人等 (如屋宇署 / 食物環境衞生署聯合辦事處等) ，如適用',
  howWasItHappened: '意外經過？',
  pleaseDescribeThe: '請述受傷部位及傷勢，如：骨折、刀傷、瘀腫等。',
  wasTheAccidentReported: '上述意外有否交由警方調查？',
  caseReferenceNumber: '案件參考編號',
  nameOfDoctorHospital: '醫生/醫院名稱',
  dateOfFirstConsultation: '初診日期',
  proofOfRelationship: '與受保人之關係證明',
  isNotAValidHKID: '無效香港身分證',
  needsToBeInEnglish: '需要使用英文填寫',
  thisFieldCannotBeBlank: '必須填寫空格',
  insuranceAdvisorMobile: '保險顧問電話號碼',
  accidentalDeath: '意外死亡',
  rentalIncomeLoss: ' 損失租金收入 /',
  deathCertificate: '死亡證明',
  includingBrandName:'包含品牌名稱、型號及產品編號。',
  dateOfPurchase: '購買日期',
  purchasePrice: '購買價值',
  fieldsMissing: '仍有空白欄位',
  someBlankFields: '目前仍有些欄位未填寫。您還要送出索償嗎？',
  resumeFilling: '返回填寫',
  thankYou: '謝謝您',
  submitted: '您的索償已成功送出。',
  claimReferenceNumber: '索償索引編號：',
  item: '物品',

  specialRequests: '其他 / 特別要求',

  declaration: '聲明',
  declaration1: '本人/吾等作出聲明，就本人/吾等所知及所信，上述資料均屬真確及並無遺漏。',
  declaration2: '本人/吾等授權任何醫院、醫生、診所、其他人士、有關官方或機構，向忠意保險有限公司(香港分行)（「忠意」）或其受權之代表提供所有有關本人之損失、受傷、症病、病歷、醫療診斷及藥方、警方報告、口供、所有醫院或醫療報告之副本。',
  declaration3: '本人同意所有文件及收據予忠意將不獲退還。',
  declaration5: '我/我們不同意提供個人資料被第三方作為行銷用途使用',
  declaration6: '我/我們不同意提供個人資料給貴公司作為行銷用途',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成申請',
  reservesTheRight: '忠意保險有限公司 (香港分行) 保留權利向索賠人要求提交索償證明文件的正本。請提交索償後，保留正本至少3個月。',

  ok: '確認',
}
