import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { host, api11, api12, busiSecKey } from './../../config/api';
import { platform } from './../../config/platform';
import { formStyles } from './../../config/styles';
import { clearEmptyStrings, hmacSha256, msgCodeDetail} from './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import Member from './../../Member';
import MemberAPI from './../../api/MemberAPI';
import TextInput from './../../components/TextField/TextField';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import NameInput from './../../components/NameInput/NameInput';
import YesNoQuestion from './../../components/YesNoQuestion/YesNoQuestion';
import LegalDocumentLink from './../../components/LegalDocumentLink/LegalDocumentLink';
import DirectMarketingTickBox from './../../components/DirectMarketingTickBox/DirectMarketingTickBox';
import Alert  from './../../components/Alert/Alert';
import validate from 'validate.js';

export default class RegisterScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      messages: {},
    };

    try {
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      const ref = searchParams.get('ref');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }
      if (ref) {
        this.state = {
          text: {},
          messages: {},
          referenceCode: ref,
        };
      
      }
    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }));
  }

  _sendShortMessage() {
    const state = clearEmptyStrings(this.state);

    let attributes = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobileNumber: this.state.mobileNumber,
      emailAddress: this.state.emailAddress,
      declaration1: this.state.declaration1,
      declaration2: this.state.declaration2,
    }

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish,
    }
    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    }
    let constraints = {
      firstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      lastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: englishFormat,
      },
      mobileNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.invalidMobileNumber},
        length: {is: 8, message: this.state.text.invalidMobileNumber},
      },
      emailAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: englishFormat,
      },
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: declarationFormat,
      },
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
      },
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
        this.setState({messages: messages});
    }
    else {
      if (this.state.shortMessageSending) {
        return;
      }
      this.setState({
        messages: {},
        shortMessageSending : true
      });
      const data = JSON.stringify({
        mobile: this.state.mobileNumber,
        timeStamp: Date.now(),
      });
      const hmac = hmacSha256(data);
      fetch(host + api11, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0) {
          this.setState({
            targetPhoneNumber: this.state.mobileNumber,
            shortMessageSent: true,
          });
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
        this.setState({shortMessageSending : false});
      })
      .catch((error) => {
        alert('Facing error when sending short message!'
          + 'Error message: ' + error);
        this.setState({shortMessageSending : false});
      });
    }
  }

  _register() {
  
    if (this.state.registering) {
      return;
    }
    this.setState({registering : true});
    const data = JSON.stringify({
      username: this.state.mobileNumber,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      mobile: this.state.mobileNumber,
      email: this.state.emailAddress,
      verify_code: this.state.confirmationCode,
      ref_code: this.state.referenceCode,
      agreePIC:this.state.declaration1,
      agreeDM:this.state.declaration2,
      timeStamp: Date.now(),
    });
    const hmac = hmacSha256(data);
    fetch(host + api12, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.msgCode == 0) {
        if (this.state.referenceCode) {
          MemberAPI.postReferenceCode(
            {
              id: responseJson.msgDesc.member.id,
              code: this.state.referenceCode,
            },
            (msgDesc) => 0
          );
        }
        Member.load().then((member) => Member._member = member);
        memberStorage.setMember(
          responseJson.msgDesc.member, responseJson.msgDesc.token, () => {
            this.setState({alert:
            Alert.alert(
              this.state.text.welcome,
              this.state.text.accountCreated,
              [{
                text: this.state.text.ok,
                onPress: () => {  
                  if(this.props.history.location.state && this.props.history.location.state.from && this.props.history.location.state.from === 'PrivilegedClubList'){
                    this.props.history.push("/privilegedClubList")
                  }else{
                    this.props.history.push("/")
                  }
                  return;
              }
                //onPress: () => this.props.history.push("/privilegedClubList")
              }]
            )
          });
          }
        );
      }
      else if (responseJson.msgCode == 31) {
        this.setState({alert:
        Alert.alert(
          this.state.text.youHaveRegistered,
          this.state.text.alreadyRegistered,
          [
            {
              text: this.state.text.login,
              onPress: () => this.props.history.push('Login'),
            }
          ],
          { cancelable: false }
        )
      });
        this.setState({registering : false});
      }
      else {
        alert(msgCodeDetail(responseJson.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        this.setState({registering : false});
      }
    })
    .catch((error) => {
      alert('Facing error when creating account!');
      this.setState({registering : false});
    });
  }

  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Registration | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.register}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

              <View style={[styles.greeting,{fontWeight:'bold',}]}>
                <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.registration}</Text>
                <p></p>
                <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.bravo}</Text>
                <img
                //  style={{width: '100%', height: '80%'}}
                //  src={'./assets/images/Registration.png'}
                >
                </img>
              </View>

          </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
          <ScrollView style={formStyles.form, styles.fieldsetldset}>

            {this.state.alert}
            <View style={formStyles.fieldset}>
              <Text style={formStyles.h3}>{this.state.text.createAccount}</Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.name}</Text>
              <NameInput
                title={this.state.title}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                onChange={(title, firstName, lastName) => this.setState({
                  title: title,
                  firstName: firstName,
                  lastName: lastName,
                })}
              />
              <Text style={formStyles.message}>{this.state.messages.firstName}</Text>
              <Text style={formStyles.message}>{this.state.messages.lastName}</Text>
            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
              <TextInput
                style={formStyles.input}
                onChangeText={(text) => this.setState({mobileNumber: text})}
                value={this.state.mobileNumber}
                keyboardType='numeric'
                maxLength={8}
              />
              <Text style={formStyles.message}>{this.state.messages.mobileNumber}</Text>

            </View>

            <View style={formStyles.fieldset}>
              <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({emailAddress: text})}
                  value={this.state.emailAddress}
                  keyboardType='email-address'
                />
                <Text style={formStyles.message}>{this.state.messages.emailAddress}</Text>
            </View>


              <View style={[formStyles.fieldset,{alignItems: 'center'}]}>
                <LegalDocumentLink
                  navigation={this.props.navigation}
                  choice={this.state.declaration1}
                  noDisclaimer
                  onChange={(choice) => this.setState({declaration1: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
              </View>

              <View style={[formStyles.fieldset,{alignItems: 'center'}]}>
                <DirectMarketingTickBox
                  history={this.props.history}
                  choice={this.state.declaration2}
                  onChange={(choice) => this.setState({declaration2: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.referenceCode}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({referenceCode: text})}
                    value={this.state.referenceCode}
                    placeholder={this.state.text.optional}
                  />
                  <Text style={formStyles.message}>{this.state.messages.referenceCode}</Text>
              </View>

            {
              this.state.shortMessageSent &&
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.confirmationCodeSent}</Text>
                  <Text>{this.state.text.confirmationCodeSentTo}{this.state.targetPhoneNumber}</Text>
                  <Text>{this.state.text.enterCodeBelow}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({confirmationCode: text})}
                    value={this.state.confirmationCode}
                    maxLength={6}
                    keyboardType='numeric'
                  />
                  <Text style={formStyles.message}>{this.state.messages.confirmationCode}</Text>
                </View>
            }
            {
              this.state.shortMessageSent ?
                <View>
                  {
                    this.state.registering ?
                      <View style={formStyles.submitButtonDisabled}>
                        <Text style={formStyles.submitButtonText}>
                          {this.state.text.pleaseWait}
                        </Text>
                      </View> :
                      <TouchableOpacity
                        style={formStyles.submitButton}
                        onPress={() => this._register()}
                      >
                        <Text style={formStyles.submitButtonText}>
                          {this.state.text.createAccount}
                        </Text>
                      </TouchableOpacity>
                  }
                </View> :
                <View>
                  {
                    this.state.shortMessageSending ?
                      <View style={formStyles.submitButtonDisabled}>
                        <Text style={formStyles.submitButtonText}>
                          {this.state.text.pleaseWait}
                        </Text>
                      </View> :
                      <TouchableOpacity
                        style={formStyles.submitButton}
                        onPress={() => this._sendShortMessage()}
                      >
                        <Text style={formStyles.submitButtonText}>
                          {this.state.text.getConfirmationCode}
                        </Text>
                      </TouchableOpacity>
                  }
                </View>
            }
          </ScrollView>
         </Grid>
           <Hidden xsDown>
            <div style={{marginRight: '5%'}} />
           </Hidden>
      </Grid>

      <Footer/>
   </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fieldsetldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
   paddingRight: 20,
  },
};
