import React from 'react';
import { Text, View, ScrollView } from 'react-native';

import { formStyles } from './../../config/styles.js';
import { languageStorage } from './../../lib/LanguageStorage';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class Document29CScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    languageStorage.getLanguage(
      (language) => {
        this.setState({
        language: language,
      })
      language ==='enUS'? window.location.href = "https://www.app-generali.com/share_files/Product_29doc/29C_en.pdf":window.location.href = "https://www.app-generali.com/share_files/Product_29doc/29C_zh.pdf";
      
    }
    );
    
  }

  render() {
    const color = Member.getThemeColor();

    return (
        <div></div>
    //   <div className='App'>
    //     <Navbar
    //       logo backButton
    //       history={this.props.history}
    //     />
    //   <View style={styles.container, styles.fie}>
    //     {
    //       this.state.language == 'enUS' &&
    //         <View>

    //           <ScrollView style={formStyles.form}>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h2, {color}]}>
    //               Disclaimers
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h3, {color}]}>
    //               All products
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 I/we (all of the insured persons)have accepted and consented to all the information contained in this application including this disclaimer, and understood that is a condition precedent to obtaining coverage and each insured person has agreed to all such information.
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 I/We hereby declare that the statements and particulars given in this application is to the best of our knowledge and belief of each of us has never been declined such insurance. I/we understand and agree that no insurance will be effected until the application is approved and premium is received.
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 I /we have understood and agreed the terms and the policy provisions of this policy.
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 With regards to individual circumstances, it is advised that potential policyholders/insured persons should seek professional advice as the information in this website or mobile application (Site/App) should not be viewed as professional advice.
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 In some jurisdictions, legal or regulatory restrictions may apply to distribute the information, services and insurance products; this Site/App is not intended for the usage of those persons located in those jurisdictions. It is the responsibility of those persons who accessing this Site/App to ensure that they are aware of and observe all relevant restrictions which will apply to them.
    //               </Text>
    //             </View>
    //             </View>
    //           </ScrollView>
    //         </View>
    //     }
    //     {
    //       this.state.language == 'zhHK' &&
    //         <View>

    //           <ScrollView style={formStyles.form}>
    //             <View style={formStyles.fieldset}>
    //               <Text style={formStyles.h2}>
    //                 免責聲明
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text style={[formStyles.h3, {color}]}>
    //                 所有產品
    //               </Text>
    //             </View>
    //             <View style={{textAlign: 'left'}}>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 本人/我們(所有受保人)接受及同意在本申請書包括各項聲明內容，並明白同意所有內容是獲得保障的先決條件。
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 本人/我們聲明，本申請內之資料均為各受保人所知所信，亦從未曾被拒絕相關保險申請。本人/我們均明白及同意申請需獲批核及保費交妥後，投保方會生效。
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 本人/我們均明白及同意此保險之條款及保單內容。
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 在個別情況，潛在保單持有人/受保人應尋找專業保險顧問意見，而此網頁/手機應用程式所發放之資料亦不應視為專業意見。
    //               </Text>
    //             </View>
    //             <View style={formStyles.fieldset}>
    //               <Text>
    //                 在某些地區，發放資料、服務及保險產品可能受到法律或監管限制，此網頁/手機應用程式無意讓身處該等受法律限制地區的人士使用。瀏覽人士應自行負責及確定了解所受限制。
    //               </Text>
    //             </View>
    //             </View>
    //           </ScrollView>
    //         </View>
    //     }
    //   </View>
    //   <Footer/>
    // </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },

  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
      margin: 'auto',
      minWidth: 300,
      maxWidth: 600,
      minHeight: 620,
    },
};
