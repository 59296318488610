export const zhHK = {
    total: '合共',
    total2: '共',
    flatDiscount: '定額折扣',
    finalTotal: '合共',
    ecLevy: '僱員補償保險徵款',
    terrorCharge: '恐佈襲擊保費',
    levy: '徵費',
    premium:'保費',
    manualUnderwriting: '您的申請需要核保',
    roundedToTheNearestPercentage: '四捨五入至最近百分比',
    less: '減',
  }
