import React from 'react';
import {
  Share, Text, Image, TextInput, Alert,
  View, ScrollView, TouchableOpacity
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { formStyles } from './../../config/styles';
import { memberStorage } from './../../lib/MemberStorage';
import { msgCodeDetail, handleExpiredLogin, hmacSha256 } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';
import { host, api21, busiSecKey } from './../../config/api';
import Footer from './../../components/Footer/Footer';
import Navbar from './../../components/Navbar/Navbar';
import YesNoQuestion from './../../components/YesNoQuestion/YesNoQuestion';

import Member from './../../Member';

export default class Broker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      noProduct: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    // init member and token
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
      referralLink: this._getReferralLink(member),
    }, () => {
      const data = JSON.stringify({
        'member.id': this.state.member.id,
        'member.token': this.state.token.tokenStr,
        'timeStamp': Date.now(),
      });
      const hmac = hmacSha256(data);
      fetch(host + '/agent' + api21, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
      })
      .then((response) => response.json())
      .then((responseJson) => {
        if(responseJson.msgCode == 0) {
          this.setState({noProduct: responseJson.msgDesc.plans.length == 0});
        }
        else if (responseJson.msgCode == 12) {
          this.setState({
            alert: handleExpiredLogin(this.state.language, this.props.history),
          });
        }
        else {
          alert(msgCodeDetail(responseJson.msgCode)
            [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
        }
      })
      .catch((error) => {
        alert('Facing error when getting the product list!');
      });
    }));

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      })
    );
  }



  _getReferralLink(member) {
    const data = {
      financialAdvisor: member.firstName,
      agentCode: member.agent.agentCode,
      broker: member.agent.broker.nameEnUs,
    }
    return 'https://app-generali.com/settings/financial-advisors/'
      + 'share.html?code='
      + (CryptoJS.AES.encrypt(JSON.stringify(data), '37bO1SVEa8qcyrtHvjrV')
      .toString().split('/').join('%2F').split('+').join('%2B'));
  }

  _navigate(screen) {
    const {broker, isAdmin} = this.state.member.agent;

    const additionalInformation = {
      name: this.state.member.agent.broker.nameEnUs,
      mobile: this.state.member.mobile,
      access: isAdmin ? 'admin' : 'sales',
    };

    Object.assign(broker, additionalInformation);

    this.props.history.push(screen, {broker: broker});
  }

  render() {
    const color = Member.getThemeColor();
    const backgroundColor = color;

    return (
      <div className='App'>
        <Helmet>
          <title>Insurance Advisor Functions | Bravo Generali</title>
        </Helmet>

      <Navbar
        title={this.state.text.getInsured}
        backButton
        history={this.props.history}
      />

      {this.state.alert}

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.borkermes}</Text>
              <p></p>
              <Text style={[styles.greetingText,{fontSize: 35,}]}>{this.state.text.welcomeborker}</Text>

            </View>

        </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50}}>
          <ScrollView style={styles.form, styles.fie}>
            <View style={formStyles.fieldset}>
              <Text style={[formStyles.h2, {color}]}>{this.state.text.insuranceAdvisor}</Text>
            </View>

            <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._navigate('/history')}>
              <Text style={formStyles.submitButtonText}>{this.state.text.history}</Text>
            </TouchableOpacity>

            {
              !this.state.noProduct &&
                <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._navigate('/instant-enrollment')}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.instantEnrollment}</Text>
                </TouchableOpacity>
            }

            {
              false &&
                <TouchableOpacity style={[formStyles.submitButton, {backgroundColor}]} onPress={() => this._navigate('/instant-claimsScreens')}>
                  <Text style={formStyles.submitButtonText}>{this.state.text.instantClaims}</Text>
                </TouchableOpacity>
            }


          </ScrollView>
       </Grid>
        <Hidden xsDown>
         <div style={{marginRight: '5%'}} />
        </Hidden>
      </Grid>
        <Footer/>
      </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  flex: {
    flexGrow: 1,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
};
