import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import validate from 'validate.js';
import CryptoJS from 'crypto-js';
import validid from 'validid';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api42, busiSecKey } from './../../../config/api';
import { clearEmptyStrings, hmacSha256, msgCodeDetail } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { languageStorage } from'./../../../lib/LanguageStorage';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert  from './../../../components/Alert/Alert';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import DatePicker from './../../../components/DatePicker/DatePicker';
import NameInput from './../../../components/NameInput/NameInput';
import AmountInput from './../../../components/AmountInput/AmountInput';
import IdentityInput from './../../../components/IdentityInput/IdentityInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import DocumentAttached from './../../../components/DocumentAttached/DocumentAttached';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink';

export default class HomeInsurance extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    if (policy) {
      this.state = {
        soleOwner: true,
        otherOwners: [{}],
        damagedItems: [{}],
        documentsAttached: [],
        messages: {},
        policyNumber: policy.policyNo,
        policyholderTitle: policy.policyHolder.salutation ?
          policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        insuredHomeAddress: policy.rawRequest ?
          JSON.parse(policy.rawRequest).propertyAddress : undefined,
        idType: policy.policyHolder.documentType,
        idNumber: policy.policyHolder.documentNo,
        mobileNumber: policy.policyHolder.mobile,
        emailAddress: policy.policyHolder.email,
        text: {},
      };
    }
    else {
      this.state = {
        soleOwner: true,
        otherOwners: [{}],
        damagedItems: [{}],
        documentsAttached: [],
        messages: {},
        broker: this.props.location.state.broker,
        product: this.props.location.state.product,
        text: {},
      };
    }
  }

  componentDidMount() {

    stateStorage.setState('home', this.state);

    window.scrollTo(0, 0)
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value)) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };
      })
    );
  }

  _addOtherOwner() {
    const otherOwners = this.state.otherOwners;
    otherOwners.push({});
    this.setState({otherOwners: otherOwners});
  }

  _onOtherOwnerChange(otherOwner, name, address) {
    this.state.otherOwners[otherOwner] = {
      name: name,
      address: address,
    };
    this.setState({otherOwners: this.state.otherOwners});
  }

  _addDamagedItem() {
    const damagedItems = this.state.damagedItems;
    damagedItems.push({});
    this.setState({damagedItems: damagedItems});
  }

  _onDamagedItemChange(damagedItem, fullDescription, purchaseDate, purchasePrice) {
    this.state.damagedItems[damagedItem] = {
      fullDescription: fullDescription,
      purchaseDate: purchaseDate,
      purchasePrice: purchasePrice,
    };
    this.setState({damagedItems: this.state.damagedItems});
  }

  _submit() {
    if (this.state.submitting) {
      return;
    }
    else {
      this.setState({submitting: true});
    }

    const state = clearEmptyStrings(this.state);
    let attributes = {
      policyNumber: state.policyNumber,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      insuredHomeAddress: state.insuredHomeAddress,
      idType: state.idType,
      idNumber: state.idNumber,
      mobileNumber: state.mobileNumber,
      emailAddress: state.emailAddress,
      declaration1: state.declaration1,
      declaration2: state.declaration2,
      declaration3: state.declaration3,
      declaration4: state.declaration4,
      declaration5: state.declaration5,
      declaration6: state.declaration6,
    };
    if (!this.state.accidentalDeath) {
      attributes.otherInsurance = state.otherInsurance;
    }

    const format = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish,
    };

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    };

    let constraints = {
      policyNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: format,
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      insuredHomeAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        format: format,
      },
      idType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      idNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: this.state.idType == 'HKID',
        format: format,
      },
      mobileNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        numericality: {message: this.state.text.notANumber},
      },
      emailAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank},
        email: {message: this.state.text.notValidEmail},
        format: format,
      },
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration5: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      declaration6: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
    }
    if (!this.state.accidentalDeath) {
      constraints.otherInsurance = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
    }

    let messages = validate(attributes, constraints, {fullMessages: false});
    if (messages) {
      this.setState({
        messages: messages,
        submitting: false,
      });
    }
    else {


        const submit = () => {
          const claimDetails = Object.assign({}, this.state);
          delete claimDetails.documentsAttached;
          delete claimDetails.messages;
          delete claimDetails.text;
          const data = JSON.stringify({
            'policy.policyNo': this.state.policyNumber,
            'policyHolder.salutation': this.state.policyholderTitle,
            'policyHolder.firstName': this.state.policyholderFirstName,
            'policyHolder.lastName': this.state.policyholderLastName,
            'policyHolder.mobile': this.state.mobileNumber,
            'policyHolder.email': this.state.emailAddress,
            'policyHolder.documentType': this.state.idType,
            'policyHolder.documentNo': this.state.idNumber,
            'claim.details': JSON.stringify(claimDetails),
            'timeStamp': Date.now(),
          });
          const hmac = hmacSha256(data);
          let formData = new FormData();
          formData.append('data', data);
          formData.append('hmac', hmac);
          if (this.state.documentsAttached.length > 0) {
            let supportDocs = [];
            let index = 1;
            this.state.documentsAttached.forEach((documentAttached) => {
              formData.append('supportDocs', {
                uri: documentAttached.uri,
                name: `image${index++}.jpg`,
                type: 'image/jpeg',
              });
            });
          }
          if (!this.state.member && !this.state.recaptchaVerified) {
            return;
          }
          else {
            const superagent = require('superagent');
superagent
  .post(host + api42)
  .withCredentials()
  .send(formData)
  .end((err, res) => {
    if (err) {
      console.log(err);
      alert('Facing error when submitting your claim!' + err);
      this.setState({submitting: false});
  } else {
      if(res.body.msgCode == 0) {
        this.setState({alert:
          Alert.alert(
            this.state.text.thankYou,
            this.state.text.submitted +
              this.state.text.claimReferenceNumber +
              res.body.msgDesc.claimNo,
            [{
              text: this.state.text.ok,
              onPress: () => this.props.history.push('/')
            }]
          )
        });
      }
      else {
        alert(msgCodeDetail(res.body.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
  }
  });

            // fetch(host + api42, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'multipart/form-data',
            //   },
            //   body: formData,
            // })
            // .then((response) => response.json())
            // .then((responseJson) => {
            //   if(responseJson.msgCode == 0) {
            //     this.setState({alert:
            //     Alert.alert(this.state.text.thankYou,
            //       this.state.text.submitted
            //       + this.state.text.claimReferenceNumber
            //       + responseJson.msgDesc.claimNo)
            //     });
            //     this.props.history.push('/');
            //   }
            //   else {
            //     alert(msgCodeDetail(responseJson.msgCode)
            //       [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
            //   }
            //   this.setState({submitting: false});
            // })
            // .catch((error) => {
            //   alert('Facing error when submitting your claim!' + error);
            //   this.setState({submitting: false});
            // });
          }
        };

        if (messages) {
          this.setState({
            messages: messages,
            alert:
          Alert.alert(
            this.state.text.fieldsMissing,
            this.state.text.someBlankFields,
            [
              {text: this.state.text.resumeFilling, style: 'cancel',
                onPress: () => this.setState({submitting: false})},
              {text: this.state.text.submit, onPress: () => submit()},
            ],
            { cancelable: false }
          )
        });
        }
        else {
          submit();
        }
    }
  }

  render() {
    let otherOwners = [];
    let otherOwner;
    for (otherOwner in this.state.otherOwners) {
      otherOwners.push(
        <OtherOwner
          key={otherOwner}
          number={parseInt(otherOwner) + 1}
          name={this.state.otherOwners[otherOwner].name}
          address={this.state.otherOwners[otherOwner].address}
          onChange={(name, address) =>
            this._onOtherOwnerChange(otherOwner, name, address)
          }
          text={this.state.text}
        />
      );
    }

    let damagedItems = [];
    let damagedItem;
    for (damagedItem in this.state.damagedItems) {
      damagedItems.push(
        <DamagedItem
          key={damagedItem}
          number={parseInt(damagedItem) + 1}
          fullDescription={this.state.damagedItems[damagedItem].fullDescription}
          purchaseDate={this.state.damagedItems[damagedItem].purchaseDate}
          purchasePriceCurrency={this.state.damagedItems[damagedItem].purchasePriceCurrency}
          purchasePrice={this.state.damagedItems[damagedItem].purchasePrice}
          claimAmountCurrency={this.state.damagedItems[damagedItem].claimAmountCurrency}
          claimAmount={this.state.damagedItems[damagedItem].claimAmount}
          onChange={(key, value) => {
            this.state.damagedItems[damagedItem][key] = value;
            this.setState({damagedItems: this.state.damagedItems});
          }}
          text={this.state.text}
        />
      );
    }

    return(
    <div className='App'>
      <Navbar
        title={this.state.text.instantClaim}
        backButton
        history={this.props.history}
      />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>Claim Submission</Text>
                <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>Easy digital claims submission at your fingertips.</Text>

            </View>

          </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
        <ScrollView style={formStyles.form,styles.fieldset}>
        {this.state.alert}
          <View style={formStyles.fieldset, styles.fie}>
            <Text style={formStyles.h2}>{this.state.text.homeInsurance}</Text>
          </View>

          {

            this.state.broker &&
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                  value={this.state.insuranceAdvisorMobile}
                />
              </View>
          }
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.insuredDetails}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.policyNumber}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({policyNumber: text})}
              value={this.state.policyNumber}
            />
            <Text style={formStyles.message}>{this.state.messages.policyNumber}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.policyholderName}</Text>
            <NameInput
              title={this.state.policyholderTitle}
              firstName={this.state.policyholderFirstName}
              lastName={this.state.policyholderLastName}
              onChange={(title, firstName, lastName) => this.setState({
                policyholderTitle: title,
                policyholderFirstName: firstName,
                policyholderLastName: lastName,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
            <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.insuredHomeAddress}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({insuredHomeAddress: text})}
              value={this.state.insuredHomeAddress}
            />
            <Text style={formStyles.message}>{this.state.messages.insuredHomeAddress}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.hkidNumberPassNumber}</Text>
            <IdentityInput
              type={this.state.idType}
              number={this.state.idNumber}
              onChange={(type, number) => this.setState({
                idType: type,
                idNumber: number,
              })}
            />
            <Text style={formStyles.message}>{this.state.messages.idType}</Text>
            <Text style={formStyles.message}>{this.state.messages.idNumber}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({mobileNumber: text})}
              value={this.state.mobileNumber}
              keyboardType='numeric'
              maxLength={8}
            />
            <Text style={formStyles.message}>{this.state.messages.mobileNumber}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({emailAddress: text})}
              value={this.state.emailAddress}
              keyboardType='email-address'
            />
            <Text style={formStyles.message}>{this.state.messages.emailAddress}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.typeOfClaim}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.propertyDamage}
                  onValueChange={(value) => this.setState({
                    propertyDamage: value,
                    publicLiability: false,
                    accidentalDeath: false,
                    others: false,
                  })}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>
                  {this.state.text.propertyDamageOrLoss}
                  {
                    this.state.policyNumber &&
                    this.state.policyNumber.startsWith('LH') ?
                    this.state.text.rentalIncomeLoss : ''
                  }</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.publicLiability}
                  onValueChange={(value) => this.setState({
                    propertyDamage: false,
                    publicLiability: value,
                    accidentalDeath: false,
                    others: false,
                  })}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.publicLiability}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.accidentalDeath}
                  onValueChange={(value) => this.setState({
                    propertyDamage: false,
                    publicLiability: false,
                    accidentalDeath: value,
                    others: false,
                  })}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.accidentalDeath}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <View style={formStyles.yesNoQuestion}>
              <View style={formStyles.yesNoQuestionSwitch}>
                <Switch
                  value={this.state.others}
                  onValueChange={(value) => this.setState({
                    propertyDamage: false,
                    publicLiability: false,
                    accidentalDeath: false,
                    others: value,
                  })}
                />
              </View>
              <View style={formStyles.yesNoQuestionText}>
                <Text>{this.state.text.others}</Text>
              </View>
            </View>
          </View>
          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.claimDetails}</Text>
          </View>
          {
            this.state.propertyDamage ?
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfIncident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.incidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({incidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentTime: text})}
                    value={this.state.incidentTime}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentPlace: text})}
                    value={this.state.incidentPlace}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.detailIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentDescription: text})}
                    value={this.state.incidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.inCaseOfBurglary}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({burglary: text})}
                    value={this.state.burglary}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.burglary}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.reportedToPolice}
                        onValueChange={(value) => this.setState({reportedToPolice: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.haveYouReportedTheIncident}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.reportedToPolice &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.nameOfThePoliceStation}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({policeStation: text})}
                          value={this.state.policeStation}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policeStation}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.policeReportNumber}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({policeReportNumber: text})}
                          value={this.state.policeReportNumber}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policeReportNumber}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.soleOwner}
                        onValueChange={(value) => this.setState({soleOwner: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.areYouTheSoleOwner}</Text>
                    </View>
                  </View>
                </View>
                {
                  !this.state.soleOwner &&
                    <View style={styles.indented}>
                      <Text style={formStyles.h3}>{this.state.text.otherOwners}</Text>
                      <Text>{this.state.text.includingTheHirePurchase}</Text>
                      { otherOwners }
                      <TouchableOpacity style={formStyles.addButton} onPress={() => this._addOtherOwner()}>
                        <Text style={formStyles.addButtonText}>{this.state.text.addAnotherOwner}</Text>
                      </TouchableOpacity>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.entitledToClaim}
                        onValueChange={(value) => this.setState({entitledToClaim: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.areYouEntitled}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.entitledToClaim &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.nameOfInsuranceCompanyies}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({entitledToClaimInsuranceCompanyName: text})}
                          value={this.state.entitledToClaimInsuranceCompanyName}
                        />
                        <Text style={formStyles.message}>{this.state.messages.entitledToClaimInsuranceCompanyName}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.respectivePolicyNumber}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({entitledToClaimPolicyNumber: text})}
                          value={this.state.entitledToClaimPolicyNumber}
                        />
                        <Text style={formStyles.message}>{this.state.messages.entitledToClaimPolicyNumber}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.detailsOfCoverage}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({entitledToClaimCoverageDetails: text})}
                          value={this.state.entitledToClaimCoverageDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.entitledToClaimCoverageDetails}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.sustainedLosses}
                        onValueChange={(value) => this.setState({sustainedLosses: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.haveYouEverSustainedLosses}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.sustainedLosses &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({incidentDetails: text})}
                          value={this.state.incidentDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.incidentDetails}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.datesOfIncidents}</Text>
                        <DatePicker
                          style={formStyles.datepicker}
                          date={this.state.incidentDates}
                          mode='date'
                          placeholder={this.state.text.pleasePickADate}
                          format='MMMM D, YYYY'
                          minDate='January 1, 1990'
                          maxDate={moment().format('MMMM D, YYYY')}
                          onDateChange={(date) => {this.setState({incidentDates: date})}}
                          confirmBtnText={this.state.text.ok}
                          cancelBtnText={this.state.text.cancel}
                        />
                        <Text style={formStyles.message}>{this.state.messages.incidentDates}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.madeClaim}
                        onValueChange={(value) => this.setState({madeClaim: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.haveYouEverMadeClaimUnder}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.madeClaim &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.details}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({claimDetails: text})}
                          value={this.state.claimDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.claimDetails}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.fullDescriptionOf}</Text>
                  { damagedItems }
                  <TouchableOpacity style={formStyles.addButton} onPress={() => this._addDamagedItem()}>
                    <Text style={formStyles.addButtonText}>{this.state.text.addAnItem}</Text>
                  </TouchableOpacity>
                </View>

                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.remarks}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({remarks: text})}
                    value={this.state.remarks}
                  />
                  <Text style={formStyles.message}>{this.state.messages.remarks}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.totalClaimAmount}</Text>
                  <AmountInput
                  currency={this.state.totalClaimableAmountCurrency}
                  number={this.state.totalClaimableAmount}
                    onChange={(currency, number) =>
                      this.setState({
                        totalClaimableAmountCurrency: currency,
                        totalClaimableAmount: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.totalClaimableAmountCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.totalClaimableAmount}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                </View>
                <View style={[formStyles.fieldset,{textAlign: 'left',}]}>
                  <Text>{this.state.text.reservesTheRight}</Text>
                </View>
                <DocumentAttached
                  text={this.state.text.detailedBreakdownOfTheClaimedItems}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.colouredPhotographsOfTheVicinity}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.incidentReportMadeByTheBuilding}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.memoReportsAndOrStatements}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                {
                  !!this.state.policyNumber &&
                    this.state.policyNumber.startsWith('LH') &&
                    <DocumentAttached
                      text={this.state.text.aCopyOfDuly}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                }
                <DocumentAttached
                  text={this.state.text.others}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View> : null
          }
          {
            this.state.publicLiability &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfIncident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.incidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({incidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentTime: text})}
                    value={this.state.incidentTime}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfIncident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentPlace: text})}
                    value={this.state.incidentPlace}
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.detailDescriptionOf}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({incidentDescription: text})}
                    value={this.state.incidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.incidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameOfWitness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessName: text})}
                    value={this.state.witnessName}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.contactOfWitness}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({witnessContact: text})}
                    value={this.state.witnessContact}
                  />
                </View>
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.reportedToPolice}
                        onValueChange={(value) => this.setState({reportedToPolice: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.wasTheIncidentReported}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.reportedToPolice &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.nameOfThePoliceStation}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({policeStation: text})}
                          value={this.state.policeStation}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policeStation}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.policeReportNumber}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({policeReportNumber: text})}
                          value={this.state.policeReportNumber}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policeReportNumber}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.precautionaryMeasures}
                        onValueChange={(value) => this.setState({precautionaryMeasures: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.hasAnyPrecautionaryMeasures}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.precautionaryMeasures &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({precautionaryMeasureDetails: text})}
                          value={this.state.precautionaryMeasureDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.precautionaryMeasureDetails}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.remedyWork}
                        onValueChange={(value) => this.setState({remedyWork: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.followingTheIncident}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.remedyWork &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({remedyWorkDetails: text})}
                          value={this.state.remedyWorkDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.remedyWorkDetails}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.similarIncident}
                        onValueChange={(value) => this.setState({similarIncident: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.haveYouEverExperienced}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.similarIncident &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({similarIncidentDetails: text})}
                          value={this.state.similarIncidentDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.similarIncidentDetails}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.datesOfIncidents}</Text>
                        <DatePicker
                          style={formStyles.datepicker}
                          date={this.state.dateOfIncident}
                          mode='date'
                          placeholder={this.state.text.pleasePickADate}
                          format='MMMM D, YYYY'
                          minDate='January 1, 1990'
                          maxDate={moment().format('MMMM D, YYYY')}
                          onDateChange={(date) => {this.setState({similarIncidentDate: date})}}
                          confirmBtnText={this.state.text.ok}
                          cancelBtnText={this.state.text.cancel}
                        />
                        <Text style={formStyles.message}>{this.state.messages.similarIncidentDate}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameOfThirdParty}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({thirdPartyName: text})}
                    value={this.state.thirdPartyName}
                  />
                  <Text style={formStyles.message}>{this.state.messages.thirdPartyName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.contactOfThirdParty}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({thirdPartyContact: text})}
                    value={this.state.thirdPartyContact}
                  />
                  <Text style={formStyles.message}>{this.state.messages.thirdPartyContact}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.addressOfThirdParty}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({thirdPartyAddress: text})}
                    value={this.state.thirdPartyAddress}
                  />
                  <Text style={formStyles.message}>{this.state.messages.thirdPartyAddress}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.natureAndExtentOfDamageOrInjuries}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({damageNature: text})}
                    value={this.state.damageNature}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.damageNature}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.claimMade}
                        onValueChange={(value) => this.setState({claimMade: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.hasClaimBeenMade} </Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.claimMade &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                        <AmountInput
                        currency={this.state.publicLiabilityClaimedAmountCurrency}
                        number={this.state.publicLiabilityClaimedAmount}
                          onChange={(currency, number) =>
                            this.setState({
                              publicLiabilityClaimedAmountCurrency: currency,
                              publicLiabilityClaimedAmount: number,
                            })
                          }
                        />
                        <Text style={formStyles.message}>{this.state.messages.publicLiabilityClaimedAmountCurrency}</Text>
                        <Text style={formStyles.message}>{this.state.messages.publicLiabilityClaimedAmount}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.stepsTaken}
                        onValueChange={(value) => this.setState({stepsTaken: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.anyStepsBeenTaken}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.stepsTaken &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({stepsTakenDetails: text})}
                          value={this.state.stepsTakenDetails}
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.stepsTakenDetails}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.particularsOfMainContractor}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({contractorParticulars: text})}
                    value={this.state.contractorParticulars}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.contractorParticulars}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.workUndertaken}
                        onValueChange={(value) => this.setState({workUndertaken: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.isThereAnyWorkByContract}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.workUndertaken &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.pleaseAdviseInDetails}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({workUndertakenName: text})}
                          value={this.state.workUndertakenName}
                          placeholder={this.state.text.name}
                          placeholderTextColor='lightgrey'
                        />
                        <Text style={formStyles.message}>{this.state.messages.workUndertakenName}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({workUndertakenTrade: text})}
                          value={this.state.workUndertakenTrade}
                          placeholder={this.state.text.trade}
                          placeholderTextColor='lightgrey'
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.workUndertakenTrade}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({workUndertakenContact: text})}
                          value={this.state.workUndertakenContact}
                          placeholder={this.state.text.contactNoOrEmail}
                          placeholderTextColor='lightgrey'
                        />
                        <Text style={formStyles.message}>{this.state.messages.workUndertakenContact}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({workUndertakenAddress: text})}
                          value={this.state.workUndertakenAddress}
                          placeholder={this.state.text.address}
                          placeholderTextColor='lightgrey'
                          multiline
                        />
                        <Text style={formStyles.message}>{this.state.messages.workUndertakenAddress}</Text>
                      </View>
                    </View>
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.theirRespectiveThirdParty}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({policyDetailInsuranceCompanyName: text})}
                    value={this.state.policyDetailInsuranceCompanyName}
                    placeholder={this.state.text.nameOfInsuranceCompany}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.policyDetailInsuranceCompanyName}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({policyDetailPolicyNumber: text})}
                    value={this.state.policyDetailPolicyNumber}
                    placeholder={this.state.text.policyNumber}
                    placeholderTextColor='lightgrey'
                  />
                  <Text style={formStyles.message}>{this.state.messages.policyDetailPolicyNumber}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                </View>
                <View style={[formStyles.fieldset,{textAlign: 'left',}]}>
                  <Text>{this.state.text.reservesTheRight}</Text>
                </View>
                <DocumentAttached
                  text={this.state.text.detailedBreakdownOfTheClaimedItems}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.colouredPhotographsOfTheVicinity}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.incidentReportMadeByTheBuilding}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.theCorrespondencesAmong}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                {
                  !!this.state.policyNumber &&
                    this.state.policyNumber.startsWith('LH') &&
                    <DocumentAttached
                      text={this.state.text.aCopyOfDuly}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                }
                <DocumentAttached
                  text={this.state.text.otherRelevantInformation}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.others}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View>
          }
          {
            this.state.accidentalDeath ?
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.accidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({accidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentTime: text})}
                    value={this.state.accidentTime}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentPlace: text})}
                    value={this.state.accidentPlace}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.howWasItHappened}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentDescription: text})}
                    value={this.state.accidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={[formStyles.h4,{textAlign: 'left',}]}>{this.state.text.pleaseDescribeThe}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({injuriesSustained: text})}
                    value={this.state.injuriesSustained}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.injuriesSustained}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <View style={formStyles.yesNoQuestion}>
                    <View style={formStyles.yesNoQuestionSwitch}>
                      <Switch
                        value={this.state.reportedToPolice}
                        onValueChange={(value) => this.setState({reportedToPolice: value})}
                      />
                    </View>
                    <View style={formStyles.yesNoQuestionText}>
                      <Text style={formStyles.h4}>{this.state.text.wasTheAccidentReported}</Text>
                    </View>
                  </View>
                </View>
                {
                  this.state.reportedToPolice ?
                    <View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.nameOfThePoliceStation}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({policeStation: text})}
                          value={this.state.policeStation}
                        />
                        <Text style={formStyles.message}>{this.state.messages.policeStation}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.caseReferenceNumber}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({caseReferenceNumber: text})}
                          value={this.state.caseReferenceNumber}
                        />
                        <Text style={formStyles.message}>{this.state.messages.caseReferenceNumber}</Text>
                      </View>
                    </View> : null
                }
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.nameOfDoctorHospital}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({doctorName: text})}
                    value={this.state.doctorName}
                  />
                  <Text style={formStyles.message}>{this.state.messages.doctorName}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfFirstConsultation}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.firstConsultationDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='DD/MM/YY'
                    minDate='01/01/90'
                    maxDate={moment().format('DD/MM/YY')}
                    onDateChange={(date) => {this.setState({firstConsultationDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.firstConsultationDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                </View>
                <View style={[formStyles.fieldset,{textAlign: 'left',}]}>
                  <Text>{this.state.text.reservesTheRight}</Text>
                </View>
                <DocumentAttached
                  text={this.state.text.deathCertificate}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.proofOfRelationship}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
                <DocumentAttached
                  text={this.state.text.others}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View> : null
          }
          {
            this.state.others ?
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                  <DatePicker
                    style={formStyles.datepicker}
                    date={this.state.accidentDate}
                    mode='date'
                    placeholder={this.state.text.pleasePickADate}
                    format='MMMM D, YYYY'
                    minDate='January 1, 1990'
                    maxDate={moment().format('MMMM D, YYYY')}
                    onDateChange={(date) => {this.setState({accidentDate: date})}}
                    confirmBtnText={this.state.text.ok}
                    cancelBtnText={this.state.text.cancel}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentTime: text})}
                    value={this.state.accidentTime}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentPlace: text})}
                    value={this.state.accidentPlace}
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.descriptionOfTheAccident}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) => this.setState({accidentDescription: text})}
                    value={this.state.accidentDescription}
                    multiline
                  />
                  <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h4}>{this.state.text.claimAmount}</Text>
                  <AmountInput
                  currency={this.state.othersClaimedAmountCurrency}
                  number={this.state.othersClaimedAmount}
                    onChange={(currency, number) =>
                      this.setState({
                        othersClaimedAmountCurrency: currency,
                        othersClaimedAmount: number,
                      })
                    }
                  />
                  <Text style={formStyles.message}>{this.state.messages.othersClaimedAmountCurrency}</Text>
                  <Text style={formStyles.message}>{this.state.messages.othersClaimedAmount}</Text>
                </View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                </View>
                <View style={[formStyles.fieldset,{textAlign: 'left',}]}>
                  <Text>{this.state.text.reservesTheRight}</Text>
                </View>
                <DocumentAttached
                  text={this.state.text.others}
                  onSelect={(selectedDocument) =>
                    this.state.documentsAttached.push(selectedDocument)}
                />
              </View> : null
          }
          {
            !this.state.accidentalDeath &&
              <View>
                <View style={formStyles.fieldset}>
                  <Text style={formStyles.h3}>{this.state.text.otherInsurance}</Text>
                </View>



                <View style={formStyles.fieldset}>
                           <YesNoQuestion
                             question={this.state.text.doYouHaveOtherInsurance}
                             choice={this.state.otherInsurance}
                             onChange={(choice) => this.setState({otherInsurance: choice})}
                           />
                           <Text style={formStyles.message}>{this.state.messages.otherInsurance}</Text>
                         </View>
                {
                  this.state.otherInsurance == 'Y' &&
                    <View style={styles.indented}>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.nameOfInsurer}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({otherInsuranceInsurerName: text})}
                          value={this.state.otherInsuranceInsurerName}
                        />
                        <Text style={formStyles.message}>{this.state.messages.otherInsuranceInsurerName}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.typeOfCoverage}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({otherInsuranceCoverageType: text})}
                          value={this.state.otherInsuranceCoverageType}
                        />
                        <Text style={formStyles.message}>{this.state.messages.otherInsuranceCoverageType}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.policyNumber}</Text>
                        <TextInput
                          style={formStyles.input}
                          onChangeText={(text) => this.setState({otherInsurancePolicyNumber: text})}
                          value={this.state.otherInsurancePolicyNumber}
                        />
                        <Text style={formStyles.message}>{this.state.messages.otherInsurancePolicyNumber}</Text>
                      </View>
                      <View style={formStyles.fieldset}>
                        <Text style={formStyles.h4}>{this.state.text.policyEffectiveDate}</Text>
                        <DatePicker
                          style={formStyles.datepicker}
                          date={this.state.otherInsuranceEffectiveDate}
                          mode='date'
                          placeholder={this.state.text.pleasePickADate}
                          format='DD/MM/YY'
                          minDate='01/01/90'
                          maxDate={moment().format('DD/MM/YY')}
                          onDateChange={(date) => {this.setState({otherInsuranceEffectiveDate: date})}}
                          confirmBtnText={this.state.text.ok}
                          cancelBtnText={this.state.text.cancel}
                        />
                        <Text style={formStyles.message}>{this.state.messages.otherInsuranceEffectiveDate}</Text>
                      </View>
                    </View>
                }
              </View>
          }

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
          </View>
          <View style={formStyles.fieldset}>
            <TextInput
              style={formStyles.input}
              onChangeText={(text) => this.setState({specialRequests: text})}
              value={this.state.specialRequests}
            />
            <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
          </View>

          <View style={formStyles.hr} />

          <View style={formStyles.fieldset}>
            <Text style={formStyles.h3}>{this.state.text.declaration}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration1}
              choice={this.state.declaration1}
              onChange={(choice) => this.setState({declaration1: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration2}
              choice={this.state.declaration2}
              onChange={(choice) => this.setState({declaration2: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration3}
              choice={this.state.declaration3}
              onChange={(choice) => this.setState({declaration3: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <LegalDocumentLink
              navigation={this.props.navigation}
              choice={this.state.declaration4}
              noDisclaimer
              onChange={(choice) => this.setState({declaration4: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration5}
              choice={this.state.declaration5}
              onChange={(choice) => this.setState({declaration5: choice})}
            />
          <Text style={formStyles.message}>{this.state.messages.declaration5}</Text>
          </View>

          <View style={formStyles.fieldset}>
            <YesNoQuestion
              question={this.state.text.declaration6}
              choice={this.state.declaration6}
              onChange={(choice) => this.setState({declaration6: choice})}
            />
            <Text style={formStyles.message}>{this.state.messages.declaration6}</Text>
          </View>

          {
            !this.state.member &&
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ReCAPTCHA
                  sitekey='6Lf7_HYUAAAAAGAlKNXmqW44LtmYH8QSzDn1rcFT'
                  onChange={(value) => value && this.setState({recaptchaVerified: true})}
                />
              </div>
          }

          {
            this.state.submitting ?
              <View style={formStyles.submitButtonDisabled}>
                <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
              </View> :
              <TouchableOpacity style={formStyles.submitButton} onPress={() => this._submit()}>
                <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
              </TouchableOpacity>
          }

        </ScrollView>
        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
      <Footer/>
    </div>
    );
  }
}

class OtherOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name ? this.props.name : '',
      address: this.props.address ? this.props.address : '',
    };
  }

  _onNameChange(name) {
    this.setState({name: name});
    this.props.onChange(name, this.state.address);
  }

  _onAddressChange(address) {
    this.setState({address: address});
    this.props.onChange(this.state.name, address);
  }

  render() {
    return (
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>
          {getOrdinalNumber(this.props.number)}{this.props.text.ofTheOtherOwners}
        </Text>
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this._onNameChange(text)}
          value={this.state.otherOwnerName}
          placeholder={this.props.text.name}
          placeholderTextColor='lightgrey'
        />
        <TextInput
          style={formStyles.input}
          onChangeText={(text) => this._onAddressChange(text)}
          value={this.state.otherOwnerAddress}
          placeholder={this.props.text.address}
          placeholderTextColor='lightgrey'
        />
      </View>
    );
  }
}

class DamagedItem extends React.Component {
  render() {
    return (
      <View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>
            {getOrdinalNumber(this.props.number)} {this.props.text.item}
          </Text>
          <TextInput
            style={formStyles.input}
            onChangeText={(text) => this.props.onChange('fullDescription', text)}
            value={this.props.fullDescription}
            placeholder={this.props.text.includingBrandName}
            placeholderTextColor='lightgrey'
            multiline
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.dateOfPurchase}</Text>
          <DatePicker
            style={formStyles.datepicker}
            date={this.props.purchaseDate}
            mode='date'
            placeholder={this.props.text.pleasePickADate}
            format='MMMM D, YYYY'
            minDate='January 1, 1990'
            maxDate={moment().format('MMMM D, YYYY')}
            onDateChange={(date) => this.props.onChange('purchaseDate', date)}
            confirmBtnText={this.props.text.ok}
            cancelBtnText={this.props.text.cancel}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.purchasePrice}</Text>
          <AmountInput
            currency={this.props.purchasePriceCurrency}
            number={this.props.purchasePrice}
            onChange={(currency, number) => {
              this.props.onChange('purchasePriceCurrency', currency);
              this.props.onChange('purchasePrice', number);
            }}
          />
        </View>
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{this.props.text.claimAmount}</Text>
          <AmountInput
            currency={this.props.claimAmountCurrency}
            number={this.props.claimAmount}
            onChange={(currency, number) => {
              this.props.onChange('claimAmountCurrency', currency);
              this.props.onChange('claimAmount', number);
            }}
          />
        </View>
      </View>
    );
  }
}

const getOrdinalNumber = function (number) {
  if (number == 1 || (number > 20 && number % 10 == 1)) {
    return number + 'st';
  }
  else if (number == 2 || (number > 20 && number % 10 == 2)) {
    return number + 'nd';
  }
  else if (number == 3 || (number > 20 && number % 10 == 3)) {
    return number + 'rd';
  }
  else {
    return number + 'th';
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  indented: {
    marginLeft: 20,
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
    paddingRight: 20,
  },
};
