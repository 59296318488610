import React from 'react';
import { Text, Switch, View } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';


export default class DirectMarketingTickBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.choice ? this.props.choice : null,
      text: {},
    };
  }

  _getAttachmentType(url) {
    if (url == document29EURL) {
      return document29E;
    }
  }

  _onChoiceChange(choice) {
    this.setState({choice: choice});
    this.props.onChange(choice);
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language),language: language}));
  }

  render() {
    let provision =  'https://www.app-generali.com/share_files/Product_29doc/29E_dm_en.pdf';

    if(this.state.language == 'enUS')
    {
       provision = 'https://www.app-generali.com/share_files/Product_29doc/29E_dm_en.pdf';
      }
      else
      {
        provision = 'https://www.app-generali.com/share_files/Product_29doc/29E_dm_zh.pdf';
      }

    return (
    
      <View style={styles.attachmentLink}>
        <Text style={{textAlign: 'left'}}>
          {this.state.text.agree}
          <a href={provision} target="_blank">{this.state.text.directMarketing}</a>
          {this.state.text.period}
        </Text>

        <FormControl style={styles.formControl}>
          <Button
          variant="contained"
            color={this.state.choice == 'Y' ? 'secondary' : null}
            onClick={(value) => this._onChoiceChange(value ? 'Y' : null)}
          >
            {this.state.text.yes}
          </Button>
          <Button
            variant="contained"
            style={{marginLeft:20}}
            color={this.state.choice == 'N' ? 'secondary' : null}
            onClick={(value) => this._onChoiceChange(value ? 'N' : null)}
          >
            {this.state.text.no}
          </Button>
        </FormControl>
      </View>
    );
  }
}


const document29E = 'document29E';
const document29EURL = 'https://www.app-generali.com/legal/29E/';

const styles = {
  attachmentLink: {
    marginTop: 10,
  },
  linkStyle: {
    color: '#C50000',
  },
  yesNoQuestion: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    display: 'block',
    marginTop: 12,
    marginBottom: 12
  },
};
