export const enUS = {
  instantClaim: 'Instant Claim',
  travelInsurance: 'Travel Insurance',
  insuredDetails: 'Insured Details',
  policyNumber: 'Policy Number',
  policyholderName: 'Policyholder Name',
  insuredName: 'Insured Name',
  hkidNumberPassNumber: 'HKID card Number / Passport Number',
  hkid: 'HKID',
  passport: 'Passport',
  cancel: 'cancel',
  pleaseEnter: 'Please enter all characters of your HKID.',
  forExample: 'For example, if your HKID is A123456(7), please enter A1234567',
  mobileNumber: 'Mobile Number',
  emailAddress: 'E-mail Address',

  typeOfClaim:'Type of Claim',

  medicalExpense: 'Medical Expenses Reimbursement',
  medicalExpenseReClaimDetails: 'Medical Expenses Reimbursement Claim Details',
  injuryOrSickness: 'Injury / Sickness',
  pleaseChoose: 'Please choose',
  injury: 'Injury',
  sickness: 'Sickness',
  dateOfInjuryOrSickness: 'Date of injury / Sickness',
  pleasePickADate: 'Please pick a date',
  ifInjury: 'If Injury, how did the accident happen',
  ifSickness: 'If Sickness, when did the Sickness first appear',
  natureOfInjury: 'Nature of Injury / Diagnosis of Sickness',
  claimAmount: 'Claim Amount',
  nameAndAddressOfDoctorOrHospital: 'Name and Address of Attending Doctor / Hospital',
  name: 'Name',
  address: 'Address',
  ifItWasAHospitalPeriod: 'If it was a Hospital, period of hospital stay',
  pleasePickADateOfHospital: 'Please pick a date of hospital',
  documentsAttached: 'Documents Attached',
  travelItinerary: 'Travel itinerary / e-ticket / boarding pass',
  openPhoto: 'open photo',
  hospitalMedicalBillsReceiptReport: 'Hospital / medical bill(s) / receipt(s) / medical report(s)',
  pleaseBeReminded: 'For inpatient claims, please be reminded to mail us the original copies of medical bills if the bill amount is more than HK$5,000 and its equivalent. We will process your claim upon this digital claim submission but approval will be pending until we receive the original copy. Our office address is: 5/F, Generali Tower, 8 Queen‘s Road East, Hong Kong. Please mark the claim reference number on the envelope. You will receive the reference number upon successful digital claim submission',

  loss: 'Loss of Baggage, Travel Documents, Personal Belongings',
  lossClaimDetail: 'Loss of Baggage, Travel Documents, Personal Belongings Claim Details',
  dateOfLossDamage: 'Date of the Loss / Damage',
  timeOfLossDamage: 'Time of the Loss / Damage',
  placeOfLossDamage: 'Place of the Loss / Damage',
  reportedPoliceStation: 'Reported police station / common carrier / hotel, etc. and its contact information',
  firstReportedPlace: '1st Reported Place',
  reportedPlace: 'Reported place',
  contactInformation: 'Contact information',
  addAReportedPlace: 'Add a Reported Place',
  howTheLossDamageOccurredDiscovered: 'How the Loss / Damage Occurred or Discovered (e.g. where the property was placed and where, when and how the loss was discovered)',
  lostDamagedItems: 'Lost / Damaged Items',
  firstLostDamagedItem: '1st Lost / Damaged Item',
  dateWhenLostDamagedItems: 'Date when Lost / Damaged Items',
  OriginalPurchaseValue: 'Original Purchase Value',
  pleaseFillInTheAmount: 'Please fill in the amount',
  replacementRepairCost: 'Replacement / Repair Cost',
  addALostDamagedItem: 'Add a Lost / Damaged Item',
  lossDamageReportIssuedByTheRelevantAuthorities: 'Loss / damage report(s) issued by the relevant authorities or organizations (e.g. police, airline, hotel. etc.)',
  photosShowingTheExtent: 'Photos showing the extent of damage to the property, if applicable',
  purchaseReceiptRepairQuotation: 'Purchase receipt, repair quotation, replacement invoice, etc. where applicable',
  compensationBreakdownFromOtherInsurers: 'Compensation breakdown from other insurers/responsible parties (e.g. airline), if applicable',

  journeyCancellation: 'Journey Cancellation and Curtailment and Re-Route',
  journeyCancellationClaimDetails: 'Journey Cancellation and Curtailment and Re-Route Claim Details',
  dateOfOriginalSchedule: 'Date of Original Schedule',
  locationOfOriginalSchedule: 'Location of Original Schedule',
  dateOfCCRJourney: 'Date of Curtailed / Cancelled / Re-arranged Journey',
  locationOfCCRJourney: 'Location of Curtailed / Cancelled / Re-arranged Journey',
  reasonForJourneyCCR: 'Reason for Journey Cancellation / Curtailment / Re-route',
  ifTheJourneyCurtailmentIsDueTo: 'If the journey curtailment/cancellation is due to death, serious injury or sickness person/immediate family member/close business partner, please state clearly the following',
  fullNameOfTheSickPerson: 'Full name of the sick/injured/deceased person',
  relationshipToTheInsuredPerson: 'Relationship to the insured person',
  diagnosis: 'Diagnosis',
  receiptShowingAnyPrepaidCosts: 'Receipt(s) showing any pre-paid costs or deposits made OR additional travel and/or accommodation expenses incurred after the commencement  Journey',
  documentationIssuedByTravelAgent: 'Documentation issued by travel agent or common carrier confirming "trip cancellation" and "non-refundable/refunded amount"',

  travelBaggageDelay: 'Travel Delay and Baggage Delay',
  travelBaggageDelayDetails: 'Travel Delay and Baggage Delay Claim Details',
  reasonForDelay: 'Reason for Delay',
  flightDelayed: 'Flight Delayed',
  missedConnection: 'Missed Connection',
  baggageDelayed: 'Baggage Delayed',
  smallOthers: 'others',
  whichAirport: 'Which Airport?',
  delayedFlightNo: 'Delayed Flight No.',
  dateNTime: 'Date & Time',
  pleasePickADateNTime: 'Please pcik a date and time',
  emergencyEssentialItemsPurchased: 'Emergency Essential items Purchased (if applicable)',
  firstItem: '1st item',
  addAPurchasedItem: 'Add a Purchased Item',
  datePurchased: 'Date Purchased',
  pricePaid: 'Price Paid',
  documentationIndicatingTheReasonForDelay: 'Documentation indicating the reason(s) for and the number of hours of delay (e.g. confirmation from common carrier/travel agent)',

  personalAccident: 'Personal Accident (Fatal / Permanent Disability / Burns Benefit)',
  personalAccidentClaimDetails: 'Personal Accident Claim Details',
  dateOfAccident: 'Date of Accident',
  timeOfAccident: 'Time of Accident',
  placeOfAccident: 'Place of Accident',
  describeHowTheAccidentOccurred: 'Describe how the accident occurred, and the injuries sustained',
  nameAndAddressOfTheAttendingDoctor: 'Name and Address of the Attending Doctor',
  permanentDisabilityDegreeNExtent: 'Permanent Disability (Degree & Extent), if applicable',
  causeOfDeath: 'Cause of Death, if applicable',
  nameAndContactNumberOfWitness: 'Name and Contact Number of Witness(es), if any',
  contactNumber: 'Contact Number',
  accidentReportIssuedByRelevantAuthorities: 'Accident report(s) issued by relevant authorities/organizations (e.g. police, hotel, common carrier, activity organizer, etc.), if any',
  medicalReportStatingTheDateOfAttendance: 'Medical report stating the date of attendance and extent of injuries sustained as certified by a Qualified Medical Practitioner, if applicable',
  copyOfDeathCertificate: 'Copy of Death Certificate/Post Mortem Report, if applicable',

  careVisit: 'Care Visit',
  careVisitClaimDetails: 'Care Visit Claim Details',
  nameOfVisitingFamilyMember: 'Name of Visiting Family Member',
  durationOfVisit: 'Duration of Visit',
  proofOfRelationship: 'Proof of relationship',
  receiptsOfTravelingAccommodationExpensesIncurred: 'Receipts of traveling and/or accommodation expenses incurred',

  personalLiability:'Personal Liability',
  personalLiabilityClaimDetails: 'Personal Liability Claim Details',
  dateOfTheIncident: 'Date of the Incident',
  timeOfTheIncident: 'Time of the Incident',
  pleasePickTime: 'Please pcik time',
  placeOfTheIncident: 'Place of the Incident',
  fullNameOfTheThirdPartyClaimant: 'Full Name of the Third Party Claimant',
  contactOfTheThirdPartyClaimant: 'Contact of the Third Party Claimant',
  fullNameOfWitness: 'Full name of Witness(es), if any',
  contactOfWitness: 'Contact of Witness(es), if any',
  copyOfIncidentReportFromRelevantParties: 'Copy of incident report(s) from relevant parties (e.g. Police and other local authorities, hotel, sports centre, etc.)',
  claimLetterAndInvoice: 'Claim letter and invoice',
  otherDocuments: 'Other documents - please state',
  importantNotes: 'Important Notes:',
  anyLawsuit: 'Any lawsuit, demand, claim or proceeding of any types relating to the incident of which the Claimant becomes aware of, and received from the third party claimant, should be immediately forwarded to the Company.',
  noLiabilityShould: 'No liability should be admitted or no settlement or promise of payment should be reached or made to the third party without the prior consent of the insurance company.',

  others: 'Others',
  othersClaimDetails: 'Others Claim Details',
  descriptionOfTheAccident: 'Description of the Accident',

  otherInsurance:'Other Insurance',
  otherInsuranceCoverage: 'Do you have other insurance coverage?',
  yes:'Yes',
  nameOfInsurer: 'Name of Insurer',
  typeOfCoverage: 'Type of Coverage',
  policyEffectiveDate: 'Policy Effective Date',
  no:'No.',
  submit: 'Submit',
  pleaseWait: 'Please Wait',

  isNotAValidHKID: 'is not a valid HKID.',
  CityUniversityOfHongKong: 'City University of Hong Kong',
  uowCollegeHongKong: 'UOW College Hong Kong',
  needsToBeInEnglish: 'needs to be in English',
  thisFieldCannotBeBlank: 'This field cannot be blank',
  applicationJson: 'application/json',
  congratulations: 'Congratulations',
  youHaveSuccessfully: 'You have successfully submitted your claim!',
  yourClaimApplication: 'Your claim application has been received.',
  failedOnSubmitting: 'Failed on submitting this claim!',
  messageCode: 'Message Code: ',
  facingErrorWhenSubmitting: 'Facing error when submitting this claim!',
  errorMessage: 'Error message: ',
  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  policyholderThe: 'Policyholder / The',
  policy: 'Policy ',
  number: 'Number',
  certificate: '/ Certificate ',
  employerName: '/ Employer Name',
  staffStudentNumber: 'Staff / Student Number',
  occupationPosition: 'Occupation / Position',
  stationedCityCountry: 'Stationed City / Country (if applicable)',
  homeResidingCountry: 'Home & Residing Country',
  dateOfSecondment: 'Date of Secondment to Stationed City / Country (if applicable)',
  purposeOfTrip: 'Purpose of Trip',
  business: 'Business',
  accidentHappen:'How did the accident happen?',
  symptomsFirstAppear: 'When did the symptom(s) first appear?',
  pleasePickTime: 'Please pick time',
  pleaseFillInDetails: 'Please fill in details',
  relationshipToInsuredPerson: 'Relationship to Insured Person',
  documentShowingTheStudentIdentity: 'Document showing the student identity of the overseas education, such as the Student Identification Card &/or admission letter',
  medicalCertificateIndicatingDiagnosis: 'Medical certificate indicating diagnosis and reason that the insured person/immediate family member/close business partner is unfit for travel, if applicable',
  deathCertificate: 'Death certificate, if applicable',
  proofOfRelationship: 'Proof of relationship, if applicable',
  item:' Item',
  lostDamagedItem: 'Lost / Damaged Item',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  fieldsMissing: 'Fields Missing',
  someBlankFields: 'There are some blank fields. Do you still want to submit your claim?',
  resumeFilling: 'Resume Filling',

  thankYou: 'Thank You',
  submitted: 'Your claim has been submitted.',
  claimReferenceNumber: 'Claim reference number: ',
  cancel: 'cancel',

  specialRequests: 'Others / Special Request',

  declaration: 'Declaration',
  declaration1: 'I/We declare that the above information is in all respect true and complete to the best of my/our knowledge and belief.',
  declaration2: 'I/We, the claimant, hereby authorize any hospital, physician, clinic, or other person/authority/organization, to furnish to Assicurazioni Generali S.p.A. - Hong Kong Branch (“the Company”) or its authorized representative, any and all information with respect to my loss, injury or illness, medical history, consultation, prescriptions or treatment and copies of police reports, incident reports, statement and all hospital or medical records.',
  declaration3: 'I hereby agree that all documents and receipts submitted to the Company will not be returned.',
  declaration5: 'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  declaration6: 'I/We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the application',
  reservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to request the claimants to submit the original copies of the supporting documents. Please keep the originals for at least 3 months after submitting the claim.',

  ok: 'OK',
}
