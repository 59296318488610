import React, { PureComponent, Component } from 'react';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { TimePicker } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import { DateTimePicker } from 'material-ui-pickers';
import { DateRange } from 'react-date-range';

export default class CalendarPicker extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }
  state = {
    selectedDate: new Date(),
  };

  render() {

    return (
      <DateRange
          onInit={this.handleSelect}
          minRangeDuration={1}
          allowRangeSelection={true}
          rangedCalendars={true}
          //twoStepChange={false}
          twoStepChange={this.props.twoStepChange}
          linkedCalendars={true}
          calendars={2}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          startDate={moment().add(1, 'months')}
          endDate={moment().add(1, 'months')}
          onChange={(range) => {
            this.props.onDateChange(moment(range.startDate), moment(range.endDate));
          } }
      />
    );
  }
}
