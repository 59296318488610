import React from 'react';
import { Text,TextInput, ImageBackground, View, ScrollView, TouchableOpacity, SafeAreaView,Linking,KeyboardAvoidingView } from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';
import Alert from './../../components/Alert/Alert';
import ListMenu from './../../components/ListMenu/ListMenu.js';
import { host } from './../../config/api';
import { memberStorage } from './../../lib/MemberStorage';
import { platform } from './../../config/platform.js';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';

import Member from './../../Member';

export default class RoarUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      width: 0, 
      height: 0 
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.scrollTo(0, 0)
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }));

    memberStorage.getMember((member, token) => {
      this.setState({member: member});
    });

    this.setState({version: platform.version});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount(){
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  _next() {
      this.setState({
        alert: Alert.alert(
          this.state.text.alert,
          this.state.text.please,
          [{
            text: this.state.text.ok,
            onPress: () => this.setState({alert: null}),
          }],
        ),
      });

  }


  render() {
    const color = Member.getThemeColor();

    
  
    const customerSupportInfo = () =>{
        return <View style={styles.customerService}>
            <Text style={[{fontWeight:'bold',fontSize:'16px'}]}>{this.state.text.customerServiceSupport}</Text>
            <p>{this.state.text.serviceTime}</p>
            <p>{this.state.text.hotline}<a href={"tel:+"+this.state.text.actualHotlineNum} style={{pointerEvents:this.state.width>600?'none':'auto'}}>{this.state.text.hotlineNum}</a></p>
            <p>{this.state.text.wts}<a href={"https://wa.me/"+this.state.text.actualWtsNum} style={{pointerEvents:this.state.width>600?'none':'auto'}}>{this.state.text.wtsNum}</a></p>
            <p >{this.state.text.email}<a href= {"mailto:"+this.state.text.companyEmail}>{this.state.text.companyEmail}</a></p>
            <br/>
            <Text style={[{fontWeight:'bold',fontSize:'16px'}]}>{this.state.text.NeedToMakeClaim}</Text>
            <p>{this.state.text.followingLink}</p>
            <a style={{
                display: 'inline-block',
                maxWidth: '100%',
                overflow: 'hidden',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                verticalAlign: 'text-bottom'
                }} 
              href={this.state.text.followingLinkText}>{this.state.text.followingLinkText}</a>
            <p>{this.state.text.claimContactText}<a href= {"mailto:"+this.state.text.claimContactEmail}>{this.state.text.claimContactEmail}</a></p>
          </View>  
    };

    return (
      <div className='App'>
        <Helmet>
          <title>Roar Us | Bravo Generali</title>
        </Helmet>

      <Navbar
        title={this.state.text.roarUs}
        backButton
        history={this.props.history}
      />

      <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
      <Hidden xsDown>
        <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.contact}</Text>
              <p></p>
              {/*<Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.help}</Text>*/}
              {customerSupportInfo()}
            </View>
        </Grid>
      </Hidden>

        <Grid item xs style={{
          /*marginTop:30*/
            marginTop:70,
            marginBottom:50
          }}>
            {this.state.alert}
            <View style={styles.container, styles.fieldset}>
              <View style={styles.jumbotron}>
                <ImageBackground source={require('./Roar-Us.png')} style={styles.jumbotronImage}>
                  <Text style={styles.jumbotronText}></Text>
                </ImageBackground>
              </View>
        
              <View style={styles.menu}>
                <SafeAreaView style={{flex: 1}}>
                  <View style={{flex: 1}}>
                    <ScrollView>
                     <TouchableOpacity onPress={() => this._next()}>
                        <View style={styles.menuItem} >
                          <Text style={[styles.jumbotronText, {color}]} >{this.state.text.phone}</Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => Linking.openURL('mailto:bravo@generali.com.hk')}>
                        <View style={styles.menuItem} >
                          <Text style={[styles.jumbotronText, {color}]} >{this.state.text.email}</Text>
                        </View>
                     </TouchableOpacity>
                    </ScrollView>
                  </View>
                </SafeAreaView>
              </View>
            </View>

            {this.state.width <= 600 && <Grid> <br/>
                  <View style={[styles.greeting,{fontWeight:'bold', paddingRight:'10px', paddingLeft:'10px'}]}>
                    <Text style={{fontSize:'20px'}}>{this.state.text.contact}</Text>
                    <p></p>
                    {/*<Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.help}</Text>*/}
                    {customerSupportInfo()}
                  </View>
                </Grid>
              }
          
        </Grid>

          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
      <Footer/>
    </div>
    );
  }
}

const styles = {
  
  customerService:{
    textAlign:'left',
    fontWeight:'200',
    fontSize:'16px'
  },
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  jumbotron: {
    flex: 2,
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto',
  },
  jumbotronText: {
    color: '#C50000',
    marginLeft: 40,
    marginTop: 15,
  },
  menu: {
    flex: 2,
    display:'none',
    pointerEvent: 'none',
  },
  menuItem: {
    height: 50,
    flexDirection: 'row',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
    pointerEvent: 'none',
  },
  demomenuItem: {
    height: 500,
    backgroundColor: 'white',
  },
  menuItemText: {
    flex: 6,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    //minHeight: 620,
    minHeight: 420,
  },
};
