import React from 'react';
import { StyleSheet, Text, ImageBackground, View } from 'react-native';

export default class Jumbotron extends React.Component {
  render() {
    const {source, children} = this.props;

    return (
      <View style={styles.jumbotron}>
        <ImageBackground source={source} style={styles.jumbotronImage}>
          <Text style={styles.jumbotronText}>
            {children}
          </Text>
        </ImageBackground>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  jumbotron: {
    flex: 1,
    minHeight: 200,
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  jumbotronText: {
    marginLeft: 10,
    marginBottom: 10,
  },
});
