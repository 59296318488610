import React from 'react';
import {View, Text} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, DropDownList, Checkbox, Button} from './../../Form';
import AHIFScreen from './AHIFScreen';
import LegalStatement from './../components/LegalStatement';
import MobileNumberOfTheInsuranceAdvisor
  from './../components/MobileNumberOfTheInsuranceAdvisor';
import EffectiveDate from './../components/EffectiveDate';
import DiscountInput from './../components/DiscountInput';
import PolicyHolderForm from './../components/PolicyHolderForm';
import MembersForm from './MembersForm';
import SpecialRequest from './../components/SpecialRequest';
import ReferralCode from './../components/ReferralCode';

export default class AHIFScreen3 extends AHIFScreen {
  render() {
    const {pl, agent, policy, policyHolder, ahif, members, payment}
      = this.state.insuranceProduct;
   
    return(
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getInsured}
        />

        {Locale.text.ahiInsurance}
        {''}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.ahiInsurance}
            </Heading1>

            <LegalStatement
              broker={this.state.broker}
              agent={this.state.agent}
            />

            <Text>
              {Locale.text.pleaseInputInEnglish}
            </Text>

            <MobileNumberOfTheInsuranceAdvisor
              broker={this.state.broker}
              value={agent.mobile}
              onChange={(mobile) => this.updateAgent({mobile})}
              messages={this.state.messages.agent}
            />

            <DropDownList
              label={Locale.text.productType}
              options={this.premiumPlanOptions}
              value={ahif.premiumPlan}
              onChange={(premiumPlan) =>
                this.updateAHIF({premiumPlan})}
            />

            <EffectiveDate
              value={policy.effectiveDateStart}
              onChange={(effectiveDate) => this.updatePolicy(effectiveDate)}
              messages={this.state.messages.policy}
            />
{/* 
            <DiscountInput
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={!this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            /> */}

            <PolicyHolderForm
              minDate={moment().subtract(71, 'years').add(1, 'day')}
              noAddress
              policyHolder={policyHolder}
              onChange={(data) => {
                if (this.state.policyholderIsInsured) {
                  this.updateMembers(0, data);
                }
                this.updatePolicyHolder(data);
              }}
              messages={this.state.messages.policyHolder}
            />

            <Checkbox
            disabled={true}
              label={Locale.text.policyholderIsTheInsured}
              value={this.state.policyholderIsInsured}
              onChange={(policyholderIsInsured) =>
                this.setState({policyholderIsInsured}, () =>
                  this.updateMembers(0, policyholderIsInsured == 'Y' ?
                    {
                      salutation: policyHolder.salutation,
                      firstName: policyHolder.firstName,
                      lastName: policyHolder.lastName,
                      documentType: policyHolder.documentType,
                      documentNo: policyHolder.documentNo,
                      dateOfBirth: policyHolder.dateOfBirth,
                    } :
                    {
                      salutation: '',
                      firstName: '',
                      lastName: '',
                      documentNo: '',
                      dateOfBirth: '',
                    }
                  )
                )
              }
            />

            <MembersForm
              policyholderIsInsured={this.state.policyholderIsInsured == 'Y'}
              policyHolder={policyHolder}
              ahif={ahif}
              members={members}
              onChange={(index, data) => this.updateMembers(index, data)}
              messages={this.state.messages.members}
            />

            {/* <SpecialRequest
              value={policy.specialRequest}
              onChange={(specialRequest) =>
                this.updatePolicy({specialRequest})}
              messages={this.state.messages.policy}
            />

            <ReferralCode
            value={policy.referralCode}
              onChange={(referralCode) =>
                this.updatePolicy({referralCode})}
              messages={this.state.messages.referralCode}>
            </ReferralCode> */}

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('ahif4')}
            >
              {Locale.text.buyNow}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
