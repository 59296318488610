import React from 'react';
import {
  AsyncStorage, Dimensions, Text, Image,
  View, ScrollView, TouchableOpacity, Switch, Linking,
} from 'react-native';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import TextInput from './../../components/TextField/TextField';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';
import { checkVersion } from './../../lib/utils';
import { platform } from './../../config/platform';
import { host } from './../../config/api';

import Member from './../../Member';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };

    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        checkVersion(this.state.language);
      })
    );
  }


  render() {
    const backgroundColor = Member.getThemeColor();

    return (
      <div>
          <footer className='App-footer'>

          <div style={{backgroundColor, padding: 20}}>
          <Container>
           <Row>

             <Col xs="auto" sm={3} style={{textAlign:'left',fontSize: 12,}}>
               {this.state.text.personCollection}
             </Col>

             <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 12,}}>
               {this.state.text.legalInformation}
             </Col>

             <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 12,}}>
               {this.state.text.Privacy}
             </Col>


             <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 13}}>
             {this.state.text.phone}

             </Col>

             <Col xs="auto" sm={3} style={{textAlign:'left',fontSize: 12,gutterWidth:20}}>
             <TouchableOpacity onPress={() => Linking.openURL('mailto:bravo@generali.com.hk')}>

                 <Text style={{fontSize: 13, color: 'white'}}>{this.state.text.email}</Text>

             </TouchableOpacity>
             </Col>

             <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 7,}}>
               {this.state.text.copy}
             </Col>

             <Col xs="auto" sm={1} style={{textAlign:'left',fontSize: 12,}}>
             </Col>
             <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 5,}}>
               {this.state.text.reserved}
             </Col>

             <Col xs="auto" sm={1} style={{textAlign:'left',fontSize: 12,}}>
             </Col>
             <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 5,}}>
               {this.state.text.group}
             </Col>

           </Row>
          </Container>
        </div>

          </footer>
        </div>
    );
  }
}

const styles = {
  //
};
