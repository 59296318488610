import React, {useEffect} from "react";
import {
  Text,
  View,
} from "react-native";
import { useState } from "react";
import { formStyles } from "../../config/styles";

import { languageStorage } from "../../lib/LanguageStorage";
import { enUS } from "../../screens/InstantEnrollment/IPA/en-US";
import { zhHK } from "../../screens/InstantEnrollment/IPA/zh-HK";

import DatePicker from "../../components/DatePicker/DatePicker";
import ModalSelector from "../../components/ModalSelector/ModalSelector";
import TextInput from "../../components/TextField/TextField";
import NameInput from "./../../components/NameInput/NameInput";
import moment from "moment";
import YesNoQuestion from "../../components/YesNoQuestion/YesNoQuestion";
import RelationshipForm from "./RelationshipForm";

const Insured = ({ type, insured, number, isEditable, formatName, messages, people, occupationsList, onInsuredChange, options, effectiveDateStart}) => {
  const [translation, setTranslation] = useState({});
  const [language, setLanguage] = useState("");
  const [insuredData, setInsuredData] = useState(insured);

  const getTranslations = (language) => {
  return language === "zhHK" ? zhHK : enUS;
  };

  useEffect(() => {
    setInsuredData(insured)
    languageStorage.getLanguage((language) => {
      const translations = getTranslations(language);
      setLanguage(language);
    setTranslation(translations);
    });
  }, [insured, insuredData, effectiveDateStart, messages, isEditable]);

  const handleInputChange = (text) => {
    insuredData.documentNo = text
    onInsuredChange(insuredData);
  };

  const _getTitle = () => {
    let ordinalNumber;
    if(type == "children" || people == "group"){
      number += 1
    }

    if (number == 1) {
      ordinalNumber = '1st ';
    }
    else if (number == 2) {
      ordinalNumber = '2nd ';
    }
    else if (number == 3) {
      ordinalNumber = '3rd ';
    }
    else {
      ordinalNumber = number + 'th ';
    }
    if (language == 'zhHK') {
      ordinalNumber = `第 ${number} 位`
    }
    if (number) {
      return ordinalNumber + (type == "children" ? translation.child : translation.insured);
    }
    return translation.insured;
  }

  return (
    <View>
      <View style={formStyles.hr} />
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h3}>{_getTitle()}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{translation.name}</Text>
          { !isEditable && 
            <TextInput
            style={formStyles.input}
            value={formatName(insuredData.salutation, insuredData.firstName, insuredData.lastName)}
            editable={isEditable}
            />
          }
          { isEditable && 
            <NameInput
              title={insuredData.salutation}
              firstName={insuredData.firstName}
              lastName={insuredData.lastName}
              onChange={(title, firstName, lastName) => {
                insuredData.salutation = title != insuredData.salutation ? title : insuredData.salutation
                insuredData.firstName = firstName != insuredData.firstName ? firstName : insuredData.firstName
                insuredData.lastName = lastName != insuredData.lastName ? lastName : insuredData.lastName
                onInsuredChange(insuredData)
              }}
            />
          }
        <Text style={formStyles.message}>{messages ? (messages.firstName || messages.lastName || messages.salutation): ""}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{translation.dateOfBirth}</Text>
        {
          type == "children" ?
          // 1-17 years old
          <DatePicker
            style={formStyles.datepicker}
            date={insuredData.dateOfBirth}
            mode="date"
            placeholder={translation.pleasePickADate}
            format="MMMM D, YYYY"
            disabled={!isEditable}
            minDate={moment(effectiveDateStart)
              .subtract(18, "years")
              .add(1, 'days')
              .format("MMMM D, YYYY")}
            maxDate={moment(effectiveDateStart)
              .subtract(1, "years")
              .format("MMMM D, YYYY")}
            onDateChange={(date) => {
              insuredData.dateOfBirth = date
              onInsuredChange(insuredData)
            }}
          />
        :
          //Adult 18-65 years old
          <DatePicker
            style={formStyles.datepicker}
            date={insuredData.dateOfBirth}
            mode="date"
            placeholder={translation.pleasePickADate}
            format="MMMM D, YYYY"
            disabled={!isEditable}
            minDate={moment(effectiveDateStart)
              .subtract(66, "years")
              .add(1, 'days')
              .format("MMMM D, YYYY")}
            maxDate={moment(effectiveDateStart)
              .subtract(18, "years")
              .format("MMMM D, YYYY")}
            onDateChange={(date) => {
              insuredData.dateOfBirth = date
              onInsuredChange(insuredData)
            }}
          />
        }

        <Text style={formStyles.message}>{messages ? messages.dateOfBirth : ""}</Text>
      </View>
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{translation.hkid}</Text>
        <TextInput
          style={formStyles.input}
          onChangeText={handleInputChange}
          value={insuredData.documentNo}
          placeholder={translation.a1234567}
          editable={isEditable}
        />
        <Text>{translation.pleaseEnterAllHKID}</Text>
        <Text style={formStyles.message}>{messages ? messages.documentNo : ""}</Text>
      </View>
      { type != "children" &&
        <View style={formStyles.fieldset}>
          <Text style={formStyles.h4}>{translation.occupation}</Text>
          <ModalSelector
            data={occupationsList}
            initValue={
              insuredData.occupation
                ? occupationsList.find((obj) => obj.key == insuredData.occupation).label
                : ""
            }
            onChange={(option) => {
              insuredData.occupation = option.key
              onInsuredChange(insuredData)
            }}
            cancelText={translation.cancel}
          />
          {(insuredData.insuredOccupation === "OC3" ||
            insuredData.insuredOccupation === "OC4") && (
            <Text>{translation.manualUnderwritingsIsRequired}</Text>
          )}
        </View>
      }
      <View style={formStyles.fieldset}>
        <Text style={formStyles.h4}>{translation.relationship}</Text>
        <RelationshipForm
          data={[
            {
              key: "insured",
              label: options.relationship.insured[language]
            },
            {
              key: "spouse",
              label: options.relationship.spouse[language]
            },
            {
              key: "child",
              label: options.relationship.child[language]
            },
            {
              key: "parents",
              label: options.relationship.parents[language]
            },
            {
              key: "parInLaw",
              label: options.relationship.parInLaw[language]
            },            
            {
              key: "employee",
              label: options.relationship.employee[language]
            },
          ]}
          initValue={isEditable ? insuredData.relationship : "insured"}
          onChange={(option) => {
            insuredData.relationship = option.key
            onInsuredChange(insuredData)
          }}
          cancelText={translation.cancel}
          disabled={type == "children" || !isEditable}
        />
        <Text style={formStyles.message}>{messages ? messages.relationship : ""}</Text>
      </View>
    </View>
  );
};

export default Insured;
