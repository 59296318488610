export const enUS = {
needsToBeInEnglish: 'Needs to be in English',
pleaseLogin: 'Please Login',
pleaseLoginBeforeYouSubmitClaims: 'Please login before you submit claims.',
loading: 'Loading...',
login: 'Login',
logIn: 'Log in',
loginExpired: 'Login Expired',
forBetterCustomerExperiencelogin:'Log in',
forBetterCustomerExperiencregister:'Register',
forBetterCustomerExperienceCon:'Continue without log in',
yourLogin: 'Your login has expired. Please login again.',
codeBySMS: 'Resend six-digit code by SMS',
codeByEmail: 'Resend six-digit code by email',
continue: 'Continue',
return: 'Return',
ok: 'OK',
applicationDeclined: 'Application declined',
reInput: 'Re-input',
backHome: 'Go Back Home',
buyAgain: 'Buy Again',
roarUs: 'Roar Us',
privilegedClub: 'Privilege Club',
cancel: 'cancel',
alert: 'Alert',
register: 'Register',
name: 'Name',
title: 'Title',
mr: 'Mr.',
ms: 'Ms.',
mrs: 'Mrs.',
miss: 'Miss',
firstName: 'First Name',
lastName: 'Last Name',
dateOfBirth: 'Date of Birth',
mobileNumber: 'Mobile Number',
emailAddress: 'Email Address',
mustBeTheTraveler: '* The policyholder must be the traveler *',
policyholderIs: 'Policyholder is ',
oneOfTheTravelers: 'one of the travelers',
theTraveler: ' the traveler',
firstAdult: '1st Adult',
insured: 'Insured',
relationshipWithThePolicyholder: 'Relationship with the Policyholder',
immediateFamily: 'Immediate Family',
hkidOrPassportNumber: 'HKID /Passport Number',
hkid: 'HKID',
passport: 'Passport',
pleaseEnterAllCharacters: 'Please enter all characters in your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
specialRequests: 'Special Requests',
referralCode: 'Referral Code',
thisFieldCannotBeBlank: 'This field cannot be blank',
invalidPromotionCode: 'Invalid promotion code',
addInsuranceAdvisor: 'Add Insurance Advisor',
wouldYouLike: 'Would you like ',
toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
check: 'Please check your inputs again.',
theInsuranceIsIssuedByContent: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
notValidEmail:'It is not a valid email address',
notANumber:'This field only accepts numbers',
notValidHKID:'It is not a valid HKID',
yes: 'Yes',
no: 'No',
submissionFailed: 'Submission failed',
invalidMobileNumber: 'This is not a valid mobile number',
theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
professionalInsurance: '.',
insuranceAdvisorMobile: 'Mobile Number of the Insurance Advisor',
brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay ',
brokerDeclarationII:' commission and provide my/our personal data to the said broker/agent for arranging and managing the said policy.',
continueWithoutLogIn: 'Continue without log in',
forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
getAQuote: 'Get a Quote',
productEdges: 'Product Edges',
promotionCodes: 'Promotion Codes',
optional: 'Optional',
businessEmailAddress: 'Business Email /Membership',
businessEmailMembership: 'Business Email\n/Membership',
periodOfInsurance: 'Period of Insurance',
iWouldLikeToBuy: 'I would like to buy',
levyIsNotIncluded: 'Levy is not included',
buyNow: 'Buy Now',
getInsured: 'Get Insured',
pleaseInputInEnglish: '*Please input in English*',
productType: 'Product Type',
effectiveDate: 'Effective Date',
pleasePickADate: 'Please pick a date',
pleaseChoose: 'Please choose',
importantNotes: 'Important Notes:',
importantNote: 'Important Notes:',
payment: 'Payment',
youHave: 'You have ',
eCashNow: 'Generali voucher now. Would you want to use it?',
youHaveGeneraliVoucherNow: 'You have Generali voucher now. Would you want to use it?',
levy: 'Levy',
total: 'Total',
submit: 'Submit',
bothDatesInclusive: '(both dates inclusive)',
pleaseWait: 'Please wait',
thankYou: 'Thank You',
youHaveSuccessfully: 'You have successfully submitted your application of this ',
ccccc: '.',
manualUnderwritingIsNeeded: ' Manual underwriting is needed and we will get back to you within two business days.',
manualUnderwritingIsRequired:'* Manual underwriting is required *',
manualUnderwriting: 'Manual underwriting is needed',
needsManualUnderwriting: ' and needs manual underwriting.',
inCaseOfAnyQuestions: ' In case of any questions, you may chat with us in the app in section Roar Us.',
congratulations: 'Congratulations',
madeAPurchase: 'You have successfully made a purchase. ',
weWillSend: 'We will send the policy schedule and policy provisions to you via email ',
anyQuestions: '. If you have any questions, please contact us.\nThank you.',
annualStudyAbroadProtector: 'Annual Study Abroad Protector',
annualStudyAbroadGenerali: 'Generali Annual Study Abroad Protector Insurance',
ussstudyplan: 'US Study Plan',
address: 'Address',
confirm: 'Confirm',
iWeDoNotConsentPDto:'I /We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
iWeDoNotConsentPDToCompanyMarketing:'I /We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
spouse: 'Spouse',
insuredName: 'Insured Name',
centalineLandlordProtector: 'Centaline Landlord Protector',
forDetails: 'For details of the product, exculsions and premium rates, please refer to Policy Provisions.',
policyHolder: 'Policyholder',
pleaseEnterAllHKID:'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
size:'Size',
house:'House',
theHomeIsUnoccupiedForLonger30Days:'The home is unoccupied for longer than 30 consecutive days',
anyLossDamageLiabilityOrAccident:'Any loss, damage, liability or accident occurred during the past 12 months',
allCoveragesAreSubject: 'All coverages are subject to the terms and conditions of the policy.',
occupation: 'Occupation',
planType: 'Plan Type',
executivePersonalAccidentProtector: 'Executive Personal Accident Protector',
travelInsurnace: 'Travel Insurance',
gereraliTravelPackageInsurance: 'Generali Travel Package Insurance',
homeInsurance: 'Home Insurance',
homeScreenInstantEnrollment: 'Home',
homePackageInsurance: 'Home Package Insurance',
homePackageInsuranceLeftPanel: 'Protect your valuable investments.',
fireInsurance: 'Fire Insurance',
fireInsuranceLeftPanel: 'Protect your home from losses due to fire.',
domesticHelperInsurance: 'Domestic Helper Insurance',
domesticHelperInsuranceLeftPanel: 'Protect your helper’s well-being and cover your legal responsibilities.',
accidentInsurance: 'Accident Insurance',
generaliPersonalAccidentInsurancePlan: 'Generali Personal Accident Insurance Plan',
affinityProduct: 'Affinity Product',
cityU: 'Travel Program (City University of HK)',
uow: 'Travel Program (UOW College HK)',
centalineHomeProtector: 'Centaline Home Protector',
centalineTravelProtector: 'Centaline Travel Protector',
centalineDomesticHelperProtector: 'Centaline Domestic Helper Protector',
travelInsurance: 'Travel Insurance',
claimThankYou: 'Thank You',
submitted: 'Your claim has been submitted. ',
claimReferenceNumber: 'Claim reference number: ',
chat: 'Chat',
email: 'Email',
policyProvision: 'Policy Provision',
insuranceAdvisor: 'Insurance Advisor',
qrcodescanner: 'QR Code Scanner',
history: 'History',
instantClaim: 'Instant Claim',
settings: 'Settings',
forDetailsOfTheProduct:'For details of the product, exclusions and premium rates, please refer to Product Leaflets and Policy Provisions ',
daysdays: 'days',
pleaseFill: 'Please fill in the amount',
others: 'Others',
openPhoto: 'Open photo',
premium: 'Premium',
roundedToTheNearestPercentage: 'Rounded to the nearest percentage',
medicalExpenses: 'Medical Expenses /Permanent Total or Partial Disablement /Accidental Hospital Cash',
personalAccident: 'Personal Accident',
currency: 'Currency',
sex:'Sex',
male:'Male',
female:'Female',
referenceCode: 'Reference Code',
assicurazioniGeneraliSpAReservesTheRightTo:'Assicurazioni Generali S.p.A reserves the right to accept or decline any application.',
iCerifyThatIHaveReadAndAgreedDisclaimerAndPersonal:'I /We certify that I /we have read and agreed to the Disclaimer and Personal Information Collection Statement',
iWeDoNotConsentPDto3PartyMarketing:'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
individual: 'Individual',
family: 'Family',
first: '1st ',
second: '2nd ',
third: '3rd ',
ordinal: '',
th: 'th ',
child: 'Child',
addAChild: 'Add a Child',
questions: 'Questions',
correspondenceAddress: 'Correspondence Address',
theInsured:'the insured',
oneOfTheInsureds: 'one of the Insureds',
sufferedArea:'Suffered Area',
diagnosis:'Diagnosis',
iWeCertifyDisclaimerAndPICS:'I /We certify that I /we have read and agreed to the Disclaimer and Personal Information Collection Statement.',
houseOrApartment:'House /Apartment',
apartment:'Apartment',
anyInsuranceCompnayDeclined:'Any Insurance company declined your proposal, cancelled or refused to renew your policy or imposed special terms and conditions for the insurance?',
generaliTravelPackageInsurance: 'Generali Travel Package Insurance',
bravoTravelProtector: 'Bravo Travel Protector',
bravoStorageProtector: 'Bravo storage protector',
bravoMobileDeviceProtector: 'Bravo Mobile Device Protector',
insuredDetails: 'Insured Details',
policyNumber: 'Policy Number',
policyholderName: 'Policyholder Name',
hkidNumberPassNumber: 'HKID card Number /Passport Number',
claimDetails: 'Claim Details',
timeOfAccident: 'Time of Accident',
pleasePickTime: 'Please pick time',
placeOfAccident: 'Place of Accident',
descriptionOfTheAccident: 'Description of the Accident',
wasTheAccidentReported: 'Was the accident reported to the Police?',
dateOfFirstConsultation: 'Date of First Consultation',
documentsAttached: 'Documents Attached',
otherInsurance: 'Other Insurance',
doYouHaveOtherInsurance: 'Do you have other insurance coverage?',
nameOfInsurer: 'Name of Insurer',
typeOfCoverage: 'Type of Coverage',
policyEffectiveDate: 'Policy Effective Date',
claimYes:'yes',
claimNo:'no',
yourClaimApplication: 'Your claim application has been received.',
messageCode: ' Message Code: ',
errorMessage: 'Error message: ',
facingErrorWhenSubmitting: 'Oops! There are some problems during the photo submissions. Can you please try again? Or you may call our hotline at 6682-1112 and quote your claim reference number: xxx',
isNotAValidHKID: 'is not a valid HKID.',
typeOfClaim: 'Type of Claim',
allowBothEnglishAndChinese: 'Allowing both English and Chinese inputs for claim details.',
claimNotValidEmail:'It is not a valid email address',
claimNotValidHKID:'It is not a valid HKID',
hospitalMedicalBillsReceiptReport: 'Hospital /medical bill(s) /receipt(s) /medical report(s)',
accidentalDeath: 'Accidental Death',
deathCertificate: 'Death certificate',
fieldsMissing: 'Fields Missing',
someBlankFields: 'There are some blank fields. Do you still want to submit your claim?',
resumeFilling: 'Resume Filling',
nameOfDoctorHospital: 'Name of Doctor /Hospital',
claimtheAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
claimsSpecialRequests: 'Others /Special Request',
declaration: 'Declaration',
declaration1: 'I /We declare that the above information is in all respect true and complete to the best of my /our knowledge and belief.',
declaration2: 'I /We, the claimant, hereby authorize any hospital, physician, clinic, or other person /authority /organization, to furnish to Assicurazioni Generali S.p.A. - Hong Kong Branch (“the Company”) or its authorized representative, any and all information with respect to my loss, injury or illness, medical history, consultation, prescriptions or treatment and copies of police reports, incident reports, statement and all hospital or medical records.',
declaration3: 'I hereby agree that all documents and receipts submitted to the Company will not be returned.',
declaration5: 'I /We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
declaration6: 'I /We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
reservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to request the claimants to submit the original copies of the supporting documents. Please keep the originals for at least 3 months after submitting the claim.',
skip: 'skip',
theTravelerCannot: 'The traveler cannot be an organization.',
less: 'Less',

  // insurance product
  quote: 'Quote',
  quotationandconfirmpurchase: 'Quotation and Confirm Purchase in a few clicks',
  removeInsured: 'Remove Insured',

  // claims
  submitAClaim: 'You can submit a claim in just a few clicks',
  helperNPersonal: 'Helper & Personal Accident Insurance',

  // claims Payment Screen
  validation: 'Validation',
  validationFailed: 'Validation Failed',
  recaptchaValidationFailed: 'Failed on validating the ReCAPTCHA!',
  retry: 'Retry',

  // roar us
  roarUsPhone: 'Phone',

  //ChatScreen
  seeMoreHistory: 'Show more',
  typeAMessage: 'Type a message...',
  chatAlert: 'Alert',
  unstableNetworkConnection: 'Unstable network connection',
  sorryOurOfficeHours: 'Sorry, our office hours are from Monday to Friday 9:00 am to 5:30 pm except public holiday. Please leave us your message, contact number or email, we will get back to you within next business day.',
  alternativelyYouMayCall: 'Alternatively, you may call (852) 6682 1112 or email bravo@generali.com.hk to contact us during business hours.',
  ifYouHavePurchased: '*** If you have purchased travel insurance and have an emergency in a foreign location, please call our 24-hour Worldwide Emergency Support Hotline at (852) 3187 6888.',
  welcomeToChat: 'Welcome to chat with Generali!',

  // priviledge club
  welcomeOffer: 'BRAVO Generali Welcome Offer',
  generaliVoucherBalance: 'Generali Voucher Balance',
  generaliVoucherBalanceIs: 'Generali Voucher Balance = HK$',
  validUntil: 'Valid until ',
  voucherTermsAndConditions: 'Terms and Conditions',
  voucherTermsAndConditions1: '1. The Generali voucher is only available on the "Bravo Generali" App for premium payment. It has a validity period of 1 year.',
  voucherTermsAndConditions2: '2. The Generali voucher is not transferable and cannot be exchanged for cash or other goods. If the insurance is withdrawn or refunded, the voucher amount will not be refunded.',
  voucherTermsAndConditions3: '3. The Company has the absolute right to interpret these terms and conditions and to change any related arrangements. In case of any dispute, The Company reserves the right of final decision without further notice',

  // priviledge club voucher
  failedToRedeem: 'Failed to Redeem',
  redeemSuccessfully: 'Redeem Successfully',
  offerExpired: 'Sorry that the offer is expired.',
  canOnlyEnjoyOurOfferOnce: 'Sorry that each customer can only enjoy our offer once.',
  forNewRegisteredMembers: 'Sorry that the offer is only valid for new registered members.',
  notEligibleToThisOffer: 'Sorry that you are not eligible to redeem this offer.',
  noLongerAvailable: 'Sorry that the offer is no longer available.',
  voucherBalanceIsCredited: 'Congratulations! Your voucher balance is credited by HK$',
  youCanCheckYourBalanceAnytime: '. You can check your balance anytime at the Privilege Club.',
  pleaseLoginWithYourAccount: 'Before you redeem the voucher, please login with your account.',

  // history
  historyList: 'History List',
  historyDetail: 'History Detail',
  notice: 'Notice',
  historyOfNotice: 'History of notice',
  noNoticeHistoryYet: 'There is no history for notices yet',
  purchase: 'Purchase',
  historyOfPilicies: 'History of policies',
  historyOfPiliciesForBroker: 'History of policies owned by your clients',
  noPurchaseHistoryYet: 'There is no history for purchase yet',
  claim: 'Claim',
  historyOfClaim: 'History of claim incurred by you',
  historyOfClaimForBroker: 'History of claim submitted by you for your clients',
  noClaimHistoryYet: 'There is no history for claim submission yet',
  attachments: 'Attachments:',
  policySchedule: 'Policy Schedule',
  weaCard: 'Worldwide Emergency Assistance',
  submitClaims: 'Submit Claims',
  of:"'s ",
  hasBeenAccepted: ' has been accepted and will be effective since ',
  period: '. ',
  periodII: '. ',
  tr:'[TR:',
  tr2:']',
  hasBeenSubmitted: ' has been submitted on ',
  yourClaimOn: 'Your claim on ',
  hasBeenSubmittedOn: ' has been submitted on ',
  accessDenied: 'Access Denied',
  cannotBeAccessed: 'The policy schedule cannot be accessed before we confirm the payment. Please try again later.',
  historyLeftPanelBrokerHeading0: '',
  historyLeftPanelBrokerContent0: '',
  historyLeftPanelBrokerHeading1: '',
  historyLeftPanelBrokerContent1: '',
  historyLeftPanelBrokerHeading2: '',
  historyLeftPanelBrokerContent2: '',
  historyLeftPanelHeading0: '',
  historyLeftPanelContent0: '',
  historyLeftPanelHeading1: '',
  historyLeftPanelContent1: '',
  historyLeftPanelHeading2: '',
  historyLeftPanelContent2: '',

  //broker
  instantEnrollment: 'Instant Enrollment',
  beforeSendingTheLink: 'Before sending the link to my/our customers, I /We have the customers’ consents to do so.',
  invalidPromotionCodes: 'Invalid promotion code',
  shareYourReferralLink: 'Share Your Referral Link',
  referralLink: 'Referral Link',
  reminder: 'Reminder',
  youNeedToAgree: 'You need to agree the statement before you share the referral link.',
  chooseAgain: 'Choose again',

  //home
  quickLinks: 'Quick links',
  homeTravel: 'Travel',
  promotion: 'Promotion',
  test: 'testing',
  homeInstantEnrollment: 'Insurance Product',
  claims: 'Claims',
  pleaseAgree: 'Please agree The legal information & Privacy statement.',
  toEnjoyTheBenefits: 'To enjoy the benefits of policy or claims history checking and one-click purchase/claim, you can now register with us.',
  forThoseAlreadyRegistered: 'Log-in (for those already registered)',
  continueWithoutRegistration: 'Continue without registration',
  youUndertake: 'You undertake and confirm that:',
  youHavePersonallyRead: 'You have personally read, understood, and accepted',
  theLegalInformation: 'The legal information',
  privacyStatement: 'Privacy statement',
  youCanAlsoChoose: 'You can also choose your preferred language',
  hellohello: 'Hello, ',

  //affinityProducts
  staff: 'Staff',
  student: 'Student',
  planB: 'Plan B',
  planC: 'Plan C',
  numberOfAdults: 'Number of Insureds',
  numberOfChildren: 'Number of Children',
  numberOfTravelers: 'Number of Travelers',
  exchangeStudent:'Exchange Student',
  planE: 'Plan E',
  affinityPeriodOfTravel: 'Period of Travel',
  pleasePutInEnglish: '* Please input in English *',
  affinityPassport: 'Passport',
  productType: 'Product Type',
  staffNumber: 'Staff Number',
  studentNumber: 'Student Number',
  facultyOrDepartment: 'Faculty /Department',
  travelProgramUOWCollegeHK:'Travel Program (UOW College HK)',

  //AmountInput
  aud: 'AUD',
  eur: 'EUR',
  gbp: 'GBP',
  hkd: 'HKD',
  jpy: 'JPY',
  rmb: 'RMB',
  usd: 'USD',

  //AttachmentLink
  productLeaflet: 'Product Leaflet',
  productLeaflets: 'Product Leaflets',
  policyProvision: 'Policy Provision',
  policyProvisions: 'Policy Provisions',
  forDetailsOfTheProductAndExclusions: 'For details of the product and exclusions, ',
  forDetailsOfTheProductExclusionsAndPremiumRates: 'For details of the product, exclusions and premium rates, ',
  forCompleteDetailsAndExclusions: 'For complete details and exclusions, please refer to the ',
  pleaseReferTo: 'please refer to ',
  productDetailsAnd: ' and ',
  productDetailsPeriod: '.',

  //DirectMarketingTickBox
  agree: 'I/We agree to the use of my/our personal data for ',
  directMarketing: 'direct marketing.',
  // directMarketingTickBoxPeriod: 'use.',

  //DocumentAttached
  documentAttachedTitle: 'Select a Photo',
  takePhoto: 'Take a Photo',
  chooseFromLibrary: 'Choose from Library',
  image: 'An error occurred when picking the image: ',

  //IdentityInput
  pleaseEnterAllChars: 'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',

  //LegalDocumentLink
  disclaimer: 'Disclaimer',
  pic: 'Personal Information Collection Statement',
  agreed: 'I/We have read and agreed to the ',
  comma: ', ',
  disclaimerComma: ', ',
  personalInformation: 'Personal Information Colletion Statement',
  and: ', and ',
  andThe: ' and the ',
  termsOfBusinessAgreement: 'Terms of Business Agreement',
  termsOfBusinessAgreementWith: 'Terms of Business Agreement with ',
  termsOfBusinessAgreementWith2: '',
  legalDocumentLinkPeriod: '.',

  //PaymentBreakdown
  ecLevy: 'EC Levy',
  terrorCharge: 'Government Terrorism Facility Charge',

  //insurance-product
  //travel
  emergencyMedicalTransport: 'Emergency Medical Evacuation',
  cancelTheJourney: 'Trip Cancellation /Curtailment',
  newCancelTheJourney: 'Trip Cancellation',
  shortenTheJourney: 'Trip Curtailment',
  travelDelay: 'Travel Delay',
  cashAllowance: 'Cash allowance:',
  per500every6hours: '500 per 6 hours (up to 2,000)',
  compensationForPaid: 'Reimburse Pre-paid Expense',
  travelOr: 'or',
  transportationAndAccommodationCosts2000: ' (transportation and accommodation): 2,000',
  transportationAndAccommodationCosts1500: ' (transportation and accommodation): 1,500',
  transportationAndAccommodationCosts1000: ' (transportation and accommodation): 1,000',
  delay: '500/every 6 hours delay',
  personalBaggage: 'Personal Baggage',
  personalResponsibility: 'Personal Liability',
  insuranceProductTravelWeaCard: '24-hour Worldwide Emergency Assistance',
  standardPremium: 'Premium (5 day individual plan)',
  reference: 'This page is designed to provide you a brief summary of the plan and is not a contract of insurance.',
  reference2: 'For complete details and exclusions, please refer to the product leaflet and policy provisions.',
  policyholderMustBeHongKongResident: 'Policyholder must be a Hong Kong resident and at least aged 18.',

  //cyberScreen
  cyberInsurance: 'Bravo Cyber',

  //ahiScreen
  ahiInsurance: 'Bravo Shield Hospital Income Plan',
  ahiInsuranceProtectorIntroParagraph: 'Life is characterised by uncertainties. Unforeseeable events happen to even the best-prepared among us. It is essential to plan to help us and our loved ones in cushioning the impact of injuries and its financial consequences.',
  ahiInsuranceProtectorProductEdge1: 'Family Plan designed to cover you and your family from new-born baby to aged 70',
  ahiInsuranceProtectorProductEdge2: 'Daily Hospital Income Benefit of HK$500 a day in the event of hospitalization due to accident. Benefit will be doubled during confinement in ICU',
  ahiInsuranceProtectorProductEdge3: 'Special COVID-19 benefit for the first year: lump sum benefit of HK$50,000 in case of ICU confinement for over 3 days or death due to COVID-19',
  //ASAPScreens
  secondarySchoolNUniversityIsATime: 'Secondary school and university is a time for exploration, and many young students will use that time to pursue the opportunity of studying overseas. With the Generali Annual Study Abroad Protector, you can make sure that your child’s time spent studying overseas is as stress-free as possible.',
  highMedicalExpenses: 'High Medical Expenses up to HK$1,500,000 (including the illness of COVID-19)',
  hospitaliztionBenefitsInHK: 'Hospitalization Benefits in Hong Kong (for newly diagnosed illness or injury) ',
  twelveMonthsConsecutiveCover: '12 months consecutive cover',
  optionalCoverForAccompanied: 'Optional Cover for Accompanied Parents',
  zeroExcess: 'Zero excess',
  asapEpaPlanType: 'Plan Type',
  withAccompanying: 'With accompanying father, mother or legal guardian',
  withoutAccompanying: 'Without accompanying father, mother or legal guardian',
  locationOfTheOverseasStudyingInstitution:'Location of The Overseas Studying Institution',
  usaCanada: 'USA /Canada',
  productType: 'Product Type',
  thePolicyHolderMustBeThe: 'The policyholder must be the student’s father, mother or legal guardian.',
  insuredStudent: 'Insured Student',
  overseasStudyingInstitution: 'Oversea Studying Institution',
  location: 'Location',
  thePolicyHolderShallBe: 'The policyholder shall be the mother, father or legal guardian of the insured student.',
  thisPolicyExcludes: 'This Policy excludes to cover the Insured Person performing job duties of Hazardous Occupation (Please refer definition in our policy jacket).',
  theInsuredShallBe: 'The insured(s) shall be in good health and agree that any pre-existing conditions will not be covered under this insurance. Furthermore, obtaining medical treatment is not a purpose of this trip. All coverages are subject to the terms and conditions of the Policy.',
  assicurazioniGeneraliReservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  assicurazioniGeneraliReservesTheRight3Months: 'Assicurazioni Generali S.p.A. reserves the right to request the claimants to submit the original copies of the supporting documents. Please keep the originals for at least 3 months after submitting the claim.',
  iWeCertifyGeneraliAsap: 'I/We certify that I/We have read and agreed to the Important Notes of GENERALI ASAP Insurance.',
  iWeCertifyDisclaimerNPersonalInformation: 'I/We certify that I/We have read and agreed to the Disclaimer and Personal Information Collection Statement.',
  iWeUnderstand: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), whom will pay the authorized insurance broker commission and provide my/our personal data to the said broker during the continuance of the policy for arranging and managing the said policy.',
  asapLevy: 'Levy',
  cardNumber: 'Card Number',
  expi: 'EXPI',
  mm: 'MM.',
  optionalBenefit: 'Optional Benefit',
  thereIsAnOptional: 'There is an optional benefit for accompanied father, mother or legal guardian.',
  personToBeInsured: 'Person to be Insured',
  sameAsThePolicyholder: 'Same as the policyholder',
  otherPerson: 'Other person',
  other: 'Other',
  otherCountry: 'Other country',
  insuredHKID: 'Insured HKID',
  insuredDateOfBirth: 'Insured Date of Birth',
  instituteName: 'Name',

  //CentalineLandlordProtectorScreens
  comprehesiveProtectionOfYourRentalPropertyContent: 'Comprehensive protection of your rental property for financial loss of rent, liability and damage of home content is what every landlord needs. To secure your property investments, Generali offers the Centaline Landlord Protector to insure the risks associated with renting your property. It also provides professional referral service by Centaline Group for rent recovery and repossession.',
  productEdges: 'Product Edges',
  productEdge1: 'Up to HK$500,000 contents damage protection',
  productEdge2: 'Up to HK$10,000,000 owner’s liability protection',
  productEdge3: 'Up to HK$200,000 rental loss protection or 6 months’ rent, whichever is lower',
  productEdge4: 'Optional building insurance',
  productEdge5: 'Professional referral services by Centaline Group to recover rent and property',
  withHomeBuilding: 'With home building',
  withoutHomeBuilding: 'Without home building',
  sizeOfTheProperty: 'Size of the Property',
  pleaseChooseTheNetSaleable: 'Please choose the net saleable area of the property',
  centalExecutivePersonalAccidentProtector: 'Centaline Landlord Protector',
  productType: 'Product Type',
  centalineHome:'Home',
  HKAdressOnly:'Hong Kond Address only',
  centalineLandlordProtectorhouseOrApartment:'House /Apartment',
  centalineLandlordProtectorApartmen:'Apartment',
  theHomeBuildingIsMoreThan40years:'The home building is more than 40 years',
  anyLegalActionAgainstTenantToRent:'Any legal actions taken against tenant to recover rent for regain possession of the insured home',
  anyDefeatOfRentalByTenantDuring12Months:'Any defeat of rental by the tenant of the insured home during the past 12 months',
  thisInsuranceDoesNotCoverCstOrGD:'This insurance does not cover the cost of gradual deterioration.',
  thisInsuranceIsNotMaintenanceContract:'This insurance is not a maintenance contract.',
  validCOverProperyGoodOrder:'Valid cover is conditional on keeping the property in good order and taking reasonable steps to avoid accident.',
  theInsuredHomeResidentalInHKBuliding:'The insured home is only applicable to residential building situated in Hong Kong and solely occupied for dwelling purpose only.',
  theInsuredHomebrickStones:'The insured home is built of bricks, stones and concrete and roofed with concrete.',
  allstructuresOfInsuredHomeBeLegal:'All structures of the insured home must be legally constructed.',
  proposerMustBeHKCitizen:'Proposer must be a Hong Kong citizen or resident in Hong Kong holding a valid  HKID card.',
  thisInsurISNotPublicHousingEstate:'This insurance is not applicable to public housing estate, and subdivided flat.',
  tenancyAgreement:'Tenancy Agreement – It must be a duly stamped Tenancy Agreement with tenant',
  section3RentProtection:'Section 3 – Rent Protection : Insured must pursue recovery of rent in arrears of first outstanding monthly rent by taking legal actions to demand payment from tenant immediately within 14 days on which the outstanding rent exceed 1 month, and inform Assicurazioni Generali S.p.A. by writing immediately.',
  sectione4buildingMax:'Section 4 – Building : Maximum Limit of Indemnity is up to the sum insured amount or the rebuilding cost of Building, whichever is the less',
  assicurazioniGenerali:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  anyLegalAction: 'Any legal action taken against tenant to recover rent or regain possession of the insured home',
  anyDefaultOfRental: 'Any default of rental by tenant of the insured home during the past 12 months',

  //DomesticHelperInsuranceScreens
  asAResponsibleEmployer: 'As a responsible employer, you want to ensure comprehensive insurance coverage for your domestic helper. Generali Domestic Helper Insurance not only provides full protection on employer’s legal obligations as required by local laws, it also offers extra benefits to  give your family comfort of mind knowing that your helper’s well-being is always protected. ',
  centalineProductDescription: 'Centaline Home Protector provides comprehensive protection including property content insurance, public liability insurance, personal accident insurance, and building insurance.',
  comprehensiveCoverageFromBasicEmployee: 'Comprehensive coverage from basic employees’ compensation to accident and medical expenses. ',
  coverHelpersDeath: 'Cover helper’s death or permanent disability due to accident on rest days – up to HK$100,000',
  coverHospitalization: 'Cover hospitalization and accidental dental expenses',
  optionalOutpatientExenses: 'Optional Outpatient Expenses – up to HK$5,000 with free benefits',
  centalineProductEdge1: 'Up to HK$1,000,000 Home Contents coverage',
  centalineProductEdge2: 'Up to HK$7,500,000 public liability',
  centalineProductEdge3: 'Up to HK$5,000 money coverage within Hong Kong',
  centalineProductEdge4: '(Enhanced) Up to HK$7,500,000 pets third party liability coverage',
  centalineProductEdge5: 'Optional Outpatient Clinical Expenses',
  centalineProductEdge6: 'Includes Feng Shui consultant expenses',
  withOutpatientBenefit: 'With outpatient benefit',
  withoutOutpatientBenefit: 'Without outpatient benefit',
  oneYear: '1 Year',
  twoYears: '2 Years',
  hkidPassportNumber: 'HKID /Passport Number',
  residentialAddress:'Residential Address',
  domesticHelper:'Domestic Helper',
  countryOfOrigin:'Country of Origin',
  workingLocationInHK:'Working Location in Hong Kong',
  sameAsTheResidentialAddress:'Same as the residential address of the policyholder',
  thePlaceOfEmploymentIsInHK:'The Place of Employment is in Hong Kong',
  theHelperHasEverBeenRufusedForEnrollment:'The helper has ever been refused for enrolment, renewal of employees’ compensation or accident or medical or similar insurance, or subject to special terms and conditions for such insurance',
  theHelperIsReceivingOrContemplatingAnyMdeical:'The helper is receiving or contemplating any medical attention or surgical treatment, or taking any medicine by doctor for a period longer than 3 months',
  theHelperIsFullTimeOverseasHelper:'The helper is full-time overseas helper with housework only',
  thisInsuranceIsAvailableForFullTimeOverseas:'This insurance is available for full-time overseas domestic helpers only and not for local helpers.',
  thisInsuranceIsAppliedForFullTimeOverseas:'This insurance is applied for only one full-time overseas domestic helper.',
  proposerOrInsuredEmploerMustBeHK:'Proposer/Insured(Employer) must be a Hong Kong citizen or resident in Hong Kong holding a valid HKID card and aged 18 or above.',
  theBenefitPayableUnderSection2:'The benefit payable under Section 2 – Personal Accident will be payable to the above Domestic Helper or his/her legal representative.',
  iHerebyDeclareThatTheBasicBenefitPayableUnderSection2:'I/We hereby declare that I understand and agree that the basic benefit payable under Section 2 – Personal Accident will be payable to the Domestic Helper or his/her legal representative.',
  iCerifyThatIHaveReadAndAgreedImportantNotes:'I/We certify that I/we have read and agreed to the Important Notes of Generali Domestic Helper Insurance',
  applicationDeclined:'Application declined',
  sorryThatYourApplicationIsDeclinedAsOnlyHKPlace:'Sorry that your application is declined as we only accept Hong Kong as place of employment.',
  sorryThatYourApplicationIsDeclinedAsOnlyFullTimeOverseas:'Sorry that your applicaiton is declined as we only accept full-time overseas helper with housework only.',
  reIput:'RE-INPUT',
  goBackHome:'GO BACK HOME',
  sriLanka:'Sri Lanka',
  indonesia:'Indonesia',
  philippines:'Philippines',
  singapore:'Singapore',
  malaysia:'Malaysia',
  thailand:'Thailand',
  nepal:'Nepal',
  bangladesh:'Bangladesh',

  //EPAScreens
  sometimesEvenMinorAccidentsLongContent: 'Our "EPA" offers you and your family a unique concierge service provide 24 hours private service delegated hotline allows you to enjoy life.',
  highBenefitLimits: 'High Benefit Limits (Personal Accident - HK$10,000,000)',
  allInOnePackageWithPersonalAccident: 'All in one package with Personal Accident, Accident Medical Expenses, and Hospital Cash Benefits',
  competitiveFamilyPlan: 'Competitive Family Plan',
  oneStopGenerali: 'One-stop Generali Concierge Hotline',
  individualPlan: 'Individual Plan',
  familyPlan: 'Family Plan',
  productType: 'Product Type',
  ndChild:'2nd Child',
  occupationClericalAndNonManualWork:'Occupation: Clerical and Non-manual work',
  fullTimeStudentUnmarriedAndUnemployed: 'Full time student, unmarried and unemployed',
  haveYourOrOtherCoveredMembersAPPOflife:'Have your or other covered members’ applications of life, accident or medical insurance ever been declined or postponed, or your insurance ever been modified, rated up, cancelled or refused invitation for renewal?',
  insurer:'Insurer',
  benefit:'Benefit',
  sumInsured:'Sum Insured',
  reason:'Reason',
  condition:'Condition',
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'Do you or other covered members have any physical or mental impairment or condition?',
  beneficiaryWillBeTheLE:'Beneficiary will be the Legal Estate of the insured according to the Hong Kong law.',
  assicurazioniGeneraliSpAReserves:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application',
  // iWECerrifyNotesOfIPAPI:'I/We certify that I/we have read and agreed to the Important Notes of Executive Personal Accident Protector Insurance.',
  iWeHerebyAuthorizedAnyLicensed:'I /We hereby authorized any licensed physician, hospital, clinic or other medical or medically related facility, insurance company, institution or persons that has any records or knowledge of myself /ourselves, to give to the Company any such information for the underwriting and claims assessment process. I /We also authorize all said sources to give such records or knowledge to any agency employed by the Company to collect and transmit such information.',
  iWeDoNotConsentToTheProvision: 'I /We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  epaExecutivePersonalAccidentProtector: 'Executive Personal Accident Protector Insurance',
  // iWeDoNotConsentToTheUse: 'I/We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',

  //FireInsuranceScreens
  yourHomeLongContent: 'Your home is a place where you spend most of your time with your family and your loved ones. Generali understands the importance of feeling safe and secure at home, our Fire Insurance is designed to protect you from the damage and loss of your residential premises due to the hazard of fire. It allows you to restore your home quickly and get back on track in life with your family.',
  coverBuildingSORB: 'Cover Building Structure of Residential Building',
  ageOfBuiling: 'Age of Building – up to 50 years',
  productType: 'Product Type',
  nnnnn:'',
  hkidNumber:'HKID Number',
  mmmmm:'is not a valid HKID.',
  beingTheOwnerOftheInsuredPremises:'Being the Owner of the Insured Premises',
  insuredPremises:'Insured Premises',
  residentialOrCommercial:'Residential /Commercial',
  residential:'Residential',
  commercial:'Commercial',
  fireTheHomeBuildingIsMoreThan50years:'The insured premises is more than 50 years',
  fireSumInsuredAmount: 'Sum Insured Amount (in HKD)',
  pleaseInputHere: 'Please input here',
  itCanBe: 'It can be any number at least HK$1 million and you can use your mortgage outstanding loan amount.',
  ageOfBuilding: 'Age of Building',
  ageOfBuilding40: '0 - 40',
  ageOfBuilding45: '41 - 45',
  ageOfBuilding50: '46 - 50',
  ageOfBuildingAbove: 'Above 50',
  lessThanMinimum: 'Your input number is less than the minimum (HK$1 million). Please re-input.',
  postCopyToMortgagee: 'Do you need us to post a copy of the policy document to the Mortgagee?',
  needToPostCopyToMortgagee: 'Need to post a copy of the policy document to the Mortgagee',
  nameOfMortgagee: 'Name of Mortgagee',
  addressOfMortgagee: 'Address of Mortgagee',
  iWECertifyThatReadAndAgreedNGPI:' I/We certify that I/we have read and agreed to the Important Notes of Generali Home Package Insurance',
  sorryThatYourApplication: 'Sorry that your application is declined as the policyholder must be the owner of the insured premises.',
  sorryThatYour: 'Sorry that your application is declined as we only accept residential premiess',
  notValidPromotionCode: ' is not a valid promotion code for Fire Insurance.',
  fireIsUnoccupiedForLonger30Days:'The insured premises is unoccupied for longer than 30 consecutive days',

  //HomePackageInsuranceScreens
  oncePurchaseLongConent: 'Once purchased, your home is likely the single most valuable asset you own, and your future financial security is dependent on maintaining the value of your investment. Generali Home Package Insurance guards against property loss and legal liability to third parties. It gives you the peace of mind knowing that the important assets in life are well protected.',
  comprehensiveCoverage: 'Comprehensive coverage –up to HK$1,000,000 for accidental physical loss or damage to home contents',
  legalLiability: 'Legal Liability – up to HK$7,500,000',
  homePackagePersonalAccident: 'Personal Accident – up to HK$250,000',
  optionalBuildingInsurance: 'Optional Building Insurance',
  CompetitiveAnnualPremiumWithLowExcess: 'Competitive annual premium with low excess',
  homePackageProductDescription: 'Centaline Home Protector provides comprehensive protection including property content insurance, public liability insurance, personal accident insurance, and building insurance.',
  homePackageProductEdge1: 'Up to HK$1,000,000 Home Contents coverage',
  homePackageProductEdge2: 'Up to HK$7,500,000 public liability',
  homePackageProductEdge3: 'Up to HK$5,000 money coverage within Hong Kong',
  homePackageProductEdge4: '(Enhanced) Up to HK$7,500,000 pets third party liability coverage',
  homePackageProductEdge5: 'Cover Mobile phone and laptop at home',
  homePackageProductEdge6: 'Includes Feng Shui consultant expenses',
  homeContentsOnly: 'Home contents only',
  homeContentsOnlyNBuilding: 'Home contents and home building',
  homePackageSizeOfTheProperty: 'Size of the Property',
  pleasChoose: 'Please choose the gross floor area of the property',
  below500: '500 or below',
  size500OfTheProperty: 'Size of the Property 500 or below',
  productType: 'Product Type',
  home:'Home',
  hongKongAddressOnly:'Hong Kong address only',
  convertNetFloorArea: 'If gross floor area information is not available for your flat, please convert net floor area to gross floor area by multiplying 1.25.',
  forExample: 'For example: Net 400 feet x 1.25 = Gross 500 feet.',
  pleaseChooseTheGrossFloorArea:'Please Choose the gross floor area of the property',
  homePackageTheHomeBuildingIsMoreThan40years:'The home building is more than 40 years',
  thisInsuranceDoesNotCoverTheCostOfGradualDeterioration:'This insurance does not cover the cost of gradual deterioration.',
  thisInsuranceISNotAMaintenanceContract:'This insurance is not a maintenance contract.',
  theInsuredHomeIsOnlyApplicableToContent:'The insured home is only applicable to residential building situated in Hong Kong and solely occupied for dwelling purpose only.',
  theInsuredHomeISBuiltOfBricksStones:'The insured home is built of bricks, stones and concrete and roofed with concrete.',
  allStructuresOfTheInsured:'All structures of the insured home must be legally constructed.',
  proposerMustBeAHKCitizen:'Proposer must be a Hong Kong citizen or resident in Hong Kong holding a valid HKID card.',
  applicationWillNotBeAccepted:'Application will not be accepted for subdivided flat.',
  section4Building:'Section 4 – Building : Maximum Limit of Indemnity is up to the sum insured or the rebuilding cost of Building, whichever is the less',
  homePackageIWECertifyThatReadAndAgreedNGPI:' I/We certify that I/we have read and agreed to the Important Notes of Generali Home Package Insurance',
  iWECertifyThatReadAndAgreedPICS:'I/We certify that I/we have read and agreed to the Disclaimer and Personal Information Collection Statement',

  //IPAScreens
  ipaSometimesEvenMinorAccidentsLongContent: 'Sometimes even minor accidents can cause huge disruptions. Loss of income, extended recovery, and long-term medical treatment can be difficult and create unexpected financial burdens. Generali offers highly flexible insurance solutions with custom-designed benefits to fit your specific needs. ',
  ipaHighMedicalExpenses: 'Up to HK$10,000,000 Personal Accident protection',
  ipaHospitaliztionBenefitsInHK: 'No Medical Examination Required',
  ipaTwelveMonthsConsecutiveCover: 'Comprehensive Coverage: including Personal Accident, Accidental Medical Expenses, Broken Bones etc',
  ipaOptionalCoverForAccompanied: 'Renewal Bonus',
  ipaZeroExcess: 'Cover for Extra Home Accident Indemnity',
  plan1: 'Plan 1',
  plan2: 'Plan 2',
  plan3: 'Plan 3',
  tailorMadePlan: 'Tailor Made Plan',
  OrWithSpouse: 'Individual or with Spouse',
  withSpouse: 'with Spouse',
  occupationOfTheInsured: 'Occupation of the Insured',
  occupationOfTheSpouse: 'Occupation of the Spouse',
  individualAndSpouse:'Individual & Spouse',
  a1234567:'A1234567',
  productType: 'Product Type',
  dateOfBirth:'Date of Birth',
  rightHanded:'Right Handed',
  ipaHaveYourOrOtherCoveredMembersAPPOflife:'Have you or other covered members’ applications of life, accident medical insurance ever been declined or postpond, or your insurance ever been modified, ratedup, cancelled or refused invitation for renewal?',
  ipaInsurer:'Insurer',
  ipaBenefit:'Benefit',
  ipaReason:'Reason',
  ipaCondition:'Condition',
  ipaDoYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'Do you or other covered members have any physical or mental impairment or condition?',
  policyholderAndInsuredsHaveValidHKID:'Policyholder and insureds have valid HKID card and are resident in Hong Kong.',
  premiumChargedWillBeBasedOnTheInsured:'Premium charged will be based on the Insured’s occupation categorized by the Occupation Classification Table.',
  ifACoupleAppliesTogether:'If a couple applies together, each child will be entitled to received 20% of sum insured of Benefit A1 & A2 and A3, A5, A6 & A7 will be 100% of the Principal Insured, except Benefit B Temporary Total Disablement and C Double Indemnity are not applicable; Benefit A1 Accidental Death and Permanent Disablement will be max. up to HK$200,000.-',
  beneficiaryWillbeTheLegalEstateOfTheInsured:'Beneficiary will be the legal Estate of the insured according to the Hong Kong law.',
  ipaAssicurazioniGeneraliSpAReserves:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  iWECerrifyNotesOfIPAPI:'I /We certify that I /we have read and agreed to the Important Notes of Individual Personal Accident Protector Insurance.',
  ipaIWeHerebyAuthorizedAnyLicensed:'I /We hereby authorized any licensed physician, hospital, clinic or other medical or medically related facility, insurance company, institution or persons that has any records or knowledge of myself /ourselves, to give to the Company any such information for the underwriting and claims assessment process. I /We also authorize all said sources to give such records or knowledge to any agency employed by the Company to collect and transmit such information.',
  ipaNdChild:'2nd Child',
  fullTimeStudent: 'Full time student, unmarried and unemployed',
  phone: 'Phone',
  class1: 'Class 1',
  class2: 'Class 2',
  class3: 'Class 3',
  class4: 'Class 4',
  nvolveMainlyIndoorWork: '(Professions & occupations involve mainly indoor work & non-hazardous nature)',
  requiringOutdoorWork: '(Professions & occupations requiring outdoor work, occasional manual work, or use of light tools or machines of non-hazardous nature)',
  ofLightManualWorks: '(Professions & occupations of light manual works)',
  ofExtraHazardousNature: '(Professions & occupations of extra-hazardous nature)',
  coverageA1: 'Coverage A1',
  coverageA2: 'Coverage A2',
  coverageB: 'Coverage B',
  coverageC: 'Coverage C',
  coverageD: 'Coverage D',
  accidentalDeathNPermanent: 'Accidental Death & Permanent Total or Partial Disablement',
  ipaSumInsured: 'Sum insured',
  accidentalMedicalExpenses: 'Accidental Medical Expenses',
  temporaryTotalDisablement: 'Temporary Total Disablement',
  doubleIndemnity: 'Double Indemnity',
  brokenBonesAndBurns: 'Broken Bones and Burns',

  //TravelInsurance
  travelCanGiveYouIncredibleLongContent: 'Travel can give you incredible experiences and lifelong memories. When you are overseas, it is reassuring to know that you are always protected. Generali Travel Package Insurance protects you and your loved ones throughout the entire journey.',
  bravoTravelProtectorIntroParagraph: 'Travel can give you incredible experiences and lifelong memories. When you are overseas, it is reassuring to know that you are always protected. Bravo Travel Protector protects you and your loved ones throughout the entire journey.',
  onlyForProductComparisonPurposes: 'For comparison purpose only.',
  travelNote: '"',
  travelNote2: '"',
  coverLossOfMobilePhone: 'Cover Loss of Mobile Phone',
  coverTerroistAct: 'Cover Terrorist Act',
  travelZeroExcess: 'Zero excess',
  coverAllKindsOfLeisureSports: 'Cover all kinds of leisure sports including skiing, scuba diving, bungee jumping, etc.',
  bravoTravelProtectorProductEdge1: 'Cover Loss of Mobile Phone',
  bravoTravelProtectorProductEdge2: 'Medical Expenses is as high as HK$1,500,000 (including the Illness of COVID-19) \n<p><b>For details on Covid coverage, please refer to <a href="http://sitecontent-dev.s3-website.ap-east-1.amazonaws.com/bravo-uat/Bravo%20Travel%20Protector%20%28FAQ%29.pdf" target="_blank" rel="noopener noreferrer">FAQ</a>.</b></p>',
  bravoTravelProtectorProductEdge3: 'Extend the Coverage of Personal Accident as “Scale 2” without additional premium',
  bravoTravelProtectorProductEdge4: 'Enhance benefit of “Travel Delay”',
  bravoTravelProtectorProductEdge5: 'Cover leisure sports including skiing, scuba diving, bungee jumping, etc.',
  annualTravel: 'Annual Travel: Maximum duration of each trip is 120 days ',
  premier: 'Premier',
  classic: 'Classic',
  standard: 'Standard',
  shortTermTripAnnual: 'Short Term Trip /Annual',
  shortTermTrip: 'Short Term Trip',
  annual: 'Annual',
  individualOrWithOthers: 'Individual or with Others',
  individualNChildren: 'Individual & Children',
  groupOfIndividuals: 'Group of Individuals',
  addtionalUpgradePersonalAccidentBenefit: 'Additional Upgrade Personal Accident Benefit',
  addtionalCruiseBenefit: 'Additional Cruise Benefit',
  total2: 'Total',
  flatDiscount: 'Flat Discount',
  finalTotal: 'Final Total',
  periodOfTravel: 'Period of Travel',
  productType: 'Product Type',
  companyName: 'Company Name',
  mainlandPermit: 'Home return permit number',
  travelHkidOrPassportNumber: 'HKID /Passport Number',
  travelPolicyholderIs: 'Policyholder is',
  children: 'Children',
  parents: 'Parents',
  relatives: 'Relatives',
  friends: 'Friends',
  haveThisTripInvolvedAnyCompetition: 'Has this trip involved any entertainment performance, reporters’ duties, tour guide /tour escort’s duties, stunt work, construction site or construction site related works?',
  numberOfChinaMedicalGuaranteeCards: 'Number of China Medical Guarantee Cards',
  thisPromotionCodeIsNotValid: 'Invalid promotion code',
  destination: 'Destination',
  travelOneOfTheTravelers: ' one of the travelers',
  travelNumberOfChildren: 'Number of Children',
  numberOfIndividuals: 'Number of Individuals',
  chinaMedicalGuaranteeCard: 'China Medical Guarantee Card',
  additionalChinaMedicalGuaranteeCard: 'Additional China Medical Guarantee Card',
  nullnull: 'null',
  travelSorryThatYourApplication: 'Sorry that your application is declined as the policyholder must be the owner of the insured premises.',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the application',
  policyholderAndInsuredsMust: 'Policyholder and insureds must have valid HKID card, and are residents of Hong Kong.',
  theCoveredTripShouldOriginate: 'The covered trip should originate from Hong Kong and does not cover within the territory of Hong Kong.',
  noRefundOfPremium: 'No refund of premium is allowed once the policy has been issued.',
  coverWillAutomatically: 'Coverage will automatically be extended up to a maximum of 10 calendar days in the event the covered trip is being unavoidably delayed.',
  thisInsuranceIsOnlyValid: 'This insurance is only valid for the purpose of leisure travel or business travel (limited to administrative duty) only and not applicable to tour guide/escort, etc.',
  allPRCCitizensWhoReside: 'All PRC citizens who reside and work in Mainland China will not be covered within the territory of Mainland China.',
  obtainingMedicalTreatment: 'Obtaining medical treatment is not a purpose of this trip.',
  thisInsuranceIsNotApplicable : 'This insurance is not applicable to artist(s) or any entertainment related activities.',
  theInsuredsShallBeInGood: 'The insured(s) shall be in good health and agree that any pre-existing conditions will not be covered under this insurance.',
  onlyStandardPlan: 'Only Standard Plan is applicable to the insured person of aged 17 or below.',
  assicurazioniGeneraliSpA: 'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  onlyStandardPlanIsApplicable: 'Only Standard Plan is applicable to the insured person aged 17 or below',
  worldwide: 'Worldwide (Except <Excluded Countries/Territories> referred to in the Territorial Exclusion Clause of the Policy)',
  question: 'Question',
  IWeCertifyThat: 'I/We certify that I/we have read and agreed to the Important Notes of GENERALI Travel Package Insurance.',
  age17OrBelow: 'Sorry that this age band can only goes with Standard Plan.',
  age0: 'Sorry that this age band can only goes with Standard Plan.',
  canOnlyChooseTheStandardPlan: 'Aged 17 or below can only choose the Standard Plan.',
  notAvailableForAgeBelow18: 'Sorry that this plan is not available for age below 18',
  roundedTo: 'Rounded to the nearest percentage',
  policyholderIsACompany: 'Policyholder is a company',

  //InstantClaimsScreens  HelperAndPersonalAccidentInsuranceScreen
  helperNPersonalAccidentInsurance: 'Helper & Personal Accident Insurance',
  insuredOccupation: 'Occupation of Insured',
  dateOfAccident: 'Date of Accident',
  howWasItHappened: 'State how the accident occured',
  pleaseDescribe: 'Please describe the injuries sustained, indicate the part of body injured and the type of injury, e.g. fracture, cut, bruise and etc.',
  nameOfThePoliceStation: 'Name of the Police Station',
  caseReferenceNumber: 'Case Reference Number',
  nameOfDoctorOrHospital: 'Name of Doctor /Hospital',
  youHaveSuccessfullySubmitted: 'You have successfully submitted your claim!',
  failedOnSubmittingThisClaim: 'Failed on submitting this claim!',
  employeesCompensation: 'Employees’ Compensation',
  claimPersonalAccident: 'Personal Accident /Hospitalization Expenses',
  clinicalExpenses: 'Out-patient',
  claimMedicalExpenses: 'Medical Expenses / Permanent Total or Partial Disablement / Accidental Hospital Cash',
  dateOfTreatment: 'Date of Treatment',
  diagnosisOfSickness: 'Diagnosis of Sickness /Injury',
  nameAndAddressOfAttendingDoctor: 'Name and Address of Attending Doctor',
  claimAmount: 'Claim Amount',
  theClaimantIs: 'The claimant is under the age of 18',
  claimedAmount: 'Claimed Amount',
  aCopyOfForm: 'A copy of Form 2/2A/2B',
  originalMedicalReceipts: 'Original medical receipt(s) &/or certificate(s), if any;',
  theEmploymentContract: 'The employment contract of the injured helper',
  completeDocumentationShowing: 'Complete documentation showing the up-to-date amount of periodical payment and medical expenses that the Insured have paid for the injured helper',
  theCertificateOfCompensationAssessment: 'The Certificate of Compensation Assessment (i.e. Form 5) and the 31.Certificate of Assessment (i.e. Form 7), if applicable',
  policeMemo: 'Police memo /statement(s) /report(s) in connection to the accident, if applicable',
  attendingDoctorStatement:'“Attending Doctor Statement”',
  pleaseBeRemindedToMail: 'For in-patient claims, please be reminded to mail us the original copies of medical bills if the bill amount is more than HK$5,000 or equivalent. We will process your claim upon this digital claim submission but the approval will be pending until we receive the original copy. Our office address is: 21/F, Cityplaza One, 1111 King’s Road, Taikoo Shing, Hong Kong. Please mark the claim reference number on the envelope. You will receive the claim reference number upon successful digital claim submission.',
  proofOfRelationship: 'Proof of relationship (for the claimant under age of 18)',
  claimsHelperFacingErrorWhenSubmitting: 'Error occurred when submitting this claim!',

  //InstantClaimsScreens  HomeInsuranceScreen
  claimsHomeInsuredName: 'Name of Insured',
  insuredHomeAddress: 'Insured Home Address',
  propertyDamageOrLoss: 'Property Damage /Loss of Property /Rental Income Loss /Building',
  publicLiability: 'Public Liability',
  dateOfIncident: 'Date & Time of Incident',
  timeOfIncident: 'Time of Incident',
  placeOfIncident: 'Place of Incident',
  detailIncident: 'Description of Incident',
  nameOfWitness: 'Name & Contact of Witness (if any)',
  contactOfWitness: 'Contact of Witness (if any)',
  wasTheIncidentReported: 'Was the incident reported to Police',
  claimsHomeNameOfThePoliceStation: 'Name of the Police Station',
  policeReportNumber: 'Police Report Number',
  hasAnyPrecautionaryMeasures: 'Has any precautionary measures been taken at the time of incident?',
  pleaseAdviseInDetails: 'Please Advise in Details',
  followingTheIncident: 'Following the incident, has any remedy work been taken to minimize the loss?',
  haveYouEverExperienced: 'Have you ever experienced similar nature of incident?',
  datesOfIncidents: 'Date(s) of Incident(s)',
  nameOfThirdParty: 'Name of Third Party',
  contactOfThirdParty: 'Contact of the Third Party',
  addressOfThirdParty: 'Address of the Third Party',
  natureAndExtentOfDamageOrInjuries: 'Nature and Extent of Damage or Injuries',
  hasClaimBeenMade: 'Has claim been made?',
  claimsHomeAmount: 'Claim Amount',
  anyStepsBeenTaken: 'Any steps been taken to compromise or settle the matter in any way?',
  particularsOfMainContractor: 'Particulars of the main contractor or contractor, if any',
  isThereAnyWorkByContract: 'Is there any work by contract undertaken at the time of incident?',
  trade: 'Trade',
  contactNoOrEmail: 'Contact no. /Email',
  theirRespectiveThirdParty: 'Their Respective Third Party Liability Insurance Policy Details',
  nameOfInsuranceCompany: 'Name of insurance company',
  detailedBreakdown: 'Detailed brakdown of the claimed items together with relevant purchase receipt(s) and /or repair quotation(s), invoice(s) and receipt(s)',
  colouredPhotograph: 'Colour photograph of the vicinity showing the extent of damage to the affected item(s)',
  incidentReportMadeByTheBuilding: 'Incident report made by the Building Management Office with their confirmation on the exact cause of damage and the remedial actions they had taken in order to minimize the damage',
  theCorrespondence: 'The corespondence(s) among the Policyholder /Insured, tenant, the building management and the parties concerned (e.g. the owner of the affected unit(s), the FEHD /BD Joint Office etc.) in connection with the matter, if any',
  otherRelevantInformation: 'Other relevant information /documents',
  clamsDateOfAccident: 'Date & Time of Incident',
  inCaseOfBurglary: 'In case of burglary, state mode of entry to and exit from the premises by the culprit',
  haveYouReportedTheIncident: 'Have you reported the incident to police?',
  areYouTheSoleOwner: 'Are you the sole owner of the damaged/lost Property(ies)?',
  otherOwners: 'Other Owner(s)',
  ofTheOtherOwners: ' of the Other Owner(s)',
  includingTheHirePurchase: 'Including the hire-purchase owner',
  addAnotherOwner: 'Add Another Owner',
  areYouEntitled: 'Are you entitled to claim under any other insurance policies in respect of this incident?',
  nameOfInsuranceCompanyies: 'Name of Insurance Company(ies) /Policy Number',
  respectivePolicyNumber: 'Respective Policy Number',
  detailsOfCoverage: 'Details of Coverage',
  haveYouEverSustainedLosses: 'Have you ever sustained losses of similar nature?',
  detailsOfIncidents: 'Details of Incident(s)',
  haveYouEverMadeClaimUnder: 'Have you ever made claim under any other insurance policy(ies)?',
  details: 'Details',
  remarks: 'Remarks',
  totalClaimAmount: 'Total Claim Amount',
  detailedBreakdownOfTheClaimedItems: 'Detailed breakdown of the claimed items together with relevant purchase receipt(s) and /or repair quotation(s), invoice(s) and receipt(s)',
  colouredPhotographsOfTheVicinity: 'Colour photographs of the vicinity showing the extent of damage to the affected item(s)',
  detailDescriptionOf: 'Detailed Description of the Incident',
  fullDescriptionOf: 'Full Description of Items Damaged or Lost',
  addAnItem: 'Add an Item',
  memoReportsAndOrStatements: 'Police memo /report(s) and /or statement(s) taken by the Insured /Family Member(s) /Parties under concerns (for Loss of Property /Attempted Theft /Theft claims only)',
  dulyStampedRentalAgreement: 'A copy of duly stamped Rental Agreement (For Centaline Landlord Protector only)',
  aCopyOfDuly: 'A copy of duly stamped Rental Agreement',
  theCorrespondencesAmong: 'The correspondence(s) among the Policyholder /Insured, tenant, the building management and the parties concerned (e.g. the owner of the affected unit(s), the FEHD /BD Joint Office etc.) in connection with the matter, if any',
  claimsHomeHowWasItHappened: 'State How the Incident Occurred',
  pleaseDescribeThe: 'Please describe the injuries sustained, indicating the part of body injured and the type of injury, e.g. fracture, cut, bruise and etc.',
  claimsHomeCaseReferenceNumber: 'Case Reference Number',
  claimsHomeProofOfRelationship: 'Proof of relationship, if applicable',
  rentalIncomeLoss: ' Rental Income Loss /',
  includingBrandName:'Including brand name, model and serial no.',
  dateOfPurchase: 'Date of Purchase',
  purchasePrice: 'Purchase Price',
  item: 'Item',

  //InstantClaimsScreens  TravelInsuranceScreen
  travelClaimsPolicyNumber: 'Policy Number /Policy Cert',
  dateOfTravelAccident: 'Date & Time of Accident',
  dateOfTravelIncident: 'Date & Time of Accident',
  pleaseEnter: 'Please enter all characters of your HKID.',
  claimsForExample: 'For example, if your HKID is A123456(7), please enter A1234567',
  stateHowTheLossDamageOccuredDiscovered: 'State how the loss /damage occured /discovered',
  medicalExpense: 'Medical Expenses Reimbursement',
  travelMedicalExpense: 'Medical Expenses',
  medicalExpenseReClaimDetails: 'Medical Expenses Reimbursement Claim Details',
  injuryOrSickness: 'Injury /Sickness',
  injury: 'Injury',
  sickness: 'Sickness',
  dateOfInjuryOrSickness: 'Date of injury /Sickness',
  // ifInjury: 'If Injury, how did the accident happen',
  // ifSickness: 'If Sickness, when did the Sickness first appear',
  // natureOfInjury: 'Nature of Injury /Diagnosis of Sickness',
  pleaseStateInDetail: 'Please state in detail how the injury occured. For sickness case, please state the symptom(s) and when the symptom(s) first appeared.',
  claimsTravelAmount: 'Claim Amount',
  flightTicketClaimsTravelAmount: 'Flight Ticket Claim Amount',
  hotelClaimsTravelAmount: 'Hotel Claim Amount',
  othersClaimedAmount: 'Others Claim Amount',
  nameAndAddressOfDoctorOrHospital: 'Name and Address of Attending Doctor /Hospital',
  ifItWasAHospitalPeriod: 'If it was a Hospital, period of hospital stay',
  pleasePickADateOfHospital: 'Please pick a date of hospital',
  travelItinerary: 'Travel itinerary /e-ticket /boarding pass',
  pleaseBeReminded: 'For in-patient claims, please be reminded to mail us the original copies of medical bills if the bill amount is more than HK$5,000 and its equivalent. We will process your claim upon this digital claim submission but approval will be pending until we receive the original copy. Our office address is: 21/F, Cityplaza One, 1111 King’s Road, Taikoo Shing, Hong Kong. Please mark the claim reference number on the envelope. You will receive the reference number upon successful digital claim submission',
  loss: 'Loss of Baggage, Travel Documents, Personal Belongings',
  lossClaimDetail: 'Loss of Baggage, Travel Documents, Personal Belongings Claim Details',
  dateOfLossDamage: 'Date and time of the Loss /Damage',
  timeOfLossDamage: 'Time of the Loss /Damage',
  placeOfLossDamage: 'Place of the Loss /Damage',
  reportedPoliceStation: 'Reported police station /common carrier /hotel, etc. and its contact information',
  firstReportedPlace: '1st Reported Place',
  reportedPlace: 'Reported place',
  contactInformation: 'Contact information',
  addAReportedPlace: 'Add a Reported Place',
  howTheLossDamageOccurredDiscovered: 'How the Loss /Damage Occurred or Discovered (e.g. where the property was placed and where, when and how the loss was discovered)',
  lostDamagedItems: 'Lost /Damaged Items',
  claimsTravelTotalClaimAmount: 'Total claim amount',
  firstLostDamagedItem: '1st Lost /Damaged Item',
  dateWhenLostDamagedItems: 'Date when Lost /Damaged Items',
  OriginalPurchaseValue: 'Original Purchase Value',
  replacementRepairCost: 'Replacement /Repair Cost',
  addALostDamagedItem: 'Add a Lost /Damaged Item',
  lossDamageReportIssuedByTheRelevantAuthorities: 'Loss /damage report(s) issued by the relevant authorities or organizations (e.g. police, airline, hotel. etc.)',
  photosShowingTheExtent: 'Photos showing the extent of damage to the property, if applicable',
  purchaseReceiptRepairQuotation: 'Purchase receipt, repair quotation, replacement invoice, etc. where applicable',
  compensationBreakdownFromOtherInsurers: 'Compensation breakdown from other insurers /responsible parties (e.g. airline), if applicable',
  journeyCancellation: 'Journey Cancellation and Curtailment and Re-Route',
  journeyCancellationClaimDetails: 'Journey Cancellation and Curtailment and Re-Route Claim Details',
  dateOfOriginalSchedule: 'Date of Original Schedule',
  locationOfOriginalSchedule: 'Location of Original Schedule',
  dateOfCCRJourney: 'Date of Curtailed /Cancelled /Re-arranged Journey',
  locationOfCCRJourney: 'Location of Curtailed /Cancelled /Re-arranged Journey',
  reasonForJourneyCCR: 'Reason for Journey Cancellation /Curtailment /Re-route',
  ifTheJourneyCurtailmentIsDueTo: 'If the journey curtailment/cancellation is due to death, serious injury or sickness person /immediate family member /close business partner, please state clearly the following',
  fullNameOfTheSickPerson: 'Full name of the sick /injured /deceased person',
  relationshipToTheInsuredPerson: 'Relationship to the insured person',
  receiptShowingAnyPrepaidCosts: 'Receipt(s) showing any pre-paid costs or deposits made OR additional travel and /or accommodation expenses incurred after the commencement of the Journey',
  documentationIssuedByTravelAgent: 'Documentation issued by travel agent or common carrier confirming "trip cancellation" and "non-refundable /refunded amount"',
  travelBaggageDelay: 'Travel Delay and Baggage Delay',
  travelBaggageDelayDetails: 'Travel Delay and Baggage Delay Claim Details',
  reasonForDelay: 'Reason for Delay',
  flightDelayed: 'Flight Delayed',
  missedConnection: 'Missed Connection',
  baggageDelayed: 'Baggage Delayed',
  smallOthers: 'others',
  whichAirport: 'Which Airport?',
  delayedFlightNo: 'Delayed Flight No.',
  dateNTime: 'Date & Time',
  pleasePickADateNTime: 'Please pick a date and time',
  emergencyEssentialItemsPurchased: 'Emergency Essential items Purchased (if applicable)',
  firstItem: '1st item',
  addAPurchasedItem: 'Add a Purchased Item',
  datePurchased: 'Date Purchased',
  pricePaid: 'Price Paid',
  documentationIndicatingTheReasonForDelay: 'Documentation indicating the reason(s) for and the number of hours of delay (e.g. confirmation from common carrier /travel agent)',
  personalAccidentClaimDetails: 'Personal Accident Claim Details',
  clamsTravelDateOfAccident: 'Date of Accident',
  clamsTravelTimeOfAccident: 'Time of Accident',
  describeHowTheAccidentOccurred: 'Describe how the accident occurred, and the injuries sustained',
  nameAndAddressOfTheAttendingDoctor: 'Name and Address of the Attending Doctor',
  permanentDisabilityDegreeNExtent: 'Permanent Disability (Degree & Extent), if applicable',
  causeOfDeath: 'Cause of Death, if applicable',
  nameAndContactNumberOfWitness: 'Name and Contact Number of Witness(es), if any',
  contactNumber: 'Contact Number',
  accidentReportIssuedByRelevantAuthorities: 'Accident report(s) issued by relevant authorities/organizations (e.g. police, hotel, common carrier, activity organizer, etc.), if any',
  medicalReportStatingTheDateOfAttendance: 'Medical report stating the date of attendance and extent of injuries sustained as certified by a Qualified Medical Practitioner, if applicable',
  copyOfDeathCertificate: 'Copy of Death Certificate /Post Mortem Report, if applicable',
  careVisit: 'Care Visit',
  careVisitClaimDetails: 'Care Visit Claim Details',
  nameOfVisitingFamilyMember: 'Name of Visiting Family Member',
  durationOfVisit: 'Duration of Visit',
  claimsTravelProofOfRelationship: 'Proof of relationship',
  receiptsOfTravelingAccommodationExpensesIncurred: 'Receipts of traveling and /or accommodation expenses incurred',
  personalLiability:'Personal Liability',
  personalLiabilityClaimDetails: 'Personal Liability Claim Details',
  dateOfTheIncident: 'Date time of the Incident',
  timeOfTheIncident: 'Time of the Incident',
  placeOfTheIncident: 'Place of the Incident',
  describeInDetailHowTheAccidentOccurred: 'Describe in detail how the accident occurred and the third party property damage /injury occurred',
  fullNameOfTheThirdPartyClaimant: 'Full Name and Contact of the Third Party Claimant',
  contactOfTheThirdPartyClaimant: 'Contact of the Third Party Claimant',
  fullNameOfWitness: 'Full Name and Contact of the Witness(es), if any',
  claimsTravelContactOfWitness: 'Contact of Witness(es), if any',
  copyOfIncidentReportFromRelevantParties: 'Copy of incident report(s) from relevant parties (e.g. Police and other local authorities, hotel, sports centre, etc.)',
  claimLetterAndInvoice: 'Claim letter and invoice',
  otherDocuments: 'Other documents - please state',
  anyLawsuit: 'Any lawsuit, demand, claim or proceeding of any types relating to the incident of which the Claimant becomes aware of, and received from the third party claimant, should be immediately forwarded to the Company.',
  noLiabilityShould: 'No liability should be admitted or no settlement or promise of payment should be reached or made to the third party without the prior consent of the insurance company.',
  othersClaimDetails: 'Others Claim Details',
  otherInsuranceCoverage: 'Do you have other insurance coverage?',
  CityUniversityOfHongKong: 'City University of Hong Kong',
  uowCollegeHongKong: 'UOW College Hong Kong',
  applicationJson: 'application/json',
  failedOnSubmitting: 'Failed on submitting this claim!',
  policyholderThe: 'Policyholder /The',
  policy: 'Policy ',
  number: 'Number',
  certificate: '/Certificate ',
  employerName: '/Employer Name',
  staffStudentNumber: 'Staff /Student Number',
  occupationPosition: 'Occupation /Position',
  stationedCityCountry: 'Stationed City /Country (if applicable)',
  homeResidingCountry: 'Home & Residing Country',
  dateOfSecondment: 'Date of Secondment to Stationed City /Country (if applicable)',
  purposeOfTrip: 'Purpose of Trip',
  business: 'Business',
  accidentHappen:'How did the accident happen?',
  symptomsFirstAppear: 'When did the symptom(s) first appear?',
  pleaseFillInDetails: 'Please fill in details',
  relationshipToInsuredPerson: 'Relationship to Insured Person',
  documentShowingTheStudentIdentity: 'Document showing the student identity of the overseas education, such as the Student Identification Card &/or admission letter',
  medicalCertificateIndicatingDiagnosis: 'Medical certificate indicating diagnosis and reason that the insured person/immediate family member /close business partner is unfit for travel, if applicable',
  claimsTravelDeathCertificate: 'Death certificate, if applicable',
  item:' Item',
  lostDamagedItem: 'Lost /Damaged Item',

  //SettingsScreens
  createAccount: 'Create Account',
  createBioAccount: 'Biometrics Authentication',
  getLoginBySMS: 'Login by SMS',
  getLoginByEmail: 'Login by Email',
  getConfirmationCode: 'Get Confirmation Code',
  confirmationCodeSent: 'Confirmation Code Sent',
  confirmationCodeSentToYourEmailAddress: 'Confirmation code has been sent to your email address',
  confirmationCodeSentTo: 'Confirmation code has been sent to: ',
  enterCodeBelow: 'Please enter the six-digit code below',
  loginWithMobilePhone: 'Login with Your Mobile Phone',
  loginWithEmailAddress: 'Login with Your Email Address',
  welcome: 'Welcome',
  loggedIn: 'You have successfully logged in',
  loggedInTo: 'You have successfully logged in to ',
  toBetterProtectYourPersonalData: '!\nTo better protect your personal data, you are encouraged to log out everytime.',
  accountCreated: 'You have successfully created an account!',
  youHaveRegistered: 'You have registered',
  alreadyRegistered: 'This account is already been registered. You may use this phone number to login.',
  logout: 'Logout',
  languages: 'Languages',
  preferredLanguage: 'Preferred Langauge',
  pleaseRegisterFirst: 'Please register first.',
  settingsTheAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the application',
  loginFailed: 'Login failed. Please check your mobile number, and the six-digit code again.',
  pleaseAcceptTheTermsAndConditions: 'Please accept the terms and conditions.',

  // Insurance Advisor Screen
  insuranceAdvisorIs: 'Insurance advisor: ',
  notSpecifiedYet: 'Not specified yet',
  change: 'CHANGE',
  changeInsuranceAdvisor: 'Change insurance advisor?',
  advisorWouldYouLike: 'Would you like ',
  advisorComma: ', ',
  advisorToBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  requiredToLogin: 'Login Required',
  advisorPleaseLogin: 'Please login before you can manage your insurance advisors',
  product: 'Product: ',
  pleaseInputTheMobileNumber: 'Please input the mobile number of the insurance advisor',
  removeInsuranceAdvisor: 'Remove the Insurance Advisor',
  doYouWantToRemove: 'Do you want to remove your insurance advisor for ',
  questionMark: '?',
  thanksAndWeWillInform: 'Thanks and we will inform ',
  accordingly: ' accordingly.',
  pleaseCheckTheProductList: ' Please check the insurance advisor list and click confirm',
  advisorInvalidMobileNumber: 'Invalid insurance advisor mobile number',
  advisorWTheAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  advisorWTheAboveIsRequiredToBeAgreed2: 'The above is required to be agreed in order to complete the Biometrics Login registration',

  // Developer Screen
  developerChecker: 'Developer Checker',
  host: 'Host',
  token: 'Token',
  version: 'Version',

  //Biometrics Authentication
  biometricpleaseRegisterFirst: 'Please register for the biometrics login',
  biometricsuccesstitle: 'Success',
  biometricsuccessdesc: 'Thanks and the Face ID / Thumb ID login is enabled. Please be reminded that if there is any change in the Face ID / Thumb ID record in your phone in the future, you will need to set up the biometric login again.',
  biometricskeepitactive: 'Keep It Active',
  biometricsdisable: 'Disable it',
  biometricsterms: 'Terms and Conditions for the use of the Biometrics Login Service',
  biometricsagress: 'You have personally read, understood and accepted the above terms and Conditions for the use of the Biometrics Login Service',
  biometricssms: 'Wrong SMS',
  biometricauthenticationQA: 'Do you authorize the app to use Face ID / Thumb ID? Bravo Generali needs your authorization to use your Face ID/ Thumb ID for enabling biometric log-in.',
  biometricauthentication: 'Biometric Authentication',
  biometricauthenticationcancel: 'Inactive Biometric Authentication',
  biometricauthenticationcanceldesc: 'Biometric Authentication currently active',
  biometricauthenticationcancelno: 'keep it active',
  biometricauthenticationcancelyes: 'Disable it',
  biometricstermsdetail: 'You must ensure that only your face or your fingerprints are stored on your permitted mobile device to access the device and you understand that upon the successful registration of your permitted mobile device, any face or fingerprint that is stored on your permitted mobile device can be used to access this app.\n\nYou should not use face authentication module if you have an identical twin sibling, in which case you are recommended instead to use the OTP log in.\n\nYou should not take any action to disable any function provided by, and/or agreeing to any settings of, your mobile device that would otherwise compromise the security of the use of your login credentials for authentication purposes (e.g. disabling “attention-aware” for face recognition). \n\nYou can deactivate the biometric login services at anytime at the settings.\n\nYou understand that the biometric authentication module is not provided by us, and we make no representation or warranty as to the security of such authentication of any permitted mobile device and whether it works in the way that the manufacturer of the device represents.\n\nUnless a law prohibits us from excluding or limiting our liability, we are not liable for any loss you incur in connection with the use or attempted use of the biometric login services, or your instructions, or any unauthorized transactions through or in connection with the biometric login services.',
  biometricauthenticationlogin: 'Biometric Login',
  biometricauthenticationloginYN: 'Face ID / Thumb ID',
  biometricauloginisneeded: 'Activate biometric authentication',
  // utils
  versionCheck: 'Version Check',
  youAreRunningAnOldVersion: 'You are running an old version of this app! ',
  pleaseHeadTo: 'Please head to ',
  toGetTheLatestVersion: ' to get the latest version.',
  visit: 'Visit ',

  //NetPromoterScore
  feedbackSubmitted: 'Feedback Submitted',
  thankYouForYourFeedback: 'Thank you for your feedback!',
  feedback: 'Feedback',
  doYouLikeThisApplicationPlatform: 'Do you like this application /platform?',

  //RPAscreen1
  bravoPassengerProtector: 'Bravo GO',
  bravoPassengerProtectorLeftPanel: 'Safeguard your every ride',
  bravoPassengerProtectorIntroParagraph: 'This pioneer personal protection covers your risks as a passenger in any public transport and any private cars. Bravo GO allows you and your family enjoy every ride in your journey with comprehensive protection: from worldwide coverage to 24-hours emergency assistance service and more.',
  bravoPassengerProtectorProductEdge1: 'Protection covers you and your family ( from new born baby to aged 70)',
  bravoPassengerProtectorProductEdge2: 'Accidental Death as high as HK$ 500,000 per policy year',
  bravoPassengerProtectorProductEdge3: 'Accidental Medical Expense up to HK$ 5,000 per accident',
  bravoPassengerProtectorProductEdge4: 'Premium is less than HK$1 per day to cover you all year long',
  Beneficiary: 'Beneficiary will be the Legal Estate of the insured according to the Hong Kong law.',
  policyholderIsTheInsured: 'Policyholder is the Insured',
  addInsured: 'Add Insured',
  IWeHereByAuthorizedAnyLicensedSuchInformation:'I/We hereby authorized any licensed physician, hospital, clinic or other medical or medically related facility, insurance company, institution or persons that has any records or knowledge of myself/ourselves, to give to the Company any such information for the underwriting and claims assessment process. I/We also authorize all said sources to give such records or knowledge to any agency employed by the Company to collect and transmit such information.',
  youHaveOneInsuredOnly: 'You have one insured only, we will change your plan choice to individual.',
  cntinueInputting: 'Continue inputting other insureds.',

  //LifeInsuranceApplicationFormScreen
  lifeInsuranceApplicationForm: 'Life Insurance Application Form',
  privateConfidential: 'Private & Confidential',
  pleaseEnsureThatYouDisclose: 'IMPORTANT NOTE: Please ensure that you disclose all material facts in this Application Form to the best of your knowledge, which shall form the basis of contract, otherwise the issued policy may be void. If you are in doubt whether a fact is material, please disclose it on the Application Form. This Application Form must be completed and signed in the Hong Kong Special Administrative Region.',
  healthInformation: 'Health Information',
  beenHospitalized: 'Have you been hospitalized for a total of more than 30 days in the past 12 months? Or have you been advised by a physician that you are suffering from a terminal illness? Or are you currently under palliative or intensive care?',
  personalDetails: 'Personal Details',
  proposedInsuredParticulars: 'Proposed Insured Particulars',
  proposedPolicyholderParticulars: 'Proposed Policyholder Particulars',
  nameInEnglish: 'Name in English',
  nameInChinese: 'Name in Chinese (if applicable)',
  countryOfBirth: 'Country of Birth',
  gender: 'Gender',
  nationality: 'Nationality',
  maritalStatus: 'Marital Status',
  flatRoom: 'Flat Room',
  floor: 'Floor',
  block: 'Block',
  buildingEstate: 'Building Estate',
  roadStreet: 'Road Street',
  district: 'District',
  area: 'Area',
  city: 'City',
  country: 'Country',
  argentina: 'Argentina',
  australia: 'Australia',
  austria: 'Austria',
  bahrain: 'Bahrain',
  belgium: 'Belgium',
  bermuda: 'Bermuda',
  brazil: 'Brazil',
  britishVirginIslands: 'British Virgin Islands',
  bulgaria: 'Bulgaria',
  canada: 'Canada',
  chile: 'Chile',
  china: 'China',
  cyprus: 'Cyprus',
  czechRepublic: 'Czech Republic',
  denmark: 'Denmark',
  estonia: 'Estonia',
  finland: 'Finland',
  france: 'France',
  germany: 'Germany',
  greece: 'Greece',
  holland: 'Holland',
  hongKong: 'Hong Kong',
  hungary: 'Hungary',
  india: 'India',
  indonesia: 'Indonesia',
  ireland: 'Ireland',
  isleOfMan: 'Isle of Man',
  italy: 'Italy',
  japan: 'Japan',
  latvia: 'Latvia',
  liechtenstein: 'Liechtenstein',
  lithuania: 'Lithuania',
  luxembourg: 'Luxembourg',
  macau: 'Macau',
  malaysia: 'Malaysia',
  malta: 'Malta',
  newZealand: 'New Zealand',
  norway: 'Norway',
  philippines: 'Philippines',
  poland: 'Poland',
  portugal: 'Portugal',
  qatar: 'Qatar',
  romania: 'Romania',
  singapore: 'Singapore',
  slovakia: 'Slovakia',
  southKorea: 'South Korea',
  spain: 'Spain',
  sweden: 'Sweden',
  switzerland: 'Switzerland',
  thailand: 'Thailand',
  taiwan: 'Taiwan',
  unitedArabEmirates: 'United Arab Emirates',
  uk: 'United Kingdom',
  usa: 'USA',
  vietnam: 'Vietnam',
  otherPleaseSpecify: 'Other (Please Specify)',
  businessRegistrationNo: 'Business Registration No.',
  hKIDPassport: 'HKID / Passport',
  single: 'Single',
  married: 'Married',
  averageMonthlyIncome: 'Average Monthly Income (HK$)',
  nameOfEmployer: 'Name of Employer',
  businessNature: 'Business Nature',
  businessAddress: 'Business Address',
  permanentAddress: 'Permanent Address',
  sameAsResidentialAddress: 'Same as residential address',
  contactTelephoneNo: 'mobile number',
  wantAnHardcopyToBeMailed: 'Policy will be sent to you via this email address. Do you still want an additional hardcopy to be mailed to your correspondance address?',
  insuredIsThePolicyholder: 'Insured is the policyholder',
  detailsOfInsurancePlan: 'Details of Insurance Plan',
  basicPlan: 'Basic Plan',
  endowment: 'Endowment',
  policyCurrency: 'Policy Currency',
  sumAssured: 'Sum Assured in USD',
  anyNumbersFrom2500To1M: 'Any numbers from $2,500 to $1m',
  premiumPaymentMode: 'Premium Payment Mode',
  withOrWithoutPrepayment: 'With or without prepayment of second year premium',
  threePercentGuaranteedInterestRate: 'Prepayment can enjoy 3% guaranteed interest rate. For details, please check the prepayment illustration.',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  singlePay: 'Single Pay',
  semiAnnual: 'Semi-annual',
  annualPay: 'Annual',
  prepayment: 'Prepayment',
  prepaymentPremium: 'Modal / Single / Prepayment Premium',
  ifNoOptionIsSelectedForNonMonthly: 'Note: If no option is selected for non-monthly payment mode, direct billing will be used.',
  initialPremiumPaymentMethod: 'Initial Premium Payment Method (Subject to the option(s) provided for the product)',
  itCanBeACombinationOfAFewMethods: 'It can be a combination of a few methods. It can be paid right after filling in the app form, or after we approve your application.',
  bankIn: 'Bank In',
  cheque: 'Cheque',
  bankDraft: 'Bank Draft',
  creditCardAutopay: 'Credit Card Autopay',
  creditCardOnlinePayment: 'Credit Card Online Payment',
  renewalPremium: 'Renewal Premium Payment Method',
  bankAutopay: 'Bank Autopay',
  chequeOrBankDraftOrBankIn: 'Cheque or Bank Draft or Bank In',
  beneficiaryInformation: 'Beneficiary Information',
  sectionIsLeftBlank: 'If this section is left blank, all benefits under this policy shall be paid towards the Proposed Policyholder if the Proposed Policyholder is alive, otherwise to the Proposed Policyholder’s estate.',
  oneBeneficiaryIsDesignated: 'If more than one beneficiary is designated, all policy proceeds will be made in equal share to the surviving beneficiaries, unless herein specified.',
  primarSecondary: 'Primary / Secondary',
  primar: 'Primary',
  secondary: 'Secondary',
  beneficiaryInEnglish: 'Name of Beneficiary In English',
  beneficiaryInChinese: 'Name of Beneficiary In Chinese',
  relationshipWithProposedInsured: 'Relationship with Proposed Insured',
  parent: 'Parent',
  employer: 'Employer',
  creditor: 'Creditor',
  partner: 'Partner',
  iDCardPassportNo: 'HKID / Passport No.',
  share: 'Share (Total 100%)',
  beneficiary: 'Beneficiary',
  addBeneficiary: 'Add Beneficiary',
  proposedInsuredsInsuranceCoverage: 'Proposed Insured’s Insurance Coverage',
  doesTheProposedInsured: 'Does the Proposed Insured have any existing insurance policy or pending insurance application on your life?',
  identificationOfThirdPartyInterest: 'Identification of Third Party Interest',
  proposedPolicyholderActingOn: 'Is Proposed Policyholder acting on behalf of others (without limitation as trustee, nominee or agent) in making this application for insurance policy?',
  provideDetailsOfTheBeneficialOwner: 'Please provide details of the beneficial owner / principal and the documentary proof of the legal capacity / authority in so acting',
  replacementDeclaration: 'Replacement Declaration',
  replacedInThePast12Months: 'Have you replaced in the past 12 months any or a substantial part of your existing life insurance policy(ies) with this application / proposal?',
  intendToReplaceInTheNext12Months: 'Do you intend to replace in the next 12 months any or a substantial part of your existing life insurance policy(ies) with this application / proposal?',
  maximumHeight: 'Maximum Height',
  issuedYear: 'Issued Year',
  sumAssuredInLifeBenefit: 'Sum Assured in Life Benefit',
  criticalIllness: 'Critical Illness',
  hospitalIncome: 'Hospital Income',
  addCoverage:'Add a coverage',
  coverage: 'Coverage',
  pleaseGiveFullDetails: 'Please give full details (including exact diagnosis, treatment received, last consultation date, current condition, etc).',
  iWeDoNotConsentToTheProvisionOfThePersonal: 'I / We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentToTheUseOf: 'I / We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  tickTheBoxesButSignTheForm: 'If you do not tick the boxes but sign the Form / document, you will be regarded as having indicated you have no objection (i.e. you consent) to the use or transfer to third parties of the Personal Data for the purpose of direct marketing by the Company.)',
  iWeDeclareUS: 'I/We declare that I am/ we are not a U.S. resident for tax purposes at the time of signing this declaration.',
  residentForTaxPurposes: 'I/We declare I am/ we are a U.S. resident for tax purposes at the time of signing this declaration.',
  iWeAcknowledgeThatGenerali: 'I/We acknowledge that Generali may transfer any required information to the Tax Authorities in or outside Hong Kong to comply with FATCA obligations and waive all rights I/we have, if any, to prohibit or restrict such disclosure.',
  taxpayerIdentification: 'U.S. Taxpayer Identification Number (TIN)',
  weAreHongKongResidentsForTax: 'I/We declare that I am / we are Hong Kong resident(s) for tax purposes and that I am / we are not resident(s) for tax purposes of any jurisdiction other than Hong Kong at the time of signing this declaration.',
  iWeTaxPurposes: 'I/We declare I am / we are resident(s) for tax purposes of a jurisdiction other than Hong Kong at the time of signing this declaration.',
  jurisdiction: 'Jurisdiction',
  jurisdictionOfResidence: 'Jurisdiction of Residence',
  taxpayerIdentificationNumber: 'Taxpayer Identification Number (TIN)',
  enterReasonTIN: 'Enter Reason A, B or C if no TIN is available',
  explainWhyTheAccount: 'Explain why the account holder is unable to obtain a TIN if you have selected Reason B',
  ifATINIsUnavailable: 'If a TIN is unavailable, please provide the appropriate reason A, B or C:',
  reasonA: 'Reason A – The jurisdiction where the account holder is a resident for tax purposes does not issue TINs to its residents.',
  reasonB: 'Reason B – The account holder is unable to obtain a TIN. Explain why the account holder is unable to obtain a TIN if you have selected this reason.',
  reasonC: 'Reason C – TIN is not required. Select this reason only if the authorities of the jurisdiction of residence do not require the TIN to be disclosed.',
  a: 'A',
  b: 'B',
  c: 'C',
  transferAnyRequiredInformationToTheIRD: 'I/We acknowledge that Generali may transfer any required information to the IRD, and the IRD may exchange this information with tax authorities outside Hong Kong, and waive all rights I/we have, if any, to prohibit or restrict such disclosure.',
  adviseGeneraliOfAnyChangeInCircumstances: 'I/We undertake to advise Generali of any change in circumstances which affects the tax residence status of the Proposed Policyholder(s) or causes the information contained herein to become incorrect, and to provide Generali with a suitably updated form within 30 days of such change in circumstances.',
  jurisdiction: 'Add Jurisdiction',
  understandAndAgreeTheDeclarationAndAuthorization: 'I/We understand and agree the declaration and authorization.',
  agreeThatThisApplicationWillBeSubjectToTheApprovalOfGenerali: 'I/We agree that this application will be subject to the approval of Generali.',
  nameOfCardIssuer: 'Name of Card Issuer',
  countryOfIssue: 'Country of Issue',
  typeOfCreditCard: 'Type of Credit Card',
  creditCardNumber: 'Credit Card Number',
  creditCardExpiryDate: 'Credit Card Expiry Date（month / year）',
  detailedDate: 'Date (dd / mm / yyyy)',
  nameOfCardholder: 'Name of Cardholder (in English)',
  signatureOfCardholder: 'Signature of Cardholder',
  asShownOnYourCreditCard: '(As shown on your Credit Card)',
  visa: 'VISA',
  master: 'Master',
  bankNameAndBranch: 'Bank Name and Branch',
  accountNoToBeDebited: 'Account No. to be debited',
  policyNumberDebtorReference: 'Policy Number (Debtor Reference)',
  nameOfBankAccountHolder: 'Name(s) of bank account holder(s)',
  policyholdersName: 'Policyholder’s name',
  addressOfBankAccountHolder: 'Address of bank account holder',
  signatureOfBankAccount: 'Signature(s) of bank account holder(s)',
  signatureMustAgreeWith: '(Signature(s) must agree with your Bank’s records)',
  forBankUseOnly: 'FOR BANK USE ONLY',
  viewDetails: 'View Details',
  signatureOfProposedPolicyholder: 'Signature of Proposed Policyholder',
  notInHongKong: 'Not in Hong Kong',
  beforeYouSignYourName: 'Before you sign your name, please make sure you are in Hong Kong.',
  failedToCheckYourLocation: 'Failed to Check your Location',
  pleasePermitTheAppToAccessYourLocation: 'Please permit Bravo Generali to access your location and try again.',
  ndmInEnglish: '* Please fill in the following form in English *',
  ndmSelf: 'Self',
  ndmPleaseUseTheSameAsMarkedOnYourHKIDPassport: 'Please use the same as marked on your HKID / passport.',
  ndmResidentialAddressIsTheHomeAddress: 'Residential address is the home address where you spent most of your time in a year.',
  ndmYourPolicyOrOtherCorrespondences: 'Your policy or other correspondences will be mailed to this address.',
  ndmMinimum10Maximum100: 'Minimum 10%, maximum 100%.',
  agreeToUsePersonalDataForSendingGiftVouchers: 'I / We agree to the use of my/our personal data for sending gift vouchers or special offer from the Company to me / us.',
  ndmSave: 'Save',
  ndmSaved: 'Saved',
  ndmBeenSaved: 'Your application has been saved.',
  ndmSignature: 'Signature',
  ndmSignatureClear: 'Clear',
  ndmSignatureSave: 'Save',
  ndmSignatureSignYourName: 'Sign Your Name',
  ndmSignatureWithinTheCanvas: 'Please sign your name within the canvas area.',
  ndmSignatureNotSatisfied: 'Not satisfied with this signature?',
  ndmSignatureSignAgain: 'Sign Again',
  ndmSignatureDoYouWantToSignAgain: 'Do you want to sign again?',
  ndmNotRobot: 'I\'m Not a Robot',
  ndmPleaseSmile: 'Please make a smile to prove that you are a human',
  ndmDetectAgain: 'Detect Again',
  ndmPictureNotSatisfied: 'Not satisfied with this picture?',
  ndmVerified: 'You Are Verified',
  ndmSubmitForm: 'Now you may submit the application form. Would you like to submit now?',
  ndmSubmitLater: 'Submit Later',
  ndmPersonalDetailsInsured: 'Personal Details – Insured',
  ndmSameAsPolicyholder: 'Same as Policyholder',
  ndmAge: 'Age',
  ndmGender: 'Gender',
  ndmSmokingStatus: 'Smoking Status',
  ndmPersonalDetailsPolicyholder: 'Personal Details – Policyholder',
  ndmProposalDetails: 'Proposal Details',
  ndmCurrency: 'Currency',
  ndmUSD: 'USD',
  ndmPaymentMode: 'Payment mode',
  ndmAnnual: 'Annual',
  ndmPlanDetails: 'Plan Details',
  ndmPremiumPaymentTerm: 'Premium Payment Term',
  ndm2Years: '2 years',
  ndmPolicyTerm: 'Policy Term',
  ndm5Years: '5 years',
  ndmAnnualPremium: 'Annual Premium',
  ndmAnnualPremiumMinAndMax: 'Min 2,500 - Max 1,000,000 (USD)',
  ndmStandardAnnualPremium: 'Standard Annual Premium',
  ndmPayablePremium: 'Payable Premium',
  ndmModalPremiumStandard: 'Modal Premium (Standard)',
  ndmProposalOptions: 'Proposal Options',
  ndmNoOfYearForPDF: 'No. of Year for PDF',
  ndmInsuredName: 'Insured Name',
  ndmPolicyholderName: 'Policyholder Name',
  thanksForYourSubmission: 'Thanks for your submission. We will get back to you within 2 business days.',

  //CancellationRightsAndRefundOfPremiumAndLevyScreen
  cancellationRightsAndRefundOfPremiumAndLevy: 'Cancellation Rights and Refund of Premium(s) and Levy',
  iProposedPolicyholderUnderstandThatIHave: 'I, proposed policyholder, understand that I have the right to cancel and obtain a refund of any premium(s) and any levy* paid (without interest) by giving written notice. Such notice must be signed by me and received directly by Generali Life (Hong Kong) Limited at 5/F, Generali Tower, 8 Queen’s Road East, Hong Kong within 21 days after the delivery of the policy or issue of a Notice to me / my representative, whichever is the earlier. No refund will be made if a claim payment has been made.',
  theInsuranceAuthorityCollects: '* Effective from 1 January 2018, the Insurance Authority collects levy on insurance premiums from policyholders through insurance companies.',

  //PersonalInformationCollectionStatementScreen
  personalInformationCollectionStatement: 'Personal Information Collection Statement',
  fromTimeToTimeItIsNecessary: 'From time to time, it is necessary for you to supply Generali Life (Hong Kong) Limited (the "Company") with data about yourself(ves),policyholder(s), life insured(s), beneficiary(ies), claimant(s), and /n or other relevant individuals (the "Personal Data") in connection with the provision of insurance and/ or related products and services to you, the processing of claims under insurance policies issued and/ or arranged by the Company, and/ or the processing of any or all other requests, enquiries and complaints from you.',
  provisionOfThePersonal: 'Provision of the Personal Data to the Company by you is voluntary. However, failure to supply the Personal Data may result in the Company being unable to provide insurance and/ or related products and services to you, process claims under insurance policies issued and/ or arranged by the Company, and/ or process any or all other requests, enquiries, or complaints from you.',
  ThePurposesForWhich: 'The purposes for which the Personal Data may be used are as follows:',
  processingYourInsuranceApplication: 'processing your insurance application, arranging and executing insurance contract, and managing your account with the Company',
  customerServices: 'customer services and other related activities;',
  conductingData: 'conducting data matching procedures;',
  designingInsurance: 'designing insurance and/ or related products and services for customers’ use;',
  designingInsuranceAnd: 'marketing insurance and/ or other related products and services of the Company and/ or its parent company and group companies (hereinafter referred to as the “Group Entities”);',
  directMarketingOfInsuranceAnd: 'direct marketing of insurance and/ or other related products and services subject to your prior prescribed consent (if any), and you can exercise the right of opt-out by notifying the Company at any time;',
  statisticalOrActuarialResearch: 'statistical or actuarial research of the Company, its Group Entities, insurance industry associations or federations, governments and/ or regulatory entities;',
  complyingWithTheRequirements: 'complying with the requirements under any laws, rules, regulations, codes, guidelines, court orders, compliance policies and procedures, and any other relevant requirements which the Company and/ or its Group Entities are expected to comply with, including, without limitation, performing due diligence on customers and making disclosures of the relevant information; and',
  fulfillingAnyOther: 'fulfilling any other purposes directly relating to (i) to (viii) above.',
  thePersonalDataHeldByTheCompany: 'The Personal Data held by the Company shall be kept confidential, but the Company may provide the Personal Data to the following parties (whether within or outside the Hong Kong Special Administrative Region) for the purposes set out in paragraph (c) above, without prior notification to you and/ or any other relevant individuals to whom the Personal Data is related:',
  intermediariesClaimsServiceProviders: 'intermediaries, claims service providers, coinsurers, reinsurers, banks and credit-card companies, health and medical organizations, business partners, and/ or any other relevant parties, as appropriate, who provide administrative, telecommunication, computer, payment, marketing, investigation, advisory and/ or other services to the Company in connection with the operation of its business;',
  relevantInsuranceIndustryAssociations: 'relevant insurance industry associations or federations, and/ or members of such industry associations or federations;',
  overseasLocations: 'overseas locations, as appropriate, of the Company and/ or its Group Entities;',
  personsToWhomTheCompany: 'persons to whom the Company and/ or its Group Entities are under an obligation to make disclosure under the requirements as mentioned in (c) (viii);',
  anyCourt: 'any court, government or regulatory entity (including, without limitation, tax authority, insurance authority, etc.) under any laws binding on the Company and/ or its Group Entities;',
  lawfulSuccessors: 'lawful successors or assigns of the Company; and',
  personsWhoOwe: 'persons who owe a duty of confidentiality to the Company and/ or its Group Entities.',
  theCompanyMayVerify: 'The Company may verify any or all of the Personal Data by using information collected and released or transferred by relevant insurance industry associations or federations, and/ or members of such industry associations or federations.',
  inAccordance: 'In accordance with the Personal Data (Privacy) Ordinance:',
  anyIndividual: 'any individual has the right to:',
  checkWhether: 'check whether the Company holds data about him/ her and, if so, obtain a copy of such data;',
  requireTheCompany: 'require the Company to correct any data relating to him/ her that is inaccurate; and',
  ascertainTheCompanysPolicies: 'ascertain the Company’s policies and practices in relation to data and to be informed of the kind of data held by the Company; and',
  rightToCharge: 'the Company has the right to charge a reasonable fee for the processing of any data access request.',
  thePersonToWhomRequests: 'The person to whom requests for access to data and/ or correction of data and/ or for information regarding policies and practices and kinds of data held are to be addressed as follows:',
  personalDataProtectionOffice: 'Personal Data Protection Office',
  generali: 'Generali Life (Hong Kong) Limited',
  adress: '5/F, Generali Tower, 8 Queen’s Road East, Hong Kong.',
  note: 'Note: In case of discrepancies between the English and Chinese versions of this Personal Information Collection Statement, the English version shall prevail.',

  //UseAndProvisionOfPersonalDataInDirectMarketingScreen
  useAndProvision: 'Use and Provision of Personal Data in Direct Marketing ',
  thisSectionFormsPart: '(This section forms part of the Personal Information Collection Statement.)',
  provisionOfConsent: 'Provision of consent in this Section by you is voluntary and it will not affect your application.',
  personalDataIncludingButNotLimited: 'The Personal Data, including but not limited to, name, contact details, other products and services portfolio information, transaction pattern and behavior, financial background and demographic information, etc. may be used for the purpose of direct marketing insurance and/ or other related products and services of the Company and its parent company and group companies (hereinafter referred to as the “Group Entities”) and/ or third parties selected by the Company.',
  personalDataMayAlsoBeProvided: 'The Personal Data may also be provided to the Group Entities and third party service providers selected by the Company for the purpose set out in paragraph (1) above, including, without limitation, call centres.',
  theCompanyRequiresYourConsent: 'The Company requires your consent (which includes an indication of no objection) to the use of Personal Data for the purpose set out in this section. If you do not wish the Company to use or provide to other parties the Personal Data for the purpose of direct marketing, you may exercise the opt-out right below or by notifying the Company at any time thereafter.',

  //ForeignAccountTaxComplianceActScreen
  foreignAccountTaxComplianceAct: 'Foreign Account Tax Compliance Act',
  foreignAccountTax: 'Under the U.S. Foreign Account Tax Compliance Act (“FATCA”), a foreign financial institution (“FFI”) is required to report to the U.S. Internal Revenue Ser ice (“IRS”) certain information on U.S. persons that hold accounts with that FFI outside the U.S. and to obtain their consent to the FFI passing that information to the IRS. An FFI which does not sign or agree to comply with the requirements of an agreement with the IRS (“FFI Agreement”) in respect of FATCA and/or who is not otherwise exempt from doing so (referred to as a “nonparticipating FFI”) will face a 30% withholding tax (“FATCA Withholding Tax”) on all “withholdable payments” (as defined under FATCA) derived from U.S. sources (initially including dividends, interest and certain derivative payments).',
  agreedAnInterGovernmentalAgreement: 'The U.S. and Hong Kong have agreed an inter-governmental agreement (“IGA”) to facilitate compliance by FFIs in Hong Kong with FATCA and which creates a framework for Hong Kong FFIs to rely on streamlined due diligence procedures to (i) identify U.S. indicia, (ii) seek consent for disclosure from its U.S. policyholders and (iii) report relevant tax information of those policyholders to the IRS.',
  fATCAAppliesToGeneraliLife: 'FATCA applies to Generali Life (Hong Kong) Limited (hereinafter “Generali”), and this Policy. Generali is a participating FFI and committed to complying with FATCA. To do so, Generali requires you to:',
  provideToGeneraliCertain: 'provide to Generali certain information including, as applicable, your U.S. identification details (e.g. name, address, the U.S. federal taxpayer identifying numbers, etc); and',
  consentToGenerali: 'consent to Generali reporting this information and your account information (such as account balances, interest and dividend income and withdrawals) to the IRS.',
  ifYouFailToComplyWith: 'If you fail to comply with these obligations (being a “Non-Compliant Accountholder”), Generali is required to report “aggregate information” of account balances, payment amounts and number of non-consenting U.S. accounts to IRS.',
  inCertainCircumstances: 'Generali could, in certain circumstances, be required to impose FATCA Withholding Tax on payments made to, or which it makes from, your Policy.Currently the only circumstances in Generali may be required to do so are:',
  inlandRevenueDepartment: 'if the Inland Revenue Department of Hong Kong fails to exchange information with the IRS under IGA (and the relevant tax information exchange agreement between Hong Kong and the U.S.), in which case Generali may be required to deduct and withhold FATCA Withholding Tax on withholdable payments made to your Policy and remit this to the IRS; and',
  nonparticipatingFfi: 'if you are (or any other account holder is) a nonparticipating FFI, in which case Generali Life (Hong Kong) Limited may be required to deduct and withhold FATCA Withholding Tax on withholdable payments made to your Policy and remit this to the IRS.',
  shouldSeekIndependentProfessional: 'You should seek independent professional advice on the impact FATCA may have on you or your Policy.',
  proposedPolicyholder: 'If the Proposed Policyholder is an individual, please complete the declaration below and provide the information requested. If the Proposed Policyholder is an entity (including but not limited to a trust or a company), such entity does not need to complete the declaration below but must complete a separate form "FATCA Self-Certification for Entities" or Form W-8BENE or Form W-8IMY.',

  //AutomaticExchangeOfInformationScreen
  automaticExchangeOfInformation: 'Automatic Exchange of Information',
  underTheLaws: 'Under the laws, regulations and international agreements for the implementation of automatic exchange of financial account information ("AEOI"), financial institutions are required to identify account holders (including certain policyholders and beneficiaries) and controlling persons of certain entity policyholders who are reportable foreign tax residents and report their information (including but not limited to their name, address, jurisdiction(s) of tax residence, tax identification number in that jurisdiction(s), account balance and income information) to the local tax authority where the financial institution operates. The local tax authority will provide this information to the tax authority of the reportable foreign tax resident’s country of tax residence on a regular, annual basis. The information provided to Generali will be used for the purpose of AEOI. This information and other information regarding the account holder may be transmitted by Generali to the Hong Kong Inland Revenue Department ("IRD") or any other relevant domestic or foreign tax authority for transfer to the tax authority of another jurisdiction. Please browse the IRD website for guidance on AEOI in Hong Kong: http://www.ird.gov.hk/eng/tax/dta_aeoi.htm.',
  theInformationRequired: 'The information required in this Part and the information regarding your name, residence address and date of birth in Part I constitute a self-certification for AEOI purposes. It is an offence under section 80(2E) of the Inland Revenue Ordinance if any person, in making a self-certification, makes a statement that is misleading, false or incorrect in a material particular and knows, or is reckless as to whether, the statement is misleading, false or incorrect in a material particular. ',
  youMustReportAllChanges: 'You must report all changes in your tax residence status to Generali within 30 days of that change.',
  youShouldSeekIndependent: 'You should seek independent professional advice on the impact AEOI may have on you or your Policy.',
  ifTheProposedPolicyholder: 'If the Proposed Policyholder is an individual, please complete the declaration below and provide the information requested. If the Proposed Policyholder is an entity (including but not limited to a trust or a company), such entity does not need to complete the declaration below but must complete separate forms titled "Entity Tax Residency Self-Certification Form" which shall form part of this application form. ',

  //DeclarationAndAuthorizationScreen
  declarationAndAuthorization: 'Declaration and Authorization',
  weAcknowledge: 'I / We acknowledge that I / we have been provided with a copy of the Personal Information Collection Statement (the “Statement”) issued by Generali Life (Hong Kong) Limited (“Generali”). I / We confirm that I / we have read and understood the Statement. I / We agree that Generali may collect, use, store, disclose, transfer and otherwise process my / our personal data in accordance with the terms of the Statement. I / We further confirm that I / we have obtained the express consent of the life insureds and any other relevant individuals (where applicable) for providing their personal data to Generali for the purposes stated in the Statement and for allowing Generali to collect, use, store, disclose, transfer and otherwise process such personal data in accordance with the terms of the Statement.',
  relevantIndividuals: 'I / We acknowledge that I / we have been provided with a copy of the notice on Foreign Account Tax Compliance Act (“FATCA”) and Automatic Exchange of Financial Account Information (“AEOI”) issued by Generali. I / We confirm that I / we have read and understood the notice on FATCA and AEOI. I / We understand that a false statement or misrepresentation of tax status by a U.S. resident for tax purposes (as defined in Part XI) may result in penalty under relevant law and regulations. If my our tax status change and I/we become a U.S. person or a resident for tax purposes in any jurisdiction not previously reported to Generali, I/we must notify Generali no later than thirty (30) days.',
  herebyDeclareAndAgree: 'I / We hereby declare and agree that all statements and information provided in this Application Form are to the best of my / our knowledge and belief complete and true, and all such statements and information shall form the basis and become a part of the policy, and understand that if any such statement or information is incomplete or untrue, the coverage provided under the policy may be void. I / We hereby declare that no information (whether or not it is covered by the questions in this application) which may influence Generali’s assessment and acceptance of application has been withheld and understand that if I / we am / are uncertain as to whether or not a particular information is material, the information should be disclosed.',
  weAuthorizeGeneraliOrAny: 'I / We authorize Generali or any of its appointed medical examiners or laboratories to perform the necessary medical assessment and tests to evaluate the health status of myself / ourselves in relation to this application and any claim arising therefrom. If I / we fail to provide any information requested in this Application From, it may result in Generali’s inability to process this application. I / We authorize any medical attendant, hospital, clinic, insurance company or other organization, institution or person, who / which has any records or knowledge of me / us or my / our health, to divulge to Generali or its authorized representatives or any reinsurers or any tribunal any information he or she or it may have with regard to me / us or the purpose of evaluating this application and any claim arising from the policy. A faxed or photographic copy of this authorization shall be as valid as the original.',
  proposedInsuredsHealth: 'I / We agree to disclose to Generali any change in the Proposed Insured’s health, insurability or other material facts between signing this application and my/our receipt of the policy.',
  iWeConfirmAndAcknowledgeThat: 'I / We confirm and acknowledge that :',
  iWeShallBeResponsibleForObserving: 'I / We shall be responsible for observing and complying with all applicable laws and regulations of any relevant jurisdiction ;',
  ifNecessary: 'If necessary, I / We shall consult independent professional advisers concerning financial, tax, legal or regulatory consequences of purchasing, holding, withdrawing, redeeming, disposing or exercising any rights of this policy. Generali has not provided any advice to me / us in respect of the taxation or citizenship；',
  shouldIWeBeCompelledBy: 'Should I / we be compelled by any applicable laws and regulations of any jurisdiction to redeem, surrender or withdraw from the policy, I / We shall bear any costs , loss or liability incurred as a result of such redemption, surrender or withdrawal;',
  generaliShallBeEntitledTo: 'Generali shall be entitled to, to the extent permitted by laws, submit or report any of my / our Personal Data and other information relating to this policy / application to the relevant governmental authorities, regulator(s), court(s), tribunal(s), administrative board(s) and/or law enforcement bodies (both local and overseas) (collectively known as “ relevant authorities”). Generali shall also be entitled to reply to any inquiry from the relevant authorities in order to comply with all applicable laws and regulations of any relevant jurisdiction. I / We understand and acknowledge that Generali will not be able to provide any insurance or related product and service to me /us if I / we refuse to give the said express consent.',

  //CreditCardPaymentAuthorizationFormScreen
  creditCardPaymentAuthorizationForm:'CREDIT CARD PAYMENT AUTHORIZATION FORM',
  pleaseFillInTheAppropriateBoxesAndInEnglish: 'Please fill in the appropriate boxes and in English (Block Letters)',
  iAuthorizeGeneraliLife: 'I authorize Generali Life (Hong Kong) Limited to debit my credit card account stated as below in respect of the payment of premium, fees and/or charges (if any) and levy* on insurance premium under this application / policy until my further written notice.',
  iunderstandAtLeast: 'I understand at least 2 working days’ written notice in advance is required for termination of this payment instruction.',

  //DirectDebitAuthorizationFormScreen
  directDebitAuthorizationForm: 'DIRECT DEBIT AUTHORIZATION FORM',
  nameOfPartyToBeCredited: 'Name of party to be credited (The Beneficiary)',
  bankNo: 'Bank No.',
  branchNo: 'Branch No',
  accountNoToBeCredited: 'Account No. to be credited',
  ourBelowNamedBankToEffectTransfer: 'I / We hereby authorize my / our below named Bank to effect transfer from my / our account in respect of the payment of premium, fees and/or charges (if any) and levy* on insurance premium under this application / policy to the above named beneficiary in accordance with such instructions as my / our Bank may receive from the beneficiary from time to time.',
  weAgreeThatMy: 'I / We agree that my / our Bank shall not be obliged to ascertain whether or not notice of any such transfer has been given to me / us.',
  weJointlyAndSeverallyAccept: 'I / We jointly and severally accept full responsibility for any overdraft (or increase in existing overdraft) on my / our account which may arise as a result of any such transfer(s).',
  shouldThereBeInsufficientFundsInMy: 'I / We agree that should there be insufficient funds in my / our account to meet any transfer hereby authorized, my / our Bank shall be entitled, at its discretion, not to effect such transfer in which event the Bank may make the usual charge and that it may cancel this authorization at any time on one week’s written notice.',
  authorizationShallHave: 'This authorization shall have effect until further notice.',
  noticeOfCancellationOrVariation: 'I / We agree that any notice of cancellation or variation of this authorization which I / we may give to my / our Bank shall be given at least two working days prior to the date on which such cancellation / variation is to take effect.',

  // footer
  footerPIC: 'Personal Information Collection Statement',
  footerPICHref: 'https://www.generali.com.hk/personal-information-collection-statement',
  footerLegalInformation: 'Legal Information',
  footerLegalInformationHref: 'https://www.generali.com.hk/legal-information',
  footerPrivacy: 'Privacy, Security & Cookies',
  footerPrivacyHref: 'https://www.generali.com.hk/privacy',
  footerEmail: 'Email: bravo@generali.com.hk',
  footerPhone: 'Tel: +852 6682 1112',
  footerCopyright: 'Copyright © 2019 GENERALI LIFE (HONG KONG) LIMITED & ASSICURAZIONI GENERALI S.p.A. – HONG KONG BRANCH',
  footerReserved: 'All rights reserved',
  footerGroup: 'PART OF GENERALI GROUP',
  footerGroupHref: 'http://www.generali.com/',
  tryAgain: 'Our system is temporarily not available, please try again later. ',

  //special case
  paymentStopService1:'Dear Valued Clients,',
  paymentStopService2:'Please be advised that due to service maintenance, purchase attempt via BRAVO during 8 July 23:00 to 9 July 23:00 will be temporarily suspended. We apologize for any inconvenience this may have caused you.',
};
