export const zhHK = {
  quickLinks: '快速鏈接',
  buyAgain: '再次投保',
  loading:'載入中',
  travel: '旅遊保險',
  helper: '家傭保險',
  promotion: '推廣',
  homeScreen: '家居保險',
  test: '測試中',

  login: '登入',
  logout: '登出',
  register: '登記',
  history: '記錄',
  home: '首頁',
  instantEnrollment: '保險產品',
  roarUs: '聯絡我們',
  privilegedClub: '專享禮遇',
  instantClaims: '索償',
  settings: '設定',
  cancel: '取消',
  alert: '請注意',
  pleaseAgree: '請您同意以上法律資訊與私隱資料的闡述。',
  ok: '確認',
  toEnjoyTheBenefits: '想輕鬆查閱保單或索償記錄及體驗一按投保及索償，請即登記成為用戶。',
  register: '註冊',
  forThoseAlreadyRegistered: ' 登入（已註冊過會員）',
  continueWithoutRegistration: '不註冊繼續',
  youUndertake: '您接受並確認：',
  youHavePersonallyRead: '您已閱讀，明白且同意',
  theLegalInformation: '法律資訊',
  privacyStatement: '私隱資料',
  youCanAlsoChoose: '您可以選擇您偏好的語言',
  confirm: '確認',
  hellohello: '您好， ',
  hisofpu:'購買及索償記錄',
  purchase:'簡易報價及投保',
  company:'聯絡我們',
  information:'現金券、優惠及推廣',
  claimpolicy:'簡易索償',
  personalInformation:'語言及保險顧問設定',
  borker: '經紀人',

  personCollection: '個人資料收集聲明',
 legalInformation: '法律信息',
 Privacy: '隱私，安全和暫存紀錄',
 email: 'mailto:bravo@generali.com.hk',
 phone: '+852 6682 1112',
 copy: '版權 © 2019 忠意人壽(香港)有限公司 及 忠意保險有限公司 香港分行',
 reserved: '版權所有',
 group: '忠意集團成員',
 welcome: '歡迎來到Bravo Generali！',
 iemes: '抱歉我們不支援IE。為獲得最佳的瀏覽體驗，請使用以下瀏覽器。',
 submission: '讓您輕鬆提交電子索償！',
 ieborker: '為您的客戶獲取報價和投保',
 tuned: '優惠即將推出，敬請留意！',
 help: '我們在此細心為您服務。',
 setingme: '在此管理您的偏好設定',
 policiemessages: '查看客戶已購買的保單。',

 apply:'新申請全年海外留學保 即賞$100咖啡禮券',
 voucher:'由即日起至8月31日，每張獲成功簽發的「全年海外留學保障」保險，可獲得$100星巴克禮劵。',
 item:'推廣優惠條款及細則：',
 itemfirst:'1. 推廣優惠由即日起至2019年8月31日晚上11時59分(香港時間)。',
 itemsecond:'2. 推廣優惠只適用於「全年海外留學保障」新申請。',
 itemthird:'3. 所有與推廣優惠有關之日期及時間均以忠意保險之伺服器為準。',
 itemfourth:'4. 本推廣優惠只限18歲或以上之持有香港身份証之居民參加。',
 itemfifth:'5. 本推廣優惠所有送出之禮劵，在任何情況下均不可更換或兌換現金。禮劵數量有限，送完即止。',
 itemsixth:'6. 禮品卡將在保單簽發日期後一個月內送出。',
 itemseventh:'7. 若客戶所提供/填寫之資料不實、不正確或有錯漏而導致無法收到得獎通知及禮劵，忠意保險 恕不負責。',
 itemeighth:'8. 本公司對本條款及細則擁有絕對解釋權及保留一切更改或取消本活動及其有關安排的絕對權利。如有任何爭議，本公司保留最終決定權而毋須另行通知。',
 time:'2019年7月10日',

 NeedToMakeClaim:'需要提出索償嗎？',
 followingLink:'提交索償，請點擊以下連結。',
 followingLinkText:'https://www.generali.com.hk/ZH_HK/claims_and_support/individual',
 claimContactText:'理賠部門聯絡電郵:',
 claimContactEmail:'genclaims_info@generali.com.hk',
}
