export default class HengFaChuenChecking {
  static check(address) {
    const searchStrings = [
      'HENGFACHUEN',
      'HANGFACHUEN',
      'HENGFACHUAN',
      'HANGFACHUAN',
      '100SHINGTAIROAD',
      'SHINGTAIROAD',
    ];

    let result = false;

    address = address.toUpperCase().replace(/ /g, '');

    searchStrings.forEach((searchString) =>
      result = result || address.includes(searchString));

    return result;
  }
}
