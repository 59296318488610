import React from 'react';
import {StyleSheet, View, Text} from 'react-native';

import Locale from './../../Locale';
import {NumberInput, Message} from './../../Form';

export default class SumInsuredAmount extends React.Component {
  render() {
    const {cozy, label, value, onChange, message} = this.props;

    return(
      <View>
        <NumberInput
          cozy={cozy}
          label={label}
          thousandSeparator
          value={value}
          onChange={(sumInsured) => onChange(sumInsured)}
          placeholder={Locale.text.pleaseInputHere}
        />

        <Text>
          {Locale.text.itCanBe}
        </Text>

        {
          parseInt(value) > 10000000 &&
            <Text>
              {Locale.text.manualUnderwritingIsRequired}
            </Text>
        }

        {message && <Message>{message}</Message>}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  parseIntText: {
    marginTop: 10,
  },
});
