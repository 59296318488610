import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { host, api11, api12, busiSecKey } from './../../config/api';
import { platform } from './../../config/platform';
import { formStyles } from './../../config/styles';
import { clearEmptyStrings, msgCodeDetail} from './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import Member from './../../Member';
import MemberAPI from './../../api/MemberAPI';
import TextInput from './../../components/TextField/TextField';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import NameInput from './../../components/NameInput/NameInput';
import YesNoQuestion from './../../components/YesNoQuestion/YesNoQuestion';
import LegalDocumentLink from './../../components/LegalDocumentLink/LegalDocumentLink';
import DirectMarketingTickBox from './../../components/DirectMarketingTickBox/DirectMarketingTickBox';
import Alert  from './../../components/Alert/Alert';
import validate from 'validate.js';

export default class Autore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      messages: {},
    };
  }

  componentDidMount() {
    this.props.history.push('/')
  }

 
  render() {
    return (
      <div className='App'>
        <Text>sds</Text>
   </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  fieldsetldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
   paddingRight: 20,
  },
};
