import React from 'react';
import {
 Text, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import TextField from '@material-ui/core/TextField';


export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const { classes } = this.props;
    // let styles = {};
    //
    // if(this.props.style[0]) {
    //   this.props.style.forEach( (style) => {
    //       styles = Object.assign({}, style);
    //   });
    // };

    return(
      <form className={this.props.style} noValidate autoComplete="off">
      <View styles={{weight: '100%', formsize: 1800,}}>
        <TextField
          // style={styles}
          placeholder={this.props.placeholder}
          onChange={(text) => this.props.onChangeText && this.props.onChangeText(text.target.value)}
          value={this.props.value}
          disabled={this.props.editable == false}
          maxlength={this.props.maxLength}
          type={this.props.keyboardType}
          multiline={this.props.multiline}
          margin="auto"
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          />
        </View>
      </form>
    );
  }
}
