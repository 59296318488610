export const enUS = {
  getAQuote: 'Get a Quote',
  generaliPersonalAccidentInsurancePlan: 'Generali Personal Accident Insurance Plan',
  sometimesEvenMinorAccidentsLongContent: 'Sometimes even minor accidents can cause huge disruptions. Loss of income, extended recovery, and long-term medical treatment can be difficult and create unexpected financial burdens. Generali offers highly flexible insurance solutions with custom-designed benefits to fit your specific needs. ',
  forDetailsOfTheProduct:'For details of the product, exculsions and premium rares, please refer to Product Leaflets and Policy Provisions.',
  productEdges:'Product Edges',
  highMedicalExpenses: 'Up to HK$1,000,000 Personal Accident protection',
  hospitaliztionBenefitsInHK: 'Comprehensive Coverage: including Personal Accident, Accidental Medical Expenses, Burns Benefit and others benefits',
  twelveMonthsConsecutiveCover: '10% premium discount for enrollment with spouse (Family Plan)',
  optionalCoverForAccompanied: 'Renewal Bonus',
  zeroExcess: 'Cover for Extra Home Accident Indemnity',

  planType: 'Plan Type',
  thisFieldCannotBeBlank: 'This field cannot be blank',
  plan1: 'Plan 1',
  plan2: 'Plan 2',
  plan3: 'Plan 3',
  tailorMadePlan: 'Tailor Made Plan',

  coverType: 'Cover Type',
  individualOrWithSpouse: 'Individual or with Spouse',
  individual: 'Individual',
  withSpouse: 'Family',
  individualNChildren: 'Individual & Children',
  groupOfIndividuals: 'Group of Individuals',

  insuredOccupation: 'Insured’s Occupation',
  spouseOccupation: 'Spouse’s Occupation',
  
  occupationOfTheInsured: 'Occupation of the Insured',
  occupationOfTheSpouse: 'Occupation of the Spouse',
  occupationOfThe: 'Occupation of the',

  numberOfChildren: 'Number of Children',
  numberOfIndividuals: 'Number of Individuals',

  yes:'Yes',
  no:'No',

  promotionCodes: 'Promotion Codes',
  optional: 'Optional',
  businessEmailAddress: 'Business Email / Membership',
  businessEmailMembership: 'Business Email\n/ Membership',
  iWouldLikeToBuy: 'I would like to buy',
  individual:'Individual',
  individualAndSpouse:'Individual & Spouse',
  occupationOfTheInsured:'Occupation of the Insured',
  levyIsNotIncluded: 'Levy is not included',
  buyNow: 'Buy Now',

  theInsuranceIsIssuedByContent:'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong.',
  pleaseInputInEnglish: '* Please input in English *',
  productType: 'Product Type',
  individual:'Individual',
  effectiveDate: 'Effective Date',
  policyholder: 'Policyholder',
  name: 'Name',
  dateOfBirth:'Date of Birth',
  title:'Title',
  firstName:'First Name',
  lastName: 'Last Name',
  hkid:'HKID',
  a1234567:'A1234567',
  pleaseEnterAllHKID:'Please enter all characters of your HKID. For example, if your HKID is A123456(7), please enter A1234567.',
  dateOfBirth:'Date of Birth',
  pleasePickADate:'Please pick a date',
  mobileNumber:'Mobile Number',
  correspondenceAddress:'Correspondence Address',
  emailAddress: 'Email Address',
  policyholderIs:'Policyholder is',
  theInsured:'the insured',
  oneOfTheInsureds: 'one of the Insureds',
  insured:'Insured',
  name: 'Name',
  sex:'Sex',
  pleaseChoose:'Please choose',
  male:'Male',
  female:'Female',
  rightHanded:'Right Handed',
  occupation:'Occupation',
  otheroccupation:'Other Occupation',
  class1ProfessionAndOccupationsInvolveManilyIndoor:'Class 1 (Professions & occupations involve manily indoor work & non-hazardous nature)',
  questions:'Questions',
  haveYourOrOtherCoveredMembersAPPOflife:'Have your or other covered members’ applications of life, accident or medical insurance ever been declined or postponed, or your insurance ever been modified, rated up, cancelled or refused invitation for renewal? If yes, please state the Insurer, benefit, sum insured, reason, condition, etc.',
  busniesstraveller:'Are you or other covered members are business traveller? If yes, please state the travelling country(ies) and number of trips per year.',
  busniesstravellerIPA4:'Are you or other covered members are business traveller?',
  isselfemployed:'Are you self-employed?',
  manualUnderwritingsIsRequired:'* Manual underwriting is required *',
  insurer:'Insurer',
  benefit:'Benefit',
  reason:'Reason',
  condition:'Condition',
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'Do you or other covered members have any physical or mental impairment or condition? If yes, please state the suffered area or diagnosis etc',
  sufferedArea:'Suffered Area',
  diagnosis:'Diagnosis',
  specialRequests:'Special Requests',
  referral: 'Referral',
  optional: 'Optional',
  buyNow:'Buy Now',

  importantNote:'Important Notes:',
  policyholderAndInsuredsHaveValidHKID:'Policyholder and insureds have valid HKID card and are resident in Hong Kong.',
  premiumChargedWillBeBasedOnTheInsured:'Premium charged will be based on the Insured’s occupation categorized by the Occupation Classification Table.',
  ifACoupleAppliesTogether:'If a couple applies together, each dependent child will be entitled to receive 20% of sum insured for Benefit A1, A2 & A4 and 100% of sum insured for A3, A5 & A6. No cover for Benefit A7.',
  beneficiaryWillbeTheLegalEstateOfTheInsured:'Beneficiary will be the legal Estate of the insured according to the Hong Kong law.',
  assicurazioniGeneraliSpAReserves:'Assicurazioni Generali S.p.A. reserves the right to accept or decline any application.',
  iWECerrifyNotesOfIPAPI:'I/We certify that I/we have read and agreed to the Important Notes of Individual Personal Accident Protector Insurance.',
  iWeCertifyDisclaimerAndPICS:'I/We certify that I/we have read and agreed to the Disclaimer and Personal Information Collection Statement.',
  iWeHerebyAuthorizedAnyLicensed:'I/We hereby authorized any licensed physician, hospital, clinic or other medical or medically related facility, insurance company, institution or persons that has any records or knowledge of myself/ ourselves, to give to the Company any such information for the underwriting and claims assessment process. I/We also authorize all said sources to give such records or knowledge to any agency employed by the Company to collect and transmit such information.',
  premium:'Premium',
  levy:'Levy',
  total:'Total',
  confirm:'Confirm',
  submit:'Submit',

  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  periodOfInsurance: 'Period of Insurance',
  bothDatesInclusive: '(both dates inclusive)',

  spouse: 'Spouse',
  child: 'Child',
  addAChild: 'Add a Child',
  ndChild:'2nd Child',
  fullTimeStudent: 'Full time student, unmarried and unemployed',
  phone: 'Phone',
  email: 'Email',


  iWeDoNotConsentPDto:'I/ We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  iWeDoNotConsentPDToCompanyMarketing:'I/ We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  brokerDeclaration: 'I/We understand, acknowledge and agree that, as a result of purchasing and subscribing the policy to be issued by Assicurazioni Generali S.p.A (Hong Kong Branch), whom will pay the authorized insurance broker commission and provide my/our personal data to the said broker for arranging and managing the said policy.',
  youHave: 'You have ',
  eCashNow: 'Generali voucher now. Would you want to use it?',
  pleaseWait: 'Please Wait',
  getInsured: 'Get Insured',
  payment:'Payment',
  invalidPromotionCode: 'Invalid promotion code',
  addInsuranceAdvisor: 'Add Insurance Advisor',
  wouldYouLike: 'Would you like ',
  toBeYourInsuranceAdvisor: ' to be your insurance advisor?',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the purchase',
  needsToBeInEnglish: 'Needs to be in English',
  thankYou: 'Thank You',
  youHaveSuccessfully: 'You have successfully submitted your application of this ',
  generaliPersonalAccidentInsurancePlan: 'Generali Personal Accident Insurance Plan',
  ccccc: '.',
  manualUnderwriting: ' Manual underwriting is needed and we will get back to you within two business days.',
  inCaseOfAnyQuestions: ' In case of any questions, you can chat with us in the app in section Roar Us.',
  class1: 'Class 1',
  class2: 'Class 2',
  class3: 'Class 3',
  class4: 'Class 4',
  nvolveMainlyIndoorWork: '(Accountant,Architect,Banking \n & Finance Staff(all clerical work),Clerk,Executives,Housewife,Indoor salesmen,Information Technology Staff,Local student,Management & Administrative Staff (all clerical work),Religious Staff,Retiree,Secretary,Teachers,Unemployed)',
  requiringOutdoorWork: '(Beauty & Cosmetic Staff,Chauffeurs,Coaches(Swimming/Badminton/Tennis),Dentist,Doctor,Electronics factory worker,Estate Agent,Factory foremen,Hairdresser,Nurse,Outdoor salesmen,Oversea Student,Social Worker)',
  ofLightManualWorks: '(Coaches(Football/Basketball),Cooks,Driver(Taxi/Van/Bus),Electrician,Light manual works not using heavy or hazardous machinery,Waiter)',
  ofExtraHazardousNature: '(Control of heavy machinery,Crossborder driver,Delivery Workers(excluding motorcycle),Lift & elevator technicians)',
  coverageA1: 'Coverage A1 - Accidental Death & Permanent Total or Partial Disablement',
  coverageA2: 'Coverage A2 - Accidental Medical Expenses',
  coverageB: 'Coverage B - Temporary Total Disablement  ',
  coverageC: 'Coverage C - Double Indemnity',
  coverageD: 'Coverage D - Broken Bones and Burns Benefit',
  accidentalDeathNPermanent: 'Accidental Death & Permanent Total or Partial Disablement',
  sumInsured: 'Sum insured',
  accidentalMedicalExpenses: 'Accidental Medical Expenses',
  temporaryTotalDisablement: 'Temporary Total Disablement',
  doubleIndemnity: 'Double Indemnity',
  brokenBonesAndBurns: 'Broken Bones and Burns',
  check: 'Please check your inputs again.',
  cancel: 'cancel',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',

  alert: 'Alert',
  ok: 'OK',
  submissionFailed: 'Submission Failed',
  invalidMobileNumber: 'Invalid insurance advisor mobile number.',
  tryAgain:'Our system is temporarily not available, please try again later.',

  theInsuranceIsIssued: 'This insurance is issued by Assicurazioni Generali S.p.A. (Hong Kong Branch), an authorized insurer in Hong Kong and arranged by ',
  professionalInsurance: '.',

  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  forBetterCustomerExperience: 'To have a better customer experience, would you want to log in first?',
  forBetterCustomerExperiencelogin:'Log in',
  forBetterCustomerExperiencregister:'Register',
  forBetterCustomerExperienceCon:'Continue without log in',
  relationship: 'Relationship',
  insured: 'Insured',
  spouse: 'Spouse',
  children: 'Children',
  parent: 'Parent',
  parentInLaw: 'Parent-In-Law',
  employee: 'Employee',

  isFulltimeStudent: 'Is a fulltime student?',
  limitedTo10: 'Limited to maximum 10 individuals'
}
