export const enUS = {
  instantClaim: 'Instant Claim',
  helperNPersonalAccidentInsurance: 'Helper & Personal Accident Insurance',
  insuredDetails: 'Insured Details',
  policyNumber: 'Policy Number',
  policyholderName: 'Policyholder Name',
  insuredName: 'Insured Name',
  insuredOccupation: 'Occupation of Insured',
  hkidNumberPassNumber:'HKID card Number / Passport Number',
  hkid: 'HKID',
  passport: 'Passport',
  cancel: 'cancel',
  mobileNumber: 'Mobile Number',
  emailAddress: 'E-mail Address',

  claimDetails: 'Claim Details',
  dateOfAccident: 'Date of Accident',
  pleasePickADate: 'Please pick a date',
  timeOfAccident: 'Time of Accident',
  pleasePickTime: 'Please pick time',
  placeOfAccident: 'Place of Accident',
  howWasItHappened: 'How was it happened?',
  pleaseDescribe: 'Please describe the injuries sustained, indicating the part of body injured and the type of injury, e.g. fracture, cut, bruise and etc.',
  wasTheAccidentReported: 'Was the accident reported to the Police?',
  yes: 'Yes',
  nameOfThePoliceStation: 'Name of the Police Station',
  caseReferenceNumber: 'Case Reference Number',
  no: 'No',
  nameOfDoctorOrHospital: 'Name of Doctor / Hospital',
  dateOfFirstConsultation: 'Date of First Consultation',
  documentsAttached: 'Documents Attached',
  otherInsurance: 'Other Insurance',
  doYouHaveOtherInsurance: 'Do you have other insurance coverage?',
  nameOfInsurer: 'Name of Insurer',
  typeOfCoverage: 'Type of Coverage',
  policyEffectiveDate: 'Policy Effective Date',
  submit: 'Submit',
  pleaseWait: 'Please Wait',

  needstobeInEnglish: 'Needs to be in English',
  thisFieldCannotBeBlank: 'This field cannot be blank',

  congratulations: 'Congratulations',
  youHaveSuccessfullySubmitted: 'You have successfully submitted your claim!',
  yourClaimApplication: 'Your claim application has been received.',
  failedOnSubmittingThisClaim: 'Failed on submitting this claim!',
  messageCode: ' Message Code: ',
  facingErrorWhenSubmitting: 'Facing error when submitting this claim!',
  errorMessage: 'Error message: ',
  isNotAValidHKID: 'is not a valid HKID.',
  insuranceAdvisorMobile: 'Insurance Advisor Mobile Number',
  typeOfClaim: 'Type of Claim',
  employeesCompensation: 'Employees’ Compensation',
  personalAccident: 'Personal Accident / Hospitalization Expenses / Out-patient (Clinical) Expenses',
  medicalExpenses: 'Medical Expenses / Permanent Total or Partial Disablement / Accidental Hospital Cash',
  others: 'Others',
  accidentalDeath: 'Accidental Death',

  notValidEmail:'It is not a valid email address',
  notANumber:'This field only accepts numbers',
  notValidHKID:'It is not a valid HKID',
  theClaimantIs: 'The claimant is under the age of 18',
  descriptionOfTheAccident: 'Description of the Accident',
  claimedAmount: 'Claimed Amount',
  aCopyOfForm: 'A copy of Form 2/2A/2B',
  originalMedicalReceipts: 'Original medical receipt(s) &/or certificate(s), if any;',
  theEmploymentContract: 'The employment contract of the Injured Helper',
  completeDocumentationShowing: 'Complete documentation showing the up-to-date amount of periodical payment and medical expenses the Insured have paid to the Injured Helper',
  theCertificateOfCompensationAssessment: 'The Certificate of Compensation Assessment (i.e. Form 5) and the 31.Certificate of Assessment (i.e. Form 7), if applicable',
  hospitalMedicalBillsReceiptReport: 'Hospital / medical bill(s) / receipt(s) / medical report(s)',
  policeMemo: 'Police memo / statement(s) / report(s) in connection to the accident, if applicable',
  attendingDoctorStatement:'“Attending Doctor Statement”',
  nameOfDoctorHospital: 'Name of Doctor/ Hospital',

  pleaseBeRemindedToMail: 'For inpatient claims, please be reminded to mail us the original copies of medical bills if the bill amount is more than HK$5,000 and its equivalent. We will process your claim upon this digital claim submission but approval will be pending until we receive the original copy. Our office address is: 5/F, Generali Tower, 8 Queen’s Road East, Hong Kong. Please mark the claim reference number on the envelope. You will receive the claim reference number upon successful digital claim submission.',
  proofOfRelationship: 'Proof of relationship (for the claimant under age of 18)',
  deathCertificate: 'Death certificate',

  fieldsMissing: 'Fields Missing',
  someBlankFields: 'There are some blank fields. Do you still want to submit your claim?',
  resumeFilling: 'Resume Filling',

  thankYou: 'Thank You',
  submitted: 'Your claim has been submitted.',
  claimReferenceNumber: 'Claim reference number: ',

  specialRequests: 'Others / Special Request',

  declaration: 'Declaration',
  declaration1: 'I/We declare that the above information is in all respect true and complete to the best of my/our knowledge and belief.',
  declaration2: 'I/We, the claimant, hereby authorize any hospital, physician, clinic, or other person/authority/organization, to furnish to Assicurazioni Generali S.p.A. - Hong Kong Branch (“the Company”) or its authorized representative, any and all information with respect to my loss, injury or illness, medical history, consultation, prescriptions or treatment and copies of police reports, incident reports, statement and all hospital or medical records.',
  declaration3: 'I hereby agree that all documents and receipts submitted to the Company will not be returned.',
  declaration5: 'I/We do not consent to the provision of the Personal Data to the third parties as described herein for the purpose of direct marketing.',
  declaration6: 'I/We do not consent to the use of the Personal Data by the Company for the purpose of direct marketing.',
  theAboveIsRequiredToBeAgreed: 'The above is required to be agreed in order to complete the application',
  reservesTheRight: 'Assicurazioni Generali S.p.A. reserves the right to request the claimants to submit the original copies of the supporting documents. Please keep the originals for at least 3 months after submitting the claim.',

  ok: 'OK',
}
