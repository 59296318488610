import React from 'react';
import {
  Text, Switch,
  View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from './../../../components/DatePicker/DatePicker';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import validate from 'validate.js';
import validid from 'validid';
import md5 from 'md5';

import { enUS } from './en-US';
import { zhHK } from './zh-HK';
import { languageStorage } from './../../../lib/LanguageStorage';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { host, api42, busiSecKey } from './../../../config/api';
import { clearEmptyStrings, hmacSha256, msgCodeDetail } from './../../../lib/utils';
import { memberStorage } from './../../../lib/MemberStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { historyStorage } from './../../../lib/HistoryStorage';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import NameInput from './../../../components/NameInput/NameInput';
import Alert  from './../../../components/Alert/Alert';
import AmountInput from './../../../components/AmountInput/AmountInput';
import IdentityInput from './../../../components/IdentityInput/IdentityInput';
import YesNoQuestion from './../../../components/YesNoQuestion/YesNoQuestion';
import ImportantNotes from './../../../components/ImportantNotes/ImportantNotes';
import DocumentAttached from './../../../components/DocumentAttached/DocumentAttached';
import LegalDocumentLink from './../../../components/LegalDocumentLink/LegalDocumentLink';

export default class HelperAndPersonalAccidentInsurance extends React.Component {
  constructor(props) {
    super(props);
    const policy = this.props.location.state.policy;
    if (policy) {
      this.state = {
        policyNumber: policy.policyNo,
        policyholderTitle: policy.policyHolder.salutation ? policy.policyHolder.salutation.descEnUs.replace(/\./g, '') : undefined,
        policyholderFirstName: policy.policyHolder.firstName,
        policyholderLastName: policy.policyHolder.lastName,
        idType: policy.policyHolder.documentType,
        idNumber: policy.policyHolder.documentNo,
        mobileNumber: policy.policyHolder.mobile,
        emailAddress: policy.policyHolder.email,
        documentsAttached: [],
        messages: {},
        text: {},
      };
    }
    else {
      this.state = {
        //broker: this.props.navigation.getParam('broker'), // BROKER
        product: this.props.location.product,
        documentsAttached: [],
        messages: {},
        text: {},
      };
    }
  }

  componentDidMount() {

    stateStorage.setState('helper', this.state);

    window.scrollTo(0, 0)
    memberStorage.getMember((member, token) => this.setState({
      member: member,
      token: token,
    }));

    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      },
      () => {
        const text = this.state.text;
        validate.validators.hkid = function(value, options, key, attributes) {
          if (!value || validid.hkid(value)) {
            return null;
          }
          else {
            return text.notValidHKID;
          }
        };
      })
    );
  }

  _submit() {
    if (this.state.submitting) {
      return;
    }
    else {
      this.setState({submitting: true});
    }

    const state = clearEmptyStrings(this.state);
    let attributes = {
      policyNumber: state.policyNumber,
      policyholderFirstName: state.policyholderFirstName,
      policyholderLastName: state.policyholderLastName,
      insuredFirstName: state.insuredFirstName,
      insuredLastName: state.insuredLastName,
      insuredOccupation: state.insuredOccupation,
      idType: state.idType,
      idNumber: state.idNumber,
      mobileNumber: state.mobileNumber,
      emailAddress: state.emailAddress,
      declaration1: state.declaration1,
      declaration2: state.declaration2,
      declaration3: state.declaration3,
      declaration4: state.declaration4,
      declaration5: state.declaration5,
      declaration6: state.declaration6,
    };
    if (!this.state.accidentalDeath) {
      attributes.otherInsurance = state.otherInsurance;
    }

    const format = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needstobeInEnglish,
    };

    const declarationFormat = {
      pattern: 'Y',
      message: this.state.text.theAboveIsRequiredToBeAgreed,
    };

    let constraints = {
      policyNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      policyholderLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      insuredFirstName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      insuredLastName: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      insuredOccupation: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: format,
      },
      idType: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      idNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        hkid: this.state.idType == 'HKID',
        format: format,
      },
      mobileNumber: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        numericality: {message: this.state.text.notANumber},
      },
      emailAddress: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        email: {message: this.state.text.notValidEmail},
        format: format,
      },
      declaration1: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration2: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration3: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration4: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
        format: declarationFormat,
      },
      declaration5: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
      declaration6: {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      },
    }
    if (!this.state.accidentalDeath) {
      constraints.otherInsurance = {
        presence: {message: this.state.text.thisFieldCannotBeBlank },
      };
    }

    let messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitting: false,
      });
    }
    else {


            const submit = () => {
              const claimDetails = Object.assign({}, this.state);
              delete claimDetails.documentsAttached;
              delete claimDetails.messages;
              delete claimDetails.text;
              const data = JSON.stringify({
                'policy.policyNo': this.state.policyNumber,
                'policyHolder.salutation': this.state.policyholderTitle,
                'policyHolder.firstName': this.state.policyholderFirstName,
                'policyHolder.lastName': this.state.policyholderLastName,
                'policyHolder.mobile': this.state.mobileNumber,
                'policyHolder.email': this.state.emailAddress,
                'policyHolder.documentType': this.state.idType,
                'policyHolder.documentNo': this.state.idNumber,
                'claim.details': JSON.stringify(claimDetails),
                'timeStamp': Date.now(),
              });
              const hmac = hmacSha256(data);
              let formData = new FormData();
              formData.append('data', data);
              formData.append('hmac', hmac);
              if (this.state.documentsAttached.length > 0) {
                let supportDocs = [];
                let index = 1;
                this.state.documentsAttached.forEach((documentAttached) => {
                  formData.append('supportDocs', {
                    uri: documentAttached.uri,
                    name: `image${index++}.jpg`,
                    type: 'image/jpeg',
                  });
                });
              }
              if (this.state.member) {

const superagent = require('superagent');
superagent
  .post(host + api42)
  .withCredentials()
  .send(formData)
  .end((err, res) => {
    if (err) {
      console.log(err);
      alert('Facing error when submitting your claim!' + err);
      this.setState({submitting: false});
  } else {
      if(res.body.msgCode == 0) {
        this.setState({alert:
          Alert.alert(
            this.state.text.thankYou,
            this.state.text.submitted +
              this.state.text.claimReferenceNumber +
              res.body.msgDesc.claimNo,
            [{
              text: this.state.text.ok,
              onPress: () => this.props.history.push('/')
            }]
          )
        });
      }
      else {
        alert(msgCodeDetail(res.body.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
  }
  });

                // fetch(host + api42, {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'multipart/form-data',
                //   },
                //   body: formData,
                // })
                // .then((response) => response.json())
                // .then((responseJson) => {
                //   if(responseJson.msgCode == 0) {
                //     this.setState({alert:
                //     Alert.alert(this.state.text.thankYou,
                //       this.state.text.submitted
                //       + this.state.text.claimReferenceNumber
                //       + responseJson.msgDesc.claimNo)
                //     });
                //     this.props.history.push('/');
                //   }
                //   else {
                //     alert(msgCodeDetail(responseJson.msgCode)
                //       [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
                //   }
                //   this.setState({submitting: false});
                // })
                // .catch((error) => {
                //   alert('Facing error when submitting your claim!' + error);
                //   this.setState({submitting: false});
                // });
              }
              else {

                stateStorage.getState('helper', (state) => {
                  if (state) {
                    this.setState(state);
                  }
                });

                this.props.history.push('Captcha', {formData: formData});
                this.setState({submitting: false});
              }
            };

            if (messages) {
              this.setState({
                alert:
              Alert.alert(
                this.state.text.fieldsMissing,
                this.state.text.someBlankFields,
                [
                  {text: this.state.text.resumeFilling, style: 'cancel',
                    onPress: () => this.setState({submitting: false})},
                  {text: this.state.text.submit, onPress: () => submit()},
                ],
                { cancelable: false }
              ),
              messages: messages,
            });
              }
            else {
              submit();
            }
          }
        }

  render() {
    return(
  <div className='App'>
      <Navbar
        title={this.state.text.instantClaim}
        backButton
        history={this.props.history}
      />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

              <View style={[styles.greeting,{fontWeight:'bold',}]}>
                <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>Claim Submission</Text>
                  <p></p>
                <Text style={[styles.greetingText,{fontSize: 20,}]}>Easy digital claims submission at your fingertips.</Text>

              </View>

            </Grid>
          </Hidden>

          <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white',}}>
            <ScrollView style={formStyles.form, styles.fieldset}>
            {this.state.alert}
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h2}>{this.state.text.helperNPersonalAccidentInsurance}</Text>
              </View>

              {

                this.state.broker &&
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h4}>{this.state.text.insuranceAdvisorMobile}</Text>
                    <TextInput
                      style={formStyles.input}
                      onChangeText={(text) => this.setState({insuranceAdvisorMobile: text})}
                      value={this.state.insuranceAdvisorMobile}
                    />
                  </View>
              }


              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.insuredDetails}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.policyNumber}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({policyNumber: text})}
                  value={this.state.policyNumber}
                />
                <Text style={formStyles.message}>{this.state.messages.policyNumber}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.policyholderName}</Text>
                <NameInput
                  title={this.state.policyholderTitle}
                  firstName={this.state.policyholderFirstName}
                  lastName={this.state.policyholderLastName}
                  onChange={(title, firstName, lastName) => this.setState({
                    policyholderTitle: title,
                    policyholderFirstName: firstName,
                    policyholderLastName: lastName,
                  })}
                />
                <Text style={formStyles.message}>{this.state.messages.policyholderFirstName}</Text>
                <Text style={formStyles.message}>{this.state.messages.policyholderLastName}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.insuredName}</Text>
                <NameInput
                  title={this.state.insuredTitle}
                  firstName={this.state.insuredFirstName}
                  lastName={this.state.insuredLastName}
                  onChange={(title, firstName, lastName) => this.setState({
                    insuredTitle: title,
                    insuredFirstName: firstName,
                    insuredLastName: lastName,
                  })}
                />
                <Text style={formStyles.message}>{this.state.messages.insuredFirstName}</Text>
                <Text style={formStyles.message}>{this.state.messages.insuredLastName}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.insuredOccupation}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({insuredOccupation: text})}
                  value={this.state.insuredOccupation}
                />
                <Text style={formStyles.message}>{this.state.messages.insuredOccupation}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.hkidNumberPassNumber}</Text>
                <IdentityInput
                  type={this.state.idType}
                  number={this.state.idNumber}
                  onChange={(type, number) => this.setState({
                    idType: type,
                    idNumber: number,
                  })}
                />
                <Text style={formStyles.message}>{this.state.messages.idType}</Text>
                <Text style={formStyles.message}>{this.state.messages.idNumber}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.mobileNumber}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({mobileNumber: text})}
                  value={this.state.mobileNumber}
                  keyboardType='numeric'
                  maxLength={8}
                />
                <Text style={formStyles.message}>{this.state.messages.mobileNumber}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <Text style={formStyles.h4}>{this.state.text.emailAddress}</Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({emailAddress: text})}
                  value={this.state.emailAddress}
                  keyboardType='email-address'
                />
                <Text style={formStyles.message}>{this.state.messages.emailAddress}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.typeOfClaim}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.compensation}
                      onValueChange={(value) => this.setState({
                        compensation: value,
                        personalAccident: false,
                        medicalExpenses: false,
                        accidentalDeath: false,
                        others: false,
                      })}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>{this.state.text.employeesCompensation}</Text>
                  </View>
                </View>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.personalAccident}
                      onValueChange={(value) => this.setState({
                        compensation: false,
                        personalAccident: value,
                        medicalExpenses: false,
                        accidentalDeath: false,
                        others: false,
                      })}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>{this.state.text.personalAccident}</Text>
                  </View>
                </View>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.medicalExpenses}
                      onValueChange={(value) => this.setState({
                        compensation: false,
                        personalAccident: false,
                        medicalExpenses: value,
                        accidentalDeath: false,
                        others: false,
                      })}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>{this.state.text.medicalExpenses}</Text>
                  </View>
                </View>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.accidentalDeath}
                      onValueChange={(value) => this.setState({
                        compensation: false,
                        personalAccident: false,
                        medicalExpenses: false,
                        accidentalDeath: value,
                        others: false,
                      })}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>{this.state.text.accidentalDeath}</Text>
                  </View>
                </View>
              </View>

              <View style={formStyles.fieldset}>
                <View style={formStyles.yesNoQuestion}>
                  <View style={formStyles.yesNoQuestionSwitch}>
                    <Switch
                      value={this.state.others}
                      onValueChange={(value) => this.setState({
                        compensation: false,
                        personalAccident: false,
                        medicalExpenses: false,
                        accidentalDeath: false,
                        others: value,
                      })}
                    />
                  </View>
                  <View style={formStyles.yesNoQuestionText}>
                    <Text>{this.state.text.others}</Text>
                  </View>
                </View>
              </View>

              {
                (!this.state.others) &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.claimDetails}</Text>
                    </View>

                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.accidentDate}
                        mode='date'
                        placeholder={this.state.text.pleasePickADate}
                        format='MMMM D, YYYY'
                        minDate='January 1, 1990'
                        maxDate={moment().format('MMMM D, YYYY')}
                        onDateChange={(date) => {this.setState({accidentDate: date})}}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentTime: text})}
                        value={this.state.accidentTime}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentPlace: text})}
                        value={this.state.accidentPlace}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.howWasItHappened}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentDescription: text})}
                        value={this.state.accidentDescription}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={[formStyles.h4,{textAlign: 'left',}]}>{this.state.text.pleaseDescribe}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({injuriesSustained: text})}
                        value={this.state.injuriesSustained}
                      />
                      <Text style={formStyles.message}>{this.state.messages.injuriesSustained}</Text>
                    </View>

                    <View style={formStyles.fieldset}>
                      <YesNoQuestion
                        question={this.state.text.wasTheAccidentReported}
                        choice={this.state.reportedToPolice}
                        onChange={(choice) => this.setState({reportedToPolice: choice})}
                        />
                      <Text style={formStyles.message}>{this.state.messages.reportedToPolice}</Text>
                    </View>
                    {
                      this.state.reportedToPolice =='Y' &&
                        <View style={styles.indented}>
                          <View style={formStyles.fieldset}>
                            <Text style={formStyles.h4}>{this.state.text.nameOfThePoliceStation}</Text>
                            <TextInput
                              style={formStyles.input}
                              onChangeText={(text) => this.setState({policeStation: text})}
                              value={this.state.policeStation}
                            />
                            <Text style={formStyles.message}>{this.state.messages.policeStation}</Text>
                          </View>
                          <View style={formStyles.fieldset}>
                            <Text style={formStyles.h4}>{this.state.text.caseReferenceNumber}</Text>
                            <TextInput
                              style={formStyles.input}
                              onChangeText={(text) => this.setState({caseReferenceNumber: text})}
                              value={this.state.caseReferenceNumber}
                            />
                            <Text style={formStyles.message}>{this.state.messages.caseReferenceNumber}</Text>
                          </View>
                        </View>
                    }
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.nameOfDoctorHospital}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({doctorName: text})}
                        value={this.state.doctorName}
                      />
                      <Text style={formStyles.message}>{this.state.messages.doctorName}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfFirstConsultation}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.firstConsultationDate}
                        mode='date'
                        placeholder= {this.state.text.pleasePickADate}
                        format='DD/MM/YY'
                        minDate='01/01/90'
                        maxDate={moment().format('DD/MM/YY')}
                        onDateChange={(date) => {this.setState({firstConsultationDate: date})}}
                      />
                      <Text style={formStyles.message}>{this.state.messages.firstConsultationDate}</Text>
                    </View>
                  </View>
              }
              {
                this.state.others &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.claimDetails}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.dateOfAccident}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.accidentDate}
                        mode='date'
                        placeholder={this.state.text.pleasePickADate}
                        format='MMMM D, YYYY'
                        minDate='January 1, 1990'
                        maxDate={moment().format('MMMM D, YYYY')}
                        onDateChange={(date) => {this.setState({accidentDate: date})}}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentDate}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.timeOfAccident}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentTime: text})}
                        value={this.state.accidentTime}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentTime}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.placeOfAccident}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentPlace: text})}
                        value={this.state.accidentPlace}
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentPlace}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.descriptionOfTheAccident}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({accidentDescription: text})}
                        value={this.state.accidentDescription}
                        multiline
                      />
                      <Text style={formStyles.message}>{this.state.messages.accidentDescription}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.claimedAmount}</Text>
                      <AmountInput
                      currency={this.state.claimedAmountCurrency}
                      number={this.state.claimedAmount}
                        onChange={(currency, number) =>
                          this.setState({
                            claimedAmountCurrency: currency,
                            claimedAmount: number,
                          })
                        }
                      />
                      <Text style={formStyles.message}>{this.state.messages.claimedAmountCurrency}</Text>
                      <Text style={formStyles.message}>{this.state.messages.claimedAmount}</Text>
                    </View>
                  </View>
              }

              {
                this.state.compensation &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                    </View>
                    <DocumentAttached
                      text={this.state.text.aCopyOfForm}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.originalMedicalReceipts}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <Text style={{textAlign: 'left',}}>
                      {this.state.text.pleaseBeRemindedToMail}
                    </Text>
                    <DocumentAttached
                      text={this.state.text.theEmploymentContract}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.completeDocumentationShowing}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.theCertificateOfCompensationAssessment}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.others}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                  </View>
              }
              {
                this.state.personalAccident &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                    </View>
                    <DocumentAttached
                      text={this.state.text.hospitalMedicalBillsReceiptReport}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <Text style={{textAlign: 'left',}}>
                      {this.state.text.pleaseBeRemindedToMail}
                    </Text>
                  </View>
              }
              {
                this.state.medicalExpenses &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                    </View>
                    <DocumentAttached
                      text={this.state.text.hospitalMedicalBillsReceiptReport}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <Text style={{textAlign: 'left',}}>
                      {this.state.text.pleaseBeRemindedToMail}
                    </Text>
                    <DocumentAttached
                      text={this.state.text.policeMemo}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.attendingDoctorStatement}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.proofOfRelationship}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                    <DocumentAttached
                      text={this.state.text.others}
                      onSelect={(selectedDocument) =>
                        this.state.documentsAttached.push(selectedDocument)}
                    />
                  </View>
              }
              {
                this.state.accidentalDeath &&
                <View>
                  <DocumentAttached
                    text={this.state.text.deathCertificate}
                    onSelect={(selectedDocument) =>
                      this.state.documentsAttached.push(selectedDocument)}
                  />
                  <DocumentAttached
                    text={this.state.text.proofOfRelationship}
                    onSelect={(selectedDocument) =>
                      this.state.documentsAttached.push(selectedDocument)}
                  />
                  <DocumentAttached
                    text={this.state.text.others}
                    onSelect={(selectedDocument) =>
                      this.state.documentsAttached.push(selectedDocument)}
                  />
                </View>
              }
              {
                this.state.others &&
                <View>
                  <View style={formStyles.fieldset}>
                    <Text style={formStyles.h3}>{this.state.text.documentsAttached}</Text>
                  </View>
                  <DocumentAttached
                    text={this.state.text.others}
                    onSelect={(selectedDocument) =>
                      this.state.documentsAttached.push(selectedDocument)}
                  />
                </View>
              }
              {
                !this.state.accidentalDeath &&
                  <View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h3}>{this.state.text.otherInsurance}</Text>
                    </View>

                    <View style={formStyles.fieldset}>
                      <YesNoQuestion
                        question={this.state.text.doYouHaveOtherInsurance}
                        choice={this.state.otherInsurance}
                        onChange={(choice) => this.setState({otherInsurance: choice})}
                        />
                      <Text style={formStyles.message}>{this.state.messages.otherInsurance}</Text>
                    </View>
                  </View>
              }
              {
                this.state.otherInsurance == 'Y' ?
                  <View style={styles.indented}>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.nameOfInsurer}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({otherInsuranceInsurerName: text})}
                        value={this.state.otherInsuranceInsurerName}
                      />
                      <Text style={formStyles.message}>{this.state.messages.otherInsuranceInsurerName}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.typeOfCoverage}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({otherInsuranceCoverageType: text})}
                        value={this.state.otherInsuranceCoverageType}
                      />
                      <Text style={formStyles.message}>{this.state.messages.otherInsuranceCoverageType}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.policyNumber}</Text>
                      <TextInput
                        style={formStyles.input}
                        onChangeText={(text) => this.setState({otherInsurancePolicyNumber: text})}
                        value={this.state.otherInsurancePolicyNumber}
                      />
                      <Text style={formStyles.message}>{this.state.messages.otherInsurancePolicyNumber}</Text>
                    </View>
                    <View style={formStyles.fieldset}>
                      <Text style={formStyles.h4}>{this.state.text.policyEffectiveDate}</Text>
                      <DatePicker
                        style={formStyles.datepicker}
                        date={this.state.otherInsuranceEffectiveDate}
                        mode='date'
                        placeholder={this.state.text.pleasePickADate}
                        format='DD/MM/YY'
                        minDate='01/01/90'
                        maxDate={moment().format('DD/MM/YY')}
                        onDateChange={(date) => {this.setState({otherInsuranceEffectiveDate: date})}}
                      />
                      <Text style={formStyles.message}>{this.state.messages.otherInsuranceEffectiveDate}</Text>
                    </View>
                  </View> : null
              }

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.specialRequests}</Text>
              </View>
              <View style={formStyles.fieldset}>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({specialRequests: text})}
                  value={this.state.specialRequests}
                />
                <Text style={formStyles.message}>{this.state.messages.specialRequests}</Text>
              </View>

              <View style={formStyles.hr} />

              <View style={formStyles.fieldset}>
                <Text style={formStyles.h3}>{this.state.text.declaration}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.declaration1}
                  choice={this.state.declaration1}
                  onChange={(choice) => this.setState({declaration1: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration1}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.declaration2}
                  choice={this.state.declaration2}
                  onChange={(choice) => this.setState({declaration2: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration2}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.declaration3}
                  choice={this.state.declaration3}
                  onChange={(choice) => this.setState({declaration3: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration3}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <LegalDocumentLink
                  navigation={this.props.navigation}
                  choice={this.state.declaration4}
                  noDisclaimer
                  onChange={(choice) => this.setState({declaration4: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration4}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.declaration5}
                  choice={this.state.declaration5}
                  onChange={(choice) => this.setState({declaration5: choice})}
                />
              <Text style={formStyles.message}>{this.state.messages.declaration5}</Text>
              </View>

              <View style={formStyles.fieldset}>
                <YesNoQuestion
                  question={this.state.text.declaration6}
                  choice={this.state.declaration6}
                  onChange={(choice) => this.setState({declaration6: choice})}
                />
                <Text style={formStyles.message}>{this.state.messages.declaration6}</Text>
              </View>

              {
                this.state.submitting ?
                  <View style={formStyles.submitButtonDisabled}>
                    <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
                  </View> :
                  <TouchableOpacity style={formStyles.submitButton} onPress={() => this._submit()}>
                    <Text style={formStyles.submitButtonText}>{this.state.text.submit}</Text>
                  </TouchableOpacity>
              }
            </ScrollView>
          </Grid>
            <Hidden xsDown>
             <div style={{marginRight: '5%'}} />
            </Hidden>
        </Grid>
        <Footer/>
    </div>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  indented: {
    marginLeft: 20,
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 580,
    paddingLeft: 20,
   paddingRight: 20,
  },
};
