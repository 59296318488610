import React from 'react';
import {StyleSheet, TouchableOpacity, Text} from 'react-native';

export default class Attachment extends React.Component {
  render() {
    const {children, onPress} = this.props;

    return (
      <TouchableOpacity onPress={onPress}>
        <Text style={styles.attachment}>{children}</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  attachment: {
    color: '#1F4E79',
    textDecorationLine: 'underline',
  },
});
