import Locale from './../Locale';

export default class LanguageStorage {
  setLanguage(value, callback) {
    Locale.setLocale(value);
    localStorage.setItem('lang', value);
    callback && callback();
  }

  initLanguage(locale, callback) {
    this.getLanguage(callback);
  }

  getLanguage(callback) {
    const lang = localStorage.getItem('lang');

    if (lang) {
      callback(lang);
    }
    else {
      this.setLanguage(defaultLanguage, () => callback(defaultLanguage));
    }
  }

  removeLanguage(callback) {
    localStorage.removeItem('lang');
    callback && callback();
  }
}

const defaultLanguage = 'enUS';

export const languageStorage = new LanguageStorage();
