export const zhHK = {
  privilegedClub: '專享禮遇',
  welcomeOffer: 'BRAVO Generali 迎新獎賞',
  voucher: 'Generali 現金券餘額',
  loading: '載入中……',

  // voucher
  failedToRedeem: '領取失敗',
  redeemSuccessfully: '成功領取',
  ok: '確認',
  confirm:'確認',
  voucherHeader:'輸入您的Generali現金券代碼',
  voucherInputPlaceHolder:'請輸入您的Generali現金券代碼',
  notLoggedIn:'請先登入您的賬戶或按「登記帳戶」建立您的帳戶。',
  usedVoucher:'現金券代碼已被使用。',
  invalidVoucher:'現金券代碼無效,請重新輸入。',
  expiredVoucher:'現金券代碼已過期。',
  offerExpired: '抱歉！此優惠已過期。',
  canOnlyEnjoyOurOfferOnce: '抱歉！每位客戶只能享用一次我們的優惠。',
  forNewRegisteredMembers: '抱歉！此優惠只適用於新註冊會員。',
  notEligibleToThisOffer: '抱歉！您未能享有此優惠。',
  noLongerAvailable: '抱歉，此優惠不再生效。',
  voucherBalanceIsCredited: '恭喜！您的優惠卷已入帳，金額為港幣',
  youCanCheckYourBalanceAnytime: '元。您可以隨時於「專享禮遇」中查看餘額。',
  pleaseLogin: '登入/登記帳戶',
  pleaseLoginWithYourAccount: '領取優惠卷之前，請先用您的帳號登入。',
  login: '登入',
  tuned: '優惠即將推出，敬請留意！',
  TC:'條款及細則',
  terms1:'1. Generali 現金券僅限於「Bravo Generali」平台支付保費時使用。自領取之日起1年內有效,逾期未使用自動失效。' ,
  terms2:'2. Generali 現金劵,不可轉讓,亦不可兌換現金或其他貨品。如退保或者退款,獎賞所對應 的金額不作為退款款項。' ,
  terms3:'3. 本公司對本條款及細則擁有絕對解釋權及保留一切更改的絕對權利。如有任何爭議,忠意 保留最終決定權而毋須另行通知。',
  balanceText:'Generali現金券餘額 = 港幣＄',
  expiryDateText:'有效期至：',
  forNewJoiner:'優惠券代碼無效，請重新輸入。'
}
