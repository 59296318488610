export const zhHK = {
  getAQuote: '索取報價',
  executivePersonalAccidentProtector: '行政人員人身意外保障',
  sometimesEvenMinorAccidentsLongContent: '行政人員人身意外保障特設禮賓服務，為專注工作同時著重生活享受的您，提供二十四小時貼心私人服務專線讓您輕鬆處理生活日常事務',
  productEdges: '產品特點',
  highBenefitLimits: '提供大額保障(高達HK$10,000,000的人身意外保障)',
  allInOnePackageWithPersonalAccident: '一份保障涵蓋個人意外，意外醫療費用及住院現金',
  competitiveFamilyPlan: '更實惠的家庭計劃',
  oneStopGenerali: '一站式禮賓服務專線',
  forDetailsOfTheProduct:'產品內容無免責條款跟最高費用，請參照產品徵費與政策條款。',

  planType: '產品類別',
  individual: '個人',
  family: '家庭',
  individualPlan: '個人計劃',
  familyPlan: '家庭計劃',
  promotionCodes: '優惠代碼',
  optional: '非必填',
  businessEmailAddress: '商業電郵 / 會員',
  businessEmailMembership: '商業電郵 / 會員',

  iWouldLikeToBuy: '我想投保',
  total: '合共$4800',
  levyIsNotIncluded: '不包括保費徵費及有關費用',
  buyNow: '立即購買',

  getInsured: '立即投保',
  theInsuranceIsIssued: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ',
  professionalInsurance: ' 安排。',
  insuranceAdvisorMobile:'保險顧問電話號碼',
  productType:'產品類型',
  pleaseInputInEnglish: '*請輸入英文*',
  effectiveDate: '生效日期',
  periodOfInsurance: '保障期限',
  bothDatesInclusive: '(包含開始日及結束日)',
  policyholder: '保單持有人',
  name: '姓名',
  title:'稱謂',
  firstName:'名字',
  lastName: '姓',
  hkid:'香港身份證',
  pleaseEnterAllHKID:'請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。',
  dateOfBirth:'出生日期',
  spouse: '配偶',
  first: '第 1 位',
  child: '子女',
  addAChild: '新增子女',
  ndChild:'第 2 位子女',
  questions: '問題',
  pleasePickADate:'請選擇日期',
  mobileNumber:'手機號碼',
  correspondenceAddress:'通訊地址',
  emailAddress: '電郵地址',
  policyholderIs:'投保人',
  theInsured:'也是受保人',
  oneOfTheInsureds: '也是受保人之一',
  insured:'受保人',
  occupationClericalAndNonManualWork:'職業:  文書和非體力勞動工作',
  fullTimeStudentUnmarriedAndUnemployed: '全職學生，未婚及沒有就業',
  yes:'是',
  no:'否',
  manualUnderwritingsIsRequired:'* 您的申請需要核保 *',
  questions:'問題',
  haveYourOrOtherCoveredMembersAPPOflife:'閣下或其他受保家庭成員有否已投保意外、疾病、傷殘、醫療或人壽保險被拒保、延擱或撤銷或曾持有該種保險或證書，而於事後曾被修正、增加保費、取消、或被拒絕續保？',
  insurer:'投保人',
  benefit:'保障項目',
  sumInsured:'投保金額',
  reason:'原因',
  condition:'現狀',
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'有任何身心疾病狀況或不適?',
  sufferedArea:'殘疾部位',
  diagnosis:'診斷',
  thisFieldCannotBeBlank: '必須填寫空格',
  specialRequests:'特別要求',
  referral: '介紹碼',

  importantNote:'重要聲明',
  beneficiaryWillBeTheLE:'根據香港法例，受益人將是受保人的法定繼承人。',
  assicurazioniGeneraliSpAReserves:'忠意保險有限公司　(香港分行)　保留接受或拒絕任何申請的權利。',
  // iWECerrifyNotesOfIPAPI:'我 / 我們證明我/我們已閱讀並同意行政人員人身意外保障的重要聲明。',
  iWeCertifyDisclaimerAndPICS:'我 / 我們證明我/我們已閱讀並同意免責聲明和收集個人資料聲明。',
  iWeHerebyAuthorizedAnyLicensed:'我/ 我們特此授權任何有註冊的醫生、醫院、診所或任何有關之醫療設施，保險公司或任何熟悉我/ 我們健康情況之組織或人士，將我/ 我們過往之病狀或病歷詳細資料提供予貴公司或貴公司之代表以作承保和索賠。我 / 我們還授權所有上述來源向貴公司僱用的任何機構提供此類記錄或知識，以收集和傳輸此類信息。',
  iWeDoNotConsentToTheProvision: '我/我們不同意提供個人資料被第三方作為行銷用途使用',
  // iWeDoNotConsentToTheUse: '我/我們不同意提供個人資料給貴公司作為行銷用途',

  confirm:'確認',
  payment: '付款',
  youHave: '您有 ',
  eCashNow: 'Generali 現金券，是否要使用它？',
  executivePersonalAccidentProtector: '行政人員人身意外保障',
  submit:'提交',
  pleaseWait:'請稍後',
  sexsex: '性別',

  brokerDeclaration: '我/我們明白，確知及同意，忠意保險有限公司（香港分行） 會就本人的申請而簽發的保單，向安排保單事宜的獲授權保險經紀或保險代理',
brokerDeclarationII:'支付佣金，並向其提供閣下之個人資料，作處理及管理該保單之用途 。',
  invalidPromotionCode: '無效的優惠代碼',
  addInsuranceAdvisor: '新增保險顧問',
  wouldYouLike: '您願意 ',
  toBeYourInsuranceAdvisor: ' 成為您的保險顧問嗎？',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成購買',
  needsToBeInEnglish: '需以英文填寫',
  thankYou: '謝謝！',
  youHaveSuccessfully: '您已成功提交',
  ccccc: '申請。',
  manualUnderwriting: '您的申請需要核保，我們會在兩個工作天內回覆您。',
  youCanAlsoFindYour: '您可以在訂單歷史區域找到您的申請資訊。',
  inCaseOfAnyQuestions: '假如有任何疑問，您可透過此 APP 的「聯絡我們」功能與我們聯絡。',
  check: '請檢查您的輸入一遍！',
  cancel: '取消',
  theInsuranceIsIssuedByContent: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。',

  notValidEmail:'這不是有效的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是有效身份証號碼',

  alert: '請注意',
  ok: '確認',
  submissionFailed: '提交失敗',
  invalidMobileNumber: '無效的保險顧問電話號碼。',
  tryAgain: '我們的系統暫時不可用，請稍後再試',

  congratulations: '恭喜',
  madeAPurchase: '你已成功購買這項產品\n',
  weWillSend: '接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱',
  anyQuestions: '\n如有任何疑問，歡迎隨時與我們聯絡，謝謝',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  epa: '行政人員人身意外保障',
  enjoy: '設有24小時私人禮賓服務，讓您可以坐享理想生活。',
  forBetterCustomerExperience: '為了獲得更好的客戶體驗，您想先登入嗎？',
  forBetterCustomerExperiencelogin:'會員登入',
  forBetterCustomerExperiencregister:'註冊新會員',
  forBetterCustomerExperienceCon:'不登入繼續',

}
