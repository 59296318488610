import React from 'react';
import moment from 'moment';

import config from './../../config';
import Locale from './../../Locale';
import Member from './../../Member';
import Validation from './../../Validation';
import ApplyAPI from './../../api/ApplyAPI';
import AgentAPI from './../../api/AgentAPI';
import InsuranceProductScreen from './../InsuranceProductScreen';

export default class CYBERScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, 'TRAVEL');

    this.premiumPlanOptions = [
      {value: '1', label: Locale.text.plan1},
      {value: '2', label: Locale.text.plan2},
    ];

    const {insuranceProduct} = this.state;

    if (!insuranceProduct.pl) {
      insuranceProduct.pl = 'CYBER';
      insuranceProduct.cyber = {premiumPlan: '1'};
      insuranceProduct.insuredPeople = [{}];
    }
  }

  updateCYBER(data) {
    this.updateInsuranceProduct('cyber', data);
  }

  updateInsuredPeople(index, data) {
    const {insuredPeople} = this.state.insuranceProduct;

    if (insuredPeople[index]) {
      Object.assign(insuredPeople[index], data);
    }
    else {
      insuredPeople[index] = data;
    }

    this.setState({insuranceProduct: this.state.insuranceProduct});
  }

  getPremiumPlanLabel() {
    const {cyber} = this.state.insuranceProduct;

    switch (cyber.premiumPlan) {
      case '1': return Locale.text.plan1;
      case '2': return Locale.text.plan2;
    }
  }

  componentDidUpdate() {
    const {cyber, insuredPeople} = this.state.insuranceProduct;
  }

  buyAgain() {
    if (super.buyAgain()) {
      const {insuranceProduct} = this.state;
      const {dataFormat} = config.date;

      insuranceProduct.cyber = this.getParam('cyber');
      insuranceProduct.insuredPeople = this.getParam('insuredPeople');

      insuranceProduct.insuredPeople.forEach((member) => {
        member.dateOfBirth = moment(member.dateOfBirth).format(dataFormat);
      });
    }
  }

  getQuote(nextScreen) {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        cyber, payment, insuredPeople} = this.state.insuranceProduct;

      const {trimAndRemoveEmptyStrings, getValidator} = Validation;

      [agent, policy, policyHolder, cyber, payment].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator('text'),
          promotionCode: getValidator('text'),
        }
      );

      messages.discount = discountValidation.validate();

      if (nextScreen == 'Cyber4') {
        const agentValidation = new Validation(
          agent,
          {mobile: getValidator('number')}
        );

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(
          policy,
          {
            effectiveDateStart: getValidator('text', true),
            specialRequest: getValidator('text'),
          }
        );

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(
          policyHolder,
          {
            firstName: getValidator('text', true),
            lastName: getValidator('text', true),
            mobile: getValidator('number', true),
            email: getValidator('email', true),
            address1: getValidator('text'),
          }
        );

        messages.policyHolder = policyHolderValidation.validate();

        const insuredPeopleMessages = [];

        insuredPeople.forEach((member, index) => {
          const memberValidation = new Validation(
            member,
            {
              firstName: getValidator('text', true),
              lastName: getValidator('text', true),
              documentType: getValidator('text', true),
              documentNo: getValidator('HKID', true),
              dateOfBirth: getValidator('text', true),
            }
          );

          const memberMessages = memberValidation.validate();

          if (memberMessages) {
            insuredPeopleMessages[index] = memberMessages;
          }
        });

        if (JSON.stringify(insuredPeopleMessages) != '[]') {
          messages.insuredPeople = insuredPeopleMessages;
        }
      }

      const invalid = JSON.stringify(messages) != '{}';

      if (invalid) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.readPremium({
          'pn': pn,
          'pl': pl,
          'member.id': Member.getMemberId(),
          'member.token': Member.getMemberToken(),
          'policy.effectiveDateStart': policy.effectiveDateStart,
          'policy.businessEmail': policy.businessEmail,
          'cyber.premiumPlan': cyber.premiumPlan,
          'payment.promotionCode': payment.promotionCode,
        })
        .then(({premium, levy}) => {
          this.push(nextScreen, {premium, levy});
        });
      }
    });
  }

  confirm() {
    this.setState({waiting: true}, () => {
      const {pn, pl, agent, policy, policyHolder,
        cyber, insuredPeople, payment} = this.state.insuranceProduct;

      const {getValidator, getProductConfirmationValidator} = Validation;

      const confirmationValidation = new Validation(
        policy,
        {
          disclaimerAndPIC: getProductConfirmationValidator(),
          allowPrivacyPromote: getValidator('text', true),
          commissionsDisclosures:
            (this.state.agent || this.state.broker || payment.promotionCode) &&
              getProductConfirmationValidator(),
        }
      );

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(
          Locale.text.alert,
          Locale.text.check,
          [{text: Locale.text.ok, onPress: () => this.dismissAlert()}]
        );

        this.setState({messages, waiting: false});
      }
      else {
        this.apply(
          Locale.text.cyberInsurance,
          this.state.broker ? AgentAPI.cyber : ApplyAPI.cyber,
          {
            'pn': pn,
            'pl': pl,
            'member.id': Member.getMemberId(),
            'member.token': Member.getMemberToken(),
            'agent.mobile': agent.mobile,
            'policy.effectiveDateStart': policy.effectiveDateStart,
            'policy.effectiveDateEnd': policy.effectiveDateEnd,
            'policy.allowPrivacyPromote3P': policy.allowPrivacyPromote3P,
            'policy.allowPrivacyPromote': policy.allowPrivacyPromote,
            'policy.businessEmail': policy.businessEmail,
            'policy.specialRequest': policy.specialRequest,
            'policyHolder.salutation': policyHolder.salutation,
            'policyHolder.firstName': policyHolder.firstName,
            'policyHolder.lastName': policyHolder.lastName,
            'policyHolder.mobile': policyHolder.mobile,
            'policyHolder.email': policyHolder.email,
            'policyHolder.address1': policyHolder.address1,
            'policyHolder.documentType': policyHolder.documentType,
            'policyHolder.documentNo': policyHolder.documentNo,
            'policyHolder.dateOfBirth': policyHolder.dateOfBirth,
            'cyber.premiumPlan': cyber.premiumPlan,
            'insuredPeople': insuredPeople,
            'payment.ecash': payment.ecash,
            'payment.promotionCode': payment.promotionCode,
            'rawRequest': JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }
}
