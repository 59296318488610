import React from 'react';
import {Text, View} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import {Layout, Navbar} from './../../Screen';
import {Form, Heading1, DropDownList,
  Divider, Button, YesNoQuestion} from './../../Form';
import FireScreen from './FireScreen';
import InsuredHomeForm from './InsuredHomeForm';
import SumInsuredAmount from './SumInsuredAmount';
import LegalStatement from './../components/LegalStatement';
import MobileNumberOfTheInsuranceAdvisor
  from './../components/MobileNumberOfTheInsuranceAdvisor';
import EffectiveDate from './../components/EffectiveDate';
import DiscountInput from './../components/DiscountInput';
import PolicyHolderForm from './../components/PolicyHolderForm';
import SpecialRequest from './../components/SpecialRequest';
import ReferralCode from './../components/ReferralCode';

export default class FireScreen3 extends FireScreen {
  render() {
    const {
      insuranceProduct: {agent, policy, policyHolder, insuredHome, payment},
      messages,
    } = this.state;

    return (
      <Layout alert={this.state.alert}>
        <Navbar
          screen={this}
          title={Locale.text.getInsured}
        />

        {Locale.text.fireInsurance}
        {Locale.text.fireInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.fireInsurance}
            </Heading1>

            <LegalStatement
              broker={this.state.broker}
              agent={this.state.agent}
            />

            <Text>
              {Locale.text.pleaseInputInEnglish}
            </Text>

            <MobileNumberOfTheInsuranceAdvisor
              broker={this.state.broker}
              value={agent.mobile}
              onChange={(mobile) => this.updateAgent({mobile})}
              messages={this.state.messages.agent}
            />

            <DropDownList
              label={Locale.text.productType}
              options={this.fireOption}
              value={'FIRE'}
              onChange={(fire) =>
                this.updateInsuredHome({fire})}
            />

            <SumInsuredAmount
              placeholder
              label={Locale.text.fireSumInsuredAmount}
              value={insuredHome.sumInsured}
              onChange={(sumInsured) => this.updateInsuredHome({sumInsured})}
              message={messages.insuredHome && messages.insuredHome.sumInsured}
            />

            <DropDownList
              label={Locale.text.ageOfBuilding}
              options={this.buildingAgeOptions}
              value={insuredHome.buildingAge}
              onChange={(buildingAge) => this.updateInsuredHome({buildingAge})}
            />

            {
              insuredHome.buildingAge > 50 &&
                <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
            }

            <EffectiveDate
              value={policy.effectiveDateStart}
              onChange={(effectiveDate) => this.updatePolicy(effectiveDate)}
              messages={this.state.messages.policy}
            />

            <DiscountInput
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={!this.state.agent && !this.state.broker}
              onChange={({promotionCode, businessEmail}) => {
                this.updatePayment({promotionCode});
                this.updatePolicy({businessEmail});
              }}
              messages={this.state.messages.discount}
            />

            <Divider />

            <PolicyHolderForm
              noDocument
              noDateOfBirth
              policyHolder={policyHolder}
              onChange={(data) => this.updatePolicyHolder(data)}
              messages={this.state.messages.policyHolder}
            />

            <InsuredHomeForm
              insuredHome={insuredHome}
              onChange={(data) => this.updateInsuredHome(data)}
              messages={this.state.messages.insuredHome}
            />

            <Divider />

            <SpecialRequest
              value={policy.specialRequest}
              onChange={(specialRequest) =>
                this.updatePolicy({specialRequest})}
              messages={messages.policy}
            />

            <ReferralCode
            value={policy.referralCode}
              onChange={(referralCode) =>
                this.updatePolicy({referralCode})}
              messages={this.state.messages.referralCode}>
            </ReferralCode>

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote('Fire4')}
            >
              {Locale.text.buyNow}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
