export const enUS = {
  manualUnderwriting: 'Manual underwriting is needed',
  total: 'Total',
  total2: 'Total',
  flatDiscount: 'Flat Discount',
  finalTotal: 'Final Total',
  pleaseWait: 'Please wait',
  levyIsnotincluded: 'Levy is not included',
  roundedToTheNearestPercentage: '*Rounded to the nearest percentage',
  less: 'Less',
  currency: 'HK$'
};
