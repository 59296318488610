import React from 'react';
import { Text } from 'react-native';

import Locale from './../../Locale';
import { FormElement, Heading2, Input, Message } from './../../Form';

export default class SpecialRequest extends React.Component {
  render() {
    const {value, onChange, messages} = this.props;

    return (
      <FormElement>
        <Heading2>{Locale.text.specialRequests}</Heading2>

        <Input
          placeholder={Locale.text.optional}
          value={value}
          onChange={(specialRequest) => onChange(specialRequest)}
        />

        {!!value && <Text>{Locale.text.manualUnderwritingIsRequired}</Text>}

        {messages && <Message>{messages.specialRequest}</Message>}
      </FormElement>
    );
  }
}
