import React from 'react';
import { Text, View } from 'react-native';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextInput from './../../components/TextField/TextField';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { formatName } from './../../lib/utils';
import { languageStorage } from './../../lib/LanguageStorage';
import { formStyles } from './../../config/styles';

export default class NameInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
    };
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language)}));
  }

  render() {
    return (
      <View>
        <Text>{this.props.placeholder}</Text>
        <View style={styles.nameInput}>
          <View style={styles.title}>

            <Select
              style={styles.field}
              value={this.props.title}
              onChange={(event) => this.props.onChange(
                event.target.value, this.props.firstName, this.props.lastName)}
            >
              <MenuItem value='Mr'>{this.state.text.mr}</MenuItem>
              <MenuItem value='Ms'>{this.state.text.ms}</MenuItem>
              <MenuItem value='Mrs'>{this.state.text.mrs}</MenuItem>
              <MenuItem value='Miss'>{this.state.text.miss}</MenuItem>
            </Select>
          </View>
          <View style={styles.name}>
            <TextInput
              style={[formStyles.input,{paddingLeft: 10}]}
              onChangeText={(text) =>
                this.props.onChange(this.props.title, text, this.props.lastName)}
              value={this.props.firstName}
              placeholder={this.state.text.firstName}
              placeholderTextColor='lightgrey'
            />
          </View>
          <View style={styles.name}>
            <TextInput
              style={[formStyles.input,{paddingLeft: 10}]}
              onChangeText={(text) =>
                this.props.onChange(this.props.title, this.props.firstName, text)}
              value={this.props.lastName}
              placeholder={this.state.text.lastName}
              placeholderTextColor='lightgrey'
            />
          </View>
        </View>
        <Text>
          {formatName(
            this.props.title, this.props.firstName, this.props.lastName)}
        </Text>
      </View>
    );
  }
}

const styles = {
  nameInput: {
    flex: 1,
    flexDirection: 'row',
  },
  title: {
    flex: 1,
  },
  name: {
    flex: 2,
    paddingLeft: 10,
  },
};
