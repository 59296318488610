import React from 'react';
import {View} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import {
  Heading2, NameInput, DocumentInput,
  DateInput, NumberInput, TextInput, EmailInput,
} from './../../Form';

export default class PolicyHolderForm extends React.Component {
  render() {
    const {
      organization, noDocument, passport,
      noDateOfBirth, minDate, maxDate,
      noAddress, addressLabel, addressMandatory,
      policyHolder, onChange, messages,
    } = this.props;

    return (
      <View>
        {
          this.props.premiumPlan ? undefined :
          <Heading2>{Locale.text.policyHolder}</Heading2>
        }

        {
          organization ?
            <TextInput
              label={Locale.text.companyName}
              value={policyHolder.organization}
              onChange={(organization) => onChange({organization})}
              message={messages && messages.organization}
            /> :
            <View>
              <NameInput
                label={Locale.text.name}
                salutation={policyHolder.salutation}
                firstName={policyHolder.firstName}
                lastName={policyHolder.lastName}
                onChange={(data) => onChange(data)}
                messages={messages}
              />

              {
                !noDocument &&
                  <DocumentInput
                    label={Locale.text.hkid}
                    passport={passport}
                    documentType={policyHolder.documentType}
                    documentNo={policyHolder.documentNo}
                    onChange={(data) => onChange(data)}
                    messages={messages}
                  />
              }

              {
                !noDateOfBirth &&
                  <DateInput
                    label={Locale.text.dateOfBirth}
                    fullDate={true}
                    minDate={minDate || moment('1900-01-02')}
                    maxDate={maxDate || moment().subtract(18, 'years')}
                    value={policyHolder.dateOfBirth}
                    onChange={(dateOfBirth) => onChange({dateOfBirth})}
                    message={messages && messages.dateOfBirth}
                  />
              }
            </View>
        }

        <NumberInput
          label={Locale.text.mobileNumber}
          maxLength={8}
          value={policyHolder.mobile}
          onChange={(mobile) => onChange({mobile})}
          message={messages && messages.mobile}
        />

        {
          !noAddress &&
            <TextInput
              label={addressLabel || Locale.text.correspondenceAddress}
              placeholder={addressMandatory ? null : Locale.text.optional}
              value={policyHolder.address1}
              onChange={(address1) => onChange({address1})}
              message={messages && messages.address1}
            />
        }

        <EmailInput
          label={Locale.text.emailAddress}
          value={policyHolder.email}
          onChange={(email) => onChange({email})}
          message={messages && messages.email}
        />
      </View>
    );
  }
}
