import React from 'react';
import {
  StyleSheet, StatusBar, Platform, Dimensions,
  Text, Image, View, TouchableOpacity,
} from 'react-native';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Hidden,
} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import {Container, Row, Col} from 'react-grid-system';

import Locale from './Locale';
import Member from './Member';

export class Screen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    window.scrollTo(0, 0);

    Member.onReady(() => this.refresh());
  }

  alert(title, message, buttons, options) {
    let buttons1 = [], buttons2 = [];
    buttons.forEach((button, index) => {
      const buttons = index < 2 ? buttons1 : buttons2;

      buttons.push(
        <Button onClick={button.onPress} color='primary'>
          {button.text}
        </Button>
      );
    });

    const alert =
      <Dialog open={true}>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {message}
            {
              options && options.image &&
                <div style={{textAlign: 'center', marginTop: 20}}>
                  <a href={options.image.href}>
                    <img
                      style={{maxHeight: 200}}
                      src={options.image.source}
                    />
                  </a>
                </div>
            }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {buttons1}
        </DialogActions>

        {
          buttons2.length > 0 &&
            <DialogActions>
              {buttons2}
            </DialogActions>
        }
      </Dialog>

    this.setState({alert});
  }

  dismissAlert() {
    this.setState({alert: null});
  }

  navigate(routeName, params, action) {
    this.push(routeName, params, action);
  }

  goBack() {
    this.props.history.goBack();
  }

  getParam(paramName, defaultValue) {
    const {state} = this.props.location;

    if (state) {
      return state[paramName] || defaultValue;
    }

    return defaultValue;
  }

  push(routeName, params, action) {
    this.setState({alert: null, waiting: false, messages: {}}, () => {
      this.props.history.push(routeName.toLowerCase(), params, action);
    });
  }

  replace(routeName, params, action) {
    this.props.history.replace(routeName, params, action);
  }

  reset(routeName) {
    this.push(routeName);
  }

  handleExpiredLogin() {
    Member.logout();

    this.alert(
      Locale.text.loginExpired,
      Locale.text.yourLogin,
      [{
        text: Locale.text.login,
        onPress: () => this.reset('Login'),
      }],
      {cancelable: false},
    );
  }
}

export class Layout extends React.Component {
  render() {
    return(
      <View style={styles.container}>
        <Helmet>
          <title>{this.props.children[1]} | Bravo Generali</title>
        </Helmet>

        {this.props.alert}

        {this.props.children[0]}

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
              <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.props.children[1]}</Text>
                <br />
                <Text style={[styles.greetingText,{fontSize: 20}]}>{this.props.children[2]}</Text>
              </View>
            </Grid>
          </Hidden>

          <Grid item xs style={{minHeight: 500, marginTop:30,marginBottom:50,backgroundColor: 'white'}}>
            {this.props.children[3]}
          </Grid>

          <Hidden xsDown>
            <div style={{marginRight: '5%'}} />
          </Hidden>
        </Grid>

        <Footer />
      </View>
    );
  }
}

export class Navbar extends React.Component {
  render() {
    const color = Member.getThemeColor();

    const brokerButton =
      this.props.brokerButton && Member.getMember() && Member.getMember().agent;

    const backButton =
      this.props.backButton == undefined || this.props.backButton;

    const homeButton =
      this.props.homeButton == undefined || this.props.homeButton;

    return (
      <View style={[styles.appBar, {backgroundColor: color}]}>
        <StatusBar
          translucent={true}
          backgroundColor= {color}
        />

        <View style={styles.content}>
          {
            this.props.logo &&
              <Image
                style={styles.logo}
                source={{uri: Member.getTheme().logo}}
              />
          }

          {
            this.props.title &&
              <Text style={styles.title}>
                {this.props.title}
              </Text>
          }
        </View>

        {
          brokerButton &&
            <TouchableOpacity
              style={styles.leftButton}
              onPress={() => this.props.screen.push('Broker')}
            >
              <Image
                style={styles.icon}
                source={require('./images/menu.png')}
              />
            </TouchableOpacity>
        }

        {
          !brokerButton && backButton &&
            <TouchableOpacity
              style={styles.leftButton}
              onPress={() => this.props.screen.goBack()}
            >
              <Image
                style={styles.icon}
                source={require('./images/back.png')}
              />
            </TouchableOpacity>
        }

        <TouchableOpacity
          style={
            this.props.homeButton == false ?
              styles.homePageLogoutButton :
              styles.logoutButton
          }
          onPress={() => {
            if (Member.getMember()) {
              Member.logout();
              this.props.screen.push('/');
            }
            else {
              this.props.screen.push('Login');
            }
          }}
        >
          <View style={{flexDirection: 'row'}}>
            <Image
              style={{width: 30, height: 30}}
              source={require('./images/lock.png')}
            />
            <Text style={{color: 'white', marginTop: 7}}>
              {Member.getMember() ? Locale.text.logout : Locale.text.login}
            </Text>
          </View>
        </TouchableOpacity>

        {
          homeButton &&
            <TouchableOpacity
              style={styles.rightButton}
              onPress={() => this.props.screen.push('/')}
            >
              <Image
                style={styles.icon}
                source={require('./images/home.png')}
              />
            </TouchableOpacity>
        }
      </View>
    );
  }
}

export class Footer extends React.Component {
  render() {
    const backgroundColor = Member.getThemeColor();

    return (
      <footer className='App-footer'>
        <div style={{backgroundColor, padding: 20}}>
          <Container>
            <Row>
              <Col xs="auto" sm={3} style={{textAlign:'left',fontSize: 12,}}>
                <a
                  href={Locale.text.footerPICHref}
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  {Locale.text.footerPIC}
                </a>
              </Col>

              <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 12,}}>
                <a
                  href={Locale.text.footerLegalInformationHref}
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  {Locale.text.footerLegalInformation}
                </a>
              </Col>

              <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 12,}}>
                <a
                  href={Locale.text.footerPrivacyHref}
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  {Locale.text.footerPrivacy}
                </a>
              </Col>

              <Col xs="auto" sm={2} style={{textAlign:'left',fontSize: 13}}>
                <a
                  href='tel:+85266821112'
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  {Locale.text.footerPhone}
                </a>
              </Col>

              <Col xs="auto" sm={3} style={{textAlign:'left',fontSize: 12,gutterWidth:20}}>
                <a
                  href='mailto:bravo@generali.com.hk'
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  <Text style={{fontSize: 13, color: 'white'}}>{Locale.text.footerEmail}</Text>
                </a>
              </Col>

              <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 7,}}>
                {Locale.text.footerCopyright}
              </Col>

              <Col xs="auto" sm={1} style={{textAlign:'left',fontSize: 12,}}>
              </Col>

              <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 5,}}>
                {Locale.text.footerReserved}
              </Col>

              <Col xs="auto" sm={1} style={{textAlign:'left',fontSize: 12,}}>
              </Col>

              <Col xs="auto" sm={11} style={{textAlign:'left',fontSize: 12,marginBottom: 5,}}>
                <a
                  href={Locale.text.footerGroupHref}
                  target="_blank"
                  style={{textDecoration: 'none',color:'#FFFFFF'}}
                >
                  {Locale.text.footerGroup}
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    );
  }
}

const {height, width} = Dimensions.get('window');
const isIphoneX = Platform.OS == 'ios' && height >= 812 && width >= 375;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  greeting: {
    marginTop: 5,
    alignItems: 'center',
  },
  greetingText: {
    color: 'black',
  },
  appBar: {
    height: isIphoneX ? 80 : 60,
  },
  logo : {
    width: 125,
    height: 18,
  },
  title: {
    color: 'white',
    fontSize: 16,
    paddingBottom: 10,
  },
  content: {
    marginBottom: 8,
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    width: 25,
    height: 25,
  },
  leftButton: {
    position: 'absolute',
    top: 18,
    left: 7,
  },
  rightButton: {
    position: 'absolute',
    top: 18,
    right: 7,
  },
  logoutButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    right: 47,
  },
  homePageLogoutButton: {
    position: 'absolute',
    top: (isIphoneX) ? 45 : 17,
    right: 17,
  },
});

Layout.container = styles.container;
