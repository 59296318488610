import React from 'react';
import {View} from 'react-native';
import moment from 'moment';

import Locale from './../../Locale';
import {
  Heading2, NameInput, DocumentInput, DropDownList, DateInput, YesNoQuestion,
} from './../../Form';

export default class DomesticHelperForm extends React.Component {
  render() {
    const {domesticHelper, onChange, messages} = this.props;

    return (
      <View>
        <Heading2>{Locale.text.domesticHelper}</Heading2>

        <NameInput
          label={Locale.text.name}
          salutation={domesticHelper.salutation}
          firstName={domesticHelper.firstName}
          lastName={domesticHelper.lastName}
          onChange={(data) => onChange(data)}
          messages={messages}
        />

        <DocumentInput
          label={Locale.text.hkidPassportNumber}
          passport
          documentType={domesticHelper.documentType}
          documentNo={domesticHelper.documentNo}
          onChange={(data) => onChange(data)}
          messages={messages}
        />

        <DateInput
          label={Locale.text.dateOfBirth}
          minDate={moment().subtract(61, 'years').add(1, 'days')}
          maxDate={moment().subtract(18, 'years')}
          value={domesticHelper.dateOfBirth}
          onChange={(dateOfBirth) => onChange({dateOfBirth})}
          message={messages && messages.dateOfBirth}
        />

        <DropDownList
          label={Locale.text.sex}
          options={[
            {value: 'M', label: Locale.text.male},
            {value: 'F', label: Locale.text.female},
          ]}
          value={domesticHelper.insuredSex}
          onChange={(insuredSex) => onChange({insuredSex})}
          message={messages && messages.insuredSex}
        />

        <DropDownList
          label={Locale.text.countryOfOrigin}
          options={[
            { value: 'sriLanka', label: Locale.text.sriLanka },
            { value: 'indonesia', label: Locale.text.indonesia },
            { value: 'philippines', label: Locale.text.philippines },
            { value: 'singapore', label: Locale.text.singapore },
            { value: 'malaysia', label: Locale.text.malaysia },
            { value: 'thailand', label: Locale.text.thailand },
            { value: 'nepal', label: Locale.text.nepal },
            { value: 'bangladesh', label: Locale.text.bangladesh },
            { value: 'others', label: Locale.text.others },
          ]}
          value={domesticHelper.insuredCountry}
          onChange={(insuredCountry) => onChange({insuredCountry})}
          message={messages && messages.insuredCountry}
        />

      </View>
    );
  }
}
