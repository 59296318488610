import React from 'react';
import {StyleSheet, View, Text, Image} from 'react-native';

import Locale from './../Locale';

export default class Loading extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.loading}>
          <Text style={styles.loadingText}>
            {Locale.text.loading}
          </Text>

          <Image
            style={styles.loadingImage}
            source={require('./images/loading.gif')}
            resizeMode='contain'
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    width: '100%',
    height: '100%',
    maxWidth: '80%',
    maxHeight: '80%',
    justifyContent: 'center',
  },
  loadingText: {
    fontSize: 20,
    textAlign: 'center',
    paddingTop: '20%',
  },
  loadingImage: {
    width: '100%',
    height: '100%',
    minWidth: 350,
    minHeight: 350,
  },
});
