import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import GrossArea from './GrossArea';
import Locale from './../../Locale';
import {FormElement, Label, Select, Message} from './../../Form';

export default class SizeOfTheProperty extends React.Component {
  render() {
    const {cozy, label, value, onChange, message} = this.props;
    const options = [];

    if (GrossArea.grossAreas) {
      GrossArea.grossAreas.forEach((grossArea) => {
        options.push({
          value: grossArea.id,
          label: grossArea.toString(),
        });
      });
    }

    return (
      <FormElement>
        <View style={styles.sizeOfTheProperty}>
          <Label cozy={cozy}>
            {label}
          </Label>

          <Text>
            {Locale.text.pleasChoose}
          </Text>

          <Select
            options={options}
            value={value}
            onChange={(grossArea) => onChange(grossArea)}
          />
        </View>

        {
          value % 6 == 0 &&
            <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        }

        <Text>
          {Locale.text.convertNetFloorArea}
        </Text>

        <Text>
          {Locale.text.forExample}
        </Text>

        {message && <Message>{message}</Message>}
      </FormElement>
    );
  }
}

const styles = StyleSheet.create({
  sizeOfTheProperty: {
    marginTop: 10,
    marginBottom: 10,
  },
  sizeOfThePropertyTitleText: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
});
