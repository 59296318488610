export const zhHK = {
  getAQuote: '索取報價',
  bravoTravelProtector: '忠意旅遊保',
  centalineTravelProtector: '中原旅遊保',
  bravoTravelProtectorIntroParagraph: '旅行可以為您帶來難忘體驗和終生回憶。忠意旅遊保會在整個旅程中保護您和您的親人，讓您安心享受悠閒假期。',

  manualUnderwritingIsNeeded: '您的申請需要核保，我們會在兩個工作天內回覆您。',

  bravoTravelProtectorProductEdge1: '保障手提電話',
  bravoTravelProtectorProductEdge2: '醫療保障額高達HK$1,500,000 (包括 COVID-19) \n有關COVID-19保障範圍，請參閱<a href="http://sitecontent-dev.s3-website.ap-east-1.amazonaws.com/bravo-uat/Bravo%20Travel%20Protector%20%28FAQ%29.pdf" target="_blank" rel="noopener noreferrer">常見問題</a>',
  bravoTravelProtectorProductEdge3: '免費増加個人保障意外保障全覧表',
  bravoTravelProtectorProductEdge4: '擴大「旅程延誤」保障',
  bravoTravelProtectorProductEdge5: '保障業餘及消閒活動 (包括滑雪、水肺潛水、高空彈跳等)',

  forDetailsOfTheProduct:'有關產品細節，不保事項及保費率，請參閱產品小冊子及保單條款',
  policyholderIsACompany: '保單持有人為公司',
  planType: '計劃類型',
  premier: '尊貴',
  classic: '優越',
  standard: '標準',

  shortTermTripAnnual: '單次旅遊計劃  / 全年旅遊計劃',
  shortTermTrip: '單次旅遊計劃',
  annual: '全年旅遊計劃',
  cancel: '取消',
  invalidPromotionCode: '無效的優惠代碼',
  addInsuranceAdvisor: '新增保險顧問',
  wouldYouLike: '您願意 ',
  toBeYourInsuranceAdvisor: ' 成為您的保險顧問嗎？',
  individualOrWithOthers: '個人或多人',
  individual:'個人',
  family: '家庭',
  individualNChildren: '個人及子女',
  groupOfIndividuals: '多人',

  addtionalUpgradePersonalAccidentBenefit: '星級個人意外附加保障',
  yes:'是',
  no:'否',
  addtionalCruiseBenefit: '升級郵輪附加保障',

  periodOfTravel: '旅遊日期',

  promotionCodes: '優惠代碼',
  optional: '非必填',
  businessEmailAddress: '商業電郵 / 會員',
  businessEmailMembership: '商業電郵 / 會員',

  iWouldLikeToBuy: '我想投保',
  total: '合共',
  levyIsNotIncluded: '不包括保險徵費及有關費用',
  buyNow: '立即購買',

  theInsuranceIsIssuedByContent:'此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。',
  theInsuranceIsIssued: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ',
  professionalInsurance: ' 安排。',
  productType: '產品類別',

  policyHolder: '保單持有人',
  name: '姓名',
  title: '稱謂',
  mr: '先生',
  ms: '女士',
  mrs: '太太',
  miss: '小姐',
  firstName: '名',
  lastName: '姓',
  dateOfBirth: '出生日期',
  pleasePickADate: '請選擇日期',
  mobileNumber: '手機號碼',
  correspondenceAddress: '通訊地址',
  emailAddress: '電郵地址',
  hkidOrPassportNumber: '香港身份證或護照',
  hkid: '香港身份證',
  passport: '護照',
  pleaseEnterAllCharacters: '請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。',
  policyholderIs: '保單持有人是',

  first: '第 1 位',
  insured: '受保人',
  relationshipWithThePolicyholder: '與保單持有人的關係',
  spouse: '配偶',
  child: '子女',
  children: '子女',
  parents: '父母親',
  relatives: '親戚',
  friends: '朋友',
  others:'其他',
  haveThisTripInvolvedAnyCompetition: '這次旅行是否涉及娛樂表演，記者的職責，導遊/旅遊護送的職責，特技作品，施工現場或施工現場相關工程？',
  manualUnderwritingIsRequired: '* 您的申請需要核保 *',
  specialRequests: '特別要求',
  referral: '介紹碼',
  thisFieldCannotBeBlank: '必須填寫空格',
  isBuyagain: '年齡超過79歲',


  iWeDoNotConsentPDto:'我/我們不同意提供個人資料被第三方作為行銷用途使用',
  iWeDoNotConsentPDToCompanyMarketing:'我/我們不同意提供個人資料給貴公司作為行銷用途',
  brokerDeclaration: '我/我們明白，確知及同意，忠意保險有限公司（香港分行） 會就本人的申請而簽發的保單，向安排保單事宜的獲授權保險經紀或保險代理',
  brokerDeclarationII:'支付佣金，並向其提供閣下之個人資料，作處理及管理該保單之用途 。',
  numberOfChinaMedicalGuaranteeCards: '中國醫療保障卡數量',
  daysdays: '天',
  thisPromotionCodeIsNotValid: '無效的優惠代碼',
  getInsured: '立即投保',
  insuranceAdvisorMobile: '保險顧問電話號碼',
  pleaseChoose: '請選擇',
  effectiveDate: '生效日期',
  periodOfInsurance: '保障期限',
  bothDatesInclusive: '(包含開始日及結束日)',
  destination: '地點',
  theTraveler: '遊客',
  travelOneOfTheTravelers: '其中一位遊客',
  numberOfChildren: '子女數量',
  numberOfIndividuals: '遊客人數',
  chinaMedicalGuaranteeCard: '中國醫療保證卡',
  additionalChinaMedicalGuaranteeCard: '中國醫療保證卡附加保障',
  nullnull: '無效',
  sorryThatYourApplication: '很抱歉您的申請被拒絕',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成購買',
  policyholderAndInsuredsMust: '保單持有人及受保人必須持有有效的香港身份證，並在香港居住。',
  theCoveredTripShouldOriginate: '本旅遊保險只適用於由香港出發的旅程，保障不適用於香港境內。',
  noRefundOfPremium: '一經發出保單，將不獲退還已支付的保費。',
  coverWillAutomatically: '若受保人因不能避免的情況下延期，本保險保障將自動延長最多10日。',
  thisInsuranceIsOnlyValid: '本旅遊保險僅適用於度假或商務旅行（僅限行政職責需要），不適用於導遊或領隊等工作。',
  allPRCCitizensWhoReside: '此保險不適用於中國公民於中國境內旅遊。',
  obtainingMedicalTreatment: '本旅遊保險並不覆蓋以醫療為目的的旅程。',
  thisInsuranceIsNotApplicable : '本旅遊保險不適用於從事藝術或任何娛樂相關活動。',
  theInsuredsShallBeInGood: '受保人的身體狀況良好，並同意本保險不包括任何已存在的疾病。',
  allCoveragesAreSubject: '所有保障範圍均以保單條款及條文作規限。',
  onlyStandardPlan: '只有標準計劃適用於17歲或以下的受保人。',
  assicurazioniGeneraliSpA: '忠意保險有限公司　(香港分行)　保留接受或拒絕任何申請的權利。',
  onlyStandardPlanIsApplicable: '只有標準計劃適用於17歲或以下的受保人。',
  generaliTravelPackageInsurance: '忠意旅遊綜合保險計劃',
  worldwide: '全球（於保單中“排除地區適用條款”內所載之<不受保國家/地區>除外）',
  question: '問題',
  IWeCertifyThat: '我 / 我們證明我/我們已閱讀並同意忠意旅遊綜合保險計劃的重要聲明。',
  payment: '付款',
  youHave: '您有 ',
  eCashNow: 'Generali 現金券，是否要使用它？',
  pleaseWait: '請稍後',
  confirm: '確認',
  pleaseInputInEnglish: '* 請輸入英文 *',
  needsToBeInEnglish: '需以英文填寫',
  thankYou: '謝謝！',
  youHaveSuccessfully: '您已成功提交',
  ccccc: '申請。',
  manualUnderwriting: '您的申請需要核保，我們會在兩個工作天內回覆您。',
  youCanAlsoFindYour: '您可以在訂單歷史區域找到您的申請資訊。',
  inCaseOfAnyQuestions: '假如有任何疑問，您可透過此 APP 的「聯絡我們」功能與我們聯絡。',
  check: '請檢查您的輸入一遍！',

  notValidEmail:'這不是有效的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是有效身份証號碼',

  alert: '請注意',
  ok: '確認',
  submissionFailed: '提交失敗',
  invalidMobileNumber: '無效的保險顧問電話號碼。',
  tryAgain: '我們的系統暫時不可用，請稍後再試',

  age17OrBelow: '未滿 18 歲僅能選擇標準計劃',

  congratulations: '恭喜',
  madeAPurchase: '你已成功購買這項產品\n',
  weWillSend: '接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱',
  anyQuestions: '\n如有任何疑問，歡迎隨時與我們聯絡，謝謝',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  travel: '忠意旅遊綜合保險計劃',
  adventure: '盡情冒險，讓您無後顧之憂。',
  mainlandPermit: '回鄉證號碼',
  companyName: '公司名稱',
  canOnlyChooseTheStandardPlan: '未滿18歲僅能選擇標準計劃',
  notAvailableForAgeBelow18: '抱歉此計劃不適用於18歲以下人士',
  travelPolicyholderIs: '保單持有人是',
  travelHkidOrPassportNumber: '香港身份證或護照',

  forBetterCustomerExperience: '為了獲得更好的客戶體驗，您想先登入嗎？',
  forBetterCustomerExperiencelogin:'會員登入',
  forBetterCustomerExperiencregister:'註冊新會員',
  forBetterCustomerExperienceCon:'不登入繼續',

  //special case
  paymentStopService1:'尊貴的客戶，',
  paymentStopService2:'由於我們現正進行系統更新，在7月8日晚上11:00至翌日7月9號晚上11:00，服務將會臨時暫停。不便之處，敬請原諒。',

}
