import React from 'react';
import {
  Text, View, ScrollView, KeyboardAvoidingView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import validate from 'validate.js';
import moment from 'moment';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { host, api23, api26, busiSecKey } from './../../../config/api';
import { planTypes } from './planTypes';
import { platform } from './../../../config/platform';
import { formStyles } from './../../../config/styles';
import { memberStorage } from './../../../lib/MemberStorage';
import { languageStorage } from './../../../lib/LanguageStorage';
import { stateStorage } from './../../../lib/StateStorage';
import { insuranceAdvisors } from './../../../lib/InsuranceAdvisors';
import PromotionCode from './../../../lib/PromotionCode';
import { clearEmptyStrings, msgCodeDetail, removeSpaces, handleExpiredLogin, hmacSha256 } from './../../../lib/utils';
import ProductAPI from './../../../api/ProductAPI';
import AgentAPI from './../../../api/AgentAPI';
import TextInput from './../../../components/TextField/TextField';
import Navbar from './../../../components/Navbar/Navbar';
import Footer from './../../../components/Footer/Footer';
import Alert from './../../../components/Alert/Alert';
import ModalSelector from './../../../components/ModalSelector/ModalSelector'
import ProductEdges from './../../../components/ProductEdges/ProductEdges';
import AttachmentLink from './../../../components/AttachmentLink/AttachmentLink';
import PlanTypePicker from './../../../components/PlanTypePicker/PlanTypePicker';

export default class HomePackageInsurance1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      broker: this.props.location.state ?
        this.props.location.state.broker : undefined,
      planType: 'without',
      propertySize: 1,
      messages: {},
      text: {},
    };

    if (!this.state.broker && localStorage.getItem('promotionCodes')) {
      this.state.promotionCodes = localStorage.getItem('promotionCodes');
    }

    if (!this.state.broker && localStorage.getItem('businessEmail')) {
      this.state.businessEmailAddress = localStorage.getItem('businessEmail');
    }

    if (this.state.centaline) {
      this.state.propertySize = 7;
    }

    try{
      const searchParams = new URLSearchParams(this.props.location.search);

      const lang = searchParams.get('lang');
      if (lang) {
        switch (lang) {
          case 'en':
            languageStorage.setLanguage('enUS');
            break;
          case 'zh':
            languageStorage.setLanguage('zhHK');
            break;
        }
      }

      const promotionCode = searchParams.get('promotionCode');
      if (promotionCode) {
        this.state.businessEmailAddress = undefined;
        localStorage.removeItem('businessEmail');

        this.state.promotionCodes = promotionCode;
        localStorage.setItem('promotionCodes', promotionCode);
      }

      const businessEmail = searchParams.get('businessEmail');
      if (businessEmail) {
        this.state.promotionCodes = undefined;
        localStorage.removeItem('promotionCodes');

        this.state.businessEmailAddress = businessEmail;
        localStorage.setItem('businessEmail', businessEmail);
      }

      const referral = searchParams.get('refcode');
      if (referral) {
        this.state.referral = referral;
      }

    }
    catch (e) {
      if (e.name != 'ReferenceError') {
        throw e;
      }
    }
    localStorage.setItem('preventGoBack', 'false');
  }

  componentDidMount() {
    stateStorage.getState('home1', (state) => {
      if (state && !this.state.promotionCodes && !this.state.businessEmailAddress) {
        if (state.promotionCodes) {
          delete this.state.businessEmailAddress;
        }
        if (state.businessEmailAddress) {
          delete this.state.promotionCodes;
        }
        this.setState(state);
      }
    });

    window.scrollTo(0, 0);
    this._getGrossAreas();

    // init language
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language),
      }, () => {
        // init member and token
        memberStorage.getMember((member, token) => this.setState({
          submitButtonDisabled: member && !this.state.broker,
          member: member,
          token: token,
        }, () => {
          if (!this.state.broker) {
            // init bound insurance advisor
            insuranceAdvisors.getInsuranceAdvisors(
              this.state.language,
              this.props.history,
              (insuranceAdvisors) => {
                if (insuranceAdvisors.HOME) {
                  if (this.state.member) {
                    PromotionCode.validatePromotionCodes(
                      insuranceAdvisors.HOME.promotionCode,
                      this.state.member.id,
                      this.state.token.tokenStr,
                      'EAPP',
                      'HOME',
                      (promotionCodes) => {
                        this.setState({
                          submitButtonDisabled: false,
                          businessEmailAddress: undefined,
                          promotionCodes: promotionCodes ?
                            promotionCodes : this.state.promotionCodes,
                        });
                      }
                    );
                  }
                }
                else {
                  this.setState({
                    showBusinessEmailAddress: true,
                    submitButtonDisabled: false,
                  });
                }
              },
              (alert) => {
                this.setState({alert: alert})
              }
            );
            if (!this.state.member) {
              this.setState({showBusinessEmailAddress: true});
            }
          }
        }));
      })
    );
  }

  _findGrossAreaById(grossAreaId) {
    let match;
    this.state.grossAreas.forEach((grossArea) => {
      if (grossArea.key == grossAreaId) {
        match = grossArea;
      }
    });
    return match;
  }

  _getGrossAreas() {
    const data = JSON.stringify({
      'dt': moment().format('YYYYMMDDhhmmss'),
      'pn': this.state.centaline ? 'CTL' : 'EAPP',
      'timeStamp': Date.now(),
    });
    const hmac = hmacSha256(data);
    fetch(host + api23, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: 'data=' + encodeURIComponent(data) + '&hmac=' + hmac,
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.msgCode == 0) {
        let grossAreas = [];
        let desc = 'descEnUs';
        if (this.state.language == 'zhHK') {
          desc = 'descZhHk';
        }
        responseJson.msgDesc.grossAreas.forEach((grossArea) => {
          grossAreas.push({
            key: grossArea.id,
            label: grossArea[desc],
          });
        });
        this.setState({
          grossAreas: grossAreas,
        }, () => {
          this.setState({
            defaultGrossAreaLabel:
              this._findGrossAreaById(this.state.propertySize).label,
          });
        });
      }
      else {
        alert(msgCodeDetail(responseJson.msgCode)
          [this.state.language == 'enUS' ? 'desc_en' : 'desc_zh']);
      }
    })
    .catch((error) => {
      alert('Facing error when processing the query!');
    });
  }

  _nextScreen() {
    this.setState({submitButtonDisabled: true});

    const state = clearEmptyStrings(this.state);
    let attributes = {
      businessEmailAddress: state.businessEmailAddress,
    };

    const englishFormat = {
      pattern: '[\x00-\x7F|’|’|‘|“|‘|@|&|-|/|,|.|(|)|.|-|/|（|）|：|_|—|‘]+',
      message: this.state.text.needsToBeInEnglish ,
    }
    let constraints = {
      businessEmailAddress: {
        format: englishFormat,
      },
    }

    const messages = validate(attributes, constraints, {fullMessages: false});

    if (messages) {
      this.setState({
        messages: messages,
        submitButtonDisabled: false,
      });
    }
    else {
      const premium = this.state.broker ? AgentAPI.premium : ProductAPI.premium;

      premium(
        {
          'dt': moment().format('YYYYMMDDhhmmss'),
          'pn': 'EAPP',
          'pl': 'HOME',
          'member.id' : this.state.member ? this.state.member.id : undefined,
          'member.token' : this.state.token ? this.state.token.tokenStr : undefined,
          'payment.promotionCode': removeSpaces(this.state.promotionCodes),
          'policy.businessEmail': this.state.centaline ?
            undefined : removeSpaces(this.state.businessEmailAddress),
          'insuredHome.grossArea': this.state.propertySize,
          'insuredHome.includeBuilding':
            this.state.planType == 'with' ? 'Y' : 'N',
        },
        (msgDesc) =>  {
          this.setState({submitButtonDisabled: false, alert: undefined}, () => {
            stateStorage.setState('home1', this.state);
            stateStorage.setState('home2', undefined);

            this.props.history.push('/home2', {
              broker: this.state.broker,
              grossAreas: this.state.grossAreas,
              centaline: this.state.centaline,
              planType: this.state.planType,
              propertySize: this.state.propertySize,
              promotionCodes: removeSpaces(this.state.promotionCodes),
              businessEmailAddress: removeSpaces(this.state.businessEmailAddress),
              showBusinessEmailAddress: this.state.showBusinessEmailAddress,
              premium: msgDesc.premium,
              referral: this.state.referral,
            });
          });
        },
        (msgCode, msgDesc, handleDefault) =>  {
          if (msgCode == 40) {
            if (this.state.broker) {
              this.setState({
                messages: {
                  promotionCodes: this.state.text['invalidPromotionCode']
                },
                alert: Alert.alert(
                  this.state.text.alert,
                  this.state.text.check,
                  [{
                    text: this.state.text.ok,
                    onPress: () => this.setState({alert: null}),
                    style: 'cancel',
                  }],
                ),
              });
            }
            else {
              this.setState({alert: Alert.alert(
                this.state.text.addInsuranceAdvisor,
                this.state.text.wouldYouLike
                + msgDesc.agent.brokerName
                + this.state.text.toBeYourInsuranceAdvisor,
                [
                  {
                    text: this.state.text.ok,
                    onPress: () => {
                      if (!this.state.bindingInsuranceAdvisor) {
                        this.setState({bindingInsuranceAdvisor: true}, () => {
                          insuranceAdvisors.setInsuranceAdvisor(
                            {agentCode: msgDesc.agent.agentCode},
                            ['HOME'],
                            this.state.promotionCodes,
                            this.state.language,
                            () => this.setState({bindingInsuranceAdvisor: false},
                              () => this._nextScreen())
                          );
                        });
                      }
                    }
                  },
                ],
                { cancelable: false }
              )});
            }
          }
          else if (msgCode == 56) {
            this.setState({
              messages: {
                promotionCodes: this.state.text['invalidPromotionCode']
              },
              alert: Alert.alert(
                this.state.text.alert,
                this.state.text.check,
                [{
                  text: this.state.text.ok,
                  onPress: () => this.setState({alert: null}),
                  style: 'cancel',
                }],
              ),
            });
          }
          else {
            handleDefault();
          }
          this.setState({submitButtonDisabled: false});
        },
      )
    }
  }

  render() {
    let leaflet, provision;
    if (this.state.centaline) {
      leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Centaline%20Home_eng.pdf';
      provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Centaline%20Home%20Protector%20Policy.pdf';
    }
    else {
      leaflet = 'https://www.app-generali.com/share_files/Product_leaflet/Product_leaflet_Home.pdf';
      provision = 'https://www.app-generali.com/share_files/Product_provision/Product%20Provision_Home%20Package%20Insurance%20Policy.pdf';
    }

    return (
      <div className='App'>
        <Helmet>
          <title>Home Package Insurance | Bravo Generali</title>
        </Helmet>


        <Navbar
          title={this.state.text.getAQuote}
          backButton
          history={this.props.history}
        />

        <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
          <Hidden xsDown>
            <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>
              <View style={[styles.greeting, {fontWeight: 'bold'}]}>
                <Text style={[styles.greetingText,{marginTop: '10%',fontSize: 35}]}>{this.state.text.homepa}</Text>
                  <p></p>
                <Text style={[styles.greetingText,{fontSize: 20}]}>{this.state.text.valuable}</Text>
              </View>
            </Grid>
          </Hidden>
        <Grid item xs style={{marginTop:30,marginBottom:50,backgroundColor: 'white'}}>
        <ScrollView style={styles.form,styles.fie}>
        {this.state.alert}
          <View style={styles.fieldset,styles.fie}>
          <Text style={styles.h2}>
            {
              this.state.centaline ?
              this.state.text.centalineHomeProtector :
              this.state.text.homePackageInsurance
            }
          </Text>


        {
          this.state.centaline ?
            <View>
              <Text>{this.state.text.centalineProductDescription}</Text>
              <ProductEdges
                productEdges={[
                  this.state.text.centalineProductEdge1,
                  this.state.text.centalineProductEdge2,
                  this.state.text.centalineProductEdge3,
                  this.state.text.centalineProductEdge4,
                  this.state.text.centalineProductEdge5,
                  this.state.text.centalineProductEdge6,
                ]}
              />
            </View> :
            <div>
            <View>
              <View style={{textAlign: 'left'}}>
              <Text>{this.state.text.oncePurchaseLongConent}</Text>
              </View>
              <ProductEdges
                productEdges={[
                  this.state.text.comprehensiveCoverage,
                  this.state.text.legalLiability,
                  this.state.text.personalAccident,
                  this.state.text.optionalBuildingInsurance,
                  this.state.text.CompetitiveAnnualPremiumWithLowExcess,
                ]}
              />
            </View>
          </div>
        }
        </View>

        <View style={[styles.fieldset, styles.line,{textAlign: 'left'}]}>
        <AttachmentLink
          leaflet={leaflet}
          provision={provision}
          history={this.props.history}
        />
        </View>

        <View style={styles.fieldset}>
          <Text style={styles.h4}>{this.state.text.planType}</Text>
          <View style={styles.planTypes}>
            <PlanTypePicker
              selectedPlanType={this.state.planType}
              onSelect={
                (selectedPlanType) =>
                  this.setState({planType: selectedPlanType})
              }
              planTypes={planTypes}
            />
          </View>
        </View>

        {
          this.state.centaline ?
            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.sizeOfTheProperty}</Text>
              <Text>{this.state.text.pleasChoose}</Text>
              <ModalSelector
                data={this.state.grossAreas}
                initValue={this.state.defaultGrossAreaLabel}
                onChange={(option) => this.setState({propertySize: option.key})}
                cancelText={this.state.text.cancel}
              />
            </View> :
            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.sizeOfTheProperty}</Text>
              <Text>{this.state.text.pleasChoose}</Text>
              <ModalSelector
                data={this.state.grossAreas}
                initValue={this.state.defaultGrossAreaLabel}
                onChange={(option) => this.setState({propertySize: option.key})}
                cancelText={this.state.text.cancel}
              />
            </View>
        }

        {
          this.state.propertySize == (this.state.centaline ? 12 : 6) &&
            <Text>{this.state.text.manualUnderwritingsIsRequired}</Text>
        }

        <View style={{textAlign: 'left'}}>
          <Text>{this.state.text.convertNetFloorArea}</Text>
          <Text>{this.state.text.forExample}</Text>
        </View>

        {
          !this.state.businessEmailAddress &&
            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.promotionCodes}</Text>
              <TextInput
                style={styles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                value={this.state.promotionCodes}
                onChangeText={(text) => this.setState({promotionCodes: text})}
              />
              <Text style={formStyles.message}>{this.state.messages.promotionCodes}</Text>
            </View>
        }

        {
          !this.state.promotionCodes && this.state.showBusinessEmailAddress &&
            <View style={styles.fieldset}>
              <Text style={styles.h4}>{this.state.text.businessEmailAddress}</Text>
              <TextInput
                style={styles.input}
                placeholder={this.state.text.optional}
                placeholderTextColor='lightgrey'
                keyboardType='email-address'
                value={this.state.businessEmailAddress}
                onChangeText={(text) => this.setState({businessEmailAddress: text})}
              />
              <Text style={formStyles.message}>{this.state.messages.businessEmailAddress}</Text>
            </View>
        }

        {
          this.state.submitButtonDisabled ?
            <View style={formStyles.submitButtonDisabled}>
              <Text style={formStyles.submitButtonText}>{this.state.text.pleaseWait}</Text>
            </View> :
            <TouchableOpacity style={formStyles.submitButton} onPress={() => this._nextScreen()}>
              <Text style={formStyles.submitButtonText}>{this.state.text.getAQuote}</Text>
            </TouchableOpacity>
        }

        </ScrollView>
        </Grid>
          <Hidden xsDown>
            <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
        <Footer/>

     </div>

    );
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  h2: {
    fontSize: 26,
    marginTop: 10,
    color: '#C50000',
  },
  h4: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
  },
  form: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  fieldset: {
    marginTop: 10,
    marginBottom: 10,
  },
  fie: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    paddingLeft: 20,
    paddingRight: 20,
  },
  line:{
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    height: 50,
    fontSize: 18,
    textAlign: 'center',
  },
  datepicker: {
    width: '100%',
    marginTop: 10,
  },
  // planTypes: {
  //   marginTop: 10,
  //   alignSelf: 'center',
  //    width: '83%',
  // },
  submitButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    marginBottom: 20,
    backgroundColor: '#C50000',
  },
  submitButtonText: {
    fontSize: 18,
    color: 'white',
  },
  line:{
    justifyContent: 'center',
    alignItems: 'center',
  },
};
