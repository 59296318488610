export const enUS = {
  privilegedClub: 'Privilege Club',
  welcomeOffer: 'BRAVO Generali Welcome Offer',
  voucher: 'Generali Voucher Balance',
  loading: 'Loading...',

  // voucher
  failedToRedeem: 'Failed to Redeem',
  redeemSuccessfully: 'Successfully Redeemed',
  ok: 'OK',
  confirm:'confirm',
  voucherHeader:'Please enter your Generali cash voucher',
  voucherInputPlaceHolder:'Please input your Generali voucher code',
  notLoggedIn:'Please log in to your account or click "Account Registration" to create your account.',
  usedVoucher:'The voucher code has been used.',
  invalidVoucher:'The voucher code is invalid, please input again.',
  expiredVoucher:'The voucher code has expired.',
  offerExpired: 'Sorry that the offer is expired.',
  canOnlyEnjoyOurOfferOnce: 'Sorry that each customer can only enjoy our offer once.',
  forNewRegisteredMembers: 'Sorry that the offer is only valid for new registered members.',
  noLongerAvailable: 'Sorry that the offer is no longer available.',
  voucherBalanceIsCredited: 'Congratulations! Your voucher balance is credited by HK$',
  youCanCheckYourBalanceAnytime: '. You can check your balance anytime at the Privilege Club.',
  pleaseLogin: 'Login / Account Registration',
  pleaseLoginWithYourAccount: 'Before you redeem the voucher, please login with your account.',
  login: 'Login',
  tuned: 'Offers are coming soon, stay tuned!',
  TC:'Terms and Conditions',
  terms1: '1. The Generali voucher is only available on the "Bravo Generali" platform for premium payment. It has a validity period of 1 year.',
  terms2: '2. The Generali voucher is not transferable and cannot be exchanged for cash or other goods. If the insurance is withdrawn or refunded, the voucher amount will not be refunded.',
  terms3: '3. The Company has the absolute right to interpret these terms and conditions and to change any related arrangements. In case of any dispute, The Company reserves the right of final decision without further notice.',
  balanceText:'Generali Voucher Balance = HK$',
  expiryDateText:'Expiry Date：',
  forNewJoiner:'The voucher code is invalid, please input again.'
}
