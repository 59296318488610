import moment from 'moment';

import config from './config';
import Locale from './Locale';

export default class Format {
  static formatNumber(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  static parseNumber(number) {
    if (number) {
      return parseInt(number.toString().replace(/,/g, ''));
    }
  }

  static getYesNoLabel(answer) {
    switch (answer) {
      case 'Y':
        return Locale.text.yes;
      case 'N':
        return Locale.text.no;
    }
  }

  static getGenderLabel(gender) {
    switch (gender) {
      case 'M':
        return Locale.text.male;
      case 'F':
        return Locale.text.female;
    }
  }

  static formatName(salutation, firstName, lastName) {
    const emptyString = '';

    if (salutation) {
      if (salutation != 'Miss') {
        salutation += '.';
      }

      salutation += ' ';
    }
    else {
      salutation = emptyString;
    }

    if (lastName) {
      lastName = lastName.toUpperCase();
    }
    else {
      lastName = emptyString;
    }

    const comma = lastName && firstName ? ', ' : emptyString;

    if (!firstName) {
      firstName = emptyString;
    }

    return salutation + lastName + comma + firstName;
  }

  static formatDate(date) {
    const {dataFormat, displayFormat} = config.date;

    return moment(date, dataFormat).format(displayFormat);
  }
}
