export const zhHK = {
  getAQuote: '索取報價',
  generaliPersonalAccidentInsurancePlan: '人身意外保險計劃',
  sometimesEvenMinorAccidentsLongContent: '有時即使是輕微的事故也可能造成巨大的破壞。收入損失，延長康復和長期醫療可能會造成財務負擔。 忠意提供高度靈活的保險解決方案，具有用戶化的優勢，以滿足您的特定需求。',
  forDetailsOfTheProduct:'有關產品細節，不保事項及保費率，請參閱產品小冊子及保單條款',
  productEdges:'產品特點',
  highMedicalExpenses: '高達港幣1,000,000個人意外保障',
  hospitaliztionBenefitsInHK: '保障全面: 包括人身意外、意外醫療費用、燒傷保障及其他保障',
  twelveMonthsConsecutiveCover: '夫婦同時投保可享有九折優惠 (家庭計劃)',
  optionalCoverForAccompanied: '保單續保時，保額自動增值',
  zeroExcess: '提供額外家居意外保障',

  planType: '計劃類型',
  thisFieldCannotBeBlank: '必須填寫空格',
  plan1: '計劃一',
  plan2: '計劃二',
  plan3: '計劃三',
  tailorMadePlan: '自訂計劃',

  coverType: '保障類型',
  individualOrWithSpouse: '個人或個人及配偶',
  individual: '個人',
  withSpouse: '家庭',
  individualNChildren: '個人及子女',
  groupOfIndividuals: '多人',

  insuredOccupation: '受保人職業',
  spouseOccupation: '配偶職業',

  occupationOfTheInsured: '受保人職業',
  occupationOfTheSpouse: '配偶職業',
  occupationOfThe: '受保職業',

  numberOfChildren: '兒童人數',
  numberOfIndividuals: '成人人數',

  yes:'是',
  no:'否',

  promotionCodes: '優惠代碼',
  optional: '非必填',
  businessEmailAddress: '商業電郵 / 會員',
  businessEmailMembership: '商業電郵 / 會員',

  iWouldLikeToBuy: '我想投保',
  individual:'個人',
  individualAndSpouse:'個人及配偶',
  occupationOfTheInsured:'受保人職業',
  levyIsNotIncluded: '不包括保費徵費及有關費用',
  buyNow: '立即購買',

  theInsuranceIsIssuedByContent:'此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。',
  pleaseInputInEnglish: '* 請以英文輸入 *',
  productType: '產品類別',
  individual:'個人',
  effectiveDate: '生效日期',
  policyholder: '保單持有人',
  name: '姓名',
  dateOfBirth:'出生日期',
  title:'稱謂',
  firstName:'名字',
  lastName: '姓',
  hkid:'香港身份證',
  a1234567:'A1234567',
  pleaseEnterAllHKID:'請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。',
  dateOfBirth:'出生日期',
  pleasePickADate:'請選擇日期',
  mobileNumber:'手機號碼',
  correspondenceAddress:'通訊地址',
  emailAddress: '電郵地址',
  policyholderIs:'投保人',
  theInsured:'也是受保人',
  oneOfTheInsureds: '也是受保人之一',
  insured:'受保人',
  name: '姓名',
  sex:'性別',
  pleaseChoose:'請選擇',
  male:'男',
  female:'女',
  rightHanded:'右撇子',
  yes:'是',
  no:'否',
  occupation:'職業',
  otheroccupation:'如職業未能獲取，則在此填入職業 ',
  questions:'問題',
  haveYourOrOtherCoveredMembersAPPOflife:'閣下或其他受保家庭成員有否巳投保意外、疾病、傷殘、醫療或人壽保險被拒保、延擱或撤銷或曾持有該種保險或證書，而於事後曾被修正、增加保費、取消、或被拒絕續保？如有，請註明保險公司、保障項目、投保額、原因、現狀等',
  busniesstraveller:'閣下或其他受保家庭成員是否須於海外工幹？如是，請列明往何國家及每年外遊次數。',
  busniesstravellerIPA4:'閣下或其他受保家庭成員是否須於海外工幹？',
  isselfemployed:'閣下是否自僱人士？',
  manualUnderwritingsIsRequired:'* 您的申請需要核保 *',
  insurer:'保險公司',
  benefit:'保障',
  reason:'理由',
  condition:'情況',
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:'閣下或其他受保家庭成員之身體或四肢有無任何殘缺？如有，請註明殘缺部位或病徵等。',
  sufferedArea:'殘疾部位',
  diagnosis:'診斷',
  specialRequests:'特別要求',
  referral: '轉介',
  optional: '非必填',
  buyNow:'立即購買',

  importantNote:'重要聲明',
  policyholderAndInsuredsHaveValidHKID:'保單持有人及受保人必須持有有效的香港身份證，並在香港居住。',
  premiumChargedWillBeBasedOnTheInsured:'受保人之保費將按照閣下「職業類別」中之職業釐訂。',
  ifACoupleAppliesTogether:'如夫婦同時投保，每名子女均可獲贈投保人保障項目A1 & A2 百分之二十之保障額及保障項目A3、A5、A6、A7 全額保障。保障項目B 暫時完全傷殘及C 雙倍賠償則不適用。保障項目A1 意外死亡及永久傷殘受保額最高為港幣$200,000。',
  beneficiaryWillbeTheLegalEstateOfTheInsured:'根據香港法例，受益人將是受保人的法定繼承人。',
  assicurazioniGeneraliSpAReserves:'忠意保險有限公司　(香港分行)　保留接受或拒絕投保申請的權利。',
  iWECerrifyNotesOfIPAPI:'我 / 我們證明我/我們已閱讀並同意人身意外保險計劃的重要聲明。',
  iWeCertifyDisclaimerAndPICS:'我 / 我們證明我/我們已閱讀並同意免責聲明和收集個人資料聲明。',
  iWeHerebyAuthorizedAnyLicensed:'我/我們特此授權任何有註冊的醫生、醫院、診所或任何有關之醫療設施，保險公司或任何熟悉我/我們健康情況之組織或人士，將我/ 我們過往之病狀或病歷詳細資料提供予貴公司或貴公司之代表以作承保和索賠。我 / 我們還授權所有上述來源向貴公司僱用的任何機構提供此類記錄或知識，以收集和傳輸此類信息。',
  premium:'保費',
  levy:'徵費',
  total:'合共',
  confirm:'確認',
  submit:'提交',

  insuranceAdvisorMobile: '保險顧問電話號碼',
  periodOfInsurance: '保障期限',
  bothDatesInclusive: '(包含開始日及結束日)',

  spouse: '配偶',
  child: '子女',
  addAChild: '新增子女',
  ndChild:'子女 2',
  fullTimeStudent: '全職學生，且未婚與尚未工作',
  phone: '電話',
  email: '電郵',

  iWeDoNotConsentPDto:'我/我們不同意提供個人資料被第三方作為行銷用途使用',
  iWeDoNotConsentPDToCompanyMarketing:'我/我們不同意提供個人資料給貴公司作為行銷用途',
  brokerDeclaration: '我/我們明白、確知及同意，忠意保險有限公司(香港分行)會就本人的申請而簽發的保單，向安排保單事宜的獲授權保險經紀支付佣金，並向其提供閣下之個人資料，作處理及管理該保單之用途。',
  youHave:'您有 ',
  eCashNow: 'Generali 現金券，是否要使用它？',
  pleaseWait: '請稍候',
  getInsured: '立即投保',
  payment:'付款',
  invalidPromotionCode: '無效的優惠代碼',
  addInsuranceAdvisor: '新增保險顧問',
  wouldYouLike: '您願意 ',
  toBeYourInsuranceAdvisor: ' 成為您的保險顧問嗎？',
  theAboveIsRequiredToBeAgreed: '您必須同意上述聲明才能完成購買',
  needsToBeInEnglish: '需以英文填寫',
  thankYou: '謝謝！',
  youHaveSuccessfully: '您已成功提交',
  generaliPersonalAccidentInsurancePlan: '人身意外保險計劃',
  ccccc: '申請。',
  manualUnderwriting: '您的申請需要核保，我們會在兩個工作天內回覆您。',
  youCanAlsoFindYour: '您可以在訂單歷史區域找到您的申請資訊。',
  inCaseOfAnyQuestions: '假如有任何疑問，您可透過此 APP 的「聯絡我們」功能與我們聯絡。',
  class1: '類別一',
  class2: '類別二',
  class3: '類別三',
  class4: '類別四',
  nvolveMainlyIndoorWork: '(會計師,建築師,銀行及金融職員(文職工作),文員,行政人員,家庭主婦,室內營業員,資訊科技人員,本地學生,管理及行政人員 (文職工作),宗教人員,退休人士,秘書,教師,待業人士)',
  requiringOutdoorWork: '(美容人員,私人司機,教練（游泳/羽毛球/網球）,牙醫,醫生,電子廠工人,房地產代理,工廠管工,髮型師,護士,外勤營業員,海外學生,社工)',
  ofLightManualWorks: '(教練(足球/籃球),廚師,司機 (的士/小巴/巴士),電工,輕量體力勞動但不須使用重型或危險性的工作,侍應)',
  ofExtraHazardousNature: '(操作重型機械者,中港司機,搬運工人(駕駛電單車除外),電梯及升降機技工)',
  coverageA1: '保障A1',
  coverageA2: '保障A2',
  coverageB: '保障B',
  coverageC: '保障C',
  coverageD: '保障D',
  accidentalDeathNPermanent: '意外死亡及永久完全或部份傷殘',
  sumInsured: '請輸入保額',
  accidentalMedicalExpenses: '事故醫療開銷',
  temporaryTotalDisablement: '暫時完全傷殘保障',
  doubleIndemnity: '雙倍賠償',
  brokenBonesAndBurns: '骨折及燒傷',
  check: '請檢查您的輸入一遍！',
  cancel: '取消',

  notValidEmail:'這不是有效的電郵地址',
  notANumber:'此欄位只接受數字',
  notValidHKID:'這不是有效身份証號碼',

  alert: '請注意',
  ok: '確認',
  submissionFailed: '提交失敗',
  invalidMobileNumber: '無效的保險顧問電話號碼。',
  tryAgain: '我們的系統暫時不可用，請稍後再試',
  theInsuranceIsIssued: '此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ',
  professionalInsurance: ' 安排。',

  congratulations: '恭喜',
  madeAPurchase: '你已成功購買這項產品\n',
  weWillSend: '接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱',
  anyQuestions: '\n如有任何疑問，歡迎隨時與我們聯絡，謝謝',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  forBetterCustomerExperience: '為了獲得更好的客戶體驗，您想先登入嗎？',
  forBetterCustomerExperiencelogin:'會員登入',
  forBetterCustomerExperiencregister:'註冊新會員',
  forBetterCustomerExperienceCon:'不登入繼續',
  relationship: '關聯',
  insured: '受保人',
  spouse: '夫婦',
  children: '子女',
  parent: '父母',
  parentInLaw: '配偶父母',
  employee: '僱員',

  isFulltimeStudent: '是全日制學生嗎？',
  limitedTo10: '僅限最多10人'
}
