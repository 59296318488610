import React from "react";
import {
  Text,
  Switch,
  View,
  ScrollView,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import validate from "validate.js";
import CryptoJS from "crypto-js";
import md5 from "md5";
import config from './../../config';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { host, api11, api13, busiSecKey } from './../../config/api';
import { platform } from './../../config/platform';
import { formStyles } from './../../config/styles';
import { clearEmptyStrings, hmacSha256, msgCodeDetail } from './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import Locale from './../../Locale';
import Member from './../../Member';
import TextInput from './../../components/TextField/TextField';
import Alert from './../../components/Alert/Alert';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: {},
      messages: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const getText = (language) => {
      let text = enUS;
      if (language == "zhHK") {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage((language) =>
      this.setState({
        language: language,
        text: getText(language),
      })
    );
  }

  _sendShortMessage(isSendEmail) {
    const state = clearEmptyStrings(this.state);

    let attributes = {
      mobileNumber: this.state.mobileNumber,
    };

    let constraints = {
      mobileNumber: {
        presence: { message: this.state.text.thisFieldCannotBeBlank },
        numericality: { message: this.state.text.invalidMobileNumber },
        length: { is: 8, message: this.state.text.invalidMobileNumber },
      },
    };

    const messages = validate(attributes, constraints, { fullMessages: false });

    if (messages) {
      this.setState({ messages: messages });
    } else {
      if (this.state.shortMessageSending) {
        return;
      }
      this.setState({
        messages: {},
        shortMessageSending: true,
        shortMessageSent: false,
      });
      const data = JSON.stringify({
        mobile: this.state.mobileNumber,
        isSendEmail: isSendEmail,
        timeStamp: Date.now(),
      });
      const hmac = hmacSha256(data);



      fetch(host + api11, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.msgCode == 0) {
            this.setState({
              targetPhoneNumber: this.state.mobileNumber,
              shortMessageSent: true,
              isSendEmail: isSendEmail,
              emailSent: true,
            });
          } else {
            alert(
              msgCodeDetail(responseJson.msgCode)[
              this.state.language == "enUS" ? "desc_en" : "desc_zh"
              ]
            );
          }
          this.setState({ shortMessageSending: false });
        })
        .catch((error) => {
          alert("Facing error when sending short message!");
          this.setState({ shortMessageSending: false });
        });
    }
  }

  divider(style) {
    return (
      <div style={style.orStyle}>
        <div style={style.horizontalLine}></div>
        <div style={{ flex: 1 }}>
          <span>{this.state.text.or}</span>
        </div>
        <div style={style.horizontalLine}></div>
      </div>
    );
  }

  _login() {
    if (this.state.loggingIn) {
      return;
    }
    this.setState({ loggingIn: true });
    const data = JSON.stringify({
      username: this.state.mobileNumber,
      verify_code: this.state.confirmationCode,
      timeStamp: Date.now(),
    });
    const key = CryptoJS.AES.decrypt(
      busiSecKey,
      "ACTj4a4589rxPEIW2PoE"
    ).toString(CryptoJS.enc.Utf8);
    const hmac = hmacSha256(data);
    fetch(host + api13, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "data=" + encodeURIComponent(data) + "&hmac=" + hmac,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const currentTimeStamp = Date.now();
        const hmacBody = `${responseJson.msgDesc.member.id}-${responseJson.msgDesc.member.firstName}-${responseJson.msgDesc.member.lastName}-${responseJson.msgDesc.member.email}-${responseJson.msgDesc.member.mobile}-${responseJson.msgDesc.timeStamp}`;
        const hmacHex = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(hmacBody, key));
        // console.log(`RESPONSE ${responseJson}`)
        if (responseJson.msgCode == 0 && hmacHex === responseJson.msgDesc.hmac) {
          console.log(`RESPONSE JSON ${responseJson.msgCode}`);
          const { member, theme, token } = responseJson.msgDesc;
          if (this.state.mobileNumber === member.mobile.toString()) {
            Object.assign(member, { theme });
            console.log(`member ${member}`);
            memberStorage.setMember(member, token, () => {
              Member.load().then((member) => (Member._member = member));
              this.setState({
                alert: Alert.alert(
                  this.state.text.welcome,
                  this.state.text.loggedIn,
                  [
                    {
                      text: this.state.text.ok,
                      onPress: () => {
                        if (this.props.history.location.state && this.props.history.location.state.from && this.props.history.location.state.from === 'PrivilegedClubList') {
                          this.props.history.push("/privilegedClubList")
                        } else {
                          this.props.history.push("/")
                        }
                      },
                      //onPress: () => this.props.history.push("/privilegedClubList")
                    },
                  ]
                ),
              });
            });
          } else {
            alert("Failed on logging in your account!");
            this.setState({ loggingIn: false });
          }
        } else if (responseJson.msgCode == 20) {
          this.setState({
            alert: Alert.alert(
              this.state.text.alert,
              this.state.text.pleaseRegisterFirst,
              [
                {
                  text: this.state.text.register,
                  onPress: () => this.props.history.push("/register"),
                },
              ],
              { cancelable: false }
            ),
          });
        } else {
          alert(
            "Failed on logging in your account! " +
            "Message code: " +
            responseJson.msgCode
          );
          this.setState({ loggingIn: false });
        }
      })
      .catch((error) => {
        alert("Facing error when logging in!" + "Error message: " + error);
        this.setState({ loggingIn: false });
      });
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Login | Bravo Generali</title>
        </Helmet>

        <Navbar
          title={this.state.text.login}
          backButton
          history={this.props.history}
        />
        <Grid container spacing={20} style={{ backgroundColor: "#f2f2f2" }}>
          <Hidden xsDown>
            <Grid
              item
              xs
              style={{ marginBottom: 13, marginLeft: "5%", marginRight: "5%" }}
            >
              <View style={[styles.greeting, { fontWeight: "bold" }]}>
                <Text
                  style={[
                    styles.greetingText,
                    { marginTop: "10%", fontSize: 35 },
                  ]}
                >
                  {this.state.text.login}
                </Text>
                <p></p>
                <Text style={[styles.greetingText, { fontSize: 20 }]}>
                  {this.state.text.back}
                </Text>
              </View>
            </Grid>
          </Hidden>

          <Grid
            item
            xs
            style={{
              marginTop: 30,
              marginBottom: 50,
              backgroundColor: "white",
            }}
          >
            <ScrollView style={[formStyles.form, styles.fieldset]}>
              {this.state.alert}
              <View style={[formStyles.fieldset]}>
                <Text style={formStyles.h3}>
                  {Locale.text.loginWithMobilePhone}
                </Text>
              </View>

              <View style={[formStyles.fieldset]}>
                <Text style={formStyles.h4}>
                  {this.state.text.mobileNumber}
                </Text>
                <TextInput
                  style={formStyles.input}
                  onChangeText={(text) => this.setState({ mobileNumber: text })}
                  value={this.state.mobileNumber}
                  keyboardType="numeric"
                />
                <Text style={formStyles.message}>
                  {this.state.messages.mobileNumber}
                </Text>
              </View>

              {this.state.shortMessageSent && (
                <View style={[formStyles.fieldset]}>
                  <Text style={formStyles.h3}>
                    {this.state.text.confirmationCodeSent}
                  </Text>
                  {this.state.isSendEmail == "Y" ? (
                    <Text>
                      {Locale.text.confirmationCodeSentToYourEmailAddress}
                    </Text>
                  ) : (
                    <Text>
                      {this.state.text.confirmationCodeSentTo}
                      {this.state.targetPhoneNumber}
                    </Text>
                  )}

                  <Text>{this.state.text.enterCodeBelow}</Text>
                  <TextInput
                    style={formStyles.input}
                    onChangeText={(text) =>
                      this.setState({ confirmationCode: text })
                    }
                    value={this.state.confirmationCode}
                    maxLength={6}
                    keyboardType="numeric"
                  />
                </View>
              )}
              {this.state.shortMessageSent ? (
                <View style={[formStyles.fieldset]}>
                  {this.state.loggingIn ? (
                    <View style={formStyles.submitButtonDisabled}>
                      <Text style={formStyles.submitButtonText}>
                        {this.state.text.pleaseWait}
                      </Text>
                    </View>
                  ) : (
                    <TouchableOpacity
                      style={formStyles.submitButton}
                      onPress={() => this._login()}
                    >
                      <Text style={formStyles.submitButtonText}>
                        {this.state.text.login}
                      </Text>
                    </TouchableOpacity>
                  )}
                  {this.state.isSendEmail == "Y" ? (
                    <TouchableOpacity
                      onPress={() => this._sendShortMessage("Y")}
                    >
                      <Text
                        style={[
                          styles.center,
                          { color: Member.getThemeColor() },
                        ]}
                      >
                        {Locale.text.codeByEmail}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      onPress={() => this._sendShortMessage("N")}
                    >
                      <Text
                        style={[
                          styles.center,
                          { color: Member.getThemeColor() },
                        ]}
                      >
                        {Locale.text.codeBySMS}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              ) : (
                <View style={[formStyles.fieldset, styles.fieldset]}>
                  {this.state.shortMessageSending ? (
                    <View style={formStyles.submitButtonDisabled}>
                      <Text style={formStyles.submitButtonText}>
                        {this.state.text.pleaseWait}
                      </Text>
                    </View>
                  ) : (
                    <View style={{ marginTop: "1em" }}>
                      <TouchableOpacity
                        style={formStyles.submitButton}
                        onPress={() => this._sendShortMessage("N")}
                      >
                        <Text style={formStyles.submitButtonText}>
                          {Locale.text.getLoginBySMS}
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={formStyles.submitButton}
                        onPress={() => this._sendShortMessage("Y")}
                      >
                        <Text style={formStyles.submitButtonText}>
                          {Locale.text.getLoginByEmail}
                        </Text>
                      </TouchableOpacity>
                      {this.divider(styles)}
                      <TouchableOpacity
                        style={formStyles.submitButton}
                        onPress={() => {
                          if (this.props.history.location.state && this.props.history.location.state.from && this.props.history.location.state.from === 'PrivilegedClubList') {
                            this.props.history.push("/register", { from: 'PrivilegedClubList' })
                          } else {
                            this.props.history.push("/register")
                          }
                        }}
                      >
                        <Text style={formStyles.submitButtonText}>
                          {Locale.text.register}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              )}
            </ScrollView>
          </Grid>
          <Hidden xsDown>
            <div style={{ marginRight: "5%" }} />
          </Hidden>
        </Grid>
        <Footer />
      </div>
    );
  }
}

const styles = {
  orStyle: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    fontSize: "14px",
    color: "grey",
    alignItems: "center",
  },
  horizontalLine: {
    width: "100%",
    height: "1px",
    backgroundColor: "grey",
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: "stretch",
  },
  form: {
    minHeight: 500,
    paddingTop: 100,
  },
  fieldset: {
    margin: "auto",
    minWidth: 300,
    maxWidth: 600,
    minHeight: 620,
  },
  link: {
    color: "#C50000",
  },
};
