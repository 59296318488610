import Request from './Request';

export default class HistoryAPI {
  /* 7.1 取得投保記錄列表 */
  static policies(data, onSuccess, onFail) {
    Request.post('/history/policies', data, onSuccess, onFail);
  }

  /* 7.2 取得投保記錄詳細 */
  static policy(data, onSuccess, onFail) {
    Request.post('/history/policy', data, onSuccess, onFail);
  }

  /* 7.3 取得索償記錄列表 */
  static claims(data, onSuccess, onFail) {
    Request.post('/history/claims', data, onSuccess, onFail);
  }
}
