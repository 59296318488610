export const zhHK = {
  instantClaims: '索償',
  submitAClaim: '即時提交索償',
  travelInsurance: '旅遊保險計劃',
  helperNPersonal: '僱傭與人身意外保險',
  homeInsurance: '家居保險',
  useTheMobileApp: '請使用行動應用程式',
  toSubmitAClaim: '如需提交索償，請下載我們的“BRAVO GENERALI”應用程式。',
  
  claimAlertText1: '一般險種(不包括”美國留學保險”)',
  claimAlertText2: '如需提交索償，請下載我們的“BRAVO GENERALI”應用程式。',
  claimAlertText3: '美國留學保險',
  claimAlertText4: '有關醫療的保障項目：請於以下連結提交你的索償申請',
  claimAlertText5: '其他保險產品: ',
  claimAlertText6: '如需提交索償，請下載我們的“BRAVO GENERALI”應用程式。',
  NeedToMakeClaim:'需要提出索償嗎？',
  followingLink:'提交索償，請點擊以下連結。',
  followingLinkText:'https://www.generali.com.hk/ZH_HK/claims_and_support/individual',
  claimContactText:'理賠部門聯絡電郵:',
  claimContactEmail:'genclaims_info@generali.com.hk',
  
  ok: '確認',

  // Payment Screen
  validation: '驗證',
  submit: '送出',
  validationFailed: '驗證失敗',
  recaptchaValidationFailed: 'ReCAPTCHA 驗證失敗！',
  retry: '重新驗證',
  thankYou: '謝謝您',
  submitted: '您的索償已成功送出。',
  claimReferenceNumber: '索償索引編號：',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  history: '記錄',
  instantEnrollment: '報價',
  roarUs: '聯絡我們',
  privilegedClub: '專享禮遇',
  instantClaims: '索償',
  settings: '設定',
  claimsSubmission: '索償提交',
  submission: '讓您輕鬆提交電子索償！',
}
