import React from 'react';
import { Text, View } from 'react-native';

import Locale from './../../Locale';
import { Heading2 } from './../../Form';

export default class SpecialRequestConfirmation extends React.Component {
  render() {
    const {policy} = this.props;

    if (policy.specialRequest) {
      return (
        <View>
          <Heading2>
            {Locale.text.specialRequests}
          </Heading2>

          <Text>
            {policy.specialRequest}
          </Text>
        </View>
      );
    }

    return (<View />);
  }
}
