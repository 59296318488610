import React from 'react';
import { AsyncStorage } from 'react-native';
import CryptoJS from 'crypto-js';

import { stateStorage } from './StateStorage';

export default class MemberStorage {
  setMember(member, token, callback) {
    AsyncStorage.setItem('MEMBER', CryptoJS.AES.encrypt(
      JSON.stringify(member), '37bO1SVEa8qcyrtHvjrV').toString(), () => {
      AsyncStorage.setItem('TOKEN', CryptoJS.AES.encrypt(
        JSON.stringify(token), '37bO1SVEa8qcyrtHvjrV').toString(), () => {
        callback();
      });
    });
  }

  getMember(callback) {
    AsyncStorage.getItem('MEMBER', (err, result) => {
      if (result) {
        const member = JSON.parse(CryptoJS.AES.decrypt(
          result, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
        AsyncStorage.getItem('TOKEN', (err, result) => {
          if (result) {
            const token = JSON.parse(CryptoJS.AES.decrypt(
              result, '37bO1SVEa8qcyrtHvjrV').toString(CryptoJS.enc.Utf8));
            callback(member, token);
          }
          else {
            callback(member, undefined);
          }
        });
      }
      else {
        callback(undefined, undefined);
      }
    });
  }

  removeMember(callback) {
    stateStorage.removeState();
    localStorage.setItem('preventGoBack', 'true');

    AsyncStorage.removeItem('MEMBER', (err) => {
      callback();
    });
  }
}

export const memberStorage = new MemberStorage();
