export const enUS = {
  insuranceAdvisor: 'Insurance Advisor',
  history: 'History',
  instantEnrollment: 'Instant Enrollment',
  instantClaims: 'Instant Claims',
  referralLink: 'Referral Link',
  beforeSendingTheLink: 'Before sending the link to my/our customers, I/ We have the customers’ consents to do so.',
  shareYourReferralLink: 'Share Your Referral Link',
  reminder: 'Reminder',
  youNeedToAgree: 'You need to agree the statement before you share the referral link.',
  chooseAgain: 'Choose again',
  home:'Home',
  borkermes: 'Insurance Advisor Functions.',
  welcomeborker: 'Welcome to Bravo Generali!',
}
