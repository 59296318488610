import React from 'react';
import {
  Text, ImageBackground,
  View, ScrollView, TouchableOpacity,
} from 'react-native';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { msgCodeDetail } from  './../../lib/utils';
import { memberStorage } from './../../lib/MemberStorage';
import { languageStorage } from './../../lib/LanguageStorage';
import { host, api21, busiSecKey } from './../../config/api';
import Alert from './../../components/Alert/Alert';
import Navbar from './../../components/Navbar/Navbar';
import Footer from './../../components/Footer/Footer';
import ListMenu from './../../components/ListMenu/ListMenu.js';
import Hyperlink from 'react-native-hyperlink';

export default class InstantClaimsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // broker: this.props.navigation.getParam('broker'),
  	  text: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({
        language: language,
        text: getText(language)
      }, () => {
        this.setState({
          alert:
            Alert.alertMulti(
              [{
                text: this.state.text.ok,
                onPress: () => this.props.history.push('/'),
              }],
              //this.state.text.claimAlertText1,
              //this.state.text.claimAlertText2,
              //this.state.text.claimAlertText3,
              //this.state.text.claimAlertText4,
              //this.state.text.claimAlertText5,
              //this.state.text.claimAlertText6,
              this.state.text.NeedToMakeClaim,
              this.state.text.followingLink,
              this.state.text.followingLinkText,
              this.state.text.claimContactText,
              this.state.text.claimContactEmail,
              {
                image: {
                  source: require('./Bravo Generali Download QR code.png'),
                  href: 'https://www.app-generali.com/load_app.html',
                },
              },
              {
                image: {
                  source: require('./Bravo Generali Download QR code.png'),
                  href: 'https://www.app-generali.com/load_app.html',
                },
              },
              {
                image: {
                  source: require('./Bravo Generali Download QR code.png'),
                  href: 'https://www.app-generali.com/load_app.html',
                },
              }
            )
        });
      })
    );
  }

  render() {
    return (
      <div className='App'>
        <Helmet>
          <title>Claims Submission | Bravo Generali</title>
        </Helmet>

      <Navbar
        title={this.state.text.instantClaims}
        backButton
        history={this.props.history}
      />

      { this.state.alert }

    <Grid container spacing={20} style={{backgroundColor: '#f2f2f2'}}>
        <Hidden xsDown>
          <Grid item xs style={{marginBottom:13,marginLeft:'5%',marginRight:'5%'}}>

            <View style={[styles.greeting,{fontWeight:'bold',}]}>
              <Text style={[styles.greetingText,{marginTop:'10%',fontSize: 35,}]}>{this.state.text.claimsSubmission}</Text>
                <p></p>
              <Text style={[styles.greetingText,{fontSize: 20,}]}>{this.state.text.submission}</Text>

            </View>

          </Grid>
        </Hidden>

        <Grid item xs style={{marginTop:30,marginBottom:50,}}>
          <View style={styles.container, styles.fieldset}>
          <View style={styles.jumbotron}>
            <ImageBackground source={require('./Instant-Claims.png')} style={styles.jumbotronImage}>
            </ImageBackground>
          </View>

          <View style={styles.menu}>
          <Text style={styles.jumbotronText}>{this.state.text.submitAClaim}</Text>
            <ScrollView>
              <ListMenu history={this.props.history} items={
                [
                  {
                    icon: require('./travel_insurance.png'),
                    text: this.state.text.travelInsurance,
                    targetScreen: '/travel',
                    navigationOptions: {broker: this.state.broker},
                  },
                  {
                    icon: require('./domestic_helper_insurance.png'),
                    text: this.state.text.helperNPersonal,
                    targetScreen: '/helper',
                    navigationOptions: {broker: this.state.broker},
                  },
                  {
                    icon: require('./home_insurance.png'),
                    text: this.state.text.homeInsurance,
                    targetScreen: '/home',
                    navigationOptions: {broker: this.state.broker},
                  },
                ]
              }/>
            </ScrollView>
          </View>
        </View>
        </Grid>
          <Hidden xsDown>
           <div style={{marginRight: '5%'}} />
          </Hidden>
      </Grid>
        <Footer/>
    </div>
    );
  }
}


const styles = {
  flex: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
  },
  jumbotron: {
    flex: 4,
    alignItems: 'flex-end',
  },
  jumbotronImage: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto',
  },
  jumbotronText: {
    marginLeft: 10,
    marginBottom: 10,
  },
  menu: {
    flex: 2,
  },
  fieldset: {
    margin: 'auto',
    minWidth: 300,
    maxWidth: 600,
    minHeight: 695,
  },
};
