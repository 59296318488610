import { NetworkLocked } from "@material-ui/icons"

export const enUS = {
  roarUs: 'Roar Us',
  chat: 'Chat',
  phone: 'Phone',
  please:'please call Phone +852 6682 1112',
  email: 'Email',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  history: 'History',
  instantEnrollment: 'Quote',
  roarUs: 'Roar Us',
  privilegedClub: 'Privilege Club',
  instantClaims: 'Claims',
  settings: 'Settings',
  ok: 'OK',
  contact: 'Contact Us',
  help: 'We are here to help.',
  customerServiceSupport:'Customer Service Support',
  serviceTime:'9:00 a.m. – 5:30 p.m., Monday to Friday (except public holidays)',
  hotline:'Hotline:',
  hotlineNum:'852 6682 1112',
  actualHotlineNum:'85266821112',
  wts:'Whatsapp:',
  wtsNum:'852 6682 1112',
  actualWtsNum:'66821112',
  email:'Email:',
  companyEmail:'bravo@generali.com.hk',
  NeedToMakeClaim:'Need to make a claim?',
  followingLink:'To submit a claim, please click the following link.',
  followingLinkText:'https://www.generali.com.hk/EN_US/claims_and_support/individual',
  claimContactText:'Claim contact email:',
  claimContactEmail:'genclaims_info@generali.com.hk',

}
