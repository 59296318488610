import React from 'react';
import { StyleSheet, Text, Switch, View } from 'react-native';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import Locale from './../../Locale';
import Member from './../../Member';

import { enUS } from './en-US.js';
import { zhHK } from './zh-HK.js';
import { languageStorage } from './../../lib/LanguageStorage';

export default class LegalDocumentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.choice ? this.props.choice : null,
      text: {},
    };
  }
  showTOBA() {
    window.open(this.props.planBroker.TOBA);
  }

  componentDidMount() {
    const getText = (language) => {
      let text = enUS;
      if (language == 'zhHK') {
        text = zhHK;
      }
      return text;
    };
    languageStorage.getLanguage(
      (language) => this.setState({text: getText(language),language: language}));
  }

  render() {
    const {planBroker} = this.props;


    let provisionE =  'https://www.app-generali.com/share_files/Product_29doc/29E_en.pdf';
    let provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_en.pdf';
    if(this.state.language == 'enUS')
    {
       provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_en.pdf';
       provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_en.pdf';
      }
      else
      {
        provisionE = 'https://www.app-generali.com/share_files/Product_29doc/29E_zh.pdf';
        provisionC =  'https://www.app-generali.com/share_files/Product_29doc/29C_zh.pdf';
      }

    return (
      <View style={styles.attachmentLink}>
        <Text style={{textAlign: 'left'}}>
          <Text>{Locale.text.agreed}</Text>
          {
            this.props.product &&
              <Text>{this.props.product + Locale.text.comma}</Text>
          }
          {
            !this.props.noDisclaimer &&
              <Text>
                <Text
                  style={{color: Member.getThemeColor()}}
                  onPress={() => {}}
                  
                >
                  <a href={provisionC} target="_blank">{Locale.text.disclaimer}</a>
                </Text>

                {planBroker && planBroker.TOBA ?
                  Locale.text.disclaimerComma : Locale.text.and}
              </Text>
          }
          <Text
            style={{color: Member.getThemeColor()}}
            onPress={() => {}}
          >
            <a href={provisionE} target="_blank">{Locale.text.pic}</a>
          </Text>

          {
            planBroker && planBroker.TOBA &&
              <Text>
                {Locale.text.andThe}
                <Text
                  style={{color: Member.getThemeColor()}}
                  onPress={() => this.showTOBA()}
                >
                  {Locale.text.termsOfBusinessAgreementWith}
                  {planBroker.nameEnUs}
                  {Locale.text.termsOfBusinessAgreementWith2}
                </Text>
              </Text>
          }

          {Locale.text.legalDocumentLinkPeriod}
        </Text>

        <FormControl style={{
          display: 'block',
          marginTop: 12,
          marginBottom: 12,
          marginLeft: -20,
        }}>
          <Button
            variant="contained"
            style={{marginLeft:20}}
            color={this.props.choice == 'Y' ? 'secondary' : null}
            onClick={(value) => this.props.onChange(value ? 'Y' : null)}
          >
            {Locale.text.yes}
          </Button>

          <Button
            variant="contained"
            style={{marginLeft:20}}
            color={this.props.choice == 'N' ? 'secondary' : null}
            onClick={(value) => this.props.onChange(value ? 'N' : null)}
          >
            {Locale.text.no}
          </Button>
        </FormControl>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  attachmentLink: {
    marginTop: 10,
  },
});
